export default {
  mounted() {
    if (!this.$store.getters['sipusers/filled']) this.loadSips();
  },
  methods: {
    loadSips() {
      if (!this.$can('Telefonanrufe starten')) return;
      this.$store.dispatch('sipusers/load');
    },
    findSip(sip) {
      return this.$store.getters['sipusers/find'](sip);
    },
    findSipByNummer(nummer) {
      return this.$store.getters['sipusers/findByNummer'](nummer);
    },
  },
  computed: {
    sipusers: function() {
      let stand = this.standSipUsers;
      return this.$helpers.sortArray(this.$store.getters['sipusers/getAll'],this.orderbySips);
    },
    sipusersOnline: function() {
      return this.sipusers.filter((user) => {
        return (user.online == true && !user.name.includes('AB'));
      })
    },
    standSipUsers: function() {
      let stand = this.$store.getters['sipusers/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadSips();
      return stand
    }
  },
  data() {
    return {
      orderbySips: 'did'
    }
  }
}
