export const state = () => ({
  notifications: []
})

const mutations = {
  set(state, notification) {
    while (state.notifications.length > 100) {
      delete state.notifications.splice(1, 1);
    }
    state.notifications.push(notification);
  },
}

const getters = {
  getNotifications(state) {
    return state.notifications;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
