<template>
    <span>
      <span v-if="verordnungPrefills.load">
        <vue-simple-suggest
          ref="verordnungDiagnoseList"
            :list="$helpers.copy(prefills)"
            :min-length="0"
            @suggestion-click="suggestionClicked($event)"
            :filter-by-query="true">
              <input type="text" class="d-none">
              <textarea-autosize
                ref="textarea"
                class="form-control"
                placeholder="Diagnose(n)"
                rows="1"
                :min-height="90"
                v-model="text"
                @input="showList"
                @click="showList"
                @blur.native="blurFeld()"
              />
          </vue-simple-suggest>
      </span>
      <span v-else>
        <textarea-autosize
            ref="textarea"
            class="form-control"
            placeholder="Diagnose"
            rows="1"
            :min-height="40"
            v-model="text"
          />
      </span>
    </span>
</template>
<script>
import prefills from '~/mixins/prefills';
import VueSimpleSuggest from '~/plugins/vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
  mixins: [prefills],
  components: {VueSimpleSuggest},
  props: ['value', 'fachbereich'],
  data() {
    return {
      text: this.value,
    }
  },
  computed: {
    prefills() {
      // Verordnungprefills erneuern, falls die neuen Daten noch nicht da sind
      if (this.verordnungPrefills && this.fachbereich != null && !this.verordnungPrefills[this.fachbereich]) this.$store.dispatch('prefills/loadVerordnung');
      if (this.fachbereich != null && this.verordnungPrefills[this.fachbereich]) return this.verordnungPrefills[this.fachbereich].diagnosen;
      return this.verordnungPrefills.diagnosen;
    }
  },
  methods: {
      suggestionClicked(event) {
        this.text = event;
        this.hideList();
      },
      showList() {
        this.$refs.verordnungDiagnoseList.showList()
        this.$emit('input', this.text);
      },
      hideList() {
        this.$nextTick(function() {
          this.$refs.verordnungDiagnoseList.hideList()
        })
      },
      blurFeld() {
        setTimeout(() => {
          this.hideList();
        }, 100);
      }

    },
}
</script>
