import {Helpers} from '~/plugins/helpers';
export default function ({$axios, store}) {
  $axios.onRequest(config => {
    let service = Helpers.getVar(config.url);
    if (!service.includes('http')) {
      let newhost = store.getters['services/find'](service);
      if (!newhost) {
        newhost = store.getters['services/find'](service);
      }

      config.url = config.url.replace('{'+service+'}', newhost);
    }
  })
}
