import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if (process.env.NODE_ENV == 'production') {
  setTimeout(() => {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_KEY,
      plugins: [new BugsnagPluginVue()],
      onError: function (event) {
        if ($nuxt) {
          event.setUser($nuxt.$auth.user.id, $nuxt.$auth.user.email, $nuxt.$auth.user);
          if (process.env.STAGE == 'stefanDev' || process.env.STAGE == 'webDev' || $nuxt.$auth.user.id == 'c693fce6-fa9f-4d3d-8b3d-33f9f6204ea2') $nuxt.$notifyError('Fehlermeldung', 'Es ist ein Fehler aufgetreten', event)
        }
        event.addMetadata('version', process.env.APP_VERSION);
      }
    })
    Bugsnag.releaseStage = process.env.NODE_ENV;
    Bugsnag.notifyReleaseStages = ['production'];
    Bugsnag.getPlugin('vue')
      .installVueErrorHandler(Vue)
  }, 1000)
}
