import terminfunktionen from '~/mixins/terminfunktionen';
export default {
  mixins: [terminfunktionen],
  data() {
    return {
      vonTimestamp: null,
      bisTimestamp: null
    }
  },
  computed: {
    uhrzeiten() {
      const uhrzeiten = [];
      if (!this.von) return uhrzeiten;
      let uhrzeit = this.$moment('1970/01/01 '+this.von+':00:00', 'YYYY/MM/DD HH:mm:ss');
      let counter = 1;
      this.vonTimestamp = uhrzeit.unix();

      for (let index = this.von; index <= this.bis; index = index + this.schritt) {
        const uhrzeitobj = {
          // Moment.JS Object
          uhrzeit: uhrzeit.clone(),
          // Anzeigeuhrzeit
          displaytime: uhrzeit.format('HH:mm'),
          // Minuten seit heute 00:00:00
          tagesMinuten: index * 60,
          // Minuten seit Von
          nullMinuten: (index*60 - this.von*60),
          stunde: uhrzeit.format('H'),
          minuten: uhrzeit.format('m'),
          mathematisch: index,
          // Durchgezählt
          counter: counter,
        };
        uhrzeiten.push(uhrzeitobj);
        uhrzeit = uhrzeit.add(this.schritt, 'hours');
        counter++;
      }
      this.bisTimestamp = uhrzeit.unix();
      return uhrzeiten;
    },
    kalenderHoehe() {
      return this.$store.getters['kalender/kalenderhoehe'];
    },
    loading() {
      return this.$store.getters['kalender/loading'];
    },
    leereSpalte() {
      return {
        titel: '',
        datum: null,
        filter: {
          mitarbeiter_id: [],
          termintyp: [],
          fachbereich: [],
          raum_id: [],
          materialien: [],
          fahrzeuge: [],
          patient_id: [],
          hausbesuch: null,
          teletherapie: null,
          abgesagt: null,
          stattgefunden: null
        },
        key: 0,
        show: false
      };
    }
  },
  methods: {
    speichernSpalte(spalte, refresh = true) {
      if (!spalte.titel || !spalte.datum || !spalte.object)  return;
      this.$store.commit('kalender/speichernSpalte', spalte);
      this.$emit('speichern', spalte);
      if (refresh) this.getApiData();
    },
    speichernSpalten(spalten) {
      this.$store.commit('kalender/speichernSpalten', spalten);
      this.getApiData();
    },
    resetSpalten() {
      this.$store.commit('kalender/resetSpalten');
    },
    loeschenSpalte(spalte) {
      this.$store.commit('kalender/loeschenSpalte', spalte);
    },
    getApiData() {
      this.$store.dispatch('kalender/getApiData');
    },
    topAbstand(uhrzeit) {
      if (!this.bisTimestamp || !this.vonTimestamp) return 0;
      this.uhrzeiten.length;
      if (!this.kalenderHoehe) return 0;
      // UhrzeitTop wird immer auf Heute gesetzt
      let uhrzeitTop = this.$moment('1970/01/01 '+ this.$moment(uhrzeit).format('HH:mm:ss'), 'YYYY/MM/DD HH:mm:ss').unix();
      // Termin Uhrzeit, Abstand nach oben
      let secondsTop = uhrzeitTop - this.vonTimestamp;
      // Spanne der Sekunden die angezeigt wird
      let maxSeconds = this.bisTimestamp - this.vonTimestamp;
      // Px Abstand nach oben pro Sekunde
      let pxPerSecond = this.kalenderHoehe / maxSeconds;
      // Abstand nach oben in Pixeln
      return secondsTop * pxPerSecond;
    }
  },
  mounted() {
    this.uhrzeiten;
  }
}
