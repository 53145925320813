<template>
  <div class="btn-group" role="group">
      <button class="btn btn-icon btn-outline-dark" @click="alleAendern(-7)"><ion-icon :src="require('~/assets/img/double-chevron-back-outline.svg')"></ion-icon></button>
      <button class="btn btn-icon btn-outline-dark" @click="alleAendern(-1)"><ion-icon name="chevron-back-outline"></ion-icon></button>
      <button class="btn btn-icon btn-outline-dark" @click="alleAufHeute()">Heute</button>
      <button class="btn btn-icon btn-outline-dark" @click="alleAufAktuelleKW()">Aktuelle Woche</button>
      <button class="btn btn-icon btn-outline-dark" @click="alleAendern(1)"><ion-icon name="chevron-forward-outline"></ion-icon></button>
      <button class="btn btn-icon btn-outline-dark" @click="alleAendern(7)"><ion-icon :src="require('~/assets/img/double-chevron-forward-outline.svg')"></ion-icon></button>
  </div>
</template>
<script>
import kalender from '~/mixins/kalender';
export default {
  mixins: [kalender],
  methods: {
    alleAufHeute() {
      let alleSpalten = this.$helpers.copy(this.spalten);
      alleSpalten.forEach(spalte => {
        spalte.datum = this.$moment().format('YYYY-MM-DD');
        spalte.key++;
      })
      this.speichernSpalten(alleSpalten);
    },
    alleAufAktuelleKW() {
      let alleSpalten = this.$helpers.copy(this.spalten);
      let index = 0;
      alleSpalten.forEach(spalte => {
        spalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        spalte.key++;
        index++;
      })
      this.speichernSpalten(alleSpalten);
    },
    alleAendern(wert) {
      let alleSpalten = this.$helpers.copy(this.spalten);
      alleSpalten.forEach(spalte => {
        spalte.datum = this.$moment(spalte.datum).add(wert, 'days').format('YYYY-MM-DD');
        spalte.key++;
      })
      this.speichernSpalten(alleSpalten);
    }
  }
}
</script>
