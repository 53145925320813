import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'aktivitaetsverlauf',
  titel: 'Aktivitätsverlauf',
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  reset: storeHelper.reset,
}
const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
