<template>
  <div class="row">
      <div class="col-3 my-auto">
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '30'}" tabindex="-1" @click="update('30')">30</button>
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '45'}" tabindex="-1" @click="update('45')">45</button>
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '60'}" tabindex="-1" @click="update('60')">60</button>
      </div>
      <span class="col">
          <datalist :id="'therapiedauer'+_uid"><option :value="bez" v-for="bez in verordnungPrefills.therapiedauern" :key="bez" /></datalist>
          <the-mask :list="'therapiedauer'+_uid" @input="update($event)" @update="update($event)"  mask="###" class="form-control" placeholder="Therapiedauer in Minuten" id="therapiedauer" v-model="text" />
      </span>
  </div>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value'],
  data() {
    return {
      text: this.value
    }
  },
  methods: {
    update(neu) {
      this.text = neu;
      this.$emit('input', neu);
      this.$emit('change', neu);
    }
  }
}
</script>
