<template>
  <div>
    <div class="row">
      <div class="col">
        Model Select
      </div>
      <div class="col">
        Model
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      zuordnungen: this.value
    }
  },
  watch: {
    value(neu) {
      this.zuordnungen = neu;
    }
  }
}
</script>
