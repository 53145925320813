<template>
  <div>
    <button v-b-modal="id" class="btn btn-outline-primary">Termin erstellen</button>
    <b-modal :id="id" :title="'Termin '+(bearbeiten ? 'bearbeiten' : 'erstellen')" hide-footer size="lg" v-if="termin" class="termin-erstellen-modal" @show="show()" @hidden="emptyTermin()">
      <legend>Terminart</legend>
      <div class="row form-group">
        <div class="col"><button :class="{active: termin.termintyp == 1}" @click="termin.termintyp = 1" class="btn btn-outline-primary btn-block btn-sm">Therapie</button></div>
        <div class="col"><button :class="{active: termin.termintyp == 2}" @click="termin.termintyp = 2" class="btn btn-outline-primary btn-block btn-sm">Sonstiges</button></div>
        <!-- <div class="col"><button disabled class="btn btn-outline-primary btn-block btn-sm">Anfahrt</button></div> -->
      </div>
      <form @submit.prevent="speichern()">

        <div class="row form-group" v-if="$can('Therapie verwalten')">
            <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Therapeut</label>
            <div class="col">
                <therapeut-auswahl multiple v-model="termin.mitarbeiter_id" @selected="updateTherapeut($event)"></therapeut-auswahl>
                <div v-if="showError && (!termin.mitarbeiter_id || !termin.mitarbeiter_id.length)" class="alert alert-danger">Bitte gib einen Mitarbeiter ein</div>
            </div>
        </div>

        <template v-if="termin.termintyp == 1">
          <legend>Patienteninfos</legend>
          <div class="row form-group">
              <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Patient</label>
              <div class="col">
                  <patient-auswahl :disabled="(termin.created_at) ? true : false" multiple v-model="termin.patient_id" @input="updatePatient()"></patient-auswahl>
              </div>
          </div>
          <div class="row form-group">
              <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Fachbereich</label>
              <div class="col">
                  <button type="button" class="btn btn-block btn-sm btn-outline-logo" :class="{active: termin.fachbereich == 0}" @click="updateFachbereich(0)">Logopädie</button>
              </div>
              <div class="col">
                  <button type="button" class="btn btn-block btn-sm btn-outline-ergo" :class="{active: termin.fachbereich == 1}" @click="updateFachbereich(1)">Ergotherapie</button>
              </div>
          </div>
          <div class="row form-group" v-if="$can('Therapie verwalten') && termin.patient_id && termin.patient_id.length == 1">
              <label class="col-2 col-form-label" :for="'verordnung'+id">Verordnung</label>
              <div class="col">
                  <verordnung-auswahl :isinvalid="(showError && !termin.verordnung_id)" :patient="termin.patient_id[0]" :id="'verordnung'+id" :fachbereich="termin.fachbereich" v-model="termin.verordnung_id" @element="verordnungAuswahl($event)"></verordnung-auswahl>
              </div>
          </div>
        </template>


        <legend>Zeit und Ort</legend>
        <von-bis-dauer
            :von="termin.von"
            :bis="termin.bis"
            @von="termin.von = $event"
            @bis="termin.bis = $event"
            @invalid="invalid=$event"
          />

        <!-- <div class="row form-group">
          <label :for="'termin_von'+id" class="col-2 col-form-label mt-auto">Von</label>
          <div class="col">
            <input type="datetime-local" name="" :id="'termin_von'+id" v-model="termin.von" class="form-control" :class="{'is-invalid': showError && !termin.von || termin.von >= termin.bis}" @change="bisBerechnen()">
          </div>
        </div>
        <div class="row form-group">
          <label :for="'termin_bis'+id" class="col-2 col-form-label mt-auto">Bis</label>
          <div class="col">
            <input type="datetime-local" name="" :id="'termin_bis'+id" v-model="termin.bis" class="form-control" :class="{'is-invalid': showError && !termin.bis || termin.von >= termin.bis}">
          </div>
        </div> -->

        <div class="row form-group">
          <label :for="'ort_auswahl'+id" class="col-2 col-form-label mt-auto">Ort</label>
          <div class="col">
            <multiselect
              v-model="termin.ort_auswahl"
              @select="updateHausbesuch($event)"
              @remove="updateHausbesuch()"
              :options="['Praxis', 'Hausbesuch', 'Heimbesuch','Anderer Ort']"
              :show-labels="false"
              :searchable="true"
              placeholder="Ort wählen"></multiselect>
          </div>
        </div>
        <template v-if="termin.ort_auswahl == 'Praxis'">
        <div class="row form-group">
          <label :for="'standort'+id" class="col-2 col-form-label mt-auto">Standort</label>
          <div class="col">
            <multiselect
              v-model="termin.standort"
              @select="updateStandort($event)"
              @remove="updateStandort($event)"
              :options="standorte"
              :show-labels="false"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Standort wählen"></multiselect>
          </div>
        </div>
        <div class="row form-group" v-if="termin.standort">
          <label :for="'raum'+id" class="col-2 col-form-label mt-auto">Raum</label>
          <div class="col">
            <multiselect
              v-model="termin.raum"
              @select="termin.raum_id = $event.id"
              @remove="termin.raum_id = null"
              :options="termin.standort.raeume"
              :show-labels="false"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Raum wählen"></multiselect>
          </div>
        </div>
        </template>
        <!-- Außer Haus Ort -->
        <div class="row form-group" v-else-if="termin.ort_auswahl">
          <label :for="'ort'+id" class="col-2 col-form-label mt-auto">Ort</label>
          <div class="col">
            <input type="text" name="" :id="'ort'+id" v-model="termin.ort" placeholder="Ort" class="form-control">
          </div>
        </div>

        <!-- Termin Neu erstellen, Serie -->
        <div class="row form-group" v-if="!bearbeiten">
          <div class="col offset-2">
            <input v-model="termin.serie" type="checkbox" name="serie_anlegen" id="serie_anlegen">
            <label for="serie_anlegen">Serientermin anlegen für</label>
            <input style="width: 70px" v-model="termin.serie_anzahl" type="number" name="serie_anzahl" id="" class="form-control inline-input"> Termine,
            alle <input style="width: 70px" v-model="termin.serie_rhythmus" type="number" name="serie_anzahl" id="" class="form-control inline-input"> Wochen
          </div>
        </div>
        <!-- Termin bearbeiten, Serie vorhanden -->
        <div class="row form-group" v-else-if="termin.seriennachfolger.length">
          <div class="col offset-2">
            <input v-model="termin.serie" type="checkbox" name="serie_bearbeiten" id="serie_bearbeiten"> <label for="serie_bearbeiten">Nachfolgende {{termin.seriennachfolger.length}} Termine auch aktualisieren.</label>
          </div>
        </div>
        <!-- Termin bearbeiten, Serie anlegen -->
        <div class="row form-group" v-else>
          <div class="col offset-2">
            <input v-model="termin.serie" type="checkbox" name="serie_anlegen" id="serie_anlegen"> <label for="serie_anlegen">Wöchentlichen Serientermin anlegen für</label> <input style="width: 70px" v-model="termin.serie_anzahl" type="number" name="serie_anzahl" id="" class="form-control inline-input"> Termine
          </div>
        </div>



        <legend>Details</legend>


        <div class="row form-group" v-if="termin.termintyp == 2">
          <label :for="'termin_titel'+id" class="col-2 col-form-label mt-auto">Titel</label>
          <div class="col">
            <input type="text" name="" :id="'termin_titel'+id" v-model="termin.titel" placeholder="Titel" class="form-control">
          </div>
        </div>

        <div class="row form-group" v-if="termin.ort_auswahl != 'Praxis'">
          <label :for="'standort'+id" class="col-2 col-form-label mt-auto">Standort</label>
          <div class="col">
            <multiselect
              v-model="termin.standort"
              @select="updateStandort($event)"
              @remove="updateStandort($event)"
              :options="standorteMaterial"
              :show-labels="false"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Standort von Material & Fahrzeugen"></multiselect>
          </div>
        </div>
        <div class="row form-group" v-if="materialien">
          <label :for="'raum'+id" class="col-2 col-form-label mt-auto">Material</label>
          <div class="col">
            <multiselect
              v-model="termin.materialien"
              :options="materialien"
              :show-labels="false"
              :searchable="true"
              track-by="id"
              label="name"
              placeholder="Kein Material"
              multiple></multiselect>
          </div>
        </div>
        <div class="row form-group" v-if="fahrzeuge">
          <label :for="'raum'+id" class="col-2 col-form-label mt-auto">Fahrzeuge</label>
          <div class="col">
            <multiselect
              v-model="termin.fahrzeuge"
              :options="fahrzeuge"
              :show-labels="false"
              :searchable="true"
              track-by="id"
              label="bezeichnung"
              placeholder="Kein Fahrzeug"
              multiple></multiselect>
          </div>
        </div>

        <div class="row form-group">
          <label :for="'termin_info'+id" class="col-2 col-form-label mt-auto">Info</label>
          <div class="col">
            <textarea-autosize
              v-if="!$isIOS()"
              class="form-control"
              placeholder="Sonstige Infos"
              v-model="termin.infos"
              rows="2"
              :min-height="35"
            />
            <textarea
              v-if="$isIOS()"
              class="form-control"
              v-model="termin.infos"
              placeholder="Sonstige Infos"
              rows="3">
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-outline-primary float-right">Speichern</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import kalenderfunktionen from '~/mixins/kalenderfunktionen';
import ressourcen from '~/mixins/ressourcen';
import Multiselect from 'vue-multiselect';
import verordnungen from '~/mixins/verordnungen';
export default {
  mixins: [kalenderfunktionen, ressourcen, verordnungen],
  components: {Multiselect},
  data() {
    return {
      id: 'termin-erstellen-'+this._uid,
      showError: false,
      termin: null,
      invalid: false
    }
  },
  computed: {
    bearbeiten() {
      if (this.termin.created_at) return true;
      return false;
    },
    materialien() {
      if (!this.termin.standort) return null;
      let standort = this.standorte.find(el => el.id == this.termin.standort_id);
      if (!standort || !standort.materialien.length) return null;
      return standort.materialien.filter(el => el.fachbereich == this.termin.fachbereich);
    },
    fahrzeuge() {
      if (!this.termin.standort) return null;
      let standort = this.standorte.find(el => el.id == this.termin.standort_id);
      if (!standort || !standort.fahrzeuge.length) return null;
      return standort.fahrzeuge;
    },
    standorteMaterial() {
      return this.standorte.filter(el => el.fahrzeuge.length || el.materialien.length);
    }
  },
  methods: {
    bisBerechnen() {
      if (!this.termin.von) return;
      let von = this.$moment(this.termin.von);
      this.termin.bis = this.$moment(this.termin.bis).year(von.year()).month(von.month()).date(von.date()).format('YYYY-MM-DDTHH:mm');
    },
    emptyTermin() {
      this.showError = false;
      this.termin = {
        id: this.$uuid.v1(),
        termintyp: 1,
        mitarbeiter_id: [this.$auth.user.model_id],
        von: this.$moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm'),
        bis: this.$moment().minutes(0).seconds(0).add(45, 'minutes').format('YYYY-MM-DDTHH:mm'),
        titel: null,
        infos: null,
        ort_auswahl: null,
        ort: null,
        standort: null,
        standort_id: null,
        raum: null,
        raum_id: null,
        materialien: null,
        fahrzeuge: null,
        patient_id: null,
        verordnung_id: null,
        hausbesuch: 0,
        fachbereich: 0,
        serie: false,
        serie_anzahl: 10,
        serie_rhythmus: 1
      };
      this.updateTherapeut(this.$auth.user.mitarbeiter);
    },
    speichern() {
      if (this.invalid) {
        this.showError = true;
        return;
      }
      if (!this.termin.mitarbeiter_id.length || !this.termin.von || !this.termin.bis ||  this.termin.von >= this.termin.bis) {
        this.showError = true;
        return false;
      }
      this.$bvModal.hide(this.id);
      let send = this.$helpers.copy(this.termin);
      delete send.raum;
      delete send.ort_auswahl;
      send.ort;
      // Therapie Termin
      if (send.termintyp == 1) {
        send.titel = null;
      }
      // Sonstiges Termin
      else if (send.termintyp == 2) {
        send.fachbereich = null;
        // send.hausbesuch = 0;
        send.patient_id = null;
        send.verordnung_id = null;
      }
      if (send.serie && send.seriennachfolger) {
        send.seriennachfolger = this.$helpers.pluck(send.seriennachfolger, 'id', 'id');
      }
      else delete send.seriennachfolger;

      this.terminSpeichern(send).then(response => {
        this.getApiData();
        this.emptyTermin();
      }, error => {
        this.$bvModal.show(this.id);
        this.$notifyError('Termin erstellen fehlgeschlagen', error);
      })
    },
    show() {
      this.$root.$emit('bv::hide::tooltip');
    },
    updateTherapeut(event) {
      if (event) {
        let therapeut = this.$istTherapeut(event[0]);
        if (therapeut.fachbereich != null) this.termin.fachbereich = therapeut.fachbereich;
      }
    },
    updateStandort(event) {
      if (!event || !event.id) this.termin.standort_id = null;
      else if (event && event.id != this.termin.standort_id) this.termin.standort_id = event.id;
      this.termin.raum_id = null;
      this.termin.raum = null;
      this.termin.materialien = null;
      this.termin.fahrzeuge = null;
    },
    updateHausbesuch(event) {
      if (event == 'Hausbesuch') {
        this.termin.hausbesuch = 1;
        this.termin.raum_id = null;
        this.termin.raum = null;
      }
      else if (event == 'Heimbesuch') {
        this.termin.hausbesuch = 2;
        this.termin.raum_id = null;
        this.termin.raum = null;
      }
      else this.termin.hausbesuch = 0;

      if (event == 'Praxis') this.termin.ort = '';
    },
    updateFachbereich(fachbereich) {
      if (fachbereich != this.termin.fachbereich) {
        this.termin.verordnung_id = null;
        this.termin.materialien = null;
      }
      this.termin.fachbereich = fachbereich;
    },
    updatePatient() {
      this.termin.verordnung_id = null;
    },
    async verordnungAuswahl(event) {
      // wenn die Verordnung eine Therapiedauer hat, dann Bis automatisch ausfüllen anhand der Dauer
      if (event.therapiedauer) this.termin.bis = this.$moment(this.termin.von).add(event.therapiedauer, 'minutes').format('YYYY-MM-DDTHH:mm');
    },
    terminBearbeiten(termin) {
      termin = this.$helpers.copy(termin);
      // Termin Mitarbeiter richtig aufbereiten
      termin.mitarbeiter_id = termin.mitarbeiter.map((el) => {return el.id});
      delete termin.mitarbeiter;
      // Termin von und bis aufbereiten
      termin.von = this.$moment(termin.von).format('YYYY-MM-DDTHH:mm');
      termin.bis = this.$moment(termin.bis).format('YYYY-MM-DDTHH:mm');
      termin.serie_anzahl = 10;
      // Termin Ort aufbereiten
      if (termin.raum_id || termin.standort_id) {
        termin.ort_auswahl = 'Praxis';
      }
      else if (termin.hausbesuch == 1) termin.ort_auswahl = 'Hausbesuch';
      else if (termin.hausbesuch == 2) termin.ort_auswahl = 'Heimbesuch';
      else if (termin.ort) termin.ort_auswahl = 'Anderer Ort';



      this.termin = termin;
      this.$bvModal.show(this.id);
    }
  },
  mounted() {
    this.emptyTermin()
    this.$nuxt.$on('kalender-termin-bearbeiten', this.terminBearbeiten);
    this.standorteAbrufen();
  },
  beforeDestroy() {
    this.$nuxt.$off('kalender-termin-bearbeiten', this.terminBearbeiten);
  }
}
</script>
