<template>
    <div>
      <div class="row form-group">
        <label :for="'termin_von'+id" class="col-2 col-form-label mt-auto">Von</label>
        <div class="col">
          <input type="datetime-local" name="" :id="'termin_von'+id" class="form-control" v-model="zeit.von" @input="updateVon()" :class="{'is-invalid': isInvalid}">
        </div>
        <div class="col-3"></div>
      </div>
      <div class="row form-group">
        <label :for="'termin_bis'+id" class="col-2 col-form-label mt-auto">Bis</label>
        <div class="col">
          <input type="datetime-local" name="" :id="'termin_bis'+id" class="form-control" v-model="zeit.bis" @click="bisChanged = true" @input="updateBis()" :class="{'is-invalid': isInvalid}">
        </div>
        <div class="col-3">
          <input type="number" class="form-control" placeholder="Dauer" step="1" v-model="zeit.dauer" @input="updateDauer()" :class="{'is-invalid': isInvalid}">
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: ['von', 'bis', 'dauer'],
  watch: {
    von(neu) {
      this.zeit.von = neu;
      this.updateVon();
    },
    bis(neu) {
      this.zeit.bis = neu;
      this.dauerBerechnen();
    },
    dauer(neu) {
      this.zeit.dauer = neu;
      this.updateDauer();
    }

  },
  data() {
    return {
      id: this._uid,
      zeit: {
        von: null,
        bis: null,
        dauer: null
      },
      bisChanged: false
    }
  },
  computed: {
    isInvalid() {
      if (this.zeit.von >= this.zeit.bis || this.zeit.dauer <= 0) {
        this.$emit('invalid', true);
        return true;
      }
      this.$emit('invalid', false);
      return false;
    }
  },
  methods: {
    updateVon() {
      if (this.bisChanged) this.dauerBerechnen();
      // Sonst Bis berechnen
      else if (this.zeit.dauer) this.bisBerechnen();
      this.$emit('von', this.zeit.von);
    },
    updateBis() {
      this.bisChanged = true;
      this.dauerBerechnen();
      this.$emit('bis', this.zeit.bis);
    },
    updateDauer() {
      this.bisBerechnen();
      this.$emit('dauer', this.zeit.dauer);
    },


    dauerBerechnen() {
      if (!this.zeit.bis || !this.zeit.von) return;
      let dauer = this.$moment(this.zeit.bis).diff(this.zeit.von) / 60 / 1000;
      this.zeit.dauer = dauer;
      this.$emit('dauer', this.zeit.dauer);
    },
    bisBerechnen() {
      if (!this.zeit.von || !this.zeit.dauer) return;
      this.zeit.bis = this.$moment(this.zeit.von).add(this.zeit.dauer, 'minutes').format('YYYY-MM-DDTHH:mm');
      this.$emit('bis', this.zeit.bis);
    },
  },
  mounted() {
    this.zeit.von = this.von;
    this.zeit.bis = this.bis;
    this.zeit.dauer = this.dauer;
    if (!this.zeit.bis) this.bisBerechnen();
    if (!this.zeit.dauer) this.dauerBerechnen();
  }
}
</script>
