<template>
<div v-if="ma">
  <arbeitszeit-formular :maProp="ma" :key="formkey" :id="'arbeitszeit-formular'+uuid" v-model="edit" :monat="arbeitsstundenMonat"></arbeitszeit-formular>
  <table class="table table-striped table-sm" :key="'arbeitszeituebersichtmitarbeiter'+arbeitsstundenkey">
    <thead>
      <th>Sync</th>
      <th>Datum</th>
      <th>Zeit</th>
      <th>Typ</th>
      <th></th>
    </thead>
    <tbody>
      <tr>
        <td colspan="2"><input type="month" min="2020-01" v-model="arbeitsstundenMonat" @input="erneuern()" class="form-control"></td>
        <td><span v-if="getStandMitarbeiter(ma.id)">Stand: {{ $helpers.datumFormatieren(getStandMitarbeiter(ma.id), 'H:mm') }}</span></td>
        <td colspan="2">
          <button class="btn btn-outline-dark btn mr-1" @click="loadArbeitsstunden(true, ma.id)">
            <span :class="{'rotating': $store.getters['arbeitsstunden/isLoading']}"><ion-icon name="refresh-outline"></ion-icon></span> Synchronisieren
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <!-- <button class="float-left btn btn-outline-primary btn-sm" @click="arbeitsstundenErneuern()"><ion-icon name="refresh-outline"></ion-icon> Tabelle neuladen</button> -->
          <button
          :disabled="!$can('Arbeitszeit verwalten') && !ma.id == $auth.user.model_id"
          v-tooltip="'Permission: Arbeitszeit verwalten'"
          class="btn-sm btn btn-outline-secondary float-right" @click="addArbeitszeit()"><ion-icon name="add-sharp"></ion-icon> Arbeitszeit</button>
        </td>
      </tr>

      <pagination :elemente="arbeitsstunden" @show="arbeitsstundenShow = $event" :anzahl="10"></pagination>
      <tr v-for="arbeitszeit in arbeitsstundenShow" :key="arbeitszeit.id" :class="{'table-danger': (arbeitszeit.deleted_at)}">
        <td><sync-status :element="arbeitszeit" :stand="getStandMitarbeiter(ma.id)" /></td>
        <td>
          {{$helpers.datumFormatieren(arbeitszeit.von, 'DD.MM.YYYY')}}
          <span v-if="arbeitszeit.nachhilfe_id" class="text-success"><br>Nachhilfe-Portal {{ arbeitszeit.nachhilfe_id }}</span>
        </td>
        <td>
          <span class="d-block">{{$helpers.datumFormatieren(arbeitszeit.von, 'HH:mm')}} - {{$helpers.datumFormatieren(arbeitszeit.bis, 'HH:mm')}}</span>
          <span class="d-block">{{arbeitszeit.dauer}} Min</span>
        </td>
        <td>
          <b>{{ arbeitszeit.trigger_type }}</b>
          <template v-if="arbeitszeit.trigger_type == 'Anfahrt' && arbeitszeit.anfahrt">
            <span class="d-block">für: {{arbeitszeit.anfahrt.trigger_type}}</span>
            <span class="d-block">
              <span v-if="arbeitszeit.anfahrt.dauer">{{arbeitszeit.anfahrt.dauer}} Min</span>
              <span v-if="arbeitszeit.anfahrt.km">{{arbeitszeit.anfahrt.km}} Km</span>
            </span>
          </template>
          <template v-if="arbeitszeit.trigger_type == 'Sonstiges' && arbeitszeit.sonstiges">
            <span class="d-block">Tätigkeit: {{arbeitszeit.sonstiges.taetigkeit}}</span>
            <span class="d-block" v-if="arbeitszeit.sonstiges.kommentar" v-html="arbeitszeit.sonstiges.kommentar"></span>
          </template>
        </td>
        <td>
          <!-- <div class="btn-group" role="group"> -->
            <button v-if="$can('Arbeitszeit verwalten') || ma.id == $auth.user.model_id"
            v-tooltip="'Permission: Arbeitszeit verwalten'"
            @click="editArbeitszeit(arbeitszeit)" class="btn btn-outline-primary btn-sm"><ion-icon name="create-sharp"></ion-icon></button>
            <button v-if="$can('Arbeitszeit verwalten') || ma.id == $auth.user.model_id" @click="arbeitszeitLoeschen(arbeitszeit)" class="btn btn-outline-danger btn-sm"><ion-icon name="trash-outline"></ion-icon></button>
          <!-- </div> -->
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import ArbeitszeitFormular from '~/components/mitarbeiter/arbeitszeit/Formular';

import arbeitsstunden from '~/mixins/arbeitsstunden';
export default {
  props: ['maProp'],
  components: {ArbeitszeitFormular},
  mixins: [arbeitsstunden],
  data() {
    return {
      formkey: 0,
      edit: null,
      ma: this.maProp,
      uuid: this._uid,
      arbeitsstundenShow: []
    }
  },
  methods: {
    editArbeitszeit(arbeitszeit) {
      this.edit = arbeitszeit;
      this.formkey++;
      this.$nextTick(function() {
        this.$bvModal.show('arbeitszeit-formular'+this.uuid);
      })
    },
    addArbeitszeit() {
      this.edit = {mitarbeiter_id: this.maProp.id};
      this.formkey++;
      this.$nextTick(function() {
        this.$bvModal.show('arbeitszeit-formular'+this.uuid);
      })
    },
    erneuern() {
      this.arbeitsstundenFilter = {mitarbeiter_id: this.ma.id, von: {'$contains': this.arbeitsstundenMonat}};
      this.arbeitsstundenAbrufen();
    }
  },
  mounted() {
    this.erneuern();
  }
}
</script>
