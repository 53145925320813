import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  setAll: storeHelperOld.setAll,
  // set: storeHelper.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled
}

const actions = {
  async load({commit, state}) {
    if (state.globals.loading) return;
    commit('loading');
    try {
      let response = await this.$axios.get('{verordnungen}/v1/stoerungsbilder');
      let zuordnungen = await this.$axios.get('{mitarbeiter}/v1/stoerungsbilder');
      let stoerungsbilder = [];
      _.forEach(response.data, (stoerungsbild) => {
        stoerungsbild.selected = Helpers.allByKey(zuordnungen.data, stoerungsbild.id, 'stoerungsbild_id');
        stoerungsbilder.push(stoerungsbild);
      })
      commit('setAll', stoerungsbilder);
    } catch (e) {
      $nuxt.$notifyError('Störungsbilder abrufen', 'Daten konnten nicht abgerufen werden.',e);
      setTimeout(() => {
        commit('loading', false);
      }, 5000)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
