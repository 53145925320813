<template>
    <span>
        <datalist :id="'bezeichnungen'+_uid"><option :value="bez" v-for="bez in $store.getters['telefonBezeichnungen/get']" :key="bez" /></datalist>
        <input @change="$emit('change', text)" @input="$emit('input', text)" :list="'bezeichnungen'+_uid" type="text" class="form-control inline-input" v-model="text" placeholder="Bezeichnung">
    </span>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      text: this.value
    }
  },
  mounted() {
    if (!this.$store.getters['telefonBezeichnungen/filled']) this.$store.dispatch('telefonBezeichnungen/load')
  }
}
</script>
