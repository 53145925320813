<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }" :anzeigen="anzeigen" :addTheta="addTheta">
      <div class="menubar">

        <button
        v-if="anzeigen('theta')"
          class="btn btn-sm btn-outline-dark"
          @click="addTheta()"
        >
          <div style="width:20px">θ</div>
        </button>
        <button
        v-if="anzeigen('tab')"
          class="btn btn-sm btn-outline-dark"
          @click="addTab()"
        >
          <div style="width:20px"><bootstrap-icon name="arrow-bar-right" /></div>
        </button>

        <button
        v-if="anzeigen('bold')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <bootstrap-icon name="type-bold" />
        </button>

        <button
        v-if="anzeigen('italic')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <bootstrap-icon name="type-italic" />
        </button>

        <button
        v-if="anzeigen('strike')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <bootstrap-icon name="type-strikethrough" />
        </button>

        <button
        v-if="anzeigen('underline')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <bootstrap-icon name="type-underline" />
        </button>

        <button
          v-if="anzeigen('code')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <bootstrap-icon name="code" />
        </button>

        <button
          v-if="anzeigen('paragraph')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <bootstrap-icon name="paragraph" />
        </button>

        <button
          v-if="anzeigen('h1')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          <bootstrap-icon name="type-h1" />
        </button>

        <button
          v-if="anzeigen('h2')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          <bootstrap-icon name="type-h2" />
        </button>

        <button
          v-if="anzeigen('h3')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          <bootstrap-icon name="type-h3" />
        </button>

        <button
          v-if="anzeigen('bullet_list')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <bootstrap-icon name="list-ul" />
        </button>

        <button
          v-if="anzeigen('ordered_lis')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <bootstrap-icon name="list-ol" />
        </button>

        <button
          v-if="anzeigen('blockquote')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <bootstrap-icon name="blockquote-left" />
        </button>


        <button
          v-if="anzeigen('code_block')"
          class="btn btn-sm btn-outline-dark"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <bootstrap-icon name="code" />
        </button>

        <button
          v-if="anzeigen('hr')"
          class="btn btn-sm btn-outline-dark"
          @click="commands.horizontal_rule"
        >
          <bootstrap-icon name="hr" />
        </button>

        <button
            v-if="anzeigen('table')"
						class="btn btn-sm btn-outline-dark"
						@click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
					>
						<bootstrap-icon name="table" />
					</button>

        <button
            v-if="anzeigen('bilder')"
						class="btn btn-sm btn-outline-dark"
						@click="bildEinfuegen()"
					>
						<bootstrap-icon name="card-image" />
					</button>

        <button
          class="btn btn-sm btn-outline-dark"
          @click="commands.undo"
        >
          <bootstrap-icon name="arrow-90deg-left" />
        </button>

        <button
          class="btn btn-sm btn-outline-dark"
          @click="commands.redo"
        >
          <bootstrap-icon name="arrow-90deg-right" />
        </button>

          <span v-if="isActive.table()" class="d-block">
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.deleteTable"
              >
                <img src="~/assets/img/editor_icons/tabelleloeschen.svg"  width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.addColumnBefore"
              >
                <img src="~/assets/img/editor_icons/linkseinfuegen.svg"  width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.addColumnAfter"
              >
                <img src="~/assets/img/editor_icons/rechtseinfuegen.svg" width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.deleteColumn"
              >
                <img src="~/assets/img/editor_icons/spalteloeschen.svg" width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.addRowBefore"
              >
                <img src="~/assets/img/editor_icons/daruebereinfuegen.svg" width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.addRowAfter"
              >
                <img src="~/assets/img/editor_icons/daruntereinfuegen.svg" width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.deleteRow"
              >
                <img src="~/assets/img/editor_icons/zeileloeschen.svg" width="20px">
              </button>
              <button
                class="btn btn-sm btn-outline-dark html-editor-button-selfmade"
                @click="commands.toggleCellMerge"
              >
                <img src="~/assets/img/editor_icons/zusammenfuehren.svg"  width="20px">
              </button>
            </span>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />

    <add-image-modal :id="id" :editor="editor" :image="image"></add-image-modal>

  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Image
} from 'tiptap-extensions'
import ImageNode from '~/plugins/HTMLEditor/ImageNode';
import AddImageModal from '~/components/elemente/AddImageModal';
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    AddImageModal
  },
  props: [
    'value', 'placeholder', 'unsichtbar', 'autoFocus', 'image'
  ],
  watch: {
    value: function(neu, alt) {
      if (neu && neu.length && this.editor.getHTML() == this.emptyContent) this.editor.setContent(neu);
    }
  },
  data() {
    return {
      id: ''+this._uid,
      emptyContent: '<p>Hier tippen...</p>',
      editor: new Editor({
        autoFocus: this.autoFocus,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
						}),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Image(),
          new ImageNode(),
        ],
        onFocus: () => {
          if (this.editor.getHTML() == this.emptyContent) {
            this.editor.setContent("<p></p>");
          }
        },
        onUpdate: ({getHTML}) => {
          let send = (getHTML() == '<p></p>') ? null : getHTML();
          this.$emit('update', send);
          this.$emit('input', send);
        },
        onBlur: () => {
          let el = this;
          setTimeout(function() {
            if(!el.editor.focused) el.$emit('blur');
          }, 200);
        }
      }),
    }
  },
  methods: {
    anzeigen(feld) {
      if (feld == 'bilder' && !this.image) return false;
      if (!this.unsichtbar) return true;
      let test = this.unsichtbar.filter(element => {
        return (element == feld);
      });
      if (test.length > 0) return false;
      return true;
    },
    addTheta() {
      const transaction = this.editor.state.tr.insertText("\u03b8");
      this.editor.view.dispatch(transaction)
      this.editor.focus();
    },
    addTab() {
      const transaction = this.editor.state.tr.insertText("     ");
      this.editor.view.dispatch(transaction)
      this.editor.focus();
    },
    bildEinfuegen() {
      this.$bvModal.show(this.id);
    }
  },
  mounted() {
    // Placeholder
    if (this.placeholder) {
      this.emptyContent = '<p>'+this.placeholder+'</p>';
      this.editor.setContent(this.emptyContent);
    }

    // Wenn Content existier
    if (!this.value) this.editor.setContent(this.emptyContent);
    else this.editor.setContent(this.value);
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
