<template>
    <div v-if="formCounter > 0">

      <!-- Vorauswahl Verordnungstyp -->
      <template v-if="verordnung.verordnungstyp === null">
        <h1 class="text-center mb-5">Verordnungstyp</h1>
        <div class="row text-center mb-5">
          <div class="col">
            <button class="btn btn-outline-primary" @click="verordnung.verordnungstyp = 0;">Heilmittelverordnung 13</button>
          </div>
          <div class="col">
            <button class="btn btn-outline-primary" @click="verordnung.verordnungstyp = 1; verordnung.fachbereich = 0; fillVonAlterVerordnung()">Zahnärztliche Heilmittelverordnung</button>
          </div>
        </div>
      </template>
      <template v-if="verordnung.fachbereich === null && verordnung.verordnungstyp !== null">
        <!-- Vorauswahl Fachbereich -->
        <h1 class="text-center mb-5">Fachbereich</h1>
        <div class="row text-center mb-5">
          <div class="col">
            <button class="btn btn-outline-logo" @click="verordnung.fachbereich = 0; fillVonAlterVerordnung()">Logopädie</button>
          </div>
          <div class="col">
            <button class="btn btn-outline-ergo" @click="verordnung.fachbereich = 1; fillVonAlterVerordnung()">Ergotherapie</button>
          </div>
        </div>
      </template>



      <!-- Abfrage Verordnungstyp und Fachbereich wenn Formular da ist -->
      <template v-if="verordnung.verordnungstyp !== null && verordnung.fachbereich !== null">
        <div class="row">
          <div class="col-3">Verordnungstyp</div>
          <div class="col">
            <input class="form-check-input" type="radio" :value="0" id="standardverordnung" required v-model="verordnung.verordnungstyp" selected>
            <label class="form-check-label mr-5" for="standardverordnung">Heilmittelverordnung 13</label>
            <input class="form-check-input" type="radio" :value="1" id="zahnarztverordnung" required v-model="verordnung.verordnungstyp">
            <label class="form-check-label mr-5" for="zahnarztverordnung">Zahnärztliche Heilmittelverordnung</label>
            <!-- <input class="form-check-input" type="radio" :value="2" id="privatverordnung" required v-model="verordnung.verordnungstyp">
            <label class="form-check-label mr-5" for="privatverordnung">Privatverordnung</label> -->
          </div>
        </div>
        <div class="row" v-if="verordnung.verordnungstyp !== null">
          <div class="col-3">Fachbereich</div>
          <div class="col">
            <input class="form-check-input" type="radio" :value="0" id="logopaedie" required v-model="verordnung.fachbereich" selected>
            <label class="form-check-label mr-5" for="logopaedie"><fachbereich-badge :fachbereich="0"></fachbereich-badge> (Stimm-, Sprech-, Sprach- und Schlucktherapie)</label>
            <template v-if="verordnung.verordnungstyp == 0">
              <input class="form-check-input" type="radio" :value="1" id="ergotherapie" required v-model="verordnung.fachbereich">
              <label class="form-check-label mr-5" for="ergotherapie"><fachbereich-badge :fachbereich="1"></fachbereich-badge></label>
            </template>
          </div>
        </div>
      </template>


      <template v-if="verordnung.verordnungstyp !== null && verordnung.fachbereich !== null">
        <button v-if="loeschenErlaubt" class="btn btn-outline-danger btn-sm" @click="loeschen()">Verordnung löschen <ion-icon name="trash-outline"></ion-icon></button>
        <formular-standardverordnung :showError="showError" @showError="showError=$event" :key="formCounter" v-if="verordnung.verordnungstyp == 0" :verordnungProp="verordnung" @speichern="speichern($event)"></formular-standardverordnung>
        <formular-zahnarztverordnung :showError="showError" @showError="showError=$event" :key="formCounter" v-if="verordnung.verordnungstyp == 1" :verordnungProp="verordnung" @speichern="speichern($event)"></formular-zahnarztverordnung>
      </template>
      <template v-if="verordnung.updated_at">
        <h3>PDF anzeigen</h3>
        <verordnungsformular-pdf :verordnung="verordnung" :id="id"></verordnungsformular-pdf>
      </template>

  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect';

import VerordnungsformularPdf from '~/components/mitarbeiter/verordnungen/VerordnungsformularPdf';
import FormularStandardverordnung from '~/components/mitarbeiter/verordnungen/FormularStandardverordnung';
import FormularZahnarztverordnung from '~/components/mitarbeiter/verordnungen/FormularZahnarztverordnung';
import verordnungformular from '~/mixins/verordnungformular';
import lokiHelper from '~/mixins/lokiHelper';
export default {
    components: { VerordnungsformularPdf, FormularStandardverordnung, FormularZahnarztverordnung},
    mixins: [verordnungformular, lokiHelper],
    props: ['value', 'id', 'verordnungenProp'],
    data() {
        return {
            formCounter: 0,
            showError: false,
            loeschenErlaubt: false
        }
    },
    methods: {
      async fillChange(neu) {
        // if (!this.verordnungen.length) return setTimeout(() =>{ return this.fillChange(neu); }, 100);
        if (neu == null) neu = { };
        // else if (neu.patient_id && !neu.updated_at) {
        //   await this.verordnungenAbrufen();
        //   // neu = this.fillVonAlterVerordnung(neu);
        // }
        // else {
        //   await this.verordnungenAbrufen();
        // }
        let verordnung = this.$helpers.copy(neu);

        if (!verordnung.id) verordnung.id = this.$uuid.v1();
        if (!verordnung.leitsymptomatik) verordnung.leitsymptomatik = {a: false, b: false, c: false, individuell: false};
        if (!verordnung.zuzahlungsbefreit || verordnung.zuzahlungsbefreit == 0)  verordnung.zuzahlungsbefreit = false;
        if (verordnung.zuzahlungsbefreit == 1) verordnung.zuzahlungsbefreit = true;

        // if (!verordnung.therapiestunden) verordnung.therapiestunden = [];
        // if (!verordnung.ausfallstunden) verordnung.ausfallstunden = [];
        // if (!verordnung.aenderungen) verordnung.aenderungen = [];
        if (!verordnung.hausbesuch) verordnung.hausbesuch = 0;
        if (verordnung.verordnungstyp == undefined) verordnung.verordnungstyp = null;
        if (verordnung.fachbereich == undefined) verordnung.fachbereich = null;

        verordnung = this.alteVerordnungAktualisieren(verordnung);
        // if (verordnung.patient_id) this.patientAuswahl(this.findPatient(verordnung.patient_id));

        // Check ob Löschen erlaubt
        if (verordnung.updated_at) {
          this.getElemente('therapiestunden', {'verordnung_id': verordnung.id}).then(stunden => {
            if (stunden.length == 0) this.loeschenErlaubt = true;
          });
        }

        this.verordnung = verordnung;
        this.showError = false;
        this.formCounter++;

        if (this.verordnungenProp && this.verordnungenProp.length) {
          this.verordnungen = this.verordnungenProp.filter(verordnung => {
            return (verordnung.fachbereich == this.verordnung.fachbereich && verordnung.verordnungstyp == this.verordnung.verordnungstyp);
          });
        }
        // Debug
          this.neuerFall
      },
      alteVerordnungAktualisieren(verordnung) {
        // Alten Verordnungen das Heilmittel ausfüllen
        if (!verordnung.heilmittel || verordnung.heilmittel.length == 0) {
          if (!verordnung.heilmittel) {
            let virtuellesHeilmittel = {heilmittel: '', menge: '', dauer: ''};
            if (verordnung.therapiedauer) virtuellesHeilmittel.dauer = verordnung.therapiedauer;
            if (verordnung.verordnungsmenge) virtuellesHeilmittel.menge = verordnung.verordnungsmenge;
            verordnung.heilmittel = [];
            verordnung.heilmittel.push(virtuellesHeilmittel);
          }
        }
        return verordnung;
      },
      async fillVonAlterVerordnung() {
        if (this.verordnungenProp && this.verordnungenProp.length) {
          this.verordnungen = this.verordnungenProp.filter(verordnung => {
            return (verordnung.fachbereich == this.verordnung.fachbereich && verordnung.verordnungstyp == this.verordnung.verordnungstyp);
          });
        }
        else {
          this.verordnungenFilter = {patient_id: this.value.patient_id, fachbereich: this.verordnung.fachbereich, verordnungstyp: this.verordnung.verordnungstyp};
          await this.verordnungenAbrufen();
        }

        let last = this.$helpers.sortArray(this.verordnungen, 'datum', 'desc')[0];
        if (last) {
          let verordnung = this.$helpers.copy(last);
          delete verordnung.id;
          delete verordnung.typ;
          delete verordnung.datum;
          delete verordnung.spaetester_behandlungsbeginn;
          delete verordnung.behandlungsabbruch;
          delete verordnung.praxiswechsel;
          delete verordnung.therapiestunden;
          delete verordnung.ausfallstunden;
          delete verordnung.aenderungen;
          delete verordnung.updated_at;
          delete verordnung.$loki;
          delete verordnung.meta;

          verordnung.fachbereich = this.verordnung.fachbereich;
          verordnung.verordnungstyp = this.verordnung.verordnungstyp;
          this.fillChange(verordnung)
          // Debug
          this.neuerFall
        }
      },
        speichern(pruefen) {
          this.verordnung.therapiefrequenz = this.therapiefrequenz(this.verordnung.therapiefrequenz);

          if (this.neuerFall.wert) {
            this.verordnung.typ = 1;
            if (!this.verordnung.praxiswechsel) this.verordnung.vorher_gelaufen = 0;
          }
          else this.verordnung.typ = 2;

          this.$emit('save', this.verordnung);
          this.$emit('input', this.verordnung);

          this.$bvModal.hide(this.id)
          this.speichernVerordnung(this.verordnung);
          if (pruefen) {
            setTimeout(() => {
              this.$bvModal.show('verordnung-pruefen'+this.verordnung.id);
            }, 5000)
          }
        },

        loeschen() {
          this.verordnungLoeschen(this.verordnung);
          this.$bvModal.hide(this.id);
        }
    },
    computed: {
        neuerFall: function() {
          let kommentar = 'Neuer Verordnungsfall, Begründung: ';
          // Letzte Verordnung nach Datum
          let alt = this.$helpers.sortArray(this.verordnungen, 'datum', 'desc');

          alt = alt.filter(element => {
            return (element.datum < this.verordnung.datum && element.fachbereich == this.verordnung.fachbereich);
          })
          let neuerFall = false;
          if (alt.length) {
            // Ausstellungsdatum älter als 6 Monate
            if (this.$moment(alt[0].datum) < this.$moment(this.verordnung.datum).subtract(6, 'months')) {neuerFall = true; kommentar += 'Mehr als 6 Monate seit Ausstellungsdatum der letzten Verordnung';}
            else {
              // Diagnosegruppe anders
              // else if (alt[0].indikationsschluessel != this.verordnung.indikationsschluessel) {
              // ICD10 ist anders
              let alt1 = null; let alt2 = null; let neu1 = null; let neu2 = null;
              if (alt[0].icd_10_code) alt1 = alt[0].icd_10_code.slice(0, 3);
              if (alt[0].icd_10_code_2) alt2 = alt[0].icd_10_code_2.slice(0, 3);
              if (this.verordnung.icd_10_code) neu1 = this.verordnung.icd_10_code.slice(0, 3);
              if (this.verordnung.icd_10_code_2) neu2 = this.verordnung.icd_10_code_2.slice(0, 3);
              let altArray = [];
              if (alt1) altArray.push(alt1);
              if (alt2) altArray.push(alt2);

              // Code nicht geändetrt
              if (!altArray.includes(neu1) && !altArray.includes(neu2)) {
                neuerFall = true; kommentar += 'ICD 10 Code(s) geändert';
              }
            }
          }
          else {
            neuerFall = true;
            kommentar += 'Keine alte Verordnung vorhanden';
          }

          return {wert: neuerFall, kommentar: (neuerFall ? kommentar : '')};
        }
    },
    mounted() {
      this.fillChange(this.value);
    },
}
</script>
