import _ from 'lodash';
import kontakte from '~/mixins/kontakte';
// import patienten from '~/mixins/patienten';
// import verordnungen from '~/mixins/verordnungen';
// import therapiestunden from '~/mixins/therapiestunden';
// import ausfallstunden from '~/mixins/ausfallstunden';
// import verordnungsaenderungen from '~/mixins/verordnungsaenderungen';
// import aerzte from '~/mixins/aerzte';
// import krankenkassen from '~/mixins/krankenkassen';
// import mitarbeiter from '~/mixins/mitarbeiter';
// import patientkasse from '~/mixins/patientkasse';
// import schreiben from '~/mixins/schreiben';
// import vorlagen from '~/mixins/schreiben-vorlagen';
export default {
  mixins: [kontakte],
  // mixins: [kontakte, patienten, verordnungen, therapiestunden, ausfallstunden, verordnungsaenderungen, aerzte,
  //   krankenkassen, mitarbeiter, patientkasse, schreiben, vorlagen],
  methods: {
    async startSockets() {
      let wartezeit = 2500;
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(3*wartezeit);
      // this.socketPatienten();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketKontakte();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketVerordnungen();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketTherapien();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketAusfallstunden();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketVerordnungsaenderungen();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketAerzte();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketKrankenkassen();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketMitarbeiter();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketSchreibenVorlagen();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketFaqs();
      // if (process.env.NODE_ENV == 'development') await this.$helpers.wait(wartezeit);
      // this.socketSchreiben();
      // if (process.env.NODE_ENV == 'development') this.$notifySync('Socketverbindungen aufgebaut');
    },
    socketPatienten() {
      if (!this.$can('Patienten verwalten')) return;
      let channelname = 'patient.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setPatient(data);
      });
    },
    async setPatient(event) {
      this.notification(event, 'Patient')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('patienten/set', element);
    },

    socketKontakte() {
      let channelname = 'kontakt.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setKontakt(data);
      });
    },
    async setKontakt(event) {
      this.notification(event, 'Kontakt')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('kontakte/set', element);
    },

    socketVerordnungen() {
      let channelname = 'verordnung.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setVerordnung(data);
      });
    },
    async setVerordnung(event) {
      this.notification(event, 'Verordnung')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('verordnungen/set', element);
    },

    socketTherapien() {
      let channelname = 'therapiestunde.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setTherapie(data);
      });
    },
    async setTherapie(event) {
      this.notification(event, 'Therapie')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('therapiestunden/set', element);
    },

    socketAusfallstunden() {
      let channelname = 'ausfallstunde.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setAusfallstunde(data);
      });
    },
    async setAusfallstunde(event) {
      this.notification(event, 'Ausfallstunde')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('ausfallstunden/set', element);
    },

    socketVerordnungsaenderungen() {
      let channelname = 'verordnungsaenderung.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setVerordnungsaenderung(data);
      });
    },
    async setVerordnungsaenderung(event) {
      this.notification(event, 'Verordnungsänderung')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('verordnungsaenderungen/set', element);
    },

    socketAerzte() {
      let channelname = 'arzt.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setArzt(data);
      });
    },
    async setArzt(event) {
      this.notification(event, 'Arzt')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('aerzte/set', element);
    },


    socketKrankenkassen() {
      let channelname = 'krankenkasse.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setKrankenkasse(data);
      });
    },
    async setKrankenkasse(event) {
      this.notification(event, 'Krankenkasse')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('krankenkassen/set', element);
    },


    socketMitarbeiter() {
      let channelname = 'mitarbeiter.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setMitarbeiter(data);
      });
    },
    async setMitarbeiter(event) {
      this.notification(event, 'Mitarbeiter')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('mtiarbeiter/set', element);
    },

    socketSchreibenVorlagen() {
      if (!this.$can('Schreiben erstellen')) return;
      let channelname = 'vorlage.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setSchreibenVorlage(data);
      });
    },
    async setSchreibenVorlage(event) {
      this.notification(event, 'Schreiben Vorlage')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('schreiben-vorlagen/set', element);
    },

    socketFaqs() {
      let channelname = 'faq.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setFaq(data);
      });
    },
    async setFaq(event) {
      this.notification(event, 'Faq')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('faqs/set', element);
    },


    socketSchreiben() {
      let channelname = 'schreiben.'+this.$auth.user.praxis_id;
      this.$echo.private(channelname).listenToAll((event, data) => {
        this.setSchreiben(data);
      });
    },
    async setSchreiben(event) {
      this.notification(event, 'Schreiben')
      let element = event.nachher
      element.socket_get = true;
      this.$store.commit('schreiben/set', element);
    },



    async notification(event, titel) {
      if (event.user.id == this.$auth.user.id) return;
      await this.kontaktAbrufenFilter({model_type: event.user.model_type, model_id: event.user.model_id});
      this.$notifySync(titel+' - Änderung von '+this.kontakt.vorname+' geladen');
    }
  }
}
