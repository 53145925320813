<template>
  <b-nav-item-dropdown text="Stundenkonto" right @show="wochenuebersichtAbrufen()" @hide="remove()">
      <div style="width: 400px;" class="p-2">
        <table class="table table-sm">
          <thead>
              <th colspan="2">
                  <div class="row">
                      <div class="col-2 my-auto">
                          <button @click="updateWoche(-1)" class="btn"><ion-icon name="chevron-back-outline"></ion-icon></button>
                      </div>
                      <div class="col text-center">
                          <!-- <a v-if="woche != 0" @click="heute()" class="mr-3" style="font-weight: normal;">Heute</a> -->
                          Stundenübersicht
                          <template v-if="wochenuebersicht"><br>KW {{ wochenuebersicht.kw }} {{ wochenuebersicht.von }} - {{ wochenuebersicht.bis }}</template>
                      </div>
                      <div class="col-2 my-auto">
                          <button @click="updateWoche(+1)" class="btn"><ion-icon name="chevron-forward-outline"></ion-icon></button>
                      </div>
                  </div>
              </th>
          </thead>
          <tbody>
              <template v-if="loadingWoche">
                  <tr>
                      <td class="text-center" rowspan="3" colspan="2"><img height="90px" src="https://portal.nachhilfe-yes.de/img/svg-loaders/tail-spin.svg" alt=""></td>
                  </tr>
              </template>
              <template v-else>
                  <tr>
                      <td>Vertragliche Arbeitszeit pro Woche</td>
                      <td>{{String(wochenuebersicht.wochenstundenzahl).replace('.', ',')}} h</td>
                  </tr>
                  <tr>
                      <td>Geleistete Arbeitszeit diese Woche</td>
                      <td>{{String(wochenuebersicht.stundenzahl).replace('.', ',')}} h</td>
                  </tr>
                  <tr>
                      <td>Stundenkonto Gesamt aktuell</td>
                      <td>{{String(wochenuebersicht.stundenkonto).replace('.', ',')}} h</td>
                  </tr>
              </template>
          </tbody>
            </table>
      </div>
    </b-nav-item-dropdown>
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            wochenuebersicht: {},
            loadingWoche: true,
            woche: 0,
        }
    },
    methods: {
        wochenuebersichtAbrufen() {
          if (!this.$auth.user.mitarbeiter || !this.$auth.user.mitarbeiter.nachhilfe_id) return;
            this.loadingWoche = true;
            let url = 'https://portal.nachhilfe-yes.de/api/stundenuebersicht?woche='+this.woche+'&user_id='+this.$auth.user.mitarbeiter.nachhilfe_id;
            this.$axios.get(url).then(response => {
                this.wochenuebersicht = response.data;
                this.loadingWoche = false;
                if (this.wochenuebersicht.stundenkonto+this.wochenuebersicht.wochenstundenzahl < 0) this.$emit('minus', true);
            });
        },
        updateWoche(param) {
            this.woche += param;
            this.wochenuebersichtAbrufen();
        },
        heute() {
            this.woche = 0;
            this.wochenuebersichtAbrufen();
        },

        remove() {
          this.woche = 0;
          this.wochenuebersicht = {};
        }


    },
    mounted() {
        // this.wochenuebersichtAbrufen();

    }
}
</script>
