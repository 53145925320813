<template>

</template>
<script>
export default {
  methods: {
    checkVersionApi() {
      this.$axios.get('https://portal.yes-gesundheit.de/api/version.php').then(response => {
        if (response.data == `<?php
  $data = readfile('../../metadata.json');
?>`) {
  // Keine Daten ausgeliefert, nur Lokal oder so
        console.log('Versionsabruf nicht möglich!');
        return;
        }
        else if (response.data.buildMajor > process.env.buildMajor ||
            response.data.buildMinor > process.env.buildMinor ||
            response.data.buildRevision > process.env.buildRevision) {

              this.$confirm({
                message: "Es gibt eine neuere Version des Portals. Bitte speichere woran du gerade arbeitest und lade dann den Browser neu!",
                button: {
                  no: "Weiterarbeiten",
                  yes: "Neuladen"
                },
                callback: confirm => {
                  if (confirm) {
                    location.reload(true);
                  }
                  else {
                    setTimeout(this.checkVersionApi, 300000);
                  }
                }
              })
        }
        else {
          setTimeout(this.checkVersionApi, 300000);
        }
      })
    }
  },
  mounted() {
    if (!this.$dev) this.checkVersionApi();
  }
}
</script>
