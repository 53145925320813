<template>
  <div v-if="!$nuxt.isOffline">
     <div class="container">
       <template  v-if="onlineUsers.length">
         <small>Online</small>
         <span v-for="user in onlineUsers" :key="user.id" class="badge badge-success mr-1">
           <kontakt-anzeigen :filter="{model_type:user.model_type, model_id: user.model_id}"></kontakt-anzeigen>
          </span>
       </template>
     </div>
   </div>
</template>
<script>
import kontakte from '~/mixins/kontakte';
import socketsync from '~/layouts/socketsync';
export default {
  mixins: [kontakte, socketsync],
  data() {
    return {
      onlineUsers: [],
      channelname: 'online.'+this.$auth.user.praxis_id,
      counter: 0
    }
  },
  methods: {
    loginOnlineChannel() {
      this.$echo.join(this.channelname)
        .here(users => {
          this.onlineUsers = users;
          this.startSockets();
          })
        .joining(user => {
          if (!this.$helpers.findByKey(this.onlineUsers, user.id)) this.onlineUsers.push(user)
        })
        .leaving(user => (this.onlineUsers = this.onlineUsers.filter(u => (u.id !== user.id))));
    },
    reconnect() {
      setTimeout(() => {
        if (this.onlineUsers.length == 0 || !this.$helpers.findByKey(this.onlineUsers, this.$auth.user.id, 'id')) {
          this.$echo.leave(this.channelname);
          this.loginOnlineChannel();
          this.counter++;
          // Irgendwie hats 3 mal nicht geklappt
          if (this.counter == 3) return ;
          return this.reconnect();
        }
      }, 1000)
    }
  },
  mounted() {
    // if (this.$dev) return;
    this.reconnect();
  }
}
</script>
