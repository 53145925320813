<template>
  <multiselect
    track-by="id"
    :class="{'is-invalid': isinvalid}"
    v-model="verordnungsaenderung"
    placeholder="Änderung wählen"
    :id="'aenderung'+id"
    :options="verordnungsaenderungen"
    :show-labels="false"
    :key="counter"
    @select="update($event)"
    @remove="update(null)"
  >
    <template slot="singleLabel" slot-scope="{ option }">{{ $helpers.datumFormatieren(option.datum)}}</template>
    <template slot="option" slot-scope="{ option }">{{ $helpers.datumFormatieren(option.datum)}}</template>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import verordnungsaenderungen from '~/mixins/verordnungsaenderungen';
export default {
  components: {Multiselect},
  mixins: [verordnungsaenderungen],
  props: {
    value: String,
    isinvalid: {
      default: false
    },
    verordnung: String,
    id: String
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    },
    verordnung(neu, alt) {
      this.reset();
      if (this.verordnung) this.verordnungsaenderungenFilter = {verordnung_id: this.verordnung};
      else this.verordnungsaenderungenFilter = null;
      this.verordnungsaenderungenAbrufen();
    },

  },
  data() {
    return {
      aenderung: [],
      counter: 0
    }
  },
  methods: {
    update(element) {
      if (this.aenderung != element || element == null) this.$emit('update', element);
      this.aenderung = element;
      if (!element || !element.id) return this.$emit('input', null);
      this.$emit('input', element.id);
    },
    reset() {
      this.aenderung = [];
      this.$emit('input', null);
      this.$emit('update', null);
    },
    externerInput(neu) {
      if (neu) this.aenderung = this.verordnungsaenderungAbrufen(neu);
      else this.aenderung = [];
      this.conter++;
    }
  },
  mounted() {
    if (this.verordnung) {
      this.verordnungsaenderungenFilter = {verordnung_id: this.verordnung};
    }
    this.verordnungsaenderungenAbrufen();
    this.externerInput(this.value);
  }
}
</script>
