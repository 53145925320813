<template>
<client-only>
<div>
   <b-navbar toggleable="lg" type="light" variant="light" class="bg-white shadow-sm">
     <div class="container">
        <NuxtLink class="navbar-brand" to="/">
            <img src="~/assets/img/logos/yes-gesundheit.svg" width="200px" alt="YES. Gesundheit">
        </NuxtLink>
     </div>
   </b-navbar>
    <main class="py-4">
      <notifications group="default" class="notification-wrapper" position="bottom right" width="400"></notifications>
      <router-view></router-view>
    </main>

</div>
</client-only>

</template>
<script>
import master from '~/layouts/master';
export default {
  mixins: [master],
  name: "guest"
}
</script>
