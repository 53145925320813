import heilmittelkatalog from "./heilmittelkatalog";
export default {
  mixins: [heilmittelkatalog],
  computed: {
    telefonBezeichnungen: function() {
      if (!this.$store.getters['prefills/bezeichnungenFilled']) this.$store.dispatch('prefills/loadBezeichnungen');
      return this.$store.getters['prefills/getBezeichnungen'];
    },
    kontaktBeschreibungen: function() {
      if (!this.$store.getters['prefills/kontaktBeschreibungenFilled']) this.$store.dispatch('prefills/loadKontaktBeschreibungen');
      return this.$store.getters['prefills/getKontaktBeschreibungen'];
    },
    verordnungPrefills: function() {
      if (!this.$store.getters['prefills/verordnungFilled']) this.$store.dispatch('prefills/loadVerordnung');
      return this.$store.getters['prefills/getVerordnung'];
    },
    indikationsschluesselPrefills() {
      return this.heilmittelkatalog.map(el => {
        return el.indikationsschluessel
      });
    },
    indikationsschluesselPrefillsLogo() {
      return this.heilmittelkatalog.flatMap(el => {
        return (el.therapie == 'logopaedie') ? el.indikationsschluessel : [];
      });
    },
    indikationsschluesselPrefillsErgo() {
      return this.heilmittelkatalog.flatMap(el => {
        return (el.therapie == 'ergotherapie') ? el.indikationsschluessel : [];
      });
    }
  }
}
