import suche from '~/mixins/suche';
export default {
  mixins: [suche],
  mounted() {
    if (!this.$store.getters['faqtags/filled']) this.loadFaqtags();
  },
  methods: {
    findFaqtags(id) {
      return this.$store.getters['faqtags/find'](id);
    },
    loadFaqtags() {
      this.$store.dispatch('faqtags/load');
    },
    tagsAufbereiten(tags) {
      let neu = [];
      _.forEach(tags, tag => {
        neu.push({text: tag.name, id: tag.id});
      })
      return neu;
      // return this.$helpers.pluck(tags, null, 'name');
    }
  },
  computed: {
    faqtags: function() {
      return this.$helpers.sortArray(this.$store.getters['faqtags/getAll'],this.orderFaqtagsBy);
    },
    faqtagsInput: function() {
      let tags = [];
      if (!this.tagtext || this.tagtext == '') tags = this.faqtags;
      else tags = this.suchen(this.faqtags, this.tagtext);
      return (this.tagsAufbereiten(tags));
    },
    standfaqtags: function() {
      // Tags nicht automatisch neuladen
      return this.$store.getters['faqtags/getStand'];
    }
  },
  data() {
    return {
      orderFaqtagsBy: 'name',
      tagtext: ''
    }
  }
}
