import Vue from 'vue';

let globals = {
  computed: {
    $fachbereiche() {
      return {0: 'Logopädie', 1: 'Ergotherapie'};
    },
    $arbeitstage() {
      return {
        1: 'Montag',
        2: 'Dienstag',
        3: 'Mittwoch',
        4: 'Donnerstag',
        5: 'Freitag',
        6: 'Samstag'
      }
    }
  }
}
Vue.mixin(globals);
export default globals;
