<template>
  <span v-if="!viewer" class="d-block" @click.prevent="viewer = !viewer">{{element.id}}</span>
  <json-viewer @click.prevent="" v-else :value="element"></json-viewer>
</template>
<script>
export default {
  props: ['element'],
  data() {
    return {
      viewer: false
    }
  }
}
</script>
