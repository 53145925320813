import _ from "lodash";

export default {

  mounted() {
    // window.addEventListener('beforeunload', this.detectTabClose());

    this.$store.commit('services/loading', false);
    // Loading Status leeren
    this.$store.commit('aerzte/loading', false);
    this.$store.commit('krankenkassen/loading', false);
    this.$store.commit('mitarbeiter/loading', false);
    this.$store.commit('patienten/loading', false);
    this.$store.commit('rollen/loading', false);
    this.$store.commit('schreiben-vorlagen/loading', false);
    this.$store.commit('verordnungen/loading', false);
    this.$store.commit('kontakte/loading', false);
    this.$store.commit('faqs/loading', false);
    this.$store.commit('arbeitsstunden/loading', false);
    this.$store.commit('anfahrten/loading', false);
    this.$store.commit('schreiben/loading', false);
    this.$store.commit('sipusers/loading', false);
    this.$store.commit('verordnungsaenderungen/loading', false);
    this.$store.commit('therapiestunden/loading', false);
    this.$store.commit('ausfallstunden/loading', false);


    // Bei Dev Umgebung nicht erneuern, weil problem
    if (!$nuxt.isOffline && !this.$dev) {
      // User erneuern nach Login irgendwann
      setTimeout(() => {
        this.$auth.fetchUser(null, true);
        this.$store.dispatch('services/load');
      }, 1000)
    }
    // this.storeFree();
  },
  methods: {
    // storeFree() {
    //   // Store Zwischenspeicher leeren, jede Sekunde die veralteten Werte kicken
    //   setInterval(() => {
    //     this.storeFreeAction();
    //   }, 5000);
    // },
    // storeFreeAction(force = false) {
    //   _.forEach(this.stores, (store) => {
    //     let storeObj = this.$store.state[store];
    //     if (storeObj.globals.elemente.length && storeObj.globals.zwischenstand && (storeObj.globals.zwischenstand > new Date().getTime() - 10000 || force)) {
    //       this.$store.commit(store+'/zwischenspeichern', {elemente: [], zwischenstand: null});
    //     }
    //   })
    // },
    // detectTabClose() {
    //   this.storeFreeAction(true);
    // }
  },
  data() {
    return {
      stores: ['aerzte', 'krankenkassen', 'mitarbeiter', 'patienten', 'patientkasse', 'schreiben-vorlagen', 'verordnungen',
              'kontakte', 'faqs', 'arbeitsstunden', 'anfahrten', 'schreiben', 'verordnungsaenderungen', 'therapiestunden', 'ausfallstunden']
    }
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.detectTabClose());
  }
}
