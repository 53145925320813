import _ from 'lodash';
import lokiHelper from '~/mixins/lokiHelper';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    loadKontakte(manuell = false) {
      if (!this.$auth.loggedIn) return false;
      this.$store.dispatch('kontakte/sync', manuell);
    },
    findKontakt(id) {
      return this.$helpers.findByKey(this.kontakte, id);
    },
    // modelFindKontakt(model_type, model_id) {
    //   this.standKontakte;
    //   if (!this.kontakte.length) return null;
    //   return this.kontakte.filter(el => {
    //     return (el.model_type == model_type && el.model_id == model_id);
    //   })[0];
    // },
    rufnummerFindKontakt(rufnummer) {
      return this.kontakte.filter(el => {
        return el.telefonnummern.filter(nummer => {
          return (nummer.nummer.replace(' ', '') === rufnummer);
        }).length > 0;
      })[0];
    },
    resetKontakte() {
      this.$store.commit('kontakte/reset');
      this.loadKontakte(true);
    },
    async speichernKontakt(model) {
      if (!model.telefonnummern) model.telefonnummern = [];
      this.$store.dispatch('kontakte/speichern', model);
      setTimeout(() => {
        this.loadKontakte();
      }, 1000);
    },
    async kontakteSuchen(returntype = null, model_type = null) {
      let kontakteCollection = await dbHelper.getDb('kontakte');
      let kontakte = await kontakteCollection.where((obj) => {
        // model_type = model_type.replace('App\\Models\\', '');
        if (model_type) return (this.suchenObject(obj, this.suchbegriff.split(' ')) && obj.model_type == model_type)
        return (this.suchenObject(obj, this.suchbegriff.split(' ')));
      });
      if (!returntype) return kontakte;
      if (returntype) return kontakte.map(kontakt => {return kontakt[returntype]});
    },
    async kontakteAbrufen() {
      this.getKontakte = true;
      this.registerKontakteEvent();
      if (!this.suchbegriff) {
        this.kontakte = await this.getElemente('kontakte', this.kontakteFilter);
        this.kontakteCount = this.kontakte.length;
        return;
      }
      this.kontakte = await this.kontakteSuchen();
    },
    kontakteAenderungenAbrufen() {
      this.getKontakteAenderungen = true;
      this.registerKontakteEvent();
      return this.getAenderungen('kontakte').then(data => this.kontaktAenderungen = data);
    },
    kontaktAbrufen(id) {
      this.getKontakt = id
      this.registerKontakteEvent();
      return this.getElement('kontakte', {id: id}).then(kontakt => {if (Object.keys(kontakt).length) this.kontakt = kontakt});
    },
    kontaktAbrufenFilter(filter) {
      this.registerKontakteEvent();
      return this.getElement('kontakte', filter).then(kontakt => {
        this.kontakt = kontakt
        this.getKontakt = kontakt.id
      });
    },
    registerKontakteEvent() {
      if (!this.kontaktEvent) this.kontaktEvent = this.$nuxt.$on('kontakte-sync', this.kontakteErneuern);
    },
    kontakteErneuern(event) {
      if (this.getKontakt) {
        if (event && this.getKontakt == event.id) this.kontakt = event;
        else  this.kontaktAbrufen(this.getKontakt);
      }
      if (this.getKontakte) this.kontakteAbrufen();
      if (this.getKontakteAenderungen) this.kontakteAenderungenAbrufen();
    },


    addTelefon(param = null) {
      let kontakt = (param) ? param : this.kontakt;
      if (!kontakt.telefonnummern) {
        this.$set(kontakt, 'telefonnummern', []);
        // kontakt.telefonnummern = [];
      }
      kontakt.telefonnummern.push({'bezeichnung': '', 'nummer': ''});
    },
    // downloadVCard(kontakt) {
    //   var vCardsJS = require('vcards-js');
    //   var vCard = vCardsJS();
    //   //set properties
    //   if (kontakt.vorname) vCard.firstName = kontakt.vorname;
    //   if (kontakt.nachname) vCard.lastName = kontakt.nachname;
    //   if (kontakt.firma) vCard.organization = kontakt.firma;

    //   _.forEach(kontakt.telefonnummern, (telefonnummer) => {
    //     console.log(telefonnummer);

    //   })

    //   if (kontakt.geburtsdatum) vCard.birthday = Helpers.make_date(kontakt.geburtsdatum);
    //   vCard.note = 'Download aus dem Logo YES Portal';

    //   //save to file
    //   vCard.saveToFile('./eric-nesser.vcf');
    // }
  },
  created() {
    this.standKontakte;
  },
  mounted() {
    if (!this.$store.getters['kontakte/filled']) this.loadKontakte();
    this.kontakteErneuern();
  },
  beforeDestroy() {
    this.$nuxt.$off('kontakte-sync', this.kontakteErneuern);
    this.kontakteEvent = null;
  },
  computed: {
    kontakteSelect: function() {
      let send = [];
      _.forEach(this.kontakte, (kt, i) => {
        if (!kt.vorname && !kt.nachname && !kt.firma) return;
        if (kt.firma) kt.name = kt.firma;
        else kt.name = kt.vorname + ' ' + kt.nachname;
        send.push(kt);
      })
      return send;
    },
    standKontakte: function() {
      let stand = this.$store.getters['kontakte/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadKontakte();
      return stand
    }
  },
  data() {
    return {
      kontakt: null,
      kontakte: [],
      kontakteCount: 0,
      kontakteAenderungen: [],
      getKontakt: false,
      getKontakte: false,
      getKontakteAenderungen: false,
      kontaktEvent: false,
      kontakteFilter: null,
      orderByKontakte: ['nachname', 'asc'],
    }
  }
}
