<template>

  <b-modal :id="id" size="md" title="Nummer zuordnen" hide-footer>
    <div class="row form-group">
      <div class="col-4 my-auto">
          <telefon-bezeichnung v-model="nummer.bezeichnung"></telefon-bezeichnung>
      </div>
      <div class="col-8">
        <vue-phone-number-input  v-model="nummer.nummer"
                                default-country-code="DE" :no-country-selector="true" :no-example="true" :translations="{phoneNumberLabel: 'Nummer'}" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <multiselect
            track-by="id"
            v-model="kontakt"
            placeholder="Kontakt wählen"
            :options="kontakteSelect"
            :show-labels="false"
            label="name"
            @select="nummer.kontakt_id = $event.id"
          >
          </multiselect>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <button class="btn btn-outline-primary btn-block" :disabled="!nummer.nummer || !nummer.kontakt_id" @click="zuordnungSpeichern()">Zuordnung übernehmen</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import kontakte from '~/mixins/kontakte';
import Multiselect from 'vue-multiselect';
export default {
  components: {Multiselect},
  mixins: [kontakte],
  props: ['id', 'anrufnummer'],
  data() {
    return {
      nummer: {
        bezeichnung: '',
        nummer: this.anrufnummer,
        kontakt_id: null
      },
      kontakt: []
    }
  },
  watch: {
    anrufnummer(neu) {
      this.nummer.nummer = neu;
    }
  },
  methods: {
    zuordnungSpeichern() {
      let kontakt = this.$helpers.copy(this.kontakt);
      kontakt.telefonnummern.push(this.nummer);
      this.speichernKontakt(kontakt);
      this.$bvModal.hide(this.id);
      this.$emit('done');
    }
  },
  mounted() {
    this.kontakteAbrufen();
  }
}
</script>
