import verordnungen from '~/mixins/verordnungen';
export default {
  mixins: [verordnungen],
  computed: {
    beginn() {
      if (!this.verordnung.datum) return '';
      if (this.verordnung.spaetester_behandlungsbeginn) return this.$moment(this.verordnung.spaetester_behandlungsbeginn).format('DD.MM.YYYY');
      if (this.verordnung.dringlicher_behandlungsbedarf) return this.$moment(this.verordnung.datum).add('13', 'days').format('DD.MM.YYYY')
      else return this.$moment(this.verordnung.datum).add('27', 'days').format('DD.MM.YYYY')
    },
    startindex: function() {
      if (!this.verordnung.updated_at) return 0;
      if (this.verordnung.verordnungstyp == 1) return 4;
      return 5;
    },
  },
  methods: {
    therapiefrequenz(frequenz) {
      if (!frequenz) return;
      if (frequenz.indexOf('-') == -1 && frequenz.length > 1) frequenz = frequenz.slice(0,1) + '-' + frequenz.slice(1);
      return frequenz;
    },
    // patientAuswahl(patient) {
    //   if (!this.verordnung || !this.verordnung.updated_at) return;
    //   // Zuzahlungsbefreit errechnen
    //   if (patient.kontakt.geburtsdatum && this.$helpers.alter(patient.kontakt.geburtsdatum) < 18) this.verordnung.zuzahlungsbefreit = true;
    //   else this.verordnung.zuzahlungsbefreit = false;

    //   // Alte Verordnung
    //   last = this.$helpers.sortArray(this.patientVerordnungen(this.verordnung.patient_id), 'datum', 'desc')[0];
    //   if (last) {
    //     this.verordnung.vorher_gelaufen = last.vorher_gelaufen + this.stundenAnzahl(last.therapiestunden);
    //   }
    // },
    addTelefon() {
      this.verordnung.kontakt.telefonnummern.push({'beschreibung': '', 'nummer': ''});
    },
  }
}
