export const state = () => ({
  telefonBezeichnungen: [],
  kontaktBeschreibungen: [],
  verordnung: {
    indikationsschluessel: [],
    icd_10_codes: [],
    diagnosen: [],
    therapiedauern: [],
    verordnungsmengen: [],
    therapiefrequenzen: [],
    load: false
  },
  loading: {
    verordnung: false,
    telefon: false,
    kontaktBeschreibungen: false
  }
})

const mutations = {
  setBezeichnungen(state, bezeichnungen) {
    state.telefonBezeichnungen = bezeichnungen;
    state.loading.telefon = false;
  },
  setKontaktBeschreibungen(state, beschreibungen) {
    state.kontaktBeschreibungen = beschreibungen;
    state.loading.kontaktBeschreibungen = false;
  },
  setVerordnung(state, verordnung) {
    state.verordnung = verordnung;
    state.loading.verordnung = false;
  },
  setLoading(state, el) {
    state.loading[el] = true;
  }
}

const getters = {
  getBezeichnungen: (state) => {
    return state.telefonBezeichnungen;
  },
  getKontaktBeschreibungen: (state) => {
    return state.kontaktBeschreibungen;
  },
  getVerordnung: (state) => {
    return state.verordnung;
  },
  bezeichnungenFilled: (state) => {
    return (state.telefonBezeichnungen.length) ? true : false;
  },
  kontaktBeschreibungenFilled: (state) => {
    return (state.kontaktBeschreibungen.length) ? true : false;
  },
  verordnungFilled: (state) => {
    return state.verordnung.load;
  },
}

const actions = {
  async loadBezeichnungen({commit, state}) {
    if (state.loading.telefon) return;
    commit('setLoading', 'telefon');
    let response = await this.$axios.get('{kontakte}/v1/bezeichnungen');
    commit('setBezeichnungen', response.data);
  },
  async loadKontaktBeschreibungen({commit, state}) {
    if (state.loading.kontaktBeschreibungen) return;
    commit('setLoading', 'kontaktBeschreibungen');
    let response = await this.$axios.get('{patienten}/v1/beschreibungen');
    commit('setKontaktBeschreibungen', response.data);
  },
  async loadVerordnung({commit, state}) {
    if (state.loading.verordnung) return;
    commit('setLoading', 'verordnung');
    let response = await this.$axios.get('{verordnungen}/v1/prefills');
    commit('setVerordnung', response.data);
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
