<template>
  <div class="form-row form-group">
        <div class="col-4 my-automt-auto">
          <div class="form-group">
            <multiselect
                v-model="value.heilmittel"
                placeholder="Heilmittel"
                :options="heilmittelOptions"
                :show-labels="false"
                @input="updateHeilmittel()"
              >
              </multiselect>
          </div>
        </div>
        <div class="col-2 my-auto text-right">
            <span v-if="heilmittelGewaehlt && heilmittelGewaehlt.dauer"><button :key="'dauer'+dauer" v-for="dauer in heilmittelGewaehlt.dauer" class="btn btn-outline-secondary btn-sm" :class="{active: value.dauer == dauer}" tabindex="-1" @click="value.dauer = dauer">{{ dauer }}</button></span>
        </div>
        <div class="col-1 my-auto">
          <the-mask :list="'dauer'+_uid"  mask="###" class="form-control" placeholder="Dauer" v-model="value.dauer" />
        </div>
        <div class="col-2 my-auto text-right">
          <button class="btn btn-outline-secondary btn-sm" :class="{active: value.menge == '5'}" tabindex="-1" @click="value.menge = 5">5</button>
          <button class="btn btn-outline-secondary btn-sm" :class="{active: value.menge == '10'}" tabindex="-1" @click="value.menge = 10">10</button>
          <button class="btn btn-outline-secondary btn-sm" :class="{active: value.menge == '20'}" tabindex="-1" @click="value.menge = 20">20</button>
        </div>
        <div class="col-2 my-auto">
          <the-mask type="number" @input.native="value.menge = Number($event.target.value)" :list="'verordnungsmenge'+_uid"  mask="###" class="form-control" placeholder="Menge" id="verordnungsmenge" :value="value.menge" />
        </div>
        <div class="col-1 my-auto text-right">
            <button class="btn btn-outline-danger" :disabled="!deleteable" @click="$emit('remove')"><ion-icon name="remove-outline"></ion-icon></button>
        </div>
        <div class="col-12">
          <label :for="'doppelbehandlung'+_uid"><input type="checkbox" :name="'doppelbehandlung'+_uid" :id="'doppelbehandlung'+_uid" v-model="value.doppelbehandlung"> Doppelbehandlung</label>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
export default {
  components: {Multiselect},
  mixins: [heilmittelkatalog],
  props: ['value', 'deleteable', 'fachbereich'],
  data() {
    return {
      heilmittelGewaehlt: null
    }
  },
  computed: {
    heilmittelOptions() {
      let heilmittelVerfuegbar = this.verfuegbare_heilmittel.filter(hm => {
        if (!this.fachbereich || this.fachbereich == 0) return hm.therapie == 'logopaedie';
        else return hm.therapie == 'ergotherapie';
      })
      return heilmittelVerfuegbar.map(hm => {
        return hm.name;
      })
    },
  },
  methods: {
    updateHeilmittel() {
      this.heilmittelGewaehlt = this.findHeilmittelByString(this.value.heilmittel)
      // Wenn lt Heilmittelkatalog nur eine Dauer erlaubt ist, dann direkt die einblenden
      if (this.heilmittelGewaehlt && this.heilmittelGewaehlt.dauer && this.heilmittelGewaehlt.dauer.length == 1) this.value.dauer = this.heilmittelGewaehlt.dauer[0];
    },
  },
  mounted() {
    this.updateHeilmittel();
  }
}
</script>
