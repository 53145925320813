<template v-if="element && element.id">
  <span v-if="synced" class="text-success">
    <ion-icon :id="''+id+element.id" name="checkmark-circle"></ion-icon>
    <b-tooltip :target="''+id+element.id" :variant="'success'">
      <b>Letzte Änderung</b><br>
      {{ $helpers.datumFormatieren(element.updated_at, 'DD.MM.Y HH:mm:ss') }}
    </b-tooltip>
  </span>
  <span v-else-if="element.socket" class="text-info">
    <ion-icon :id="''+id+element.id" name="checkmark-circle"></ion-icon>
    <b-tooltip :target="''+id+element.id" :variant="'success'">
      <b>Live aktualisiert</b><br>
      {{ $helpers.datumFormatieren(element.updated_at, 'DD.MM.Y HH:mm:ss') }}
    </b-tooltip>
  </span>
  <span v-else>
    <span v-if="element.fehler" class="text-danger" :id="''+id+element.id">
      <ion-icon name="alert-circle"></ion-icon>
      <b-tooltip :target="''+id+element.id" :variant="'danger'">
        <b>Error {{ element.fehler.code }}</b> <small>{{ $helpers.datumFormatieren(element.fehler.date, 'DD.MM.Y HH:mm:ss') }}</small><br >
        {{ element.fehler.message }}<br >
        Letzte Änderung:<br>{{ $helpers.datumFormatieren(element.updated_at, 'DD.MM.Y HH:mm:ss') }}
      </b-tooltip>
    </span>
    <span v-else :id="''+id+element.id"  class="text-warning"><ion-icon name="sync-circle"></ion-icon>
      <b-tooltip :target="''+id+element.id" :variant="'warning'">
        <b>Letzte Änderung</b><br>
        {{ $helpers.datumFormatieren(element.updated_at, 'DD.MM.Y HH:mm:ss') }}
      </b-tooltip>
    </span>
  </span>

</template>
<script>
export default {
  props: ['element', 'stand'],
  data() {
    return {
      id: this._uid,
    }
  },
  computed: {
    synced() {
      if (this.element.fehler) return false;
      let stand = this.$helpers.make_date(this.stand);
      let element = this.$helpers.make_date(this.element.updated_at);
      if (stand >= element && !this.element.created_at) {
        this.element.fehler = {code: 999, date: new Date(), message: "Bitte nochmal synchronisieren!"};
        return false;
      }

      // synced
      if (stand >= element) return true;
      // Neuer
      return false;
    }
  }
}
</script>
