import VueSimpleSuggest from 'vue-simple-suggest/lib/index';

export default {
  extends: VueSimpleSuggest,
  async mounted () {
    await this.$slots.default;

    this.$nextTick(() => {
      this.inputElement = this.$refs['inputSlot'].querySelector('textarea')

      if (this.inputElement) {
        this.setInputAriaAttributes()
        this.prepareEventHandlers(true)
      } else {
        console.error('No input element found 23')
      }
    })
  },
  methods: {
    displayProperty (obj) {
        if (this.isPlainSuggestion) {
          return obj
        }

        let display = this.getPropertyByAttribute(obj, this.displayAttribute);

        if (typeof display === 'undefined') {
          display = JSON.stringify(obj)
        }
    },
    setInputAriaAttributes () {
      this.$nextTick(function() {
        this.inputElement.setAttribute('aria-activedescendant', '')
        this.inputElement.setAttribute('aria-autocomplete', 'list')
        this.inputElement.setAttribute('aria-controls', this.listId)
      })
    },
    prepareEventHandlers(enable) {
      this.$nextTick(function() {
        VueSimpleSuggest.methods.prepareEventHandlers.call(this, enable);
      })
    }
  },
  computed: {
    textLength() {
      return this.text && this.text.length || (this.inputElement && this.inputElement.value ? this.inputElement.value.length : false) || 0;
    },
  }
}
