import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'patientkasse',
  titel: 'Patienten Versicherungen',
  syncurl: '{krankenkassen}/v1/patientkasse',
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  set: storeHelper.set,
  stand: storeHelper.stand,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled
}

const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern,
  async sync(context, manuell = false) {
    await storeHelper.sync(context, manuell);
  },
  loeschen: storeHelper.loeschen
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
