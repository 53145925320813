<template>
  <div class="kalender-spalte kalender-tag" :key="editSpalte.key+'-'+uhrzeiten.length">
    <kalenderspalte-formular :spalte="spalte" :target="elementid"></kalenderspalte-formular>

      <div :id="elementid" class="kalender-spaltentitel" @click="show()">
        <div>
          <div class="kalender-spalte-beschriftung">{{ editSpalte.titel }}</div>
          <div class="kalender-spalte-datum">{{ $moment(editSpalte.datum).format('dddd') }}, {{ $moment(editSpalte.datum).format('DD.MM.YYYY') }}</div>
        </div>
        <div class="kalender-spalte-options my-auto">
          <ion-icon name="options-outline"></ion-icon>
        </div>
      </div>

      <div class="kalender-termine" v-if="istFeiertag">
        <div class="termin-feiertag termin d-flex flex-column justify-content-between text-center"><b>{{ istFeiertag }}</b></div>
      </div>
      <kalender-termine :spalte="spalte"></kalender-termine>
      <!-- <div class="kalender-box text-dark" :class="{anwesend: anwesend(zeit)}" v-for="(zeit, index) in uhrzeiten" :key="index" v-html="anwesendOrt(zeit)">&nbsp;</div> -->
      <div class="kalender-box text-dark" :class="{abwesend: !anwesend(zeit).anwesend}" v-for="(zeit, index) in uhrzeiten" :key="index" :style="{'background-color': (anwesend(zeit).anwesend) ? anwesend(zeit).color+'20' : ''}">
        <template v-if="index < uhrzeiten.length -1">
          <div class="arbeitszeit-standort-beschriftung" v-if="anwesend(uhrzeiten[index+1]).anwesend && anwesend(uhrzeiten[index+1]).erste_zeile">{{$helpers.truncate(anwesend(uhrzeiten[index+1]).standort, 15)}} {{anwesend(uhrzeiten[index+1]).von }} - {{anwesend(uhrzeiten[index+1]).bis}}</div>
        </template>
        <template v-else>&nbsp;</template>
      </div>


  </div>
</template>
<script>
import KalenderspalteFormular from '~/components/kalender/KalenderspalteFormular';
import KalenderTermine from '~/components/kalender/KalenderTermine';
import kalender from '~/mixins/kalender';
export default {
  props: ['spalte'],
  mixins: [kalender],
  components: {KalenderspalteFormular, KalenderTermine},
  data() {
    return {
      elementid: 'kalender-spaltentitel-'+this._uid,
      editSpalte: this.$helpers.copy(this.spalte)
    }
  },
  computed: {
    istFeiertag() {
      if (!this.spaltenData || !this.spaltenData.spalten[this.spalte.id] || !this.spaltenData.spalten[this.spalte.id].feiertag) return false;
      return this.spaltenData.spalten[this.spalte.id].feiertag;
    }
  },
  methods: {
    show() {
      this.$nuxt.$emit('bv::hide::tooltip')
      this.editSpalte.show = !this.editSpalte.show
      this.editSpalte.key++;
      this.speichernSpalte(this.editSpalte, false);
    },
    anwesend(zeit) {
      if (!this.spaltenData || !this.spaltenData.spalten[this.spalte.id]) return {anwesend: true};
      if (!this.spaltenData.spalten[this.spalte.id].arbeitszeiten.length) return {anwesend: false};
      let arbeitszeiten = this.spaltenData.spalten[this.spalte.id].arbeitszeiten;
      let ret = {anwesend: false};

      arbeitszeiten.forEach(az => {
        if (zeit.uhrzeit.isBetween('1970-01-01 '+az.von, '1970-01-01 '+az.bis, undefined, '[)')) {
          ret = {anwesend: true, von: az.von, bis: az.bis};
          if (az.standort) {
            if (az.standort.color) ret.color = az.standort.color;
            ret.standort = az.standort.name;
          }
          else ret.color = "#FFF";


          if (az.von == zeit.displaytime) {
            ret.erste_zeile = true;
          }
        }
      });

      return ret;
    },
    anwesendOrt(zeit) {
      if (!this.spaltenData || !this.spaltenData.spalten[this.spalte.id] || !this.spaltenData.spalten[this.spalte.id].arbeitszeiten.length) return "&nbsp;";
      let arbeitszeiten = this.spaltenData.spalten[this.spalte.id].arbeitszeiten;
      let ret = "&nbsp;";
      arbeitszeiten.forEach(az => {
        if (!az.standort_id || !az.standort || !az.standort.name) return;
        if (this.$moment(az.von).format('HH:mm') == zeit.uhrzeit.format('HH:mm')) ret = az.standort.name;
      });
      return ret;
    }
  },
}
</script>
