<template>
    <span>
        <datalist :id="'bezeichnungen'+_uid"><option :value="bez" v-for="bez in telefonBezeichnungen" :key="bez" /></datalist>
        <input @change="$emit('change', text)" @input="$emit('input', text)" :list="'bezeichnungen'+_uid" type="text" class="form-control inline-input" v-model="text" placeholder="Bezeichnung">
    </span>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value'],
  data() {
    return {
      text: this.value
    }
  }
}
</script>
