<template>
  <div  v-if="ptkt.beschreibung && (!ptkt.deleted_at || $trashedMode)" class="boxed" :class="{'bg-danger': ptkt.deleted_at}">
    <div class="row" v-if="ptkt.beschreibung">
      <div class="col">Beschreibung</div>
      <div class="col">{{ ptkt.beschreibung }}</div>
    </div>
    <template v-if="kontakt">
      <div class="row" v-if="kontakt.firma">
        <div class="col">Organisation</div>
        <div class="col">{{kontakt.firma}}</div>
      </div>
      <div class="row">
        <div class="col">Name</div>
        <div class="col"><kontakt-anzeigen :kontaktProp="kontakt" :display="{anrede: true}"></kontakt-anzeigen></div>
      </div>
      <div class="row" v-if="kontakt.geburtsdatum">
        <div class="col">Geburtsdatum</div>
        <div class="col"><span v-html="$helpers.datumFormatieren(kontakt.geburtsdatum)"></span> (<span v-html="$helpers.alter(kontakt.geburtsdatum)"></span>)</div>
      </div>
      <div class="row" v-if="kontakt.strasse">
        <div class="col">Adresse</div>
        <div class="col">
          {{ kontakt.strasse }}<br />
          {{ kontakt.plz }} {{ kontakt.ort }}
          <span class="d-block" v-if="ptkt.hausbesuch"><span class="badge badge-secondary">Adresse für Hausbesuche</span></span>
        </div>
      </div>
      <div class="row" v-if="kontakt.email">
        <div class="col">E-Mail</div>
        <div class="col"><a :href="'mailto:'+kontakt.email">{{ kontakt.email }}</a></div>
      </div>
      <div class="row" v-if="kontakt.telefonnummern && kontakt.telefonnummern.length">
        <div class="col">Telefon</div>
        <div class="col">
          <telefonnummer v-for="(telefon, telefonindex) in kontakt.telefonnummern" class="d-block" :key="'kontakttelefon_uebersicht_'+telefonindex+telefon.nummer" :telefon="telefon"></telefonnummer>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import kontakte from '~/mixins/kontakte';
export default {
  mixins: [kontakte],
  props: ['ptkt'],
  data() {
    return {
      kontakt: null
    }
  },
  mounted() {
    this.kontaktAbrufen(this.ptkt.kontakt_id);
  }
}
</script>
