<template>
    <span>
        <span class="text-edit" v-bind:class="classes" v-if="!edit && type != 'checkbox'" @click="editText()">
            <span v-if="type == 'date'">{{$helpers.datumFormatieren(text, format)}}{{after}}</span>
            <span v-else-if="type == 'number2'">{{$helpers.zahlFormatieren(text)}}{{after}}</span>
            <span v-else-if="type == 'number'">{{$helpers.zahlFormatieren(text, -1)}}{{after}}</span>
            <span v-else-if="type == 'textarea'"><span v-html="$helpers.nl2br(text)"></span>{{after}}</span>
            <span v-else-if="type == 'html' && (text == '' || text == null)">
                <span class="text-muted" v-if="placeholder">{{ placeholder }}</span>
                <span class="text-muted" v-else>Hier klicken oder tippen, um Text einzugeben.</span>
                {{after}}</span>
            <span v-else-if="type == 'html'"><span v-html="text"></span>{{after}}</span>
            <span v-else>{{text}}{{after}}</span>
        </span>
        <html-editor
          v-else-if="type=='html'"
          class="form-invisible"
          ref="inputfeld"
          v-model="text"
          @input="changeText()"
          :placeholder="placeholder"
          :unsichtbar="['h1', 'h2', 'h3', 'paragraph', 'code', 'code_block']"
          @blur="saveText()"
          :autoFocus="true"
          ></html-editor>
        <textarea-autosize
            v-else-if="type=='textarea'"
            @blur.native="saveText()"
            class="form-invisible"
            ref="inputfeld"
            v-model="text"
            rows="1"
            @input="changeText()"
            :placeholder="placeholder"
        />
        <datum-input
          v-else-if="type=='date'"
          @blur="saveText()"
          class="form-invisible"
          ref="inputfeld"
          v-model="text"
          @input="changeText()"
          :placeholder="placeholder"
          />
        <input
            v-else-if="type == 'checkbox'"
            @blur="saveText()"
            class="form-invisible mr-1"
            ref="inputfeld"
            type="checkbox"
            v-model="text"
            @change="changeText()"
        >
        <input
            v-autowidth="{maxWidth: '300px', minWidth: '20px', comfortZone: comfortZone}"
            v-else
            @blur="saveText()"
            class="form-invisible"
            ref="inputfeld"
            :type='inputtype'
            v-model="text"
            :step="step"
            @input="changeText()"
            :placeholder="placeholder"
        >

    </span>
</template>

<script>
    export default {
        props: {
            'value':{},
            'type':{},
            'step':{},
            'classes':{},
            'after':{},
            'format':{},
            'placeholder': {
                default: ''
            },
            'disabled': {
                default: false
            },
            defaultedit: {
              default: false
            }
        },
        data() {
            return {
                edit: false,
                text: "",
                inputtype: 'text'
            }
        },
        methods: {
            editText() {
                if (this.disabled) return;
                this.edit = !this.edit;

                this.$nextTick(function() {
                    if (this.type == 'textarea') this.$refs.inputfeld.$el.focus();
                    else if (this.type == 'html') this.$refs.inputfeld.editor.focus();
                    else this.$refs.inputfeld.focus();
                })
            },
            saveText() {
                if ((this.text || this.inputtype == 'html') && !this.defaultedit) this.edit = !this.edit;
                this.model = this.text;
                this.$emit('update', this.text);
            },
            changeText() {
                this.$emit('input', this.text);
                this.$emit('change', this.text);
            },
        },
        mounted() {
            this.text = this.value;
            if (!this.text && this.type!='html') this.edit = true;
            if (this.type == 'number2') this.inputtype = 'number';
            else this.inputtype = this.type;

            if (this.defaultedit) this.editText();
        },
        computed: {
            comfortZone: function() {
                if (this.type == 'date') return 50;
                if (this.type == 'number') return 20;
                if (this.type == 'number2') return 20;
                return 0;
            }
        }
    }
</script>
