<template>
  <b-nav-item-dropdown right @show="show()" @hide="displayed = false">
    <template slot="button-content">
        <ion-icon name="notifications-outline"></ion-icon>
        <span v-if="unreadNotifications.length" class="badge badge-danger rounded-pill">{{unreadNotifications.length}}</span>
    </template>
    <div class="p-1" style="width: 400px" v-if="displayed">
      <h2 class="text-center">Notifications</h2>
      <hr>
      <div>
        <b-tabs content-class="mt-3" >
          <b-tab title="Global" active>

            <div class="btn-group mt-2 mb-2" role="group" :class="{'d-none': laedt}">
              <button @click="reset()" class="btn btn-outline-primary btn-sm"><ion-icon name="refresh-outline"></ion-icon> Neuladen</button>
              <button v-if="unreadNotifications.length" @click="alleGelesen()" class="btn btn-outline-primary btn-sm">Alle gelesen</button>
            </div>

            <div style="height: 60vh; overflow: scroll" @scroll="onScroll">
              <template v-for="(notification, index) in globaleNotifications.notifications">
                <div class="vue-notification-template vue-notification"
                    :class="{'notification-read': notification.read_at,
                        'info': notification.data.type == 'info',
                        'warning': notification.data.type == 'warning',
                        'error': notification.data.type == 'error',
                        'success': notification.data.type == 'success'}"

                    :key="index" @click="alsGelesenMarkieren(notification)">
                  <div class="notification-title"><small>Kanal: {{notification.data.kanal}}</small> <small class="float-right">{{ $helpers.datumFormatieren(notification.created_at, 'DD.MM. HH:mm') }} Uhr</small></div>
                  <div class="notification-title">{{ notification.data.titel }}</div>
                  <div class="notification-content" v-if="notification.data.text" v-html="notification.data.text"></div>
                </div>
                <div :key="'link'+index">
                  <nuxt-link @click="alsGelesenMarkieren(notification, true)" :to="notification.data.link" class="btn btn-outline-dark btn-sm" v-if="notification.data.link">{{ (notification.data.linktext) ? notification.data.linktext: notification.data.link }}</nuxt-link>
                  <button @click="$nuxt.$emit(notification.data.event[0], notification.data.event[1]); alsGelesenMarkieren(notification, true)" class="btn btn-outline-dark btn-sm" v-else-if="notification.data.event">{{ (notification.data.linktext) ? notification.data.linktext: notification.data.link }}</button>
                </div>
              </template>
              <hr>
              <p class="text-center text-muted">{{ (globaleNotifications.noMore) ? 'Keine weiteren Notifications' : 'Lädt...' }}</p>
            </div>
          </b-tab>
          <b-tab title="Lokal" style="height: 60vh; overflow: scroll">
            <template #title>
              Lokal <tooltip-information>Diese Benachrichtigungen sind nur auf dem aktuellen Gerät verfügbar und wurden während der Benutzung erstellt.</tooltip-information>
            </template>
            <div class="vue-notification-template vue-notification" :class="notification.type" v-for="(notification, index) in lokaleNotifications.slice(this.lokalanzahl*(this.lokalpage-1), this.lokalanzahl*this.lokalpage)" :key="index">
              <div class="notification-title"><small class="float-right">{{ $helpers.datumFormatieren(notification.datetime, 'DD.MM. HH:mm') }} Uhr</small></div>
              <div class="notification-title">{{ notification.title }}</div>
              <div class="notification-content" v-if="notification.text" v-html="notification.text"></div>
            </div>
            <hr>
            <button class="btn btn-outline-dark btn-sm btn-block" @click="lokalpage++">Weitere Laden, falls verfügbar...</button>
            <!-- <p class="text-center text-muted">Keine weiteren Notifications</p> -->
          </b-tab>
        </b-tabs>
      </div>

    </div>
  </b-nav-item-dropdown>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      lokaleNotifications: [],
      globaleNotifications: {
        notifications: [],
        hasMore: true,
        noMore: false,
        page : -1,
        unread: 0,
        registered: false,
        socketchannel: null,
        abrufZeit: null,
      },
      laedt: false,
      displayed: false,
      lokalanzahl: 10,
      lokalpage: 1
    }
  },
  computed: {
    unreadNotifications() {
      let unread = this.globaleNotifications.notifications.filter(el => {return el && !el.read_at});
      if (this.globaleNotifications.unread > unread.length) {
        // Wenn noch mehr ungelesene am Server sind, wird weiter nachgeladen
        this.globaleNotificationsAbrufen();
      }
      return unread;
    }
  },
  methods: {
    show() {
      this.lokaleNotifications = this.$store.getters['lokalenotifications/getNotifications'];
      this.displayed = true;
    },
    globaleNotificationsAbrufen() {
      // Wurde rausgeworfen
      if (!this.$auth.user) return;

      this.laedt = true;
      if (this.globaleNotifications.page == -1) this.globaleNotifications.abrufZeit = moment().format('YYYY-MM-DD HH:mm:ss');
      if (this.globaleNotifications.hasMore) {
          this.globaleNotifications.hasMore = false;
          this.$axios.get("{notifications}/v1/notifications/"+this.$auth.user.model_type+'/'+this.$auth.user.model_id+"?page="+this.globaleNotifications.page+"&time="+this.globaleNotifications.abrufZeit).then(response => {
            this.notificationsSetzen(response);
          });
      }
      if (!this.globaleNotifications.registered) this.registerForUpdates();
    },
    reset() {
      this.globaleNotifications.notifications = [];
      this.globaleNotifications.hasMore = true;
      this.globaleNotifications.noMore = false;
      this.globaleNotifications.page = 1;
      this.globaleNotificationsAbrufen();
    },
    notificationsSetzen(response) {
      this.globaleNotifications.notifications = this.globaleNotifications.notifications.concat(response.data.notifications.data);
      this.globaleNotifications.hasMore = response.data.hasMore;
      this.globaleNotifications.unread = response.data.unread;
      if (this.globaleNotifications.hasMore == false) this.globaleNotifications.noMore = true;
      this.globaleNotifications.page++;
      this.laedt = false;
    },
    alleGelesen() {
      this.globaleNotifications.abrufZeit = moment().format('YYYY-MM-DD HH:mm:ss');
      this.laedt = true;
      this.$axios.put('{notifications}/v1/notifications/'+this.$auth.user.model_type+'/'+this.$auth.user.model_id+"?page=1&time="+this.globaleNotifications.abrufZeit, {}).then(response => {
        this.globaleNotifications.notifications = [];
        this.globaleNotifications.page = 1;
        this.notificationsSetzen(response);
        if (this.globaleNotifications.socketchannel) this.globaleNotifications.socketchannel.whisper('Reload');
      });
    },
    alsGelesenMarkieren(notification, value = null) {
      let read_at = null;
      if (!notification.read_at || value == true) {
        read_at = moment().format('YYYY-MM-DD HH:mm:ss');
        this.globaleNotifications.unread--;
      }
      notification.read_at = read_at;
      this.$axios.put('{notifications}/v1/notifications/'+notification.id, {read_at}).then(response => {
        this.notificationErsetzen(response.data);
        // geänderte Notification an die anderen Frontends schicken die Online sind
        if (this.globaleNotifications.socketchannel) this.globaleNotifications.socketchannel.whisper('UpdateNotification', response.data);
      });
    },
    registerForUpdates() {
      if (this.globaleNotifications.registered) return;
      try {
        this.globaleNotifications.socketchannel = this.$echo.private('User.'+this.$auth.user.id).listenToAll((event, data) => {
          console.log('event', event);
          // Geänderte Notification, bei markiert als gelesen o.Ä.
          if (event == '.client-UpdateNotification') this.notificationErsetzen(data);
          if (event == '.client-Reload') this.reset();
          else {
            this.globaleNotifications.notifications.unshift(data);
            let notificationdata = {
                datetime: new Date(),
                group: 'default',
                title: data.data.titel,
                type: data.data.typ,
                text: data.data.text,
                duration: 10000,
            }
            this.$notify(notificationdata);
          }
        });
        this.globaleNotifications.registered = true;
      } catch (error) {
        this.globaleNotifications.registered = false;
      }
    },
    notificationErsetzen(neu) {
      this.globaleNotifications.notifications.splice(this.globaleNotifications.notifications.indexOf(this.$helpers.findByKey(this.globaleNotifications.notifications, neu.id)), 1, neu);
      if (neu.unread) this.unread++;
      else this.unread--;
    },
    onScroll: function({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight-300 && this.globaleNotifications.hasMore) {
        this.globaleNotificationsAbrufen()
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.globaleNotificationsAbrufen();
    }, 1000);
  }
}
</script>
