import faqtags from '~/mixins/faqtags';
import lokiHelper from '~/mixins/lokiHelper';
import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
export default {
  mixins: [faqtags, lokiHelper, suche],
  methods: {
    loadFaqs(manuell = false) {
      this.$store.dispatch('faqs/sync', manuell);
    },
    resetFaqs() {
      this.$store.commit('faqs/reset');
      this.loadFaqs(true);
    },
    speichernFaqs(model) {
      this.$store.dispatch('faqs/speichern', model);
      setTimeout(() => {
        this.loadFaqs();
      }, 1000);
    },
    async loeschenFaqs(model) {
      await this.$store.dispatch('faqs/loeschen', model);
      this.$router.push({name: 'mitarbeiter-listen-faqs'});
    },
    async faqsAbrufen() {
      this.getFaqs = true;
      this.registerFaqsEvent();
      if (!this.suchbegriff) {
        this.faqs = await this.getElemente('faqs', this.faqsFilter);
        this.faqCount = this.faqs.length;
        return;
      }
      let faqCollection = await dbHelper.getDb('faqs');
      this.faqs = await faqCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    faqsAenderungenAbrufen() {
      this.getFaqAenderungen = true;
      this.registerFaqsEvent();
      return this.getAenderungen('faqs').then(data => this.faqsAenderungen = data);
    },
    faqAbrufen(id) {
      this.getFaq = id
      this.registerFaqsEvent();
      return this.getElement('faqs', {id: id}).then(faq => {if (Object.keys(faq).length) this.faq = faq});
    },
    registerFaqsEvent() {
      if (!this.faqEvent) this.faqEvent = this.$nuxt.$on('faqs-sync', this.faqsErneuern);
    },
    faqsErneuern(event) {
      if (this.getFaq) {
        if (event && this.getFaq == event.id) this.faq = event;
        else  this.faqAbrufen(this.getFaq);
      }
      if (this.getFaqs) this.faqsAbrufen();
      if (this.getFaqAenderungen) this.faqsAenderungenAbrufen();
    }
  },
  created() {
    this.standFaqs;
  },
  mounted() {
    if (!this.$store.getters['faqs/filled']) this.loadFaqs();
  },
  computed: {
    standFaqs: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['faqs/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadFaqs();
      return stand;
    },
  },
  data() {
    return {
      faq: null,
      faqs: [],
      faqsCount: 0,
      faqsAenderungen: [],
      getFaq: false,
      getFaqs: false,
      getFaqAenderungen: false,
      faqEvent: false,
      faqsFilter: null,
      orderbyFaqs: ['name', 'asc']
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('faqs-sync', this.faqsErneuern);
    this.faqvent = null;
  },
}
