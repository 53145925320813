<template>
  <div>
    <slot></slot>
    <b-modal id="pdf-modal" hide-footer size="xl" style="height:80%" @hide="showPdf=false" :title="titel">
      <object :data="pdf" width="100%" style="height:90vh" v-if="showPdf"></object>
      <h1 v-else>Lädt...</h1>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      pdf: '',
      displayPage: 1,
      pageCount: 0,
      showPdf: false,
      titel: 'PDF'
    }
  },
  methods: {
    loadPdf(url) {
      this.$axios({url: url, method: 'GET', responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(response.data);
        this.pdf = url;
        this.$nextTick(function() {
          this.showPdf = true;
        })
      });
    },
    viewPdf(meta) {
      this.loadPdf(meta.url);
      this.titel = meta.titel;
      this.$bvModal.show('pdf-modal');
    }
  },
  mounted() {
    this.$nuxt.$on('view-pdf', this.viewPdf)
  },
  beforeDestroy() {
    this.$nuxt.$off('view-pdf', this.viewPdf)
  }
}
</script>
