import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  setAll: storeHelperOld.setAll,
  // set: storeHelper.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled
}

const actions = {
  async load({commit, state}, payload) {
    let url = "{placetel}/v1/faxe";
    let notifyBetreff = "Faxe aktualisieren";
    return storeHelperOld.load(commit, state, url, notifyBetreff, payload);
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
