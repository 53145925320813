import _ from 'lodash';
import kontakte from '~/mixins/kontakte';
import lokiHelper from '~/mixins/lokiHelper';
import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
export default {
  mixins: [kontakte, lokiHelper, suche],
  methods: {
    // findPatient(id) {
    //   return this.$helpers.findByKey(this.patienten, id);
    // },
    // /** Alt und liefert nur 1 Patient */
    // findPatientByKontakt(kontakt) {
    //   return this.patienten.filter(patient => {
    //     return this.$helpers.findByKey(patient.patientenkontakte, kontakt);
    //   })[0];
    // },
    // findPatientenByKontakt(kontakt_id) {
    //   return this.patienten.filter(patient => {
    //     return this.$helpers.findByKey(patient.patientenkontakte, kontakt_id, 'kontakt_id');
    //   });
    // },
    // findPatientenByPatientenkontakt(ptkt_id) {
    //   return this.patienten.filter(patient => {
    //     return this.$helpers.findByKey(patient.patientenkontakte, ptkt_id, 'id');
    //   });
    // },
    loadPatienten(manuell = false) {
      this.$store.dispatch('patienten/sync', manuell);
    },
    resetPatienten() {
      this.$store.commit('patienten/reset');
      this.loadPatienten(true);
    },
    speichernPatient(model) {
      if (model.patientenkontakte) {
        _.forEach(model.patientenkontakte, (ptkt) => {
          if (ptkt.kontakt) {
            if (!ptkt.kontakt.model_type) ptkt.model_type = 'Patientenkontakt';
            this.$store.dispatch('kontakte/speichern', ptkt.kontakt);
            if (!ptkt.kontakt_id) ptkt.kontakt_id = ptkt.kontakt.id;
            delete ptkt.kontakt;
          }
          ptkt.updated_at = $nuxt.$moment().format('YYYY-MM-DD HH:mm:ss');
        });
      }
      this.$store.dispatch('patienten/speichern', model);
      setTimeout(() => {
        this.loadPatienten();
      }, 1000);
    },
    patientLoeschen(patient) {
      this.$confirm(
        {
          message: 'Möchtest du den gewählten Patienten wirklich löschen?',
          button: {
            no: 'Nicht löschen',
            yes: 'Ja löschen'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('kontakte/loeschen', patient.kontakt);
              delete patient.kontakt;
              _.forEach(patient.patientenkontakte, (ptkt) => {
                this.$store.dispatch('kontakte/loeschen', ptkt.kontakt);
                delete ptkt.kontakt;
                ptkt.updated_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                ptkt.deleted_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
              })
              this.$store.dispatch('patienten/loeschen', patient);
            }
          }
        }
      )
    },

    async patientenAbrufen() {
      this.getPatienten = true;
      this.registerPatientenEvent();
      if (!this.suchbegriff) {
       this.patienten = await this.getElemente('patienten', this.patientenFilter);
       this.patientenCount = this.patienten.length;
       return;
      }

      // Kontakte mit Suchbegriff suchen
      let kontakteIds = await this.kontakteSuchen('model_id');
      let ptktIds = await this.kontakteSuchen('id', 'Patientenkontakt');

      // Patienten suchen anhand Fachgebiete und Kontakte
      let patientenCollection = await dbHelper.getDb('patienten');
      this.patienten = await patientenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' '))
        || kontakteIds.includes(obj.id)
        // || kontakteIds.includes(obj.mitarbeiter_id)
        || (obj.patientenkontakte && undefined != obj.patientenkontakte.find(ptkt => {return ptktIds.includes(ptkt.kontakt_id)}))
        )}
      );
    },
    async patientenByPatientenkontakt(ptkt_id) {
      let patientenCollection = await dbHelper.getDb('patienten');
      return this.patienten = await patientenCollection.where((obj) => {
        return ((obj.patientenkontakte && undefined != obj.patientenkontakte.find(ptkt => {return ptkt.kontakt_id == ptkt_id}))
        )}
      );
    },
    patientenAenderungenAbrufen() {
      this.getPatientenAenderungen = true;
      this.registerPatientenEvent();
      return this.getAenderungen('patienten').then(data => this.patientenAenderungen = data);
    },
    patientAbrufen(id) {
      this.getPatient = id
      this.registerPatientenEvent();
      return this.getElement('patienten', {id: id}).then(patient => {if (Object.keys(patient).length) this.patient = patient});
    },
    registerPatientenEvent() {
      if (!this.patientEvent) this.patientEvent = this.$nuxt.$on('patienten-sync', this.patientenErneuern);
    },
    patientenErneuern(event) {
      if (this.getPatient) {
        if (event && this.getPatient == event.id) this.patient = event;
        else  this.patientAbrufen(this.getPatient);
      }
      if (this.getPatienten) this.patientenAbrufen();
      if (this.getPatientenAenderungen) this.patientenAenderungenAbrufen();
    },


  },
  created() {
    this.standPatienten;
  },
  mounted() {
    if (!this.$store.getters['patienten/filled']) this.loadPatienten();
  },
  computed: {
    standPatienten: function() {
      let stand = this.$store.getters['patienten/getStand'];

      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadPatienten();
      return stand
    }
  },
  data() {
    return {
      patient: null,
      patienten: [],
      patientenCount: 0,
      patientenAenderungen: [],
      getPatient: false,
      getPatienten: false,
      getPatientAenderungen: false,
      patientEvent: false,
      patientenFilter: null,
      orderByPatienten: ['created_at', 'desc'],
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('patienten-sync', this.patientenErneuern);
    this.patientEvent = null;
  },
}
