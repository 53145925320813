<template>
     <b-modal :id="id" size="lg" title="Arbeitszeit erfassen" hide-footer @show="formShow()">
        <form-wizard
        color="#1D637E"
            :key="formCounter"
            title=""
            subtitle=""
            nextButtonText="Weiter"
            backButtonText="Zurück"
            finishButtonText="Speichern"
            stepSize="xs"
            :von-index="vonindex"
            @on-complete="speichern()"
            ref="wizard"
        >
          <tab-content title="Tätigkeit" icon="&nbsp;" :before-change="taetigkeitValide" v-if="!edit || sonstiges">
            <taetigkeit-formular :maProp="ma" :key="formCounter" :id="id" v-model="sonstiges" @change-zeit="arbeitszeitKey++" :showError="showError" :neu="true"></taetigkeit-formular>
          </tab-content>
          <tab-content title="Arbeitszeit" icon="&nbsp;" :before-change="arbeitszeitValide">
                <arbeitszeit-therapie-formular :key="formCounter+arbeitszeitKey" :id="id" v-model="arbeitszeit" :edit="edit"
                  :anfahrt="anfahrt" @anfahrtUpdate="anfahrt = $event"
                  :abfahrt="abfahrt" @abfahrtUpdate="abfahrt = $event"
                  :taetigkeit="sonstiges"
                  :showError="showError"></arbeitszeit-therapie-formular>
          </tab-content>
          <tab-content title="Zusammenfassung" icon="&nbsp;">
              <div class="row">
                <div class="col" v-if="sonstiges">
                  <legend>Sonstige Tätigkeit</legend>
                  <table class="table table-sm">
                    <tr v-if="sonstiges.mitarbeiter_id">
                      <td>Therapeut</td>
                      <td><kontakt-anzeigen :filter="{model_type: 'Mitarbeiter', model_id: sonstiges.mitarbeiter_id}"></kontakt-anzeigen></td>
                    </tr>
                    <tr v-if="sonstiges.taetigkeit">
                      <td>Tätigkeit</td>
                      <td>{{sonstiges.taetigkeit}}</td>
                    </tr>
                    <tr v-if="sonstiges.kommentar">
                      <td>Kommentar</td>
                      <td v-html="sonstiges.kommentar"></td>
                    </tr>
                  </table>
                </div>
                <div class="col">
                  <arbeitszeit-zusammenfassung
                    :anfahrt="anfahrt"
                    :abfahrt="abfahrt"
                    :arbeitszeit="arbeitszeit"
                  ></arbeitszeit-zusammenfassung>
                </div>
              </div>
          </tab-content>
        </form-wizard>
    </b-modal>
</template>

<script>

import ArbeitszeitTherapieFormular from '~/components/mitarbeiter/arbeitszeit/Therapieformular';
import ArbeitszeitZusammenfassung from '~/components/mitarbeiter/arbeitszeit/Zusammenfassung';
import TaetigkeitFormular from '~/components/mitarbeiter/arbeitszeit/TaetigkeitFormular';
import mitarbeiter from '~/mixins/mitarbeiter';
import kontakte from '~/mixins/kontakte';
import arbeitsstunden from '~/mixins/arbeitsstunden';
import anfahrten from '~/mixins/anfahrten';
export default {
  mixins: [mitarbeiter, kontakte, arbeitsstunden, anfahrten, kontakte],
  props: ['value', 'id', 'maProp', 'monat'],
  components: {ArbeitszeitTherapieFormular, TaetigkeitFormular, ArbeitszeitZusammenfassung},
  data() {
      return {
        formCounter: 0,
        arbeitszeitKey: 0,
        sonstiges: null,
        arbeitszeit: null,
        anfahrt: null,
        abfahrt: null,
        showError: false,
        edit: false,
        ma: this.maProp,
        arbeitsstundenMonat: this.monat
      }
  },
  watch: {
    value: function(neu, alt) {
      this.fillChange(neu);
    }
  },
  methods: {
    fillChange(neu) {
      let arbeitszeit = this.$helpers.copy(neu);
      if (!arbeitszeit.von) arbeitszeit.von = this.$moment();
      if (!arbeitszeit.dauer) arbeitszeit.dauer = 15;

      if (arbeitszeit.sonstiges) this.sonstiges = this.$helpers.copy(arbeitszeit.sonstiges);
      // Neu
      else if (!arbeitszeit.id && !this.sonstiges) this.sonstiges = {};
      // Neu
      if (!arbeitszeit.id) {
        if (!this.sonstiges.mitarbeiter_id) {
          if (arbeitszeit.mitarbeiter_id) this.sonstiges.mitarbeiter_id = arbeitszeit.mitarbeiter_id;
          else this.sonstiges.mitarbeiter_id = this.$auth.user.mitarbeiter.id
        }
      }
      else this.edit = true;

      if (!arbeitszeit.id) arbeitszeit.id = this.$uuid.v1();
      if (arbeitszeit.von) arbeitszeit.von = this.$moment(arbeitszeit.von).format("YYYY-MM-DDTHH:mm");
      if (arbeitszeit.bis) arbeitszeit.bis = this.$moment(arbeitszeit.bis).format("YYYY-MM-DDTHH:mm");
      this.arbeitszeit = arbeitszeit


      this.showError = false;
      this.formCounter++;
    },
      arbeitszeitValide: function() {
          this.showError = true;
          if (!this.arbeitszeit.von || !this.arbeitszeit.dauer || this.arbeitszeit.dauer <= 0) return false;
          this.showError = false;
          return true;
      },
      taetigkeitValide: function() {
          this.showError = true;
          if (!this.sonstiges.taetigkeit) return false;
          if (!this.sonstiges.mitarbeiter_id) return false;
          this.kontaktAbrufen(this.sonstiges.mitarbeiter_id);
          this.showError = false;
          return true;
      },
      speichern() {
        let sonstiges = null;
        if (this.sonstiges) {
          sonstiges = {};
          sonstiges.id = this.sonstiges.id;
          sonstiges.mitarbeiter_id = this.sonstiges.mitarbeiter_id;
          sonstiges.taetigkeit = this.sonstiges.taetigkeit;
          sonstiges.kommentar = this.sonstiges.kommentar;
          sonstiges.von = this.arbeitszeit.von;
          sonstiges.bis = this.arbeitszeit.bis;
          sonstiges.dauer = this.arbeitszeit.dauer;
          sonstiges.updated_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        }

        let arbeitsstunde = {};
        arbeitsstunde.id = this.arbeitszeit.id;
        arbeitsstunde.von = this.arbeitszeit.von;
        arbeitsstunde.bis = this.arbeitszeit.bis;
        arbeitsstunde.dauer = this.arbeitszeit.dauer;
        arbeitsstunde.trigger_type = this.arbeitszeit.trigger_type ?? 'Sonstiges';
        arbeitsstunde.trigger_id = this.arbeitszeit.trigger_id ?? this.sonstiges.id;
        arbeitsstunde.mitarbeiter_id = this.arbeitszeit.mitarbeiter_id ?? this.sonstiges.mitarbeiter_id;
        if (sonstiges) arbeitsstunde.sonstiges = sonstiges;
        this.speichernArbeitsstunde(arbeitsstunde);

        // Anfahrt speichern
      if (this.anfahrt.erfassen) {
        let anfahrt = {};
        anfahrt.id = this.anfahrt.id;
        anfahrt.mitarbeiter_id = this.sonstiges.mitarbeiter_id;
        anfahrt.trigger_id = sonstiges.id;
        anfahrt.trigger_type = 'Sonstiges';
        anfahrt.von = this.anfahrt.von;
        anfahrt.bis = this.anfahrt.bis;
        anfahrt.dauer = this.anfahrt.dauer;
        anfahrt.km = this.anfahrt.km;
        this.speichernAnfahrt(anfahrt);

      // Anfahrt als Arbeitszeit speichern
        if (this.anfahrt.arbeitszeit) {
          let anfahrtArbeitszeit = this.$helpers.copy(anfahrt);
          anfahrtArbeitszeit.trigger_type = 'Anfahrt';
          anfahrtArbeitszeit.trigger_id = anfahrt.id;
          this.speichernArbeitsstunde(anfahrtArbeitszeit);
        }
      }

      // Abfahrt speichern
      if (this.abfahrt.erfassen) {
        let abfahrt = {};
        abfahrt.id = this.abfahrt.id;
        abfahrt.mitarbeiter_id = this.sonstiges.mitarbeiter_id;
        abfahrt.trigger_id = sonstiges.id;
        abfahrt.trigger_type = 'Sonstiges';
        abfahrt.von = this.abfahrt.von;
        abfahrt.bis = this.abfahrt.bis;
        abfahrt.dauer = this.abfahrt.dauer;
        abfahrt.km = this.abfahrt.km;
        this.speichernAnfahrt(abfahrt);

      // Abfahrt als Arbeitszeit speichern
        if (this.abfahrt.arbeitszeit) {
          let arbeitsstunde = {};
          arbeitsstunde.id = this.$uuid.v1();
          arbeitsstunde.von = abfahrt.von;
          arbeitsstunde.bis = abfahrt.bis;
          arbeitsstunde.dauer = abfahrt.dauer;
          arbeitsstunde.trigger_type = 'Anfahrt';
          arbeitsstunde.trigger_id = abfahrt.id;
          arbeitsstunde.mitarbeiter_id = abfahrt.mitarbeiter_id;
          this.speichernArbeitsstunde(arbeitsstunde);
        }
      }

        this.$bvModal.hide(this.id)
      },
      formShow() {
        this.fillChange(this.value);
      }
  },
  mounted() {
    this.fillChange(this.value);
  },
  computed: {
      vonindex: function() {
        if (!this.arbeitszeit || !this.arbeitszeit.updated_at) return 0;
        return 2;
      }
  }
}
</script>
