import _ from 'lodash';
import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'ausfallstunden',
  titel: "Ausfallstunden",
  syncurl: "{verordnungen}/v1/ausfallstunden",
  globals: Helpers.copy(storeHelper.empty),
  statisch: true,
})

const mutations = {
  set: storeHelper.set,
  stand: storeHelper.stand,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled,
  // getVerordnungsaenderungen(state) {
  //   let aenderungen = [];
  //   _.forEach(state.globals.elemente, vo => {
  //     _.forEach(vo.aenderungen, aenderung => {
  //       aenderungen.push(aenderung);
  //     })
  //   })
  //   return aenderungen;
  // }
}

const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern,
  async sync(context, manuell = false) {
    await storeHelper.sync(context, manuell);
  },
  loeschen: storeHelper.loeschen
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
