<template>
  <span :id="id" :class="'text-'+type" style="font-size: 14pt">
    <ion-icon name="information-circle-outline"></ion-icon>
    <b-tooltip :target="id" :variant="type" @show="$emit('show')">
      <slot></slot>
    </b-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    type: {
      default: 'primary'
    }
  },
  data() {
    return {
      displayTooltip: false,
      id: 'tooltip'+this._uid
    }
  }
}
</script>
