<template>
    <span>
        <datalist :id="'indikationsschluessel'+_uid"><option :value="bez" v-for="bez in listePrefills" :key="'indikation'+bez+id" /></datalist>
        <the-mask @change="$emit('change', text)" @input="$emit('input', text)" :list="'indikationsschluessel'+_uid"  mask="XXXX" class="form-control" placeholder="" id="indikationsschluessel" v-model="text" />
    </span>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value', 'fachbereich'],
  data() {
    return {
      text: this.value,
      id: this._uid
    }
  },
  computed: {
    listePrefills() {
      if (this.fachbereich == 0 || !this.fachbereich) return this.indikationsschluesselPrefillsLogo;
      if (this.fachbereich == 1) return this.indikationsschluesselPrefillsErgo;
      return this.indikationsschluesselPrefills;
    }
  }
}
</script>
