<template>
  <b-nav-item-dropdown right>
    <template slot="button-content">
        <ion-icon name="call-outline"></ion-icon>
    </template>
    <div class="p-1" style="width: 400px">
      <durchwahl-uebersicht></durchwahl-uebersicht>
    </div>
  </b-nav-item-dropdown>
</template>
<script>
import DurchwahlUebersicht from '~/components/mitarbeiter/telefon/DurchwahlUebersicht';
export default {
  components: {DurchwahlUebersicht},
}
</script>
