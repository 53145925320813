<template>
<client-only>
<div>
   <b-navbar toggleable="lg" type="light" variant="light" class="bg-white shadow-sm">
     <div class="container">
        <NuxtLink class="navbar-brand" to="/dashboard">
            <img src="~/assets/img/logos/yes-gesundheit.svg" width="200px" alt="YES. Gesundheit">
        </NuxtLink>
        <b-navbar-toggle target="nav-collapse" v-if="$auth.loggedIn"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav v-if="$auth.loggedIn && $auth.user">
            <ul class="navbar-nav mr-auto">
                <b-nav-item-dropdown>
                  <template v-slot:button-content>Listen</template>
                  <b-dropdown-item to="/mitarbeiter/listen/patienten" v-if="$can('Patienten verwalten')">Patienten</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/absagen" v-if="$can('Ausfall verwalten')">Ausfälle</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/verordnungen" v-if="$can('Verordnungen verwalten')">Verordnungen</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/mitarbeiter" v-if="$can('Mitarbeiter verwalten')">Mitarbeiter</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/rollen" v-if="$can('Permissions verwalten')">Rollen</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/krankenkassen">Krankenkassen</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/aerzte">Ärzte</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/faqs" v-if="$can('FAQs sehen')">FAQs</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/kontakte">Kontakte</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/listen/status" v-if="$can('Service Status prüfen')">Status</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown>
                  <template v-slot:button-content>Dokumente</template>
                  <b-dropdown-item to="/mitarbeiter/dokumente/schreiben" v-if="$can('Schreiben erstellen')">Schreiben</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/dokumente/faxe" v-if="$can('Faxe verwalten')">Faxe</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="$can('Schreiben Vorlagen verwalten')">
                  <template v-slot:button-content>System</template>
                  <b-dropdown-item to="/mitarbeiter/system/vorlagen" v-if="$can('Schreiben Vorlagen verwalten')">Schreiben Vorlagen</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/system/cloud" v-if="$can('Cloudtags verwalten')">Cloud Tags</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/system/ressourcen" v-if="$can('Standorte verwalten')">Ressourcen</b-dropdown-item>
                  <b-dropdown-item disabled>Version: {{ $appVersion }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item to="/mitarbeiter/telefonie">Telefonie</b-nav-item>
                <b-nav-item to="/mitarbeiter/warteliste">Warteliste</b-nav-item>
            </ul>

            <ul class="navbar-nav ml-auto">
              <telefon-agent-status />
              <durchwahl-uebersicht-menue />
              <notifications-uebersicht />
              <b-nav-item disabled class="bg-danger" v-if="$nuxt.isOffline"><span class="text-white text-uppercase">Offline</span></b-nav-item>
              <b-nav-item disabled class="bg-danger" v-if="$dev"><span class="text-white text-uppercase">Staging</span></b-nav-item>
              <nachhilfe-verguetung-woche v-if="$auth.user.mitarbeiter.nachhilfe_id"></nachhilfe-verguetung-woche>
              <arbeitszeit-uebersicht></arbeitszeit-uebersicht>
              <sync-uebersicht></sync-uebersicht>
                <b-nav-item-dropdown right>
                  <template v-slot:button-content>
                    <span>
                      <template v-if="!$auth.user || !Object.keys($auth.user).length">Hier klicken und User Cache aktualisieren.</template>
                      <span class="easteregg" v-else-if="$auth.user.id == 'c693fce6-fa9f-4d3d-8b3d-33f9f6204ea2'">GodMode</span>
                      <span class="easteregg" v-else-if="$auth.user.id == '584c7325-7dd1-4675-8070-1f963ed7f461'">Cheffe</span>
                      <template v-else><kontakt-anzeigen :filter="{model_type:$auth.user.model_type, model_id: $auth.user.model_id}" :display="{nachname: false, vorname: true}"></kontakt-anzeigen></template>
                    </span>
                    <span class="caret"></span>
                  </template>
                  <div class="form-switch mt-1 ml-2 mb-1" :key="debugkey">
                    <toggle :value="debugMode" @input="changeDebug()" />
                    <label class="form-check-label" for="flexSwitchCheckDefault" @click="changeDebug()">Debug Mode</label>
                  </div>
                  <div class="form-switch mt-1 ml-2 mb-1" :key="'trashed'+debugkey">
                    <toggle :value="trashedMode" @input="changeTrashed()" />
                    <label class="form-check-label" for="flexSwitchCheckDefault" @click="changeTrashed()">Archiv Mode</label>
                  </div>
                  <b-dropdown-item @click="refreshMe()">User Cache leeren</b-dropdown-item>
                  <b-dropdown-item to="/account">Account</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/arbeitszeiten">Arbeitszeiten</b-dropdown-item>
                  <b-dropdown-item to="/mitarbeiter/stoerungsbilder">Störungsbilder</b-dropdown-item>
                  <b-dropdown-item @click="leaveImpersonation()" v-if="$store.getters['impersonate/isImpersonated']">Rolle verlassen</b-dropdown-item>
                  <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
                </b-nav-item-dropdown>
            </ul>
          </b-collapse>
     </div>
   </b-navbar>
  <aktueller-anruf></aktueller-anruf>
  <online-users v-if="displayOnlineUsers"></online-users>
  <check-version></check-version>
  <main class="py-4">
    <vue-confirm-dialog></vue-confirm-dialog>
    <suchfunktion v-if="$auth.loggedIn"></suchfunktion>
      <div class="container d-flex"><button @click="kalenderAnzeigen = !kalenderAnzeigen" class="btn btn-outline-primary btn-sm ml-auto">Kalender {{ kalenderAnzeigen ? 'ausblenden' : 'einblenden' }}</button></div>
      <div class="container">
        <keep-alive>
          <kalender v-if="kalenderAnzeigen" class="mt-2"></kalender>
        </keep-alive>
      </div>


    <notifications group="default" class="notification-wrapper" position="bottom right" width="400"></notifications>
    <view-pdf></view-pdf>
    <router-view :key="$route.fullPath+'content'+contentkey"></router-view>
  </main>
</div>
</client-only>

</template>
<script>
import master from '~/layouts/master';
import Kalender from '~/components/kalender/Kalender';
import DurchwahlUebersichtMenue from '~/components/elemente/DurchwahlUebersichtMenue';
import NotificationsUebersicht from '~/components/elemente/NotificationsUebersicht';
import NachhilfeVerguetungWoche from '~/components/mitarbeiter/mitarbeiter/NachhilfeVerguetungWoche';
export default {
  name: "default",
  mixins: [master],
  components: {Kalender, DurchwahlUebersichtMenue, NotificationsUebersicht, NachhilfeVerguetungWoche},
  data() {
    return {
      debugMode: this.$store.getters['debug/isDebugmode'],
      trashedMode: this.$store.getters['debug/istrashedMode'],
      debugkey: 0,
      contentkey: 0,
      displayOnlineUsers: false,
      kalenderAnzeigen: false
    }
  },
    methods: {
        logout() {
          this.$store.commit('authsafe/setUser', false);
          this.$router.push('/logout');
          this.resetAll();
          this.$auth.logout();
          this.$auth.strategy._clearToken();
        },
        resetAll() {
          this.$store.commit('services/loading', false);
          // Loading Status leeren
          this.$store.commit('aerzte/reset');
          this.$store.commit('krankenkassen/reset');
          this.$store.commit('mitarbeiter/reset');
          this.$store.commit('patienten/reset');
          this.$store.commit('rollen/reset');
          this.$store.commit('schreiben-vorlagen/reset');
          this.$store.commit('verordnungen/reset');
          this.$store.commit('kontakte/reset');
          this.$store.commit('faqs/reset');
          this.$store.commit('faqtags/reset');
          this.$store.commit('syncprotocol/reset');
          this.$store.commit('arbeitsstunden/reset');
          this.$store.commit('anfahrten/reset');
          this.$store.commit('schreiben/reset');
          this.$store.commit('verordnungsaenderungen/reset');
          this.$store.commit('therapiestunden/reset');
          this.$store.commit('ausfallstunden/reset');
          window.indexedDB.deleteDatabase('logoyesportal');
          // this.$store.replaceState({});
        },
        leaveImpersonation() {
          this.$auth.setUserToken(this.$store.getters['impersonate/getUserToken']).then(() => {this.$auth.fetchUser(null, true); this.$store.commit('impersonate/leave');});
        },
        refreshMe() {
          this.$store.commit('authsafe/setUser', false);
          this.$auth.fetchUser(null, true).then((antwort) => {
            if (antwort == false) return this.logout();
          });
          this.$notifyInfo('Daten erneuern', 'Deine Profildaten werden erneut heruntergeladen und Deine Permissions aktualisiert');
        },
        changeDebug() {
          this.debugMode = !this.debugMode;
          this.$store.commit('debug/setDebug', this.debugMode);
          this.debugkey++;
          this.contentkey++;
        },
        changeTrashed() {
          this.trashedMode = !this.trashedMode;
          this.$store.commit('debug/setTrashed', this.trashedMode);
          this.debugkey++;
          this.contentkey++;
        }
    },
    mounted() {
      this.$nuxt.$on('unauthorized', () => {
        console.log('catch unauthorized');
        $nuxt.$notifyError('Du bist nicht mehr authorisiert und wirst jetzt ausgeloggt.');
        this.logout();
        this.$nuxt.$off('unauthorized');
      })

      // Online Users nicht direkt einblenden und damit auch die ganzen Socketsync Sachen nachladen
      setTimeout(() => {
        this.displayOnlineUsers = true;
      }, 5000)
    }
}
</script>
