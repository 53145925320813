import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'anfahrten',
  titel: 'Anfahrten',
  syncurl: '{mitarbeiter}/v1/anfahrten',
  globals: Helpers.copy(storeHelper.empty),
  standModels: {}
})

const mutations = {
  set: storeHelper.set,
  stand: storeHelper.stand,
  loading: storeHelper.loading,
  reset(state) {
    state.standModels = {};
    storeHelper.reset(state);
  },
  setModelsStand(state, {mitarbeiter, monat, stand}) {
    if (!state.standModels[mitarbeiter]) state.standModels[mitarbeiter] = {};
    state.standModels[mitarbeiter][monat] = stand;
  },
}

const getters = {
  isLoading: storeHelper.isLoading,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled,
  getStandModels: (state) => (mitarbeiter, monat) => {
    if (state.standModels[mitarbeiter] && state.standModels[mitarbeiter][monat]) return Helpers.make_date(state.standModels[mitarbeiter][monat]);
    return null;
  }
}

const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern,
  /** Die Funktion lädt alle lokalen Änderungen hoch, lädt aber nur herunter was mit dem Mitarbeiter zusammenhängt */
  async sync(context, {manuell = false, params = {}}) {
    let sendparams = {}

    if (params.mitarbeiter) {
      sendparams.last = context.getters.getStandModels(params.mitarbeiter, params.monat);
      sendparams.filter = {monat: params.monat, mitarbeiter_id: params.mitarbeiter};
    }
    await storeHelper.sync(context, manuell, sendparams);
    if (params.mitarbeiter) context.commit('setModelsStand', {mitarbeiter: params.mitarbeiter, monat: sendparams.filter.monat, stand: context.state.globals.date});
  },
  loeschen: storeHelper.loeschen
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
