<template>
  <svg class="bi" :width="width" :height="height">
    <!-- <use :xlink:href="'/_nuxt/img/bootstrap-icons.0452c3b.svg#'+name"/> -->
    <use :xlink:href="icons+'#'+name"/>
  </svg>
</template>

<script>
import icons from 'bootstrap-icons/bootstrap-icons.svg';
export default {
  props: {
    name: {},
    width: {
      default: 20
    },
    height: {
      default: 20
    },
    size: {
      default: 'normal',
    },
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  },
  data() {
    return {
      icons: icons
    }
  }
}
</script>
