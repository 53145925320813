import axios from 'axios';
export default async function ({store}) {

  let services = store.state.services.globals.elemente;
  if (!services.length) {
    store.commit('services/loading', true);
    try {
      let response = await axios.get(process.env.SERVICES+'/v1/services?stage='+process.env.STAGE);
      store.commit('services/setAll', response.data);
    } catch (error) {
      alert(error);
      location.reload();
    }
  }
}
