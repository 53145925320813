<template>
    <div class="row">
      <div class="col-6 my-auto">
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '1'}" tabindex="-1" @click="update('1')">1</button>
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '1-2'}" tabindex="-1" @click="update('1-2')">1-2</button>
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '2'}" tabindex="-1" @click="update('2')">2</button>
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '1-3'}" tabindex="-1" @click="update('1-3')">1-3</button>
      </div>
      <span class="col">
        <datalist :id="'therapiefrequenz'+_uid"><option :value="bez" v-for="bez in prefills" :key="bez" /></datalist>
        <the-mask :masked="true" @change="update($event)" @input="update($event)" :list="'therapiefrequenz'+_uid"  mask="#!-#" class="form-control" placeholder="Frequenz" id="therapiefrequenz" v-model="text" />
      </span>
    </div>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value', 'fachbereich'],
  data() {
    return {
      text: this.value
    }
  },
  computed: {
    prefills() {
      // Verordnungprefills erneuern, falls die neuen Daten noch nicht da sind
      if (this.verordnungPrefills && this.fachbereich != null && !this.verordnungPrefills[this.fachbereich]) this.$store.dispatch('prefills/loadVerordnung');
      if (this.fachbereich != null && this.verordnungPrefills[this.fachbereich]) return this.verordnungPrefills[this.fachbereich].therapiefrequenzen;
      return this.verordnungPrefills.therapiefrequenzen;
    }
  },
  methods: {
    update(neu) {
      this.text = neu;
      this.$emit('input', neu);
      this.$emit('change', neu);
    }
  }
}
</script>
