<template>
  <span v-if="$istTherapeut(maProp)">
    <fachbereich-badge v-if="$istTherapeut(maProp).fachbereich != null" :fachbereich="$istTherapeut(maProp).fachbereich"></fachbereich-badge>
    <span v-else class="badge badge-primary" style="font-size: 15pt">Therapeut</span>
  </span>
</template>
<script>
export default {
  props: ['maProp'],
}
</script>
