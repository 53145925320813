import _ from 'lodash';
import kontakte from '~/mixins/kontakte';
import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
import lokiHelper from '~/mixins/lokiHelper';
export default {
  mixins: [kontakte, suche, lokiHelper],
  methods: {
    loadMitarbeiter(manuell = false) {
      this.$store.dispatch('mitarbeiter/sync', manuell);
    },
    resetMitarbeiter() {
      this.$store.commit('mitarbeiter/reset');
      this.mitarbeiterErneuern();
      this.loadMitarbeiter(true);
    },
    speichernMitarbeiter(model) {
      if (!this.$can('Mitarbeiter verwalten') && (this.$auth.user.model_type != 'Mitarbeiter' || model.id != this.$auth.user.model_id)) return;
      this.$store.dispatch('mitarbeiter/speichern', model);
      setTimeout(() => {
        this.loadMitarbeiter();
      }, 1000);
    },
    mitarbeiterLoeschen(mitarbeiter) {
      this.$confirm(
        {
          message: 'Möchtest du den gewählten Mitarbeiter wirklich archivieren?',
          button: {
            no: 'Nicht archivieren',
            yes: 'Ja archivieren'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('mitarbeiter/loeschen', mitarbeiter);
            }
          }
        }
      )
    },

    async mitarbeiterAbrufen() {
      this.getMitarbeiter = true;
      this.registerMitarbeiterEvent();
      if (!this.suchbegriff) {
        this.mitarbeiter = await this.getElemente('mitarbeiter', this.mitarbeiterFilter);
        this.mitarbeiterCount = this.mitarbeiter.length;
        return;
      }

      // Kontakte mit Suchbegriff suchen
      let kontakteIds = await this.kontakteSuchen('model_id', 'Mitarbeiter');

      // Mitarbeiter suchen anhand Kontakte
      let mitarbeiterCollection = await dbHelper.getDb('mitarbeiter');
      this.mitarbeiter = await mitarbeiterCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')) || kontakteIds.includes(obj.id))}
      );
    },
    mitarbeiterAenderungenAbrufen() {
      this.getMitarbeiterAenderungen = true;
      this.registerMitarbeiterEvent();
      return this.getAenderungen('mitarbeiter').then(data => this.mitarbeiterAenderungen = data);
    },
    maAbrufen(id) {
      this.getMa = id
      this.registerMitarbeiterEvent();
      return this.getElement('mitarbeiter', {id: id}).then(mitarbeiter => {if (Object.keys(mitarbeiter).length) this.ma = mitarbeiter});
    },
    registerMitarbeiterEvent() {
      if (!this.mitarbeiterEvent) this.mitarbeiterEvent = this.$nuxt.$on('mitarbeiter-sync', this.mitarbeiterErneuern);
    },
    mitarbeiterErneuern(event) {
      if (this.getMa) {
        if (event && this.getMa == event.id) this.ma = event;
        else  this.maAbrufen(this.getMa);
      }
      if (this.getMitarbeiter) this.mitarbeiterAbrufen();
      if (this.getMitarbeiterAenderungen) this.mitarbeiterAenderungenAbrufen();
    },

  },
  created() {
    this.standMitarbeiter;
  },
  mounted() {
    if (!this.$store.getters['mitarbeiter/filled']) this.loadMitarbeiter();
  },
  beforeDestroy() {
    this.$nuxt.$off('mitarbeiter-sync', this.mitarbeiterErneuern);
    this.mitarbeiterEvent = null;
  },
  computed: {
    therapeuten: function() {
      this.standMitarbeiter;
      return this.mitarbeiter.filter(ma => {
        return $nuxt.$istTherapeut(ma);
      });
    },
    logoTherapeuten: function() {
      this.standMitarbeiter;
      return this.mitarbeiter.filter(ma => {
        return ($nuxt.$istTherapeut(ma) && $nuxt.$istTherapeut(ma).fachbereich == 0);
      });
    },
    ergoTherapeuten: function() {
      this.standMitarbeiter;
      return this.mitarbeiter.filter(ma => {
        return ($nuxt.$istTherapeut(ma) && $nuxt.$istTherapeut(ma).fachbereich == 1);
      });
    },
    standMitarbeiter: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['mitarbeiter/getStand'];
      let jetzt = new Date();

      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadMitarbeiter();
      return stand;
    }
  },
  data() {
    return {
      ma: null,
      mitarbeiter: [],
      mitarbeiterCount: 0,
      mitarbeiterAenderungen: [],
      getMa: false,
      getMitarbeiter: false,
      getMitarbeiterAenderungen: false,
      mitarbeiterEvent: false,
      mitarbeiterFilter: null,
      orderbyMitarbeiter: 'kontakt.nachname',
    }
  },
}
