<template>
  <div v-if="arbeitszeit && anfahrt && abfahrt">
      <legend>Arbeitszeit</legend>
      <table class="table table-sm">
        <tr v-if="arbeitszeit.erfassen">
          <td>Zeit</td>
          <td>{{$helpers.datumFormatieren(arbeitszeit.von, 'DD.MM HH:mm')}} - {{$helpers.datumFormatieren(arbeitszeit.bis, 'HH:mm')}} ({{arbeitszeit.dauer}} Min)</td>
        </tr>
        <tr v-if="anfahrt && anfahrt.erfassen">
          <td>Anfahrt</td>
          <td>
            {{anfahrt.dauer}} Min
            <span class="d-block" v-if="anfahrt.km">{{anfahrt.km}} km</span>
            <!-- <span v-if="anfahrt.arbeitszeit"><span class="badge badge-secondary">Arbeitszeit</span></span> -->
          </td>
        </tr>
        <tr v-if="abfahrt && abfahrt.erfassen">
          <td>Rückweg</td>
          <td>
            {{abfahrt.dauer}} Min
            <span class="d-block" v-if="abfahrt.km">{{abfahrt.km}} km</span>
            <!-- <span v-if="abfahrt.arbeitszeit"><span class="badge badge-secondary">Arbeitszeit</span></span> -->
          </td>
        </tr>
        <!-- <tr v-if="anfahrt && anfahrt.erfassen">
          <td>Summe</td>
          <td>
            {{ summeArbeitszeit }} Arbeitszeit
          </td>
        </tr> -->
      </table>
  </div>
</template>
<script>
export default {
  props: ['anfahrt', 'abfahrt', 'arbeitszeit'],
  computed: {
    summeArbeitszeit: function() {
        let az = this.arbeitszeit;
        let summe = Number(this.arbeitszeit.dauer);
        if (this.anfahrt && this.anfahrt.arbeitszeit) {
          summe += Number(this.anfahrt.dauer);
          if (this.abfahrt && this.abfahrt.arbeitszeit) {
            summe += Number(this.abfahrt.dauer);
          }
        }
        let stunden = Math.floor(summe / 60);

        let minuten = summe % 60;
        if (stunden > 0) return stunden + "h " + ((minuten > 0) ? minuten + " Min" : "");
        return minuten + " Min";
      }
  }
}
</script>
