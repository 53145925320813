<template>
  <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-12">
            <suchfeld v-model="suchbegriff" :key="suchfeldkey" @focus="erstesuche = true"></suchfeld>
            <suchfunktion-ergebnisse @close="suchbegriff = ''; suchfeldkey++" v-if="erstesuche" :class="{'d-none': !suchbegriff.length}" :suchbegriff="suchbegriff" @faq="showFaq($event)"></suchfunktion-ergebnisse>
          </div>
      </div>
    <faq-modal :faq="displayFaq" id="faq-suche"></faq-modal>
  </div>
</template>
<script>
import FaqModal from '~/components/mitarbeiter/faqs/modal';
import faqs from '~/mixins/faqs';
export default {
  components: {FaqModal},
  mixins: [faqs],
  data() {
    return {
      suchfeldkey: 0,
      suchbegriff: '',
      erstesuche: false,
      displayFaq: {},
    }
  },
  // watch: {
  //   suchbegriff() {
  //     this.erstesuche = true;
  //   }
  // },
  methods: {
    showFaq(faq) {
      this.displayFaq = faq;
      this.$bvModal.show('faq-suche');
    },
    async onFaq(param) {
      if (typeof param === 'string') {
          await this.faqAbrufen(param);
          this.showFaq(this.faq);
        }
        else {
          this.showFaq(param);
        }
    },
    start() {
      this.$nuxt.$on('faq', this.onFaq)
    }
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {
    this.$nuxt.$off('faq', this.onFaq)
  }

}
</script>
