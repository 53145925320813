<template>
    <span>
        <datalist v-if="prefills" :id="'icd_10_code'+_uid"><option :value="bez" v-for="bez in Object.values(prefills).sort()" :key="bez" /></datalist>
        <the-mask :masked="true" @change="$emit('change', text)" @input="$emit('input', text)" :list="'icd_10_code'+_uid"  mask="XXX!.XX" class="form-control" placeholder="ICD-10 Code" :id="id" v-model="text" />
    </span>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value', 'id', 'fachbereich'],
  data() {
    return {
      text: this.value
    }
  },
  computed: {
    prefills() {
      // Verordnungprefills erneuern, falls die neuen Daten noch nicht da sind
      if (this.verordnungPrefills && this.fachbereich != null && !this.verordnungPrefills[this.fachbereich]) this.$store.dispatch('prefills/loadVerordnung');

      if (this.fachbereich != null && this.verordnungPrefills[this.fachbereich]) return this.verordnungPrefills[this.fachbereich].icd_10_codes;
      return this.verordnungPrefills.icd_10_codes;
    }
  },
}
</script>
