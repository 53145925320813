<template>
<div class="row">
  <div class="col">
    <div class="row">
      <div class="col" v-if="logoTherapeuten.length">
        <fachbereich-badge :fachbereich="0"></fachbereich-badge>
        <button class="btn btn-sm btn-outline-primary m-1" @click="preset(therapeut)" v-for="therapeut in logoTherapeutenFiltered" :key="therapeut.id"><kontakt-anzeigen @kontakt="therapeut.kontakt = $event" :filter="{model_type: 'Mitarbeiter', model_id: therapeut.id}"></kontakt-anzeigen></button>
      </div>
      <div class="col" v-if="ergoTherapeuten.length">
        <fachbereich-badge :fachbereich="1"></fachbereich-badge>
      <button class="btn btn-sm btn-outline-primary m-1" @click="preset(therapeut)" v-for="therapeut in ergoTherapeutenFiltered" :key="therapeut.id"><kontakt-anzeigen @kontakt="therapeut.kontakt = $event" :filter="{model_type: 'Mitarbeiter', model_id: therapeut.id}"></kontakt-anzeigen></button>
      </div>
    </div>
    <div class="row">
      <div class="ml-2 col-2">Standort</div>
      <div class="col">Räume</div>
      <div class="ml-2 col-2">Material</div>
      <div class="ml-2 col-2">Fahrzeuge</div>
    </div>
    <template v-if="standorte.length">
      <hr>
      <div v-for="standort in standorte" :key="standort.id" class="row">
        <div class="col-2"><button :style="{'background-color': standort.color+'20'}" class="btn btn-sm btn-outline-dark m-1" @click="presetStandort(standort)" :disabled="!standort.raeume.length">{{ standort.name }}</button></div>
        <div class="col"><button class="btn btn-sm btn-outline-dark m-1" @click="presetRaum(raum)" v-for="raum in standort.raeume" :key="raum.id">{{ raum.name }}</button></div>
        <div class="col-2"><button class="btn btn-sm btn-outline-dark m-1" @click="presetMaterial(material)" v-for="material in standort.materialien" :key="material.id">{{ material.name }}</button></div>
        <div class="col-2"><button class="btn btn-sm btn-outline-dark m-1" @click="presetFahrzeug(fahrzeug)" v-for="fahrzeug in standort.fahrzeuge" :key="fahrzeug.id">{{ fahrzeug.bezeichnung }}</button></div>
      </div>
    </template>
  </div>
</div>
</template>
<script>
import mitarbeiter from '~/mixins/mitarbeiter';
import kalenderfunktionen from '~/mixins/kalenderfunktionen';
import ressourcen from '~/mixins/ressourcen';
export default {
  mixins: [mitarbeiter, kalenderfunktionen, ressourcen],
  computed: {
    logoTherapeutenFiltered() {
      if (!this.logoTherapeuten || !this.logoTherapeuten[0].kontakt) return this.logoTherapeuten;
      return _.sortBy(this.logoTherapeuten, function (obj) {
        if (obj.kontakt) return obj.kontakt.nachname + ' ' + obj.kontakt.vorname;
        return obj.id;
      })
    },
    ergoTherapeutenFiltered() {
      if (!this.ergoTherapeuten || !this.ergoTherapeuten[0].kontakt) return this.ergoTherapeuten;
      return _.sortBy(this.ergoTherapeuten, function (obj) {
        if (obj.kontakt) return obj.kontakt.nachname + ' ' + obj.kontakt.vorname;
        return obj.id;
      })
    },
  },
  methods: {
    async preset(mitarbeiter) {
      this.resetSpalten();
      await this.kontaktAbrufenFilter({model_type: 'Mitarbeiter', model_id: mitarbeiter.id});
     for (let index = 0; index < 5; index++) {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = mitarbeiter;
        addSpalte.model_id = mitarbeiter.id;
        addSpalte.model_type = "Mitarbeiter";
        addSpalte.titel = this.kontakt.nachname+', '+this.kontakt.vorname;
        addSpalte.filter.mitarbeiter_id.push(mitarbeiter.id);
        addSpalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      }
      this.getApiData();
    },
    presetRaum(raum) {
      this.resetSpalten();
     for (let index = 0; index < 5; index++) {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = raum;
        addSpalte.model_id = raum.id;
        addSpalte.model_type = "Raum";
        addSpalte.titel = raum.name;
        addSpalte.filter.raum_id.push(raum.id);
        addSpalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      }
      this.getApiData();
    },
    presetMaterial(material) {
      this.resetSpalten();
     for (let index = 0; index < 5; index++) {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = material;
        addSpalte.model_id = material.id;
        addSpalte.model_type = "Material";
        addSpalte.titel = material.name;
        addSpalte.filter.materialien.push(material.id);
        addSpalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      }
      this.getApiData();
    },
    presetFahrzeug(fahrzeug) {
      this.resetSpalten();
     for (let index = 0; index < 5; index++) {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = fahrzeug;
        addSpalte.model_id = fahrzeug.id;
        addSpalte.model_type = "Fahrzeug";
        addSpalte.titel = fahrzeug.bezeichnung;
        addSpalte.filter.fahrzeuge.push(fahrzeug.id);
        addSpalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      }
      this.getApiData();
    },
    presetStandort(standort) {
      this.resetSpalten();
      standort.raeume.forEach(raum => {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = raum;
        addSpalte.model_id = raum.id;
        addSpalte.model_type = "Raum";
        addSpalte.titel = raum.name;
        addSpalte.filter.raum_id.push(raum.id);
        addSpalte.datum = this.$moment().format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      })
      this.getApiData();
    }
  },
  mounted() {
    this.mitarbeiterAbrufen();
    this.standorteAbrufen();
  }
}
</script>
