import dbHelper from '~/mixins/dbHelper';
export default {
  methods: {
    async getElement(table, filter) {
      let f = this.$helpers.copy(filter);
      if (!this.$trashedMode) {
        if (!f) f = {};
        f.deleted_at = {'$eq': null};
      }
      return this.$helpers.copy(await dbHelper.getElement(table, f));
    },
    async getElemente(table, filter) {
      let f = this.$helpers.copy(filter);
      if (!this.$trashedMode) {
        if (!f) f = {};
        f.deleted_at = {'$eq': null};
      }
      return this.$helpers.copy(await dbHelper.getElemente(table, f));
    },
    async getAenderungen(table) {
      // if (table == 'verordnungen' || table == 'therapiestunden' || table == 'ausfallstunden' || table == 'verordnungsaenderungen') return [];
      let data = await dbHelper.getAenderungen(table, this.$store.getters[table+'/getStand']);
      return this.$helpers.copy(data);
    },
  },
}
