<template>
  <multiselect
    :key="counter"
    :class="{'is-invalid': isinvalid}"
    track-by="id"
    v-model="krankenkasse"
    placeholder="Krankenkasse wählen"
    :id="'krankenkasse'+id"
    :options="krankenkassen"
    :show-labels="false"
    @select="update($event)"
    @remove="update(null)"
    :custom-label="krankenkassenLabel"
  >
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import krankenkassen from '~/mixins/krankenkassen';
export default {
  components: {Multiselect},
  mixins: [krankenkassen],
  props: {
    value: String,
    isinvalid: {
      default: false
    }
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    },
    krankenkassen(neu, alt) {
      this.externerInput(this.value);
    }
  },
  data() {
    return {
      krankenkasse: [],
      counter: 0,
      id: this._uid,
    }
  },
  methods: {
    update(element) {
      if (this.krankenkasse != element) this.$emit('update', element);
      this.krankenkasse = element;
      if (!element || !element.id) return this.$emit('input', null);
      this.$emit('input', element.id);
    },
    externerInput(neu) {
      if (neu) this.krankenkasseAbrufen(neu);
      else this.krankenkasse = [];
      this.counter++;
    },
    krankenkassenLabel(krankenkasse) {
      if (krankenkasse.kontakt) return krankenkasse.kontakt.name;
    }
  },
  mounted() {
    this.externerInput(this.value);
    this.krankenkassenAbrufen();
  }
}
</script>
