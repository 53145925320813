import verordnungen from '~/mixins/verordnungen';
import verordnunghelfer from '~/mixins/helfer/verordnunghelfer';
import therapiestunden from '~/mixins/therapiestunden';
export default {
  mixins: [verordnungen, verordnunghelfer, therapiestunden],
  data() {
    return {
      neuerVerordnungsfall: null
    }
  },
  computed: {
    therapiestundenFrei() {
      if (!this.verordnung) return 0;
      if (!this.verordnungsmenge(this.verordnung)) return -1;
      let ret = this.verordnungsmenge(this.verordnung) - this.therapiestunden.length;
      if (this.neuerVerordnungsfall && this.neuerVerordnungsfall.wert) ret += 1;
      if (ret < 0) return 0;
      return ret;
    }
  },
  methods: {
    async erklaerungBauen() {
      this.verordnungenFilter = {patient_id: this.verordnung.patient_id};
      await this.verordnungenAbrufen();

      let kommentar = 'Neuer Verordnungsfall, Begründung: ';
        // Letzte Verordnung nach Datum
        let alt = this.$helpers.sortArray(this.verordnungen, 'datum', 'desc');
        alt = alt.filter(element => {
          return (element.datum < this.verordnung.datum && element.fachbereich == this.verordnung.fachbereich);
        })

        let neuerFall = false;
        if (alt.length) {
          if (alt[0].indikationsschluessel != this.verordnung.indikationsschluessel && (alt[0].icd_10_code != this.verordnung.icd_10_code || alt[0].icd_10_code_2 != this.verordnung.icd_10_code_2)) {neuerFall = true; kommentar += 'Diagnosgruppe und ICD-10-Code geändert';}
          // Ausstellungsdatum älter als 6 Monate
          else if (this.$moment(alt[0].datum) < this.$moment(this.verordnung.datum).subtract(6, 'months')) {neuerFall = true; kommentar += 'Mehr als 6 Monate seit Ausstellungsdatum der letzten Verordnung';}
        }
        else {
          neuerFall = true;
          kommentar += 'Keine alte Verordnung vorhanden';
        }

        this.neuerVerordnungsfall = {wert: neuerFall, kommentar: (neuerFall ? kommentar : '')};
        this.$emit('neuerFall', this.neuerVerordnungsfall);
    },
    start() {
      if(!this.verordnung || !this.verordnung.patient_id) return;
      this.verordnungenFilter = {patient_id: this.verordnung.patient_id};
      this.therapiestundenFilter = {verordnung_id: this.verordnung.id, deleted_at: null};
      this.therapiestundenAbrufen();
      this.erklaerungBauen();
    }
  },
  mounted() {
    this.start();
  },
}
