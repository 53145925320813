import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';
import axios from 'axios';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty),
  name: 'services'
})

const mutations = {
  setAll: storeHelperOld.setAll,
  // set: storeHelper.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  find: (state, dispatch) => (service) => {
    if (state.globals.elemente[service]) return state.globals.elemente[service];
    console.error(service, 'Services nicht gefunden');
    return null;
    }
}

const actions = {
  async load({commit, state}) {
    if (state.globals.loading) return;
    commit('loading');
    try {
      let response = await axios.get(process.env.SERVICES+'/v1/services?stage='+process.env.STAGE);
      commit('setAll', response.data);
    }
    catch (error) {
      commit('loading', false);
      $nuxt.$notifyError('Die Dienste konnten nicht abgerufen werden. Ich versuche es in einer Minute erneut.')
      setTimeout(() => {
        $nuxt.$store.dispatch('services/load');
      }, 5000)
    }
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
