export default {
  methods: {
    // terminLoeschen(termin) {
    //   return new Promise((resolve) => {
    //     this.$confirm(
    //       {
    //         message: 'Möchtest du den Termin wirklich löschen?',
    //         button: {
    //           no: 'Nicht löschen',
    //           yes: 'Ja löschen'
    //         },
    //         callback: async confirm => {
    //           if (confirm) {
    //             await this.$axios.delete('{kalender}/v1/termine/'+termin.id);
    //             resolve();
    //           }
    //           else resolve();
    //         }
    //       }
    //     )
    //   })
    // },
    terminLoeschen(termin, serie = false) {
      return new Promise(async (resolve) => {
        await this.$axios.delete('{kalender}/v1/termine/'+termin.id+'?serie='+serie);
        resolve();
      });
    },
    terminSpeichern(termin) {
      return this.$axios.put('{kalender}/v1/termine', termin);
    },
    terminAbrufen(termin_id) {
      this.$axios.get('{kalender}/v1/termine/'+termin_id).then(response => {
        let termin = response.data;
        termin.details = true;
        this.$store.commit('kalender/speichernTermin', termin);

      });
    }
  }
}
