<template>
  <span v-if="neuerVerordnungsfall && neuerVerordnungsfall.wert">(+1) <tooltip-information><b>+1</b> weil eine zusätzliche Diagnostik erlaubt ist
      <br>(Es ist das erste Rezept, oder der ICD10 Code und die Diagnosegruppe haben sich geändert)
      <span class="d-block mt-3"><b>{{neuerVerordnungsfall.kommentar}}</b></span>
      </tooltip-information>
    <tooltip-information type="danger" v-if="verordnungProp.verordnungsmenge_geaendert">{{verordnungProp.verordnungsmenge_geaendert}}</tooltip-information>
  </span>
</template>
<script>
import neuerverordnungsfall from '~/mixins/helfer/neuerverordnungsfall';
export default {
  mixins: [neuerverordnungsfall],
  props: ['verordnungProp'],
  mounted() {
    this.verordnung = this.verordnungProp;
  }
}
</script>
