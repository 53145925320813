import _ from 'lodash';
import lokiHelper from '~/mixins/lokiHelper';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    loadAusfallstunden(manuell = false) {
      if (!this.$can('Ausfall verwalten')) return;
      this.$store.dispatch('ausfallstunden/sync', manuell);
    },
    resetAusfallstunden() {
      this.$store.commit('ausfallstunden/reset');
      this.loadAusfallstunden(true);
    },
    async speichernAusfallstunde(model) {
      this.$store.dispatch('ausfallstunden/speichern', model);
      setTimeout(() => {
        this.loadAusfallstunden();
      }, 1000);
    },
    ausfallLoeschen(stunde) {
      this.$confirm(
        {
          message: `Möchtest du die Ausfallstunde vom ` +this.$helpers.datumFormatieren(stunde.von) + ' wirklich löschen? Deine Arbeitszeit bleibt unverändert.',
          button: {
            no: 'Nicht löschen',
            yes: 'Ja löschen'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('ausfallstunden/loeschen', stunde);
            }
          }
        }
      )
    },


    async ausfallstundenAbrufen() {
      this.getAusfallstunden = true;
      this.registerausfallstundenEvent();
      if (!this.suchbegriff) {
       this.ausfallstunden = await this.getElemente('ausfallstunden', this.ausfallstundenFilter);
       this.ausfallstundenCount = this.ausfallstunden.length;
       return;
      }

      // ausfallstunden suchen
      let ausfallstundenCollection = await dbHelper.getDb('ausfallstunden');
      this.ausfallstunden = await ausfallstundenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    ausfallstundenAenderungenAbrufen() {
      this.getAusfallstundenAenderungen = true;
      this.registerausfallstundenEvent();
      return this.getAenderungen('ausfallstunden').then(data => this.ausfallstundenAenderungen = data);
    },
    ausfallstundeAbrufen(id) {
      this.getAusfallstunde = id
      this.registerausfallstundenEvent();
      return this.getElement('ausfallstunden', {id: id}).then(ausfallstunde => {if (Object.keys(ausfallstunde).length) this.ausfallstunde = ausfallstunde});
    },
    registerausfallstundenEvent() {
      if (!this.ausfallstundeEvent) this.ausfallstundeEvent = this.$nuxt.$on('ausfallstunden-sync', this.ausfallstundenErneuern);
    },
    ausfallstundenErneuern(event) {
      if (this.getAusfallstunde) {
        if (event && this.getAusfallstunde == event.id) this.ausfallstunde = event;
        else  this.ausfallstundeAbrufen(this.getAusfallstunde);
      }
      if (this.getAusfallstunden) this.ausfallstundenAbrufen();
      if (this.getAusfallstundenAenderungen) this.ausfallstundenAenderungenAbrufen();
    },

  },
  created() {
    this.standAusfallstunden;
  },
  mounted() {
    if (!this.$store.getters['ausfallstunden/filled']) this.loadAusfallstunden();
  },
  computed: {
    standAusfallstunden: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['ausfallstunden/getStand'];
      let jetzt = new Date();

      // if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadAusfallstunden();
      return stand;
    }
  },
  data() {
    return {
      ausfallstunde: null,
      ausfallstunden: [],
      ausfallstundenCount: 0,
      ausfallstundenAenderungen: [],
      getAusfallstunde: false,
      getAusfallstunden: false,
      getAusfallstundenAenderungen: false,
      ausfallstundeEvent: null,
      ausfallstundenFilter: null,
      ausfallstundenOrderby: ['datum', 'desc'],
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('ausfallstunden-sync', this.ausfallstundenErneuern);
    this.ausfallstundevent = null;
  },
}
