<template>
  <div class="kalender-termine" v-if="termine">
    <termin v-for="termin in termine" :key="termin.id" :termin="termin"></termin>
  </div>
</template>
<script>
import kalender from '~/mixins/kalender';
import Termin from '~/components/kalender/Termin';
export default {
  props: ['spalte'],
  mixins: [kalender],
  components: {Termin},
  computed: {
    termine() {
      if (!this.spaltenData || !this.spaltenData.spalten[this.spalte.id] || !this.spaltenData.spalten[this.spalte.id].termine.length) return null;
      let termine = this.$helpers.sortArray(this.spaltenData.spalten[this.spalte.id].termine, 'von', 'asc');
      termine = termine.map(termin => {termin.uebernscheidung = 0; termin.ueberschneidungen_anzahl = 1; return termin;});

      for (let index = 0; index < termine.length; index++) {
        if (!termine[index].ueberschneidung) termine[index].ueberschneidung = 0;
        if (!termine[index].ueberschneidungen_anzahl) termine[index].ueberschneidungen_anzahl = 1;
        if (index > 0) {
          if (termine[index-1].bis > termine[index].von) {
            // Vorheriger hatte schon eine Überschneidung
            termine = this.vorherigenAnpassen(termine, index);
            termine[index-1].ueberschneidungen_anzahl++;
            termine[index].ueberschneidungen_anzahl++;

            // Aktueller Termin kriegt Wert vorheriger plus 1
            termine[index].ueberschneidung = termine[index-1].ueberschneidung + 1;
            termine[index].ueberschneidungen_anzahl = termine[index-1].ueberschneidungen_anzahl;
          }
        }
      }
      return termine;
    },
  },
  methods: {
    vorherigenAnpassen(termine, index) {
      if (termine[index-1] && termine[index-1].ueberschneidungen_anzahl > 1 && termine[index-2]) {
          termine[index-2].ueberschneidungen_anzahl++;
          this.vorherigenAnpassen(termine, index-1);
        }
        return termine;
    }
  }
}
</script>
