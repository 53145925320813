<template>
  <div>
    <b-modal :id="id" :title="'Termin absagen'" hide-footer size="lg" @show="show()" @hidden="emptyTermin()">
      <form @submit.prevent="speichern()">
        <div v-if="termin">
          <div class="row form-group">
            <label :for="'termin_info'+id" class="col-2 col-form-label mt-auto">Begründung</label>
            <div class="col">
              <textarea-autosize
                v-if="!$isIOS()"
                class="form-control"
                placeholder="Begründung"
                v-model="ausfallstunde.begruendung"
                rows="2"
                :min-height="35"
              />
              <textarea
                v-if="$isIOS()"
                class="form-control"
                v-model="ausfallstunde.begruendung"
                placeholder="Begründung"
                rows="3">
              </textarea>
            </div>
          </div>
          <div class="row" @keyup.enter="$refs.wizard.nextTab()">
            <label class="col-2 col-form-label" :for="'kurzfristig'+id">Kurzfristig</label>
            <div class="col">
              <label class="col-form-label form-check-label" :for="'kurzfristig'+id">
                  <input class="" type="checkbox" value="1" :id="'kurzfristig'+id" v-model="ausfallstunde.kurzfristig" @change="kurzfristig()">
                  Die Stunde wurde kurzfristig (weniger als 24h vorher) abgesagt
              </label>
            </div>
          </div>
          <div class="row" @keyup.enter="$refs.wizard.nextTab()" v-if="ausfallstunde.kurzfristig">
            <label class="col-2 col-form-label" :for="'berechnen'+id">Berechnen</label>
            <div class="col">
              <label class="col-form-label form-check-label" :for="'berechnen'+id">
                  <input class="" type="checkbox" value="1" :id="'berechnen'+id" v-model="ausfallstunde.berechnen">
                  Die Stunde soll dem Patienten in Rechnung gestellt werden
              </label>
            </div>
        </div>

        <div class="form-group">
            <button class="btn btn-outline-primary float-right">Termin Absagen</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import ausfallstunden from '~/mixins/ausfallstunden';
import kalenderfunktionen from '~/mixins/kalenderfunktionen';
export default {
  mixins: [ausfallstunden, kalenderfunktionen],
  components: {},
  data() {
    return {
      id: 'termin-absage-'+this._uid,
      showError: false,
      termin: null,
      ausfallstunde: null,
      invalid: false,
      event: null,
    }
  },
  methods: {
    emptyTermin() {
      this.showError = false;
      this.termin = null
      this.ausfallstunde = {
        id: this.$uuid.v1(),
        patient_id: null,
        verordnung_id: null,
        mitarbeiter_id: null,
        begruendung: '',
        kurzfristig: false,
        berechnen: false,
        von: null,
        bis: null,
        dauer: null,
        hausbesuch: 0
      }
    },
    speichern() {
      this.ausfallstunde.von = this.termin.von;
      this.ausfallstunde.bis = this.termin.bis;
      this.ausfallstunde.dauer = this.$moment(this.termin.bis).diff(this.termin.von) / 60000;
      this.ausfallstunde.verordnung_id = this.termin.verordnung_id;
      this.ausfallstunde.mitarbeiter_id = this.$auth.user.model_id;
      this.ausfallstunde.patient_id = this.termin.patient_id[0];

      let begruendung = (this.ausfallstunde.begruendung != '') ? this.ausfallstunde.begruendung : 'Keine Begründung';

      this.$bvModal.hide(this.id);
      this.speichernAusfallstunde(this.ausfallstunde);
      let sendTermin = {id: this.termin.id, abgesagt: begruendung}
      this.terminSpeichern(sendTermin).then(response => {
        this.getApiData();
        this.emptyTermin();
      }, error => {
        this.$bvModal.show(this.id);
        this.$notifyError('Termin Absagen fehlgeschlagen', error);
      })
    },
    show() {
      this.$root.$emit('bv::hide::tooltip');
    },
    kurzfristig() {
      if (this.ausfallstunde.kurzfristig) this.ausfallstunde.berechnen = true;
      else this.ausfallstunde.berechnen = false;
    },
    terminAbsage(termin) {
      termin = this.$helpers.copy(termin);
      this.termin = termin;
      this.$bvModal.show(this.id);
    }
  },
  mounted() {
    this.emptyTermin()
    this.event = this.$nuxt.$on('kalender-termin-absage', this.terminAbsage);
  },
  beforeDestroy() {
    this.$nuxt.$off('kalender-termin-absage', this.terminAbsage);
  }
}
</script>
