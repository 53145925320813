import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';

export const state = () => ({
  rollen: [],
  permissions: [],
  globals: {
    date: new Date(0),
    loading: false,
  }
})

const mutations = {
  reset(state) {
    state.rollen = [];
    state.permissions = [];
    state.globals.loading = false;
    state.date = new Date(0);
  },
  setAll(state, data) {
    state.rollen = data.rollen;
    state.permissions = data.permissions;
    state.globals.loading = false;
    state.globals.date = new Date();
  },
  set(state, rolle) {
    const item = state.rollen.find(r => r.id === rolle.id)
    if (item) state.rollen.splice(state.rollen.indexOf(item), 1, rolle)
    else state.rollen.push(rolle);
  },
  loading: storeHelper.loading,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getRollen: (state) => {
    return state.rollen;
  },
  getPermissions: (state) => {
    return state.permissions;
  },
  getStand: storeHelper.getStand,
  filled: (state) => {
    return (state.rollen.length) ? true : false;
  }
}

const actions = {
  async load({commit, state}) {
    if (state.globals.loading) return;
    commit('loading');
    try {
      let response = await this.$axios.get('{auth}/v1/rollen');
      commit('setAll', response.data);
    } catch (e) {
      $nuxt.$notifyError('Daten abrufen', 'Rollen konnten nicht abgerufen werden.', e);
      setTimeout(() => {
        commit('loading', false);
      }, 5000)
    }
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
