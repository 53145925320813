import verordnungpruefen from '~/mixins/verordnungpruefen';
import verordnungenStatic from '~/mixins/static/verordnungen';
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
import lokiHelper from '~/mixins/lokiHelper';
import _ from 'lodash';
import verordnunghelfer from './helfer/verordnunghelfer';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
import kontakte from '~/mixins/kontakte';
export default {
  mixins: [verordnungpruefen, heilmittelkatalog, lokiHelper, verordnunghelfer, suche, kontakte, verordnungenStatic],
  methods: {
    loadVerordnungen(manuell = false) {
      this.$store.dispatch('verordnungen/sync', manuell);
    },
    resetVerordnungen() {
      this.$store.commit('verordnungen/reset');
      this.loadVerordnungen(true);
    },
    async speichernVerordnung(model) {
      if (!model.therapiefrequenz) model.therapiefrequenz = null;
      this.$store.dispatch('verordnungen/speichern', model);
      setTimeout(() => {
        this.loadVerordnungen();
      }, 1000);
    },

    async verordnungenAbrufen() {
      await this.verordnungenAbrufenStatic();
      this.getVerordnungen = true;
      this.registerVerordnungenEvent();
      return;
      if (!this.suchbegriff) {
       this.verordnungen = await this.getElemente('verordnungen', this.verordnungenFilter);
       this.verordnungenCount = this.verordnungen.length;
       return;
      }

      // Kontakte mit Suchbegriff suchen (Ärzte, Patienten => lässt sich so noch nicht filtern, also alle)
      // let kontakteIds = await this.kontakteSuchen('model_id');

      // Verordnungen suchen
      let verordnungenCollection = await dbHelper.getDb('verordnungen');
      this.verordnungen = await verordnungenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
        // return (this.suchenObject(obj, this.suchbegriff.split(' ')) || kontakteIds.includes(obj.patient_id))}
        // return (this.suchenObject(obj, this.suchbegriff.split(' ')) || kontakteIds.includes(obj.patient_id) || kontakteIds.includes(obj.arzt_id))}
      );
    },
    verordnungenAenderungenAbrufen() {
      this.getVerordnungenAenderungen = true;
      this.registerVerordnungenEvent();
      return this.getAenderungen('verordnungen').then(data => this.verordnungAenderungen = data);
    },
    verordnungAbrufen(id) {
      this.verordnungAbrufenStatic(id);
      this.getVerordnung = id
      this.registerVerordnungenEvent();
      return;
      return this.getElement('verordnungen', {id: id}).then(verordnung => {if (Object.keys(verordnung).length) this.verordnung = verordnung});
    },
    registerVerordnungenEvent() {
      if (!this.verordnungEvent) this.verordnungEvent = this.$nuxt.$on('verordnungen-sync', this.verordnungenErneuern);
      if (!this.events.ausfallstunde) this.events.ausfallstunde = this.$nuxt.$on('ausfallstunden-sync', this.verordnungenErneuern);
      if (!this.events.therapiestunde) this.events.therapiestunde = this.$nuxt.$on('therapiestunden-sync', this.verordnungenErneuern);
      if (!this.events.verordnungsaenderung) this.events.verordnungsaenderung = this.$nuxt.$on('verordnungsaenderungen-sync', this.verordnungenErneuern);
    },
    verordnungenErneuern(event) {
      if (this.getVerordnung) {
        if (event && this.getVerordnung == event.id) this.verordnung = event;
        else  this.verordnungAbrufen(this.getVerordnung);
      }
      if (this.getVerordnungen) this.verordnungenAbrufen();
      if (this.getVerordnungenAenderungen) this.verordnungenAenderungenAbrufen();
    },

    // patientVerordnungen(patient, nurFreieStunden = false, vorauswahl = null) {
    //   // Erlaube ID oder Object als Patient Input
    //   let patientid = (typeof patient == 'object') ? patient.id : patient;
    //   let alle = this.$helpers.allByKey(this.verordnungen, patientid, 'patient_id');
    //   if (!nurFreieStunden) return alle;
    //   alle = alle.filter((vo) => {
    //     return (this.therapiestundenFrei(vo));
    //   });
    //   if (vorauswahl) {
    //     // Wenn keine Therapiestunden mehr frei sind, dann die alte Verordnung (beim bearbeiten) wieder hinzufügen, sonst ist sie eh dabei.
    //     if (!this.therapiestundenFrei(this.findVerordnung(vorauswahl))) {
    //       alle.push(this.findVerordnung(vorauswahl));
    //     }
    //   }
    //   return alle;
    // },
    // verordnungSetStunde(therapiestunde, verordnung) {
    //   let vo = this.$helpers.copy(verordnung);
    //   let alt = this.$helpers.findByKey(vo.therapiestunden, therapiestunde.id);
    //   if (alt) vo.therapiestunden.splice(vo.therapiestunden.indexOf(alt), 1, therapiestunde);
    //   else vo.therapiestunden.push(therapiestunde);
    //   this.$store.commit('verordnungen/set', vo);
    // },
    // verordnungSetAusfallStunde(ausfallstunde, verordnung) {
    //   let vo = this.$helpers.copy(verordnung);
    //   let alt = this.$helpers.findByKey(vo.ausfallstunden, ausfallstunde.id);
    //   if (alt) vo.ausfallstunden.splice(vo.ausfallstunden.indexOf(alt), 1, ausfallstunde);
    //   else vo.ausfallstunden.push(ausfallstunde);
    //   this.$store.commit('verordnungen/set', vo);
    // },
    // verordnungRemoveStunde(therapiestunde) {

    //   let vo = this.$helpers.copy(this.findVerordnung(therapiestunde.verordnung_id));
    //   let alt = this.$helpers.findByKey(vo.therapiestunden, therapiestunde.id);
    //   if (alt) {
    //     vo.therapiestunden.splice(vo.therapiestunden.indexOf(alt), 1);
    //   }

    //   this.$store.commit('verordnungen/set', vo);
    // },
    // stundenAnzahl(stunden) {
    //   let count = 0;
    //   if (!stunden.length) return count;
    //   let verordnung = this.findVerordnung(stunden[0].verordnung_id);
    //   _.forEach(stunden, (stunde) => {
    //     if (!stunde.erstbefundung && !stunde.deleted_at) count++;
    //   });
    //   return count;
    // },
    // erstbefundungStattgefunden(verordnung) {
    //   let verordnungobj = (typeof verordnung == 'object') ? verordnung : this.findVerordnung(verordnung);
    //   let ret = false;
    //   _.forEach(verordnungobj.therapiestunden, (stunde) => {
    //     if (stunde.erstbefundung) ret = stunde;
    //   })
    //   return ret;
    // },
    // async therapiestundenFrei(verordnung) {
    //   if (! verordnung) return 0;
    //   if (!this.verordnungsmenge(verordnung)) return -1;
    //   let ret = this.verordnungsmenge(verordnung) - await this.getElemente('therapiestunden', {verordnung_id: verordnung.id, deleted_at: null}).length;

    //   if (this.erstbefundungErlaubt(verordnung)) ret += 1;
    //   if (ret < 0) return 0;
    //   return ret;
    // },
    verordnungLoeschen(verordnung) {
      this.$confirm(
        {
          message: 'Möchtest du die gewählte Verordnung wirklich löschen?',
          button: {
            no: 'Nicht löschen',
            yes: 'Ja löschen'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('verordnungen/loeschen', verordnung);
              setTimeout(() => {
                this.loadVerordnungen();
              }, 1000);
            }
          }
        }
      )
    },
    diagnoseInformation(verordnung) {
      if (!verordnung.indikationsschluessel) return;
      let katalog = this.katalog(verordnung);
      if (!katalog) return;
      let text = '<b>'+verordnung.indikationsschluessel+'</b><br>'+katalog.bezeichnung;
      if (!verordnung.leitsymptomatik) return text;
      _.forEach(verordnung.leitsymptomatik, (val, buchstabe) => {
        if (val && buchstabe != 'individuell') text += '<br><br><b>'+buchstabe+'</b><br>'+katalog.leitsymptomatik[buchstabe];
        else if (buchstabe == 'individuell' && val) text += '<br><br><b>'+buchstabe+'</b><br>'+verordnung.individuelle_leitsymptomatik;
      })
      return text;
    },
  },
  created() {
    this.standVerordnungen;
  },
  mounted() {
    if (!this.$store.getters['verordnungen/filled']) this.loadVerordnungen();
  },
  computed: {
    verordnungenLogo() {
      return this.verordnungen.filter((el) => {return el.fachbereich == 0});
    },
    verordnungenErgo() {
      return this.verordnungen.filter((el) => {return el.fachbereich == 1});
    },
    verordnungTypen: function() {
      return [
        {id: 1, desc: 'Erstverordnung'},
        {id: 2, desc: 'Folgeverordnung'},
        {id: 3, desc: 'Außerhalb des Regelfalls'},
      ];
    },
    verordnungFachbereiche() {
      return {0: 'Logopädie', 1: 'Ergotherapie'};
    },
    standVerordnungen: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['verordnungen/getStand'];
      let jetzt = new Date();

      // if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadVerordnungen();
      return stand;
    }
  },
  data() {
    return {
      verordnung: null,
      verordnungen: [],
      verordnungenCount: 0,
      verordnungenAenderungen: [],
      getVerordnung: false,
      getVerordnungen: false,
      getVerordnungAenderungen: false,
      verordnungEvent: false,
      events: {therapiestunde: false, verordnungsaenderung: false, ausfallstunde: false},
      verordnungenFilter: null,
      verordnungenOrderby: 'datum',
    }
  },
  beforeDestroy() {
    if (!this.verordnungEvent) this.verordnungEvent = this.$nuxt.$off('verordnungen-sync', this.verordnungenErneuern);
    if (!this.events.ausfallstunde) this.events.ausfallstunde = this.$nuxt.$off('ausfallstunden-sync', this.verordnungenErneuern);
    if (!this.events.therapiestunde) this.events.therapiestunde = this.$nuxt.$off('therapiestunden-sync', this.verordnungenErneuern);
    if (!this.events.verordnungsaenderung) this.events.verordnungsaenderung = this.$nuxt.$off('verordnungsaenderungen-sync', this.verordnungenErneuern);
  },
}
