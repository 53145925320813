<template>
  <h4 class="kalender-termintitel" v-if="termin.titel">
      {{termin.titel}}
    <fachbereich-badge v-if="displayFachbereich && termin.fachbereich != null" class="float-right" :fachbereich="termin.fachbereich"></fachbereich-badge>
  </h4>
  <h4 class="kalender-termintitel d-flex justify-content-between" v-else-if="termin.patient_id">
    <div>
      <span class="d-block" v-for="patient_id in termin.patient_id" :key="patient_id">
        <kontakt-anzeigen :display="{anrede: true}" :filter="{model_id: patient_id, model_type: 'Patient'}"></kontakt-anzeigen>
      </span>
    </div>
    <div>
      <fachbereich-badge v-if="displayFachbereich && termin.fachbereich != null" class="float-right" :fachbereich="termin.fachbereich"></fachbereich-badge>
    </div>
  </h4>
</template>
<script>
export default {
  props: ['termin', 'displayFachbereich']
}
</script>
