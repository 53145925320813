<template>
    <div v-if="heilmittel">
      <!-- <div class="form-row form-group">
        <div class="col-12">
          Heilmittel
        </div>
        <div class="col offset-1">Behandlungseinheiten</div>
      </div> -->
      <div class="form-row form-group">
        <div class="col-12 my-auto mt-auto">
          <div class="form-group">
            <multiselect
                v-model="heilmittel.heilmittel"
                placeholder="Ergänzendes Heilmittel"
                :options="heilmittelOptions"
                :show-labels="false"
              >
              </multiselect>
          </div>
        </div>
        <!-- <div class="col-2 my-auto text-right">
          <button class="btn btn-outline-secondary btn-sm" :class="{active: heilmittel.menge == '5'}" tabindex="-1" @click="heilmittel.menge = 5; update()">5</button>
          <button class="btn btn-outline-secondary btn-sm" :class="{active: heilmittel.menge == '10'}" tabindex="-1" @click="heilmittel.menge = 10; update()">10</button>
          <button class="btn btn-outline-secondary btn-sm" :class="{active: heilmittel.menge == '20'}" tabindex="-1" @click="heilmittel.menge = 20; update()">20</button>
        </div> -->
        <!-- <div class="col-2 my-auto">
          <datalist :id="'verordnungsmenge'+_uid"><option :value="bez" v-for="bez in verordnungPrefills.verordnungsmengen" :key="bez" /></datalist>
          <the-mask type="number" @change="update()" @input.native="update(); heilmittel.menge = Number($event.target.value)" :list="'verordnungsmenge'+_uid"  mask="###" class="form-control" placeholder="Menge" id="verordnungsmenge" :value="heilmittel.menge" />
        </div> -->
      </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import prefills from '~/mixins/prefills';
export default {
  components: {Multiselect},
  mixins: [prefills],
  props: ['value', 'fachbereich'],
  data() {
    return {
      heilmittel: {heilmittel: null, menge: null},
    }
  },
  methods: {
    update() {
      this.$emit('input', this.heilmittel);
      this.$emit('change', this.heilmittel);
    },
  },
  mounted() {
    if (this.value != null) this.heilmittel = this.value;
    this.update();
  },
  computed: {
    heilmittelOptions() {
      // Ergo
      return [
        'Thermische Anwendungen'
      ]
    }
  }
}
</script>
