<template>
  <b-modal :id="id" title="Schreiben erstellen" hide-footer @show="show()">
    <div>
      <multiselect
        track-by="id"
        placeholder="Neues Schreiben, Vorlage wählen"
        :options="vorlagen"
        :show-labels="false"
        label="name"
        v-model="vorlageSelected"
      >
      </multiselect>
    </div>
    <template v-if="vorlageSelected && vorlageSelected.variablen">
        <div class="mt-3 row">
          <div class="col-3 my-auto">
            <label for="titel">Titel</label>
          </div>
          <div class="col">
            <input type="text" class="form-control" v-model="titel" id="titel" placeholder="Titel für das Schreiben">
          </div>
        </div>
        <div class="mt-3">
          <hr>
          <h4>Anschrift übernehmen von</h4>
          <div class="alert alert-warning" v-if="modelsMitKontakt.length == 0">Keine Person zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelsMitKontakt">
              <label :for="'anschrift'+model.model.id" :key="model.model.id" class="d-block">
                <input type="radio" name="adresse" :value="model.model" v-model="adresse" :id="'anschrift'+model.model.id">
                <b>{{ model.model_type }}</b>
                <span v-if="model.model.kontakt">{{$ganzerName(model.model)}}</span>
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3">
          <hr>
          <h4>Schreiben verknüpfen mit</h4>
          <div class="ml-3">
            <template v-for="model in zuordnungenListe">
              <label :for="'zuordnungen'+model.model.id" :key="model.model.id" class="d-block" v-if="model.model">
                <input type="checkbox" :value="model" v-model="zuordnungen" :id="'zuordnungen'+model.model.id">
                <b>{{ model.model_type }}</b> <span v-if="model.model.kontakt">{{$ganzerName(model.model)}}</span>
                <span v-else-if="model.model_type == 'Verordnung' || model.model_type == 'Verordnungsaenderung'"><span v-if="model.model.datum">{{$helpers.datumFormatieren(model.model.datum)}}</span></span>
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3" v-if="variablenVorhandenTyp('Kontakt')">
          <hr>
          <h4>Variablen vom Typ Kontakt</h4>
          <div class="alert alert-warning" v-if="modelsMitKontakt.length == 0">Keine Person zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelsMitKontakt">
              <label :for="'kontakt'+model.model.id" :key="model.model.id" class="d-block">
                <input type="radio" name="kontaktvariablen" :value="model.model" v-model="kontaktvariablen" :id="'kontakt'+model.model.id">
                <b>{{ model.model_type }}</b> {{$ganzerName(model.model)}}
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3" v-if="variablenVorhandenTyp('Verordnung')">
          <hr>
          <h4>Variablen vom Typ Verordnung</h4>
          <div class="alert alert-warning" v-if="modelNachType('Verordnung').length == 0">Keine Verordnung zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelNachType('Verordnung')">
              <label :for="'verordnung'+model.model.id" :key="'vo'+model.model.id" class="d-block">
                <input type="radio" name="verordnungvariablen" :value="model.model" v-model="verordnungvariablen" :id="'verordnung'+model.model.id">
                <span v-if="model.model.datum">{{$helpers.datumFormatieren(model.model.datum)}}</span>
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3" v-if="variablenVorhandenTyp('Verordnungsaenderung')">
          <hr>
          <h4>Variablen vom Typ Verordnungsänderung</h4>
          <div class="alert alert-warning" v-if="modelNachType('Verordnungsaenderung').length == 0">Keine Änderung zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelNachType('Verordnungsaenderung')">
              <label :for="'aenderung'+model.model.id" :key="'aenderung'+model.model.id" class="d-block">
                <input type="radio" name="verordnungsaenderungvariablen" :value="model.model" v-model="verordnungsaenderungvariablen" :id="'aenderung'+model.model.id">
                <span v-if="model.model.datum">{{$helpers.datumFormatieren(model.model.datum)}}</span>
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3" v-if="variablenVorhandenTyp('Mitarbeiter')">
          <hr>
          <h4>Variablen vom Typ Mitarbeiter</h4>
          <div class="alert alert-warning" v-if="modelNachType('Mitarbeiter').length == 0">Kein Mitarbeiter zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelNachType('Mitarbeiter')">
              <label :for="'mitarbeiter'+model.model.id" :key="'mitarbeiter'+model.model.id" class="d-block">
                <input type="radio" name="mitarbeitervariablen" :value="model.model" v-model="mitarbeitervariablen" :id="'mitarbeiter'+model.model.id">
                {{$ganzerName(model.model)}}
              </label>
            </template>
          </div>
        </div>
        <div class="mt-3" v-if="variablenVorhandenTyp('Patient')">
          <hr>
          <h4>Variablen vom Typ Patient</h4>
          <div class="alert alert-warning" v-if="modelNachType('Patient').length == 0">Kein Patient zur Auswahl</div>
          <div class="ml-3">
            <template v-for="model in modelNachType('Patient')">
              <label :for="'patient'+model.model.id" :key="'patient'+model.model.id" class="d-block">
                <input type="radio" name="patientvariablen" :value="model.model" v-model="patientvariablen" :id="'patient'+model.model.id">
                {{$ganzerName(model.model)}}
              </label>
            </template>
          </div>
        </div>
        <button class="btn btn-outline-primary btn-sm mb-3 mt-3" @click="detailsEinblenden = !detailsEinblenden">zu füllende Platzhalter einblenden</button>
        <div v-if="detailsEinblenden">
          <hr>
          <h4>
            Im Schreiben zu füllende Platzhalter
          </h4>
          <ul>
            <!-- Wenn kein Platzhalter dann Ansprechpartner, das nicht anzeigen -->
            <li v-for="variable in $helpers.sortArray(vorlageSelected.variablen, 'name')" :key="variable.name" v-if="variable.platzhalter">
              <b>{{ variablenTyp(variable) }}</b>
              {{variable.platzhalter}}
              <template v-if="$helpers.findByKey(presets, variable.name, 'name') && $helpers.findByKey(presets, variable.name, 'name').value != ''">
                <br >&nbsp;
                <em v-if="variable.typ == 'date'">Wert: {{ $helpers.datumFormatieren($helpers.findByKey(presets, variable.name, 'name').value) }}</em>
                <em v-else>Wert: <span v-html="$helpers.findByKey(presets, variable.name, 'name').value"></span></em>
              </template>
            </li>
          </ul>
        </div>
        <div>
          <button class="btn btn-outline-primary btn-block" @click="erstellen()">Schreiben erstellen</button>
        </div>


    </template>

  </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect';
import vorlagen from '~/mixins/schreiben-vorlagen';
import schreibenZuordnungen from '~/mixins/schreiben-zuordnungen';
export default {
  mixins: [vorlagen, schreibenZuordnungen],
  components: {Multiselect},
  props: ['id', 'models', 'anhang'],
  data() {
    return {
      vorlageSelected: [],
      adresse: {},
      kontaktvariablen: {},
      verordnungvariablen: {},
      mitarbeitervariablen: {},
      patientvariablen: {},
      verordnungsaenderungvariablen: {},
      zuordnungen: [],
      titel: '',
      detailsEinblenden: false
    }
  },
  methods: {
    erstellen() {
      let zuordnungen = [];
      _.forEach(this.zuordnungen, zuordnung => {
        if (zuordnung.model) zuordnungen.push({model_id: zuordnung.model.id, model_type: zuordnung.model_type});
      })

      this.$router.push({name: 'mitarbeiter-dokumente-schreiben-id', params: {id: this.$uuid.v1(), vorlage_id: this.vorlageSelected.id, presets: this.presets, zuordnungen: zuordnungen, anhang: this.anhang}});
    },
    variablenTyp(variable) {
      let res = variable.name.split(".");
      if (res[1]) {
        if (res[0] == 'kontakt') return 'Kontakt';
        if (res[0] == 'verordnung') return 'Verordnung';
        if (res[0] == 'mitarbeiter') return 'Mitarbeiter';
        if (res[0] == 'patient') return 'Patient';
        if (res[0] == 'verordnungsaenderung') return 'Verordnungsaenderung';
      }
      return '';
    },
    variablenVorhandenTyp(typ) {
      let ergebnis = false;
      if (!this.vorlageSelected) return false;
      _.forEach(this.vorlageSelected.variablen, variable => {
        if (this.variablenTyp(variable) == typ) {
          ergebnis = true;
          return false;
        }
      });
      return ergebnis;
    },
    modelNachType(type) {
      return this.models.filter(model => {
        return (model.model_type == type);
      })
    },
    show() {
      this.zuordnungen = this.zuordnungenListe;
    }
  },
  computed: {
    zuordnungenListe() {
      let send = [];
      _.forEach(this.models, (model) => {
        if (model.model_type != 'Patientenkontakt') send.push(model);
      })
      return send;
    },
    modelsMitKontakt() {
      return this.models.filter(model => {
        return (model && model.model && model.model.kontakt);
      })
    },
    presets() {
      let presets = [];

      if (this.adresse && Object.keys(this.adresse).length) presets = this.schreibenPresetsAnschrift(presets, this.adresse.kontakt);
      if (this.kontaktvariablen && Object.keys(this.kontaktvariablen).length) presets = this.schreibenPresetsKontakt(presets, this.kontaktvariablen.kontakt);
      if (this.verordnungvariablen && Object.keys(this.verordnungvariablen).length) presets = this.schreibenPresetsVerordnung(presets, this.verordnungvariablen);
      if (this.mitarbeitervariablen && Object.keys(this.mitarbeitervariablen).length) presets = this.schreibenPresetsMitarbeiter(presets, this.mitarbeitervariablen);
      if (this.patientvariablen && Object.keys(this.patientvariablen).length) presets = this.schreibenPresetsPatient(presets, this.patientvariablen);
      if (this.verordnungsaenderungvariablen && Object.keys(this.verordnungsaenderungvariablen).length) presets = this.schreibenPresetsAenderungen(presets, this.verordnungsaenderungvariablen);
      presets.push({'name': 'schreiben.name', value: this.titel});

      return presets;
    }
    /**
     *
     *
     * Zuordnungen: Automatisch verknüpfen mit allem, außer bei Ausfallrechnungen, also als Model alle Stunden mitgeben, aber dann nicht automatisch verknüpfen sondern manuell, oder neben Stunde einen Button: Ausfallrechnunge erstellen
     *
     *
     */

  },
  mounted() {
    this.vorlagenAbrufen();
    setTimeout(() => {
      if (this.modelsMitKontakt.length) {
        this.adresse = this.modelsMitKontakt[0].model;
        this.kontaktvariablen = this.modelsMitKontakt[0].model;
      }
      if (this.modelNachType('Verordnung').length) this.verordnungvariablen = this.modelNachType('Verordnung')[0].model;
      if (this.modelNachType('Mitarbeiter').length) this.mitarbeitervariablen = this.modelNachType('Mitarbeiter')[0].model;
      if (this.modelNachType('Patient').length) this.patientvariablen = this.modelNachType('Patient')[0].model;
      if (this.modelNachType('Verordnungsaenderung').length) this.verordnungsaenderungvariablen = this.modelNachType('Verordnungsaenderung')[0].model;
    }, 100);
  }
}
</script>
