import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  setAll: storeHelperOld.setAll,
  // set: storeHelper.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  find: (state) => (sipuser) => {
    return Helpers.findByKey(state.globals.elemente, sipuser, 'sipuid');
  },
  findByNummer: (state) => (nummer) => {
    return Helpers.findByKey(state.globals.elemente, nummer, 'did');
  },
  filled: storeHelper.filled
}

const actions = {
  async load({commit, state}) {
    let url = "{placetel}/v1/sipusers";
    let notifyBetreff = "Durchwahlen aktualisieren";
    return storeHelperOld.load(commit, state, url, notifyBetreff);
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
