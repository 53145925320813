<template>
    <span>
        <datalist :id="'beschreibungen'+_uid"><option :value="bez" v-for="bez in kontaktBeschreibungen" :key="bez" /></datalist>
        <input :class="{'is-invalid': showError && !text}" @change="$emit('change', text)" @input="$emit('input', text)" :list="'beschreibungen'+_uid" type="text" class="form-control inline-input" v-model="text" placeholder="z.B.: Mutter, Großmutter, Kindergarten,...">
    </span>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value', 'showError'],
  data() {
    return {
      text: this.value
    }
  }
}
</script>
