export default {
  methods: {
    findSchreiben(alleSchreiben, zuordnung_typ, zuordnung_id) {
      if (!alleSchreiben) return null;
      return alleSchreiben.filter(schreiben => {
        return (schreiben.zuordnungen.filter(zuordnung => {
          return (zuordnung.model_id == zuordnung_id && zuordnung.model_type == zuordnung_typ);
        }).length > 0);
      })
    },
    schreibenIndex(alleSchreiben, zuordnung_typ, zuordnung_id, index) {
      if (!alleSchreiben) return null;
      let schreiben = this.findSchreiben(alleSchreiben, zuordnung_typ, zuordnung_id);
      if (schreiben && schreiben.length && schreiben[index]) return schreiben[index];
      return null;
    },
    schreibenPresetsKontakt(presets, kontakt, prefix = '') {
      if (!kontakt) return presets;
      presets.push({name: prefix+'kontakt.firma', value: kontakt.firma});
      presets.push({name: prefix+'kontakt.vorname', value: kontakt.vorname});
      presets.push({name: prefix+'kontakt.nachname', value: kontakt.nachname});
      presets.push({name: prefix+'kontakt.name', value: kontakt.nachname+', '+kontakt.vorname});
      presets.push({name: prefix+'kontakt.strasse', value: kontakt.strasse});
      presets.push({name: prefix+'kontakt.plz', value: kontakt.plz});
      presets.push({name: prefix+'kontakt.ort', value: kontakt.ort});
      presets.push({name: prefix+'kontakt.geburtsdatum', value: kontakt.geburtsdatum});
      presets.push({name: prefix+'kontakt.email', value: kontakt.email});
      // Herr
      if (kontakt.geschlecht == 1) {
        presets.push({name: prefix+'kontakt.anrede.foermlich', value: 'Sehr geehrter Herr '+kontakt.nachname});
        presets.push({name: prefix+'kontakt.anrede.persoenlich', value: 'Lieber '+kontakt.nachname});
      }
      else if (kontakt.geschlecht == 2) {
        presets.push({name: prefix+'kontakt.anrede.foermlich', value: 'Sehr geehrte Frau '+kontakt.nachname});
        presets.push({name: prefix+'kontakt.anrede.persoenlich', value: 'Liebe '+kontakt.nachname});
      }
      return presets;
    },
    schreibenPresetsVerordnung(presets, verordnung) {
      if (!verordnung) return presets;
      presets.push({name: 'verordnung.datum', value: verordnung.datum});
      presets.push({name: 'verordnung.diagnose', value: verordnung.diagnose});
      presets.push({name: 'verordnung.indikationsschluessel', value: verordnung.indikationsschluessel});
      presets.push({name: 'verordnung.diagnosegruppe', value: verordnung.indikationsschluessel});
      presets.push({name: 'verordnung.therapiedauer', value: verordnung.therapiedauer});
      presets.push({name: 'verordnung.verordnungsmenge', value: verordnung.verordnungsmenge});
      presets.push({name: 'verordnung.vorher.gelaufen', value: verordnung.vorher_gelaufen});
      presets.push({name: 'verordnung.icd_10_code', value: verordnung.icd_10_code});
      presets.push({name: 'verordnung.icd_10_code_2', value: verordnung.icd_10_code_2});
      presets.push({name: 'verordnung.besonderheiten', value: verordnung.besonderheiten});
      presets.push({name: 'verordnung.therapieziele', value: verordnung.therapieziele});
      presets.push({name: 'verordnung.therapiefrequenz', value: verordnung.therapiefrequenz});
      presets.push({name: 'verordnung.spaetester.behandlungsbeginn', value: verordnung.spaetester_behandlungsbeginn});

      return presets;
    },
    schreibenPresetsMitarbeiter(presets, mitarbeiter) {
      if (!mitarbeiter) return presets;
      presets.push({name: 'mitarbeiter.berufsbezeichnung', value: mitarbeiter.berufsbezeichnung});
      presets.push({name: 'mitarbeiter.titel', value: mitarbeiter.titel});
      presets.push({name: 'mitarbeiter.akademischer.grad', value: mitarbeiter.akademischer_grad});
      presets.push({name: 'mitarbeiter.steuer.id', value: mitarbeiter.steuer_id});
      presets.push({name: 'mitarbeiter.sozialversicherungsnummer', value: mitarbeiter.sozialversicherungsnummer});
      presets.push({name: 'mitarbeiter.kreditinstitut', value: mitarbeiter.kreditinstitut});
      presets.push({name: 'mitarbeiter.iban', value: mitarbeiter.iban});
      presets = this.schreibenPresetsKontakt(presets, mitarbeiter.kontakt, 'mitarbeiter.');

      return presets;
    },
    schreibenPresetsPatient(presets, patient) {
      if (!patient) return presets;
      presets = this.schreibenPresetsKontakt(presets, patient.kontakt, 'patient.');

      return presets;
    },
    schreibenPresetsAenderungen(presets, aenderung) {
      let val = '';
      _.forEach(aenderung.details, (aenderung) => {
        if (aenderung.aendern) {
          if (val != '') val += '<br>';
          if (aenderung.feld == 'freifeld') {
            val += this.$helpers.nl2br(aenderung.soll);
          }
          else {
            val += '<b>'+aenderung.name+'</b> &nbsp; '+aenderung.soll;
          }
        }
      });
      presets.push({name: 'verordnungsaenderung.text', value: val});

      return presets;
    },
    schreibenPresetsAnschrift(presets, kontakt) {
      if (!kontakt) return presets;
      presets.push({name: 'schreiben.firma', value: kontakt.firma});
      presets.push({name: 'schreiben.strasse', value: kontakt.strasse});
      presets.push({name: 'schreiben.plz', value: kontakt.plz});
      presets.push({name: 'schreiben.ort', value: kontakt.ort});
      presets.push({name: 'schreiben.vorname', value: kontakt.vorname});
      presets.push({name: 'schreiben.nachname', value: kontakt.nachname});

      return presets;
    }
  }
}
