<template>
  <div>
        <button class="btn btn-outline-dark btn mr-1" @click="reload">
          <span :class="{'rotating': loading}"><ion-icon name="refresh-outline"></ion-icon></span> Synchronisieren
        </button>
        <button class="btn btn-outline-danger ml-2" @click="reset" v-if="$listeners.reset">
          <span><ion-icon name="refresh-circle"></ion-icon></span> Tabelle neu laden
        </button>

        <span class="d-block">zuletzt:
          <span v-if="$helpers.datumFormatieren(datum, 'DD') != $helpers.datumFormatieren(new Date, 'DD')">{{ $helpers.datumFormatieren(datum, 'DD.MM') }} </span>
          {{ $helpers.datumFormatieren(datum, 'H:mm') }} Uhr
        </span>
        <span  class="d-block" v-if="datum.getTime() > 0">{{ elemente.length }} {{ (elemente.length == 1) ? 'Eintrag' : 'Einträge' }}
          <span v-if="gesamtZahl && gesamtZahl != elemente.length"> von {{gesamt}}</span>
        </span>
        <span v-if="aenderungen && aenderungen.length" class="d-block">{{aenderungen.length}} ungesicherte {{ aenderungen.length == 1 ? 'Änderung' : 'Änderungen' }}</span>
  </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
    export default {
        components: {ModelSelect},
        props: ['elemente', 'aenderungen', 'datum', 'loading', 'gesamt'],
        methods: {
            reload() {
                this.$emit('reload');
            },
            reset() {
              let message = 'Soll die Tabelle neu geladen werden?';
              if (this.aenderungen.length > 0) message += ' Dabei geh'+(this.aenderungen.length > 1 ? 'en' : 't')+' '+this.aenderungen.length+' Änderung'+(this.aenderungen.length > 1 ? 'en' : '')+' verloren!';
                this.$confirm(
                    {
                      message: message,
                      button: {
                        no: 'Lieber nicht',
                        yes: 'Tabelle leeren'
                      },
                      callback: confirm => {
                        if (confirm) {
                          this.$emit('reset');
                        }
                      }
                    }
                  )
            },
        },
        computed: {
          gesamtZahl() {
            if (!this.gesamt) return null;
            if (Number.isInteger(this.gesamt)) return this.gesamt;
            if (this.gesamt.length) return this.gesamt.length;
            return null;
          }
        }
    }
</script>
