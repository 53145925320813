import Vue from 'vue';
import {Helpers} from '@/plugins/helpers';

let can = {
  methods: {
    $can (permission) {
      if (Helpers.findByKey(this.$auth.user.allePermissions, permission, 'name')) return true;
      return false;
    },
    $ganzerName(option) {
      let el = this.$helpers.copy(option);
      if (!el.kontakt) {
        if (el.vorname) el.kontakt = el;
        else return;
      }
      if (el.kontakt.vorname && el.kontakt.nachname) return el.kontakt.nachname + ', ' + el.kontakt.vorname;
      if (el.kontakt.firma) return el.kontakt.firma;
      if (el.kontakt.vorname) return el.kontakt.vorname;
      if (el.kontakt.nachname) return el.kontakt.nachname;
      return "";
    },
    $istTherapeut(mitarbeiter = null) {
      if (mitarbeiter == null) mitarbeiter = this.$auth.user.mitarbeiter;
      if (mitarbeiter.stoerungsbilder && mitarbeiter.stoerungsbilder.length) {
        let stoerungsbilder = mitarbeiter.stoerungsbilder.filter(el => el.praeferenz != 0);
        if (!stoerungsbilder.length) return false;
        if (stoerungsbilder[0].fachbereich != null) return {fachbereich: stoerungsbilder[0].fachbereich};
        return true;
      }
      return false;
    },
    $isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    $isIOS() {
      if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

  },
  computed: {
    $debugMode() {
      return this.$store.getters['debug/isDebugmode'];
    },
    $trashedMode() {
      return this.$store.getters['debug/isTrashedmode'];
    },
    $dev() {
      // Auch in Bugsnag so definiert
      if (process.env.STAGE == 'stefanDev' || process.env.STAGE == 'webDev') return true;
      return false;
    },
    $appVersion() {
      return process.env.APP_VERSION;
    }
  }
}
Vue.mixin(can);
export default can;
