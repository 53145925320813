<template>
    <b-modal :id="id" title="Bild hinzufügen" hide-footer @show="download()" v-if="image">
      <p v-if="image.model_id == 'neu'">Um Bilder hinzuzufügen muss zunächst gespeichert werden.</p>
      <template v-else>
        <div class="form-group">
          Achtung, je größer die Zahl, desto länger die Ladezeit.
          Das Foto wird auf folgende Maße geändert:
          <div>
            <label for="breite"><input type="radio" name="direction" v-model="direction" value="breite" id="breite"> Breite</label>
            <label for="hoehe"><input type="radio" name="direction" v-model="direction" value="hoehe" id="hoehe"> Höhe</label>
            <input type="number" class="form-control" :placeholder="placeholder" v-model="groesse" max="1200">
          </div>
        </div>
        <template v-if="modeldateien.length">
        Foto wählen
        <div class="gallery">
          <div class="m-2 pics" v-for="datei in modeldateien" :key="datei.id" @click="addImage(datei)">
            <!-- Bilder mit neuen Maßen laden -->
            <img-nachladen class="img-fluid" :src="urlModelDatei(datei.aktuelle_version)+'?hoehe=200'" />
          </div>
        </div>
      </template>
      <div v-else>Noch keine Fotos hochgeladen.</div>
      <div class="form-group mt-5">
        <label class="label-inline">Neues Bild hochladen: <input type="file" class="form-control-file" @change="dateiUpload($event.target.files)"></label>
      </div>
      </template>
  </b-modal>
</template>
<script>
import modelDateien from '~/mixins/model_dateien';
import { DOMParser } from 'prosemirror-model'
export default {
  mixins: [modelDateien],
  props: ['id', 'editor', 'image'],
  data() {
    return {
      direction: 'breite',
      groesse: '400'
    }
  },
  methods: {
    addImage(datei) {
      let html = "<img-nachladen src='"+this.urlModelDatei(datei.aktuelle_version)+"?"+this.direction+"="+this.groesse+"' />";
      this.insertHTML(this.editor, html);
      this.$bvModal.hide(this.id);
      this.editor.focus();
    },
    dateiUpload(dateiarray) {
      let error = false;
      // Nur Bilderupload erlauben
      _.forEach(dateiarray, datei => {
        if (this.$helpers.getFileType(datei) != 'image') {error = true; return false;}
      });
      if (error) {
        this.$notifyError('Die Datei wurde nicht als Foto erkannt und ist deshalb nicht erlaubt.');
        return;
      }
      this.modelDateiUpload(dateiarray);
    },
    elementFromString(value) {
        const element = document.createElement('div')
        element.innerHTML = value.trim()

        return element
    },

    insertHTML({ state, view }, value) {
        const { selection } = state
        const element = this.elementFromString(value)
        const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
        const transaction = state.tr.insert(selection.anchor, slice.content)

        view.dispatch(transaction)
    },
    download() {
      if (this.image.model_id == 'neu') return;
      if (this.modeldateien.length) return;
      if (this.image.model_type && this.image.model_id) this.modelDateienAbrufen(this.image.model_type, this.image.model_id);
      else console.error('Da stimmt etwas nicht beim Dateiupload');
    }

  },
  computed: {
    placeholder() {
      if (this.direction == 'breite') return 'Neue Breite';
      return 'Neue Höhe';
    }
  }
}
</script>
