import moment from "moment"
import lokiHelper from '~/mixins/lokiHelper';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    async speichernAnfahrt(model) {
      this.$store.dispatch('anfahrten/speichern', model);
      setTimeout(() => {
        this.loadAnfahrten(false, model.mitarbeiter_id);
      }, 1000);
    },
    loadAnfahrten(manuell = false, mitarbeiter= null) {
      let params = {
        mitarbeiter: mitarbeiter,
        monat: this.anfahrtenMonat
      }
      this.$store.dispatch('anfahrten/sync', {manuell, params});
    },
    // getAnfahrten(mitarbeiter) {
    //   let check = this.$moment(this.anfahrtenMonat);
    //   let elemente = this.anfahrten.filter((h) => {
    //     let mom = this.$moment(h.von);
    //     // Nur aktueller Mitarbeiter und nur gewählter Monat anzeigen
    //     return (h.mitarbeiter_id == mitarbeiter && mom.format('M') == check.format('M') && mom.format('Y') == check.format('Y'))
    //   });
    //   return this.$helpers.sortArray(elemente, 'von', 'desc');
    // },
    getStandMitarbeiterAnfahrten(mitarbeiter) {
      // Nur damit das refreshed wird
      this.$store.getters['anfahrten/getStand'];
      let jetzt = new Date();
      let stand = this.$store.getters['anfahrten/getStandModels'](mitarbeiter, this.anfahrtenMonat);
      if (!stand || stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadAnfahrten(false, mitarbeiter);
      return stand;
    },

    async anfahrtenAbrufen() {
      this.getAnfahrten = true;
      this.registerAnfahrtenEvent();
      if (!this.suchbegriff) {
       this.anfahrten = await this.getElemente('anfahrten', this.anfahrtenFilter);
       this.anfahrtenCount = this.anfahrten.length;
       return;
      }

      // anfahrten suchen
      let anfahrtenCollection = await dbHelper.getDb('anfahrten');
      this.anfahrten = await anfahrtenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    anfahrtenAenderungenAbrufen() {
      this.getAnfahrtenAenderungen = true;
      this.registerAnfahrtenEvent();
      return this.getAenderungen('anfahrten').then(data => this.anfahrtenAenderungen = data);
    },
    anfahrtAbrufen(id) {
      this.getAnfahrt = id
      this.registerAnfahrtenEvent();
      return this.getElement('anfahrten', {id: id}).then(anfahrt =>{if (Object.keys(anfahrt).length)  this.anfahrt = anfahrt});
    },
    registerAnfahrtenEvent() {
      if (!this.anfahrtEvent) this.anfahrtEvent = this.$nuxt.$on('anfahrten-sync', this.anfahrtenErneuern);
    },
    anfahrtenErneuern(event) {
      if (this.getAnfahrt) {
        if (event && this.getAnfahrt == event.id) this.anfahrt = event;
        else this.anfahrtAbrufen(this.getAnfahrt);
      }
      if (this.getAnfahrten) this.anfahrtenAbrufen();
      if (this.getAnfahrtenAenderungen) this.anfahrtenAenderungenAbrufen();
    },
  },
  data() {
    return {
      anfahrt: null,
      anfahrten: [],
      anfahrtenCount: 0,
      anfahrtenAenderungen: [],
      getAnfahrt: false,
      getAnfahrten: false,
      getAnfahrtenAenderungen: false,
      anfahrtEvent: null,
      anfahrtFilter: null,
      anfahrtenMonat: this.$moment().format('YYYY-MM')
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('anfahrten-sync', this.anfahrtenErneuern);
    this.anfahrtEvent = null;
  },
}
