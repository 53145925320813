<template>
  <div v-if="verordnung">
    <verordnungen-formular v-if="$can('Verordnungen verwalten')" :id="'verordnungen-formular'+verordnung.id" :verordnung="verordnung">
      <verordnungen-content v-model="verordnung" :id="'verordnungen-formular'+verordnung.id" :verordnungenProp="verordnungenProp"></verordnungen-content>
    </verordnungen-formular>
    <pruefen-modal v-if="$can('Verordnungsänderungen verwalten')" :id="'verordnung-pruefen'+verordnung.id" :verordnungProp="verordnungProp"></pruefen-modal>
    <therapie-formular :key="modalkey" :id="'therapie-formular'+verordnung.id" v-model="therapiestunde" v-if="($can('Therapie erfassen') || $can('Therapie verwalten')) && therapiestunde" :verordnungProp="verordnung"></therapie-formular>
    <ausfall-formular :key="'ausfall'+modalkey" :id="'ausfall-formular'+verordnung.id" v-model="ausfallstunde" v-if="($can('Ausfall erfassen') || $can('Ausfall verwalten')) && ausfallstunde" :verordnungProp="verordnung"></ausfall-formular>

    <div class="btn-group mt-2 mb-2" role="group">
      <template v-if="therapiestundenFrei != 0">
        <button type="button" @click="addTherapie(verordnung)" class="btn btn-outline-primary" v-if="($can('Therapie erfassen') && $istTherapeut()) || $can('Therapie verwalten')"><ion-icon name="time-outline"></ion-icon> Therapie</button>
        <button type="button" @click="addAusfall(verordnung)" class="btn btn-outline-danger" v-if="($can('Ausfall erfassen') && $istTherapeut()) || $can('Ausfall verwalten')"><ion-icon name="time-outline"></ion-icon> Ausfall</button>
      </template>
      <button v-if="$can('Verordnungen verwalten')" v-b-modal="'verordnungen-formular'+verordnung.id" class="btn btn-outline-primary"><ion-icon name="create-sharp"></ion-icon> Verordnung bearbeiten</button>
      <button v-if="$can('Verordnungsänderungen verwalten')" v-b-modal="'aenderungsformular'+verordnung.id" class="btn btn-outline-primary"><ion-icon name="build-outline"></ion-icon> Verordnungsänderung</button>
      <button v-if="$can('Verordnungsänderungen verwalten')" @click="verordnungPruefen(verordnung)" class="btn btn-outline-primary"><ion-icon name="build-outline"></ion-icon> Prüfen</button>
    </div>
  </div>
</template>
<script>
import verordnungen from '~/mixins/verordnungen';
import lokiHelper from '~/mixins/lokiHelper';
import therapiestunden from '~/mixins/therapiestunden';
import VerordnungenFormular from '~/components/mitarbeiter/verordnungen/FormularModal';
import VerordnungenContent from '~/components/mitarbeiter/verordnungen/FormularContent';
import TherapieFormular from '~/components/mitarbeiter/therapie/neu';
import AusfallFormular from '~/components/mitarbeiter/therapie/ausfall/modal';
import PruefenModal from '~/components/mitarbeiter/verordnungen/pruefenModal';
export default {
  mixins: [verordnungen, therapiestunden, lokiHelper],
  components: {VerordnungenFormular, VerordnungenContent, TherapieFormular, AusfallFormular, PruefenModal},
  props: ['verordnungProp', 'verordnungenProp', 'patientProp'],
  data() {
    return {
      ausfallstunde: null,
      modalkey: 0,
      verordnung: null,
    }
  },
  methods: {
    verordnungPruefen(verordnung) {
      this.edit = this.$helpers.copy(verordnung);
      this.pruefenkey++;
      this.$nextTick(() => {
        this.$bvModal.show('verordnung-pruefen'+verordnung.id);
      })
    },
    addTherapie(verordnung) {
      let dauer = (this.verordnungDauer(verordnung)) ? this.verordnungDauer(verordnung) : null;
      let neu = {verordnung_id: verordnung.id, patient_id: this.patientProp.id, von: this.$moment().subtract(this.verordnungDauer(verordnung), 'm').format("YYYY-MM-DDTHH:mm"), dauer: dauer};
      if (verordnung.typ == 1 && this.therapiestunden.length == 0) neu.erstbefundung = 1;
      this.therapiestunde = neu;
      this.$nextTick(() => {
        this.$bvModal.show('therapie-formular'+verordnung.id);
      })
    },
    addAusfall(verordnung) {
      let dauer = (this.verordnungDauer(verordnung)) ? this.verordnungDauer(verordnung) : null;
      let neu = {verordnung_id: verordnung.id, patient_id: this.patientProp.id, von: this.$moment().subtract(this.verordnungDauer(verordnung), 'm').format("YYYY-MM-DDTHH:mm"), dauer: dauer};
      if (verordnung.typ == 1 && this.therapiestunden.length == 0) neu.erstbefundung = 1;
      this.ausfallstunde = neu;
      this.modalkey++;
      this.$nextTick(() => {
        this.$bvModal.show('ausfall-formular'+verordnung.id);
      })
    },
  },
  computed: {
    therapiestundenFrei() {
      if (!this.verordnung) return 0;
      if (!this.verordnungsmenge(this.verordnung)) return -1;
      let ret = this.verordnungsmenge(this.verordnung) - this.therapiestunden.length;
        if (this.erstbefundungErlaubt(this.verordnung)) ret += 1;
      if (ret < 0) return 0;
      return ret;
    }
  },
  mounted() {
    this.verordnung = this.verordnungProp;
    this.getVerordnung = this.verordnung.id;
    if (this.verordnung.therapiestunden) {
      this.therapiestunden = this.verordnung.therapiestunden;
      this.therapiestundenCount = this.therapiestunden.length;
    }
    else {
      this.registerVerordnungenEvent();
      this.therapiestundenFilter = {verordnung_id: this.verordnung.id, deleted_at: null};
      this.therapiestundenAbrufen();
    }
  }
}
</script>
