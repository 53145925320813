import ArbeitszeitTherapieFormular from '~/components/mitarbeiter/arbeitszeit/Therapieformular';
import ZusammenfassungAllgemein from '~/components/mitarbeiter/therapie/ZusammenfassungAllgemein';
import ArbeitszeitZusammenfassung from '~/components/mitarbeiter/arbeitszeit/Zusammenfassung';

import verordnungen from '~/mixins/verordnungen';
import arbeitsstunden from '~/mixins/arbeitsstunden';
import anfahrten from '~/mixins/anfahrten';
import mitarbeiter from '~/mixins/mitarbeiter';
import therapiestunden from '~/mixins/therapiestunden';
import kontakte from '~/mixins/kontakte';
export default {
  props: ['value', 'id'],
  mixins: [kontakte, verordnungen, mitarbeiter, arbeitsstunden, anfahrten, therapiestunden],
  components: {ArbeitszeitTherapieFormular, ZusammenfassungAllgemein, ArbeitszeitZusammenfassung},
  watch: {
    value: function(neu, alt) {
      this.fillChange(neu);
    }
  },
  data() {
    return {
        formCounter: 0,
        arbeitszeitKey: 0,
        arbeitszeit: null,
        anfahrt: null,
        abfahrt: null,
        showError: false,
    }
  },
  methods: {
    formShow() {
      this.fillChange(this.value);
    },
    therapieArbeitszeitSpeichern(model, type) {

      // Arbeitszeit speichern
      if (this.arbeitszeit.erfassen) {
        let arbeitsstunde = {};
          arbeitsstunde.id = this.$uuid.v1();
          arbeitsstunde.von = this.arbeitszeit.von;
          arbeitsstunde.bis = this.arbeitszeit.bis;
          arbeitsstunde.dauer = this.arbeitszeit.dauer;
          arbeitsstunde.trigger_type = type;
          arbeitsstunde.trigger_id = model.id;
          arbeitsstunde.mitarbeiter_id = model.mitarbeiter_id;
          // this.speichernArbeitsstunde(arbeitsstunde);
      }

      // Anfahrt speichern
      if (this.anfahrt.erfassen) {
        let anfahrt = {};
        anfahrt.id = this.$uuid.v1();
        anfahrt.mitarbeiter_id = model.mitarbeiter_id;
        anfahrt.verordnung_id = model.verordnung_id;
        anfahrt.patient_id = model.patient_id;
        anfahrt.trigger_id = model.id;
        anfahrt.trigger_type = type;
        anfahrt.von = this.anfahrt.von;
        anfahrt.bis = this.anfahrt.bis;
        anfahrt.dauer = this.anfahrt.dauer;
        anfahrt.km = this.anfahrt.km;
        this.speichernAnfahrt(anfahrt);

      // Anfahrt als Arbeitszeit speichern
        if (this.anfahrt.arbeitszeit) {
          this.anfahrt.trigger_type = 'Anfahrt';
          this.anfahrt.trigger_id = anfahrt.id;
          this.anfahrt.mitarbeiter_id = anfahrt.mitarbeiter_id;
          delete this.anfahrt.erfassen
          // this.speichernArbeitsstunde(this.anfahrt);
        }
      }

      // Abfahrt speichern
      if (this.abfahrt.erfassen) {
        let abfahrt = {};
        abfahrt.id = this.$uuid.v1();
        abfahrt.mitarbeiter_id = model.mitarbeiter_id;
        abfahrt.verordnung_id = model.verordnung_id;
        abfahrt.patient_id = model.patient_id;
        abfahrt.trigger_id = model.id;
        abfahrt.trigger_type = type;
        abfahrt.von = this.abfahrt.von;
        abfahrt.bis = this.abfahrt.bis;
        abfahrt.dauer = this.abfahrt.dauer;
        abfahrt.km = this.abfahrt.km;
        this.speichernAnfahrt(abfahrt);

      // Abfahrt als Arbeitszeit speichern
        if (this.abfahrt.arbeitszeit) {
          let arbeitsstunde = {};
          arbeitsstunde.id = this.$uuid.v1();
          arbeitsstunde.von = this.abfahrt.von;
          arbeitsstunde.bis = this.abfahrt.bis;
          arbeitsstunde.dauer = this.abfahrt.dauer;
          arbeitsstunde.trigger_type = 'Anfahrt';
          arbeitsstunde.trigger_id = abfahrt.id;
          arbeitsstunde.mitarbeiter_id = abfahrt.mitarbeiter_id;
          // this.speichernArbeitsstunde(arbeitsstunde);
        }
      }
    },
    arbeitszeitValide() {
        this.showError = true;
        if (!this.arbeitszeit) return false;
        if (this.arbeitszeit.erfassen && (!this.arbeitszeit.von || !this.arbeitszeit.dauer)) return false;
        if (this.anfahrt.erfassen && (!this.anfahrt.von || !this.anfahrt.dauer)) return false;
        if (this.abfahrt.erfassen && (!this.abfahrt.von || !this.abfahrt.dauer)) return false;
        this.showError = false;
        return true;
    },
  },
  mounted() {
    this.fillChange(this.value);
  },
}
