export const state = () => ({
  standorte: []
})

const mutations = {
  set(state, standorte){
    state.standorte = standorte;
  }
}

const getters = {
  get(state){
    return state.standorte;
  },
  filled(state){
    return (state.standorte.length) ? true : false;
  },
  find: (state) => (id) => {
    return state.standorte.find(el => {
      return el.id == id;
    })
  }
}

const actions = {
  async abrufen({commit}) {
    let response = await this.$axios.get('{kalender}/v1/standorte')
    commit('set', response.data);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
