import _ from "lodash";
import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'syncprotocol',
  titel: 'Syncprotokoll',
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  set: storeHelper.set,
  reset: storeHelper.reset
}

const actions = {
  getAll: storeHelper.getAll,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
