import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'kontakte',
  titel: 'Kontakte',
  syncurl: '{kontakte}/v1/kontakte',
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  set: storeHelper.set,
  stand: storeHelper.stand,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getStand: storeHelper.getStand,
  // findModel: (state, getters) => (model_type, model_id) => {
  //   return getters.getAll.filter(el => {
  //     return (el.model_type == model_type && el.model_id == model_id);
  //   })[0];
  // },
  // findRufnummer: (state, getters) => (rufnummer) => {
  //   return getters.getAll.filter(el => {
  //     return el.telefonnummern.filter(nummer => {
  //       return (nummer.nummer.replace(' ', '') == rufnummer);
  //     })
  //   })[0];
  // },
  filled: storeHelper.filled
}

const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern,
  async sync(context, manuell = false) {
    await storeHelper.sync(context, manuell);
    // $nuxt.$store.dispatch('prefills/loadBezeichnungen');
  },
  loeschen: storeHelper.loeschen
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
