export const state = () => ({
  user: {},
  // token: ''
})

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  // setToken(state, token) {
  //   state.token = token;
  // }
}

const getters = {
  getUser(state) {
    return state.user;
  },
  // getToken(state) {
  //   return state.token;
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
