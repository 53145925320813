<template>
  <multiselect
    :key="counter"
    :class="{'is-invalid': isinvalid}"
    track-by="id"
    v-model="selected"
    placeholder="Patient wählen"
    :id="'patient'+id"
    :options="patienten"
    :show-labels="false"
    :custom-label="patientLabel"
    @select="update($event)"
    @remove="remove($event)"
    :multiple="multiple"
    :disabled="disabled"
  >
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import patienten from '~/mixins/patienten';
export default {
  components: {Multiselect},
  mixins: [patienten],
  props: {
    value: {
      default() {
        return []
      }
    },
    isinvalid: {
      default: false
    },
    multiple: Boolean,
    disabled: {
      default: false
    }
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    }
  },
  data() {
    return {
      patient: null,
      counter: 0,
      id: this._uid,
      selected: []
    }
  },
  methods: {
    update(element) {
      if (!this.multiple) {
        if (this.selected != element) this.$emit('update', element);
        this.selected = element;
        if (!element || !element.id) return this.$emit('input', null);
        this.$emit('input', element.id);
      }
      else this.emitMultiple()
    },
    remove() {
      if (!this.multiple) return this.update(null);
      this.emitMultiple();
    },
    externerInput(neu) {
      if (neu && !Array.isArray(neu)) {
        this.selected = [];
        this.getElement('patienten', {id: neu}).then(el => {this.selected.push(el)});
      }
      else if (neu) {
        this.selected = [];
        _.forEach(neu, (el) => {
          this.getElement('patienten', {id: el}).then((gefunden) =>{
            this.selected.push(gefunden);
          });
        })
      }
      else this.selected = [];
      this.counter++;
    },
    emitMultiple() {
      this.$nextTick(() => {
        if (!Array.isArray(this.selected)) return this.$emit('input', [this.selected.id]);
        let emit = [];
        _.forEach(this.selected, (el) => {
          emit.push(el.id);
        })
        console.log(emit);

        return this.$emit('input', emit);
      })
    },
    patientLabel(obj) {
      let kontakt = this.kontakte.find(kontakt => {
        return (kontakt.model_id == obj.id);
      });
      if (kontakt) return this.$ganzerName({kontakt: kontakt});
      return 'Nicht gefunden';
    }
  },
  created() {
    this.kontakteFilter = {model_type: 'Patient'};
  },
  mounted() {
    this.externerInput(this.value);
    this.patientenAbrufen();
    this.kontakteAbrufen();
  }
}
</script>
