<template>
    <div v-if="therapiestunde">
        <legend>{{titel}}</legend>
        <table class="table table-sm" v-if="therapiestunde">
          <tr v-if="therapiestunde.patient_id">
            <td>Patient</td>
            <td><kontakt-anzeigen :filter="{model_type: 'Patient', model_id: therapiestunde.patient_id}"></kontakt-anzeigen></td>
          </tr>
          <tr v-if="therapiestunde.verordnung_id && verordnung">
            <td>Verordnung</td>
            <td>{{ $helpers.datumFormatieren(verordnung.datum) }}</td>
          </tr>
          <tr v-if="therapiestunde.mitarbeiter_id">
            <td>Therapeut</td>
            <td>
              <kontakt-anzeigen :filter="{model_type: 'Mitarbeiter', model_id: therapiestunde.mitarbeiter_id}"></kontakt-anzeigen>
            </td>
          </tr>
          <tr>
            <td>Zeit</td>
            <td>{{$helpers.datumFormatieren(therapiestunde.von, 'DD.MM HH:mm')}} - {{$helpers.datumFormatieren(therapiestunde.bis, 'HH:mm')}} ({{therapiestunde.dauer}} Min)</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <span v-if="therapiestunde.hausbesuch"><span class="badge badge-secondary">Hausbesuch</span></span>
              <span v-if="therapiestunde.erstbefundung"><span class="badge badge-secondary">Erstbefundung</span></span>
            </td>
          </tr>
          <!-- Nur für Therapiestunden -->
          <tr v-if="therapiestunde.dokumentation">
            <td>Dokumentation</td>
            <td v-html="$helpers.nl2br(therapiestunde.dokumentation)"></td>
          </tr>
          <tr v-if="therapiestunde.naechster_termin">
            <td>Vorplanung</td>
            <td v-html="$helpers.nl2br(therapiestunde.naechster_termin)"></td>
          </tr>
          <!-- Nur für Ausfallstunden -->
          <tr v-if="therapiestunde.begruendung">
            <td>Begründung</td>
            <td v-html="$helpers.nl2br(therapiestunde.begruendung)"></td>
          </tr>
          <tr v-if="therapiestunde.kurzfristig">
            <td>Kurzfristig</td>
            <td>Die Therapiestunde wurde kurzfristig abgesagt.</td>
          </tr>
          <tr v-if="therapiestunde.berechnen">
            <td>Berechnen</td>
            <td>Die Therapiestunde soll dem Patienten in Rechnung gestellt werden.</td>
          </tr>
        </table>
    </div>
</template>
<script>
import lokiHelper from '~/mixins/lokiHelper';
export default {
  props: ['therapiestunde', 'titel'],
  mixins: [lokiHelper],
  data() {
    return {
      verordnung: null
    }
  },
  mounted() {
    this.getElement('verordnungen', {id: this.therapiestunde.verordnung_id}).then(verordnung => {
      this.verordnung = verordnung;
    });
  }
}
</script>
