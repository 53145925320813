<template>
    <span>
        <template v-if="geschlecht == 1">Frau</template>
        <template v-else-if="geschlecht == 2">Herr</template>
    </span>
</template>

<script>
export default {
    props: ['geschlecht'],
}
</script>
