<template>
  <multiselect
    :key="counter"
    :class="{'is-invalid': isinvalid}"
    track-by="id"
    v-model="art"
    placeholder="Art wählen"
    :id="'art'+id"
    :options="versicherungsArten"
    :show-labels="false"
    label="name"
    @select="update($event)"
    @remove="update(null)"
  >
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {Multiselect},
  props: {
    value: {
      default: null
    },
    isinvalid: {
      default: false
    }
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    }
  },
  data() {
    return {
      art: null,
      counter: 0,
      id: this._uid,
    }
  },
  methods: {
    update(element) {
      if (this.art != element) this.$emit('update', element);
      this.art = element;
      if (!element) return this.$emit('input', null);
      this.$emit('input', element.id);
    },
    externerInput(neu) {
      if (neu != null) this.art = this.findVersicherungsArt(neu);
      else this.art = [];
      this.counter++;
    },
    findVersicherungsArt(art) {
      return this.$helpers.findByKey(this.versicherungsArten, art, 'id');
    },

  },
  computed: {
    versicherungsArten: function() {
      return [
        {id: 0, name: 'Gesetzlich versichert'},
        {id: 1, name: 'Privat versichert'},
        {id: 2, name: 'Beihilfe mit Zusatzversicherung'},
        {id: 3, name: 'Beihilfe ohne Zusatzversicherung'},
      ];
    },
  },
  mounted() {
    this.externerInput(this.value);
  }
}
</script>
