<template>
  <multiselect
    track-by="id"
    :class="{'is-invalid': isinvalid}"
    v-model="verordnung"
    placeholder="Verordnung wählen"
    :id="'verordnung'+id"
    :options="verordnungen"
    :show-labels="false"
    :key="counter"
    @select="update($event)"
    @remove="update(null)"
  >
    <template slot="singleLabel" slot-scope="{ option }">{{ verordnungFachbereiche[option.fachbereich] }} {{ $helpers.datumFormatieren(option.datum)}}</template>
    <template slot="option" slot-scope="{ option }">{{ verordnungFachbereiche[option.fachbereich] }} {{ $helpers.datumFormatieren(option.datum)}}</template>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import verordnungen from '~/mixins/verordnungen';
export default {
  components: {Multiselect},
  mixins: [verordnungen],
  props: {
    value: String,
    isinvalid: {
      default: false
    },
    patient: String,
    id: String,
    fachbereich: {
      default: null
    }
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    },
    patient(neu, alt) {
      this.reset();
      this.verordnungenErneuern()
    },
    fachbereich(neu, alt) {
      this.reset();
      this.verordnungenErneuern()
    }
  },
  data() {
    return {
      verordnung: [],
      counter: 0
    }
  },
  methods: {
    update(element) {
      if (this.verordnung != element || element == null) this.$emit('update', element);
      this.verordnung = element;
      if (!element || !element.id) return this.$emit('input', null);
      this.$emit('input', element.id);
      this.$emit('element', element);
    },
    reset() {
      this.verordnung = [];
      this.$emit('input', null);
      this.$emit('update', null);
    },
    externerInput(neu) {
      if (neu) this.verordnungAbrufen(neu);
      else this.verordnung = [];
      this.conter++;
    },
    verordnungenErneuern() {
      this.verordnungenFilter = {};
      if (this.patient) this.verordnungenFilter.patient_id = this.patient;
      if (this.fachbereich !== null) this.verordnungenFilter.fachbereich = this.fachbereich;
      if (this.verordnungenFilter == {}) this.verordnungenFilter = null;
      this.verordnungenAbrufen();
    }
  },
  created() {
    if (this.patient) {
      let patientid = (typeof this.patient == 'object') ? this.patient.id : this.patient;
      this.verordnungenFilter = {patient_id: patientid};
    }
  },
  mounted() {
    this.externerInput(this.value);
    this.verordnungenErneuern();
  }
}
</script>
