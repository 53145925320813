import kalenderfunktionen from '~/mixins/kalenderfunktionen';
export default {
  mixins: [kalenderfunktionen],
  computed: {
    von() {
      return this.$store.getters['kalender/von'];
    },
    bis() {
      return this.$store.getters['kalender/bis'];
    },
    schritt() {
      return this.$store.getters['kalender/schritt'];
    },
    spalten() {
      return this.$store.getters['kalender/spalten'];
    },
    spaltenData() {
      return this.$store.getters['kalender/spaltenData'];
    },
  }
}
