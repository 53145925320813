<template>
  <span v-if="kontakt">
    <anrede v-if="display && display.anrede" :geschlecht="kontakt.geschlecht"></anrede>
    <template v-if="display && display.firma">{{kontakt.firma}}</template>
    <template v-if="!display || (display.vorname == undefined && display.nachname == undefined)">{{ $ganzerName(kontakt) }}</template>
    <template v-else-if="display.nachname == true">{{ kontakt.nachname }}</template>
    <template v-else-if="display.vorname == true">{{ kontakt.vorname }}</template>
    <template v-else-if="display.initialen == true">{{ kontakt.vorname[0]+kontakt.nachname[0] }}</template>
  </span>
</template>
<script>
import kontakte from '~/mixins/kontakte';
export default {
  mixins: [kontakte],
  props: {
    filter: null,
    display: {
      default: null
    },
    kontaktProp: null
  },
  watch: {
    filter(neu, alt) {
      if (JSON.stringify(neu) != JSON.stringify(alt)) {
        this.fill();
      }
    }
  },
  data() {
    return {
      element: undefined
    }
  },
  methods: {
    async fill() {
      let filter = this.filter;
      if (filter.model_type) filter.model_type = filter.model_type.replace('App\\Models\\', '');
      await this.kontaktAbrufenFilter(filter);
      this.$emit('kontakt', this.kontakt);
    }
  },
  mounted() {
    if (this.kontaktProp) {
      this.kontakt = this.kontaktProp;
      this.getKontakt = this.kontaktProp.id
      this.registerKontakteEvent();
    }
    else this.fill();
  },
}
</script>
