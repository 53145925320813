// Broadcast that you're opening a page.
localStorage.openpages = Date.now();
var onLocalStorageEvent = function(e){
    if(e.key == "openpages"){
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
    }
    if(e.key == "page_available"){
      alert('Du hast das Portal in einem anderen Tab offen. Das wird derzeit nicht unterstützt.\nNutze für eine zweite Instanz zum Beispiel einen anderen Browser.')
      window.location = 'https://yes-gesundheit.de';
    }
};
window.addEventListener('storage', onLocalStorageEvent, false);
