<template>
<div>
  <div v-for="kommentar in $helpers.sortArray(kommentare, 'created_at', 'desc')" :key="kommentar.id">
    <kommentar :kommentar="kommentar" :showDate="showDate" :id="id" :type="type" />
  </div>

</div>
</template>
<script>
export default {
  props: ['kommentare', 'showDateSet', 'id', 'type'],
  watch: {
    showDateSet: function(neu) {
      this.showDate = neu;
    }
  },
  data() {
    return {
      showDate: false
    }
  }
}
</script>
