<template>
  <div class="mb-2 row">
    <div class="col-2 my-auto" v-if="sortby">Sortieren</div>
    <div class="col-3" v-if="sortby">
      <model-select @input="reorder()" v-model="order" :options="sortby" placeholder="Sortieren"></model-select>
    </div>
    <div class="col offset-4" :class="{'offset-9' : !sortby}">
      <suchfeld v-model="suche" @input="search()"></suchfeld>
    </div>
  </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
    export default {
        components: {ModelSelect},
        props: ['sortby', 'orderby', 'value'],
        data() {
          return {
            order: this.orderby,
            suche: ''
          }
        },
        methods: {
            reorder() {
              this.$emit('reorder', this.order);
            },
            search() {
              this.$emit('search', this.suche);
              this.$emit('input', this.suche);
            }
        },
        mounted() {
          this.suche = this.value;
        }
    }
</script>
