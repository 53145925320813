import storeHelper from '~/mixins/storeHelper';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  name: 'schreiben',
  titel: 'Schreiben',
  syncurl: '{schreiben}/v1/schreiben',
  globals: Helpers.copy(storeHelper.empty),
  standModels: {}
})

const mutations = {
  set: storeHelper.set,
  stand: storeHelper.stand,
  loading: storeHelper.loading,
  reset(state) {
    state.standModels = {};
    storeHelper.reset(state);
  },
  setModelStand(state, {model_type, model_id, stand}) {
    state.standModels[model_type+model_id] = stand;
  },
}

const getters = {
  isLoading: storeHelper.isLoading,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled,
  getStandModels: (state) => (model_type, model_id) => {
    if (state.standModels[model_type+model_id]) return Helpers.make_date(state.standModels[model_type+model_id]);
    return null;
  }
}

const actions = {
  getAll: storeHelper.getAll,
  withTrashed: storeHelper.withTrashed,
  speichern: storeHelper.speichern,
  async sync(context, manuell = false) {
    await storeHelper.sync(context, manuell);
  },
  loeschen: storeHelper.loeschen
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
