<template>
  <b-nav-item-dropdown v-if="agent">
    <template v-slot:button-content><span :class="{'text-danger': agent.status == 'offline', 'text-success': agent.status == 'online'}">{{ agent.status }}</span></template>
    <b-dropdown-item @click="switchStatus()">Telefonverfügbarkeit ändern zu {{ agent.status == 'online' ? 'offline' : 'online' }}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
export default {
  mixins: [],
  props: [],
  data() {
    return {
      agent: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    switchStatus() {
      if (!this.agent) return;
      let newStatus = 'offline';
      if (this.agent.status == 'offline') newStatus = 'online';
      this.$axios.put('{placetel}/v1/sipagent', {status: newStatus}).then(response => {
        this.agent = response.data;
      })
    }
  },
  mounted() {
    this.$axios.get('{placetel}/v1/sipagent').then(response => {
      this.agent = response.data;
    })
  },

}
</script>
