<template>
  <div class="card mb-3">
      <div class="list-group list-group-flush">
        <div class="list-group-item active">
          Patienten
          <nuxt-link class="btn btn-outline-light btn-sm float-right" :to="{name: 'mitarbeiter-listen-patienten'}" @click.native="$emit('close')">Alle Patienten</nuxt-link>
        </div>
        <nuxt-link v-for="(patient, index) in patientenFiltered" :key="index+patient.id" :to="{name: 'mitarbeiter-patienten-id', params: {id: patient.id, patient: patient}}" @click.native="$emit('close')"
          class="list-group-item list-group-item-action"><kontakt-anzeigen :filter="{model_type: 'Patient', model_id: patient.id}"></kontakt-anzeigen>
        </nuxt-link>
        <!-- <template v-if="$can('Mitarbeiter verwalten')">
          <div class="list-group-item active">
            Mitarbeiter
            <nuxt-link class="btn btn-outline-light btn-sm float-right" :to="{name: 'mitarbeiter-listen-mitarbeiter'}" @click.native="$emit('close')">Alle Mitarbeiter</nuxt-link>
          </div>
          <nuxt-link v-for="(mitarbeiter, index) in mitarbeiterFiltered" :key="mitarbeiter.id" :to="{name: 'mitarbeiter-mitarbeiter-id', params: {id: mitarbeiter.id, mitarbeiter: mitarbeiter}}" @click.native="$emit('close')"
          class="list-group-item list-group-item-action">{{ $ganzerName(mitarbeiter) }}</nuxt-link>
        </template> -->
        <template v-if="$can('FAQs sehen')">
          <div class="list-group-item active">
            FAQs
            <nuxt-link class="btn btn-outline-light btn-sm float-right" :to="{name: 'mitarbeiter-listen-faqs'}" @click.native="$emit('close')">Alle FAQs</nuxt-link>
          </div>
          <div v-for="(faq, index) in faqsFiltered" :key="index+faq.id" @click="$emit('close'); $emit('faq', faq)"
            class="list-group-item list-group-item-action">{{ faq.titel }} <span class="badge badge-primary m-1" v-for="tag in faq.tags" :key="tag.id">{{tag.name}}</span></div>
        </template>
      </div>
  </div>
</template>
<script>
import patienten from '~/mixins/patienten';
import faqs from '~/mixins/faqs';
import suche from '~/mixins/suche';
export default {
  mixins: [patienten, suche, faqs],
  props: ['suchbegriff'],
  watch: {
    suchbegriff(neu, alt) {
      this.faqsAbrufen();
      this.patientenAbrufen();
    }
  },
  computed: {
    patientenFiltered() {
      return this.patienten.slice(0, 5);
    },
    // mitarbeiterFiltered() {
    //   return this.suchen(this.mitarbeiter, this.suchbegriff).slice(0, 5);
    // },
    faqsFiltered() {
      return this.faqs.slice(0, 5);
    }
  },
  mounted() {
    this.faqsAbrufen();
    this.patientenAbrufen();
  }
}
</script>
