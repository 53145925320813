<template>
  <div>
    <object v-if="pdf" :data="pdf" width="100%" style="height:40vh; width:100%"></object>
    <aenderung-file v-if="verordnung && verordnung.id" :id="'verordnungfile'+id" :verordnung="verordnung.id" v-model="dateiversion_id" @input="loadPdf()"></aenderung-file>
  </div>
</template>
<script>
import model_dateien from '~/mixins/model_dateien';
import AenderungFile from '~/components/mitarbeiter/verordnungen/AenderungFile';
export default {
  props: ['verordnung'],
  mixins: [model_dateien],
  components: {AenderungFile},
  data() {
    return {
      dateiversion_id: '',
      id: this._uid,
      pdf: ''
    }
  },
  methods: {
    loadPdf() {
      if (!this.dateiversion_id) return;
      this.model_type = 'Verordnung';
      this.model_id = this.verordnung.id;
      let url = this.urlModelDatei()+this.dateiversion_id;
      this.$axios({url: url, method: 'GET', responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(response.data);
        this.pdf = url;
        this.$nextTick(function() {
          this.showPdf = true;
        })
      });
    },
  }
}
</script>
