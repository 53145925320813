import moment, { HTML5_FMT } from 'moment';
import Vue from 'vue';
import dbHelper from '~/mixins/dbHelper';

export class Helpers {
    static datumFormatieren(datum, format = "DD.MM.YYYY") {
      if (!datum) return "";
      return moment(datum).local('de').format(format);
    }
    static uhrzeitFormatieren(uhrzeit, format = "HH:mm") {
      if (!uhrzeit) return "";
      return moment('1970-01-01 '+uhrzeit).local('de').format(format);
    }
    static zahlFormatieren(zahl, nachkomma = 2) {
      // Nachkomma nur anzeigen, wenn da
      if (nachkomma == -1) {
          // Wenn durch 0 Teilbar, dann keine Nachkommas
          if (zahl % 1 == 0) {
              return this.zahlFormatieren(zahl, 0);
          }
          // Wenn Nachkomma da ist, dann 2, default
          return this.zahlFormatieren(zahl);
      }
      let val = (zahl/1).toFixed(nachkomma).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
    static alter(datum) {
        return moment().local('de').diff(datum, 'years', false);
    }
    static pad (num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    static truncate(str, n){
      if (!str) return str;
      return (str.length > n) ? str.substr(0, n-1) + '' : str;
    }

    static nl2br (text) {
      if (!text) return text;
      return text.replace(/\n/g, '<br>');
    }
    // static updateTelefonnummer(neueNummer, nummerobjekt, bez) {
    //   let telefon = neueNummer;
    //   if (!telefon) return;
    //   if (telefon.length <= 4) return;

    //   nummerobjekt[bez] = telefon.replace(/^0+/, '+49 ');
    // }

    static make_array(element, defaultVal = 'arr') {
      if (!element) {
        if (defaultVal == 'arr') return [];
        if (defaultVal) return defaultVal;
      }
      if (Array.isArray(element)) return element;
      try {
        if (JSON.parse(element)) element = JSON.parse(element);
        else element = [element];
      } catch (error) {
        element = [element];
      }
      return element;
    }

    static validateEmail(email, nullable = true) {
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        if (nullable) return (email == "" || email == null || email == undefined) ? true : (reg.test(email) ? true : false);
        else return (email == "") ? false : (reg.test(email) ? true : false);
    }
    static allByKey(array, search, key = 'id') {
      if (!array || array.length == 0) return null;
      return array.filter(element => {
        return element[key] == search;
      });
    }
    static allByKey2(array, search, key = 'id', key2 = 'id') {
      if (!array || array.length == 0) return null;
      return array.filter(element => {
          return (element[key] && element[key][key2] == search);
        });
    }
    static findByKey(array, search, key = 'id') {
      if (!array || array.length == 0) return null;
        return this.allByKey(array, search, key)[0];
    }
    static findByKey2(array, search, key = 'id', key2 = 'id') {
      if (!array || array.length == 0) return null;
      return this.allByKey2(array, search, key, key2)[0];
    }

    static pluck(obj, key1, key2) {
        let neu = {};
        if (key1 == null) neu = [];
        obj.filter(element => {
          if (key1 == null) neu.push(element[key2]);
          else neu[element[key1]] = element[key2];
        });
        return neu;
    }

    static sortArray(array, by, order = 'asc') {
      return _.orderBy(array, by, order);
    }

    static getVar(string) {
      let re = /\{(.*)\}/;
      if (!(re).test(string)) return string;
      return string.match(re)[1];
    }

    static copy(obj) {
      if (!obj) return {};
      return JSON.parse(JSON.stringify(obj));
    }
    static is_date(input) {
      if ( Object.prototype.toString.call(input) === "[object Date]" )
        return true;
      return false;
    };
    static make_date(input) {
      if ( Object.prototype.toString.call(input) === "[object Date]" ) return input;
      // Einmal für Safari umformen, weil der das Datenbankformat nicht versteht
        let zwischenspeicher = moment(input).local('de');

      return new Date(
        zwischenspeicher.format('YYYY'),
        zwischenspeicher.format('M')-1,
        zwischenspeicher.format('D'),
        zwischenspeicher.format('HH'),
        zwischenspeicher.format('mm'),
        zwischenspeicher.format('ss')
      );
    };
    static getFileType(file) {
      if(file.type.match('image.*'))
        return 'image';
      if(file.type.match('video.*'))
        return 'video';
      if(file.type.match('audio.*'))
        return 'audio';

      return 'other';
    };

    static dauer(duration) {
      let mom = moment.utc(duration*1000);
      if (mom.format('H') > 0) return mom.format('H:mm:ss');
      return mom.format('mm:ss');
    };

    static aenderungen(elemente, datum) {
      return elemente.filter(el => {
        return (this.make_date(el.updated_at) > this.make_date(datum) && !el.socket);
      });
    }

    static wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    static zufallszahlZwischen(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    static getLocalStorageSize() {
      var _lsTotal = 0,
    _xLen, _x;
      for (_x in localStorage) {
          if (!localStorage.hasOwnProperty(_x)) {
              continue;
          }
          _xLen = ((localStorage[_x].length + _x.length) * 2);
          _lsTotal += _xLen;
      };
      return (_lsTotal / 1024).toFixed(2);
    }
}

Vue.prototype.$helpers = Helpers;
