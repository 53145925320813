<template>
  <div v-if="sonstiges">
        <legend>Tätigkeit</legend>
        <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" v-if="$can('Therapie verwalten')">
          <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Mitarbeiter</label>
          <div class="col">
              <mitarbeiter-auswahl :isinvalid="(showError && !sonstiges.mitarbeiter_id)" v-model="sonstiges.mitarbeiter_id"></mitarbeiter-auswahl>
          </div>
        </div>
        <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
          <label :for="'taetigkeit'+id" class="col-2 col-form-label mt-auto">Tätigkeit</label>
          <div class="col">
            <input type="text" name="" :id="'taetigkeit'+id" v-model="sonstiges.taetigkeit" class="form-control" :class="{'is-invalid': showError && !sonstiges.taetigkeit}" placeholder="Welche Tätigkeit hast du ausgeführt?">
          </div>
        </div>
        <div class="row form-group">
          <label :for="'kommentar'+id" class="col-2 col-form-label mt-auto">Details</label>
          <div class="col">
            <textarea-autosize
            v-if="!$isIOS()"
            class="form-control"
            placeholder="Kommentar"
            v-model="sonstiges.kommentar"
            rows="1"
            :min-height="35"
          />

        <textarea
          v-if="$isIOS()"
          class="form-control"
          v-model="sonstiges.kommentar"
          placeholder="Kommentar"
          rows="10">
        </textarea>
          </div>
        </div>
  </div>
</template>
<script>
import mitarbeiter from '~/mixins/mitarbeiter';

export default {
  mixins: [mitarbeiter],
  props: ['value', 'showError', 'id', 'neu', 'maProp'],
  watch: {
    sonstiges: function(neu, alt) {
      this.$emit('input', neu);
    },
  },
  data() {
    return {
      sonstiges: null,
      mitarbeiterKey: 0,
      verordnungenKey: 0,
      biskey: 0,
      ma: this.maProp
    }
  },
  methods: {
    fillChange(neu) {
      let sonstiges = this.$helpers.copy(neu);
      if (sonstiges == null) sonstiges = { };
      if (!sonstiges.id) sonstiges.id = this.$uuid.v1();
      if (!sonstiges.mitarbeiter_id) {
        if (this.ma) sonstiges.mitarbeiter_id = this.ma.id;
        else if (!this.$can('Therapie verwalten')) sonstiges.mitarbeiter_id = this.$auth.user.mitarbeiter.id;
      }
      this.sonstiges = sonstiges;
    }
  },
  mounted() {
    this.fillChange(this.value);
  }
}
</script>
