<template>
<tr v-if="elemente">
  <td colspan="100%">
    <div class="float-left">
      Seite {{ usepage }} von {{ Math.ceil(elemente.length / useanzahl) }}
      <button class="btn btn-sm btn-outline-dark" @click="usepage--" :disabled="usepage<=1"><ion-icon name="arrow-back"></ion-icon></button>
      <button class="btn btn-sm btn-outline-dark" @click="usepage++" :disabled="usepage>=elemente.length / useanzahl"><ion-icon name="arrow-forward"></ion-icon></button>
    </div>
    <div class="float-right form-inline"><label for="anzahl" class="form-check-label">Anzahl <input min="1" type="number" v-model="useanzahl" style="width:100px" id="anzahl" class="form-control ml-3"></label></div>
  </td>
</tr>
</template>

<script>
export default {
    props: ['elemente', 'anzahl', 'page'],
    data() {
      return {
        usepage: 1,
        useanzahl: 20,
        showAlt: []
      }
    },
    watch: {
      elemente() {
        this.emitChange();
      },
      anzahl() {
        this.useanzahl = this.anzahl;
        this.emitChange();
      },
      useanzahl() {
        this.emitChange();
      },
      page() {
        this.usepage = this.page;
        this.emitChange();
      },
      usepage() {
        this.emitChange();
      },
    },
    methods: {
      emitChange() {
        if (!this.elemente) return;
        let show = this.elemente.slice(this.useanzahl*(this.usepage-1), this.useanzahl*this.usepage);
        if (show != this.showAlt) {
          this.$emit('show', show);
          this.showAlt = show;
        }
        this.$emit('page', this.usepage);
      }
    },
    mounted() {
      if (this.anzahl) this.useanzahl = this.anzahl;
      if (this.page) this.usepage = this.page;
    }

}
</script>
