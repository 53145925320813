import {Helpers} from '~/plugins/helpers';
import _ from 'lodash';
import dbHelper from '~/mixins/dbHelper';
import moment from 'moment-timezone';
export default {
  empty: {
    elemente: [],
    loading: false,
    loadingProcess: null,
    date: new Date(0)
  },
  set (state, element) {
    let obj = element;
    // Möglichkeit mehr Parameter mitzugeben
    if (element.element) obj = element.element;
    // Wurde gerade vom Socket gesetzt
    if (obj.socket_get) {
      delete obj.socket_get;
      obj.socket = true;
    }
    // Wurde bearbeitet aber letztes Mal vom Socket gesetzt
    else if (obj.socket) delete obj.socket;
    dbHelper.saveElement(state.name, Helpers.copy(obj)).then(() => {
      // Hinterher ein Sync Event ballern
      if (state.name) {
        let objcopy = Helpers.copy(obj);
        if (!state.statisch) $nuxt.$emit(state.name+'-sync', objcopy);
        if (objcopy.updated_at) { // Hats das nicht, ists kein Model
          // Aktivitätsverlauf setzen
          objcopy.model_id = objcopy.id;
          objcopy.id = $nuxt.$uuid.v1();
          objcopy.tabelle = state.name;
          dbHelper.saveElement('aktivitaetsverlauf', objcopy);
        }
      }
    });
  },
  stand (state, stand) {
    state.globals.loading = false;
    state.globals.date = stand;
    stand = $nuxt.$moment(stand);
    let jetzt = $nuxt.$moment();
    let diff = stand.diff(jetzt);

    if (diff > 600000 || diff < -600000) {
      alert('Die lokale Zeit weicht um mehr als 10 Minuten von der Serverzeit ab, bitte konktaktiere den Administrator mit genau dieser Fehlermeldung!\nServer '+(state.name ? state.name : '')+': '
            +stand.format('HH:mm:ss')+' Lokal: '+jetzt.format('HH:mm:ss'));
    }
  },
  loading (state, param = {}) {
    let val = (param.val) ?? true;
    if (typeof param == 'boolean') val = param;
    let processnumber = (param.number) ?? null;

    state.globals.loading = val;
    state.globals.loadingProcess = processnumber;
  },
  reset (state) {
    state.globals = Helpers.copy({
      elemente: [],
      loading: false,
      loadingProcess: null,
      date: new Date(0)
    });
    dbHelper.resetTable(state.name).then(() => {
      $nuxt.$emit(state.name+'-sync');
    });

  },



  isLoading(state) {
    return state.globals.loading;
  },
  async getAll({state}) {
    // Wenn stand auf null ist, dann immer ein leeres Array liefern
    if (state.name != 'syncprotocol' && state.name != 'aktivitaetsverlauf' && new Date(0).getTime() == Helpers.make_date(state.globals.date).getTime()) {return [];}
    // Den deleted_at Filter weg
    let returnval = null;
    if (state.name == 'syncprotocol' || state.name == 'aktivitaetsverlauf') returnval = await dbHelper.getElemente(state.name);
    else returnval = await dbHelper.getElemente(state.name, {deleted_at: null});
    if (!returnval || !returnval.length) return [];
    return returnval;
  },
  async withTrashed({state}) {
    let send = state.globals.elemente;
    if (!send || send.length == 0) send = await dbHelper.getElemente(state.name);
    return send;
  },
  getStand(state, commit) {
      return Helpers.make_date(state.globals.date);
  },
  filled(state) {
    if (Helpers.make_date(state.globals.date).getTime() > 0) return true;
    return false;
  },


  async speichern({commit, state}, model) {
    // 1 Sekunde verzögern, damit Speicheruhrzeit und Last Sync Uhrzeit nicht mehr identisch sein können.
    await Helpers.wait(1000);
    model.updated_at = $nuxt.$moment().toISOString();
    if (!model.deleted_at) model.deleted_at = null;
    commit('set', model);
  },
  async loeschen({commit, state}, model) {
    model.updated_at = $nuxt.$moment().toISOString();
    model.deleted_at = $nuxt.$moment().toISOString();
    commit('set', model);
  },

  async sync({commit, dispatch, getters, state}, manuell, params = {}) {
    // Check ob das Element schon gesynct wird
    if (getters.isLoading) return;
    // Check ob überhaupt online
    if ($nuxt.isOffline) return $nuxt.$notifyError('Sync nicht möglich. Keine Internetverbindung.');
    // Check ob eingeloggt
    if (!$nuxt.$auth.loggedIn) {
      $nuxt.$emit('unauthorized');
      return false;
    }
    let processnumber = Math.random();
    let checkstand = (params.last) ?? getters.getStand;
    let jetzt = new Date();
    // Stand ist erst 1 Sekunde alt
    if (checkstand.getTime() > (jetzt.getTime() - 1000)) { $nuxt.$notifyError('Sync '+state.titel+' ist nur einmal pro Sekunden möglich.'); return};

    // Loading setzen
    commit('loading', {val: true, number: processnumber});
    // Checken ob auch wirklich der Prozess am Laden ist und nicht ein anderer
    if (state.globals.loadingProcess != processnumber) return;

    let aenderungen = await dbHelper.getAenderungen(state.name, getters.getStand)

    let send = {elemente: aenderungen, last: getters.getStand};

    // Parameter im Sync übergeben, zb für Arbeitszeiten
    if (Object.keys(params).length) {
      _.forEach(params, (param, key) => {
        send[key] = param;
      });
    }

    let stand = null;
    let serveranzahl = 0;
    let url = state.syncurl;
    // Letzter Check ob wir immernoch im richtigen Prozess sind und nicht zwei gleichzeitig laufen
    if (state.globals.loadingProcess != processnumber) return;

    // 1 Sekunde verzögern, damit Speicheruhrzeit und Last Sync Uhrzeit nicht mehr identisch sein können.
    await Helpers.wait(1000);
    // Do While für den Sync
    do {
        let response = await this.syncRequest(commit, url, state, send, manuell, alle);


      // Nur Relevant, wenn Paginated, dann nächste Seite URL speichern
      url = (response && response.data) ? response.data.elemente.next_page_url : null;
      if (state.statisch) url = null;
      if (url != null) {
        // Beim Pagination Abruf nicht nochmal die geänderten Elemente mtischicken
        send.elemente = {};
      }
      // Wenn keine weiteren kommen
      else {
        stand = (response.data && response.data.stand) ? response.data.stand : null;
        serveranzahl = (response && response.data) ? response.data.anzahlElemente : 0;
      }
    } while (url != null)

    if (stand) commit('stand', stand);
    // Event an alle
    // Checken ob Serveranzahl und lokale Anzahl übereinstimmen
    let alle = await dbHelper.getElemente(state.name)
    $nuxt.$emit(state.name+'-sync');

    if (!state.statisch && alle.length < serveranzahl) {
      $nuxt.$notifyError(state.name+': '+(serveranzahl - alle.length)+' Fehlende Elemente entdeckt. Alle Elemente werden neu abgerufen');
      this.sync({commit, dispatch, getters, state}, manuell, params = {last: Helpers.make_date('1970-01-01 00:00')});
    }
  },

  async syncRequest(commit, url, state, send, manuell, alle) {
    try {
      // Lokal eine Zufallslatenz
      let status = null;
      let zufallszahl = Math.random()*1000;
      if (process.env.NODE_ENV == 'development') await Helpers.wait(zufallszahl);
      // Request
      let response = await $nuxt.$axios.post(url, send);

      // Alle speichern
      if (!state.statisch) {
        if (response.data.elemente.data.length) await dbHelper.saveElemente(state.name, response.data.elemente.data);
      }
      if (response.data.done.length) await dbHelper.saveElemente(state.name, response.data.done);
      if (response.data.failed.length) {
        // Failed auf unsynced stellen
        let failed = response.data.failed.map(el => {
          el.updated_at = $nuxt.$moment(response.data.stand).add(10, 'seconds').toISOString();
          return el;
        })
        await dbHelper.saveElemente(state.name, failed);
      }


      if (response.data.failed.length) {
        $nuxt.$notifyInfo(state.titel + ' aktualisieren', 'Beim Synchronisieren traten Fehler mit '+response.data.failed.length+' Elementen auf.');
        status = 2;
      }
      else if (!response.data.elemente.next_page_url || state.statisch) {
        // Weil auch heruntergeladen wird, was hochgeladen wurde. Die Zahl der Uploads von den Downloads abziehen
        let text = '<ion-icon name="arrow-up"></ion-icon>'+ (send.elemente.length ? send.elemente.length : 0)  + ' Element'+(send.elemente.length != 1 ? 'e' : '')+' wurde'+(send.elemente.length != 1 ? 'n' : '')+' hochgeladen<br>';
        if (!state.statisch) text += '<ion-icon name="arrow-down"></ion-icon> '+(response.data.elemente.total)+' Element'+((response.data.elemente.total) != 1 ? 'e' : '')+' wurde'+((response.data.elemente.total) != 1 ? 'n' : '')+' heruntergeladen';
        if (manuell) $nuxt.$notifySuccess(state.titel + ' aktualisieren',text);
        status = 1;
      }
      // es gibt noch etwas nachzuladen, Zwischenstandsinfo
      else {
        if (manuell) $nuxt.$notifySuccess(state.titel + ' aktualisieren', 'Weitere Daten laden.<br>Aktuell: '+response.data.elemente.from+'-'+response.data.elemente.to+'/'+response.data.elemente.total+'.');
        status = 1;
      }
      $nuxt.$store.commit('syncprotocol/set', {typ: state.name, time: response.data.stand, send: send, antwort: response.data, status: status, id: $nuxt.$uuid.v1()});
      return response;
    } catch (error) {
      setTimeout(() => {
        commit('loading', false);
      }, 1000);

      $nuxt.$store.commit('syncprotocol/set', {typ: state.name, time: $nuxt.$moment().format('YYYY-MM-DD HH:mm:ss'), send: send, antwort: {error: error}, status: 0});

      if (error.response && error.response.data && error.response.data.error.code && error.response.data.error.code == 401) {
        console.log('Nicht eingeloggt');
        // Nicht eingeloggt aber im Frontend noch eingeloggt
        if ($nuxt.$auth.loggedIn) $nuxt.$emit('unauthorized');
      }
      else {
        $nuxt.$notifyError(state.titel + ' aktualisieren', 'Daten konnten nicht aktualisiert werden.', error);
        console.error(error);
      }
      return false;
    }
  }
}
