import Vue from 'vue';
import {Helpers} from '@/plugins/helpers';

let notifications = {
  methods: {
    $notifySuccess (title, message) {
      let data = {
          datetime: new Date(),
          group: 'default',
          title: title,
          type: 'success',
          text: message,
          duration: 10000,
      };
      this.$notify(data);
    this.$store.commit('lokalenotifications/set', data);
    },
    $notifyError (title, message, error = null) {
      if (error != null) {
        if (error.response && error.response.data && error.response.data.error) {
          message += '<br />Fehler: '+ error.response.data.error.code+': '+error.response.data.error.message;
        }
        else message += '<br />Fehler: '+error;
      }
      let data = {
          datetime: new Date(),
          group: 'default',
          title: title,
          type: 'error',
          text: message,
          duration: 5000,
      }
      this.$notify(data);
      this.$store.commit('lokalenotifications/set', data);
    },
    $notifyInfo (title, message) {
      let data = {
          datetime: new Date(),
          group: 'default',
          title: title,
          type: 'warn',
          text: message,
          duration: 5000,
      }
      this.$notify(data);
      this.$store.commit('lokalenotifications/set', data);
    },
    $notifySync (title, message) {
      let data = {
          datetime: new Date(),
          group: 'default',
          title: title,
          type: 'null',
          text: message,
          duration: 3000,
      }
      this.$notify(data);
      this.$store.commit('lokalenotifications/set', data);
    }
  }
}

Vue.mixin(notifications);
export default notifications;
