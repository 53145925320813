<template>
  <multiselect
    :key="counter"
    :class="{'is-invalid': isinvalid}"
    track-by="id"
    v-model="arzt"
    placeholder="Arzt wählen"
    :id="'arzt'+id"
    :options="aerzte"
    :custom-label="arztLabel"
    @select="update($event)"
    @remove="update(null)"
    :clear-on-select="false"
    :close-on-select="false"
    @search-change="asyncFind"
    :internal-search="false"

  >
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import aerzte from '~/mixins/aerzte';
import kontakte from '~/mixins/kontakte';
export default {
  components: {Multiselect},
  mixins: [aerzte, kontakte],
  props: {
    value: String,
    isinvalid: {
      default: false
    }
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
      this.counter++;
    },
    aerzte(neu, alt) {
      this.externerInput(this.value);
    }
  },
  data() {
    return {
      arzt: null,
      counter: 0,
      id: this._uid,
      suchbegriff: ''
    }
  },
  methods: {
    update(element) {
      if (this.arzt != element) this.$emit('update', element);
      this.arzt = element;
      if (!element || !element.id) return this.$emit('input', null);
      this.$emit('input', element.id);
    },
    async externerInput(neu) {
      if (neu) await this.arztAbrufen(neu);
      else this.arzt = [];
    },
    arztLabel(obj) {
      let kontakt = this.kontakte.find(kontakt => {
        return (kontakt.model_id == obj.id);
      });
      if (kontakt) return this.$ganzerName({kontakt:kontakt});
      return 'Nicht gefunden';
    },
    asyncFind(query) {
      console.log(query);

      this.suchbegriff = query;
      this.aerzteAbrufen();
    }
  },
  mounted() {
    this.externerInput(this.value);
    this.aerzteAbrufen();
    this.kontakteFilter = {model_type: 'Arzt'};
    this.kontakteAbrufen();
  }
}
</script>
