import Vue from 'vue'

Vue.directive('auth-image', {
  inserted: (el, binding) => {
    const url = binding.value;
    el.src = '/img-loader.svg';
    $nuxt.$axios.get(url, {responseType: 'arraybuffer'}).then(response => {
      var mimeType = response.headers['content-type'].toLowerCase();
      var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
      el.src = 'data:' + mimeType + ';base64,' + imgBase64;
    })
  }
})
