<template>
  <multiselect
    :key="counter"
    :class="{'is-invalid': isinvalid}"
    track-by="id"
    v-model="kontakt"
    placeholder="Kontakt wählen"
    :id="'kontakt'+id"
    :options="kontakteSelect"
    :show-labels="false"
    label="name"
    @select="update($event)"
    @remove="update(null)"
  >
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import kontakte from '~/mixins/kontakte';
export default {
  components: {Multiselect},
  mixins: [kontakte],
  props: {
    value: String,
    isinvalid: {
      default: false
    },
    filter: null
  },
  watch: {
    value(neu, alt) {
      this.externerInput(neu);
    },
    kontakte() {
      this.externerInput(this.value);
    }
  },
  data() {
    return {
      kontakt: null,
      counter: 0,
      id: this._uid,
    }
  },
  methods: {
    update(element) {
      if (this.kontakt != element) this.$emit('update', element);
      this.kontakt = element;
      if (!element || !element.id) return this.$emit('input', null);
      this.$emit('input', element.id);
    },
    externerInput(neu) {
      if (neu) this.kontaktAbrufen(neu);
      else this.kontakt = [];
      this.conter++;
    }
  },
  mounted() {
    this.externerInput(this.value);
    if (this.filter) this.kontakteFilter = this.filter;
    this.kontakteAbrufen();
  }
}
</script>
