<template>
     <b-modal :id="id" size="lg" :title="'Therapiestunde erfassen '+ ((therapiestunde && therapiestunde.patient_id && kontakt) ? $ganzerName({kontakt:kontakt}) : '')" hide-footer @shown="formShow()" :ref="'modal-'+id">
        <form-wizard
        color="#1D637E"
            :key="formCounter"
            title=""
            subtitle=""
            nextButtonText="Weiter"
            backButtonText="Zurück"
            finishButtonText="Speichern"
            stepSize="xs"
            :von-index="vonindex"
            @on-complete="speichern()"
            ref="wizard"
        >
            <tab-content title="Therapie" icon="&nbsp;" :before-change="therapieValide">
                <therapiestunde-formular :verordnungProp="verordnungProp" :key="formCounter" :id="id" v-model="therapiestunde" @change-zeit="arbeitszeitKey++" :showError="showError" :neu="true"></therapiestunde-formular>
            </tab-content>
            <tab-content title="Arbeitszeit" icon="&nbsp;" :before-change="arbeitszeitValide">
                <arbeitszeit-therapie-formular :key="formCounter+arbeitszeitKey" :id="id" v-model="arbeitszeit"
                  :anfahrt="anfahrt" @anfahrtUpdate="anfahrt = $event"
                  :abfahrt="abfahrt" @abfahrtUpdate="abfahrt = $event"
                  :therapiestunde="therapiestunde"
                  :showError="showError"></arbeitszeit-therapie-formular>
            </tab-content>
            <tab-content title="Zusammenfassung" icon="&nbsp;">
                <div class="row" v-if="therapiestunde">
                  <div class="col">
                    <zusammenfassung-allgemein
                      titel="Therapiestunde"
                     :therapiestunde="therapiestunde"></zusammenfassung-allgemein>
                  </div>
                  <div class="col">
                    <arbeitszeit-zusammenfassung
                      :anfahrt="anfahrt"
                      :abfahrt="abfahrt"
                      :arbeitszeit="arbeitszeit"
                    ></arbeitszeit-zusammenfassung>
                  </div>
                </div>
            </tab-content>
        </form-wizard>
    </b-modal>
</template>

<script>
import therapieformular from '~/mixins/therapieformular';
import TherapiestundeFormular from '~/components/mitarbeiter/therapie/formular';

export default {
  components: {TherapiestundeFormular},
  mixins: [therapieformular],
  props: ['verordnungProp'],
  data() {
      return {
          therapiestunde: null
      }
  },
  methods: {
    fillChange(neu) {
      if (neu != null) this.therapiestunde = this.$helpers.copy(neu);
      this.kontaktAbrufenFilter({model_type: 'Patient', model_id: neu.patient_id});

      this.showError = false;
      this.formCounter++;
    },
    therapieValide() {
        this.showError = true;
        if (!this.therapiestunde) return false;

        if (!this.therapiestunde.patient_id || !this.therapiestunde.mitarbeiter_id || !this.therapiestunde.von || !this.therapiestunde.dauer) return false;
        this.showError = false;
        if (this.therapiestunde.hausbesuch) {
          this.anfahrt.erfassen = true;
          if (!this.anfahrt.von) this.anfahrt.von = this.$moment(this.therapiestunde.von).subtract(15, 'm').format("YYYY-MM-DDTHH:mm");
          if (!this.anfahrt.dauer) this.anfahrt.dauer = 15;
          if (this.anfahrt.von && this.anfahrt.dauer) this.anfahrt.bis = this.$moment(this.anfahrt.von).add(this.anfahrt.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
        }
        return true;
    },

    speichern() {
      // Keine Verordnung, keine Kekse
      if (!this.therapiestunde.verordnung_id) return;

      // Therapiestunde speichern
      this.speichernTherapiestunde(this.therapiestunde)

      this.therapieArbeitszeitSpeichern(this.therapiestunde, 'Therapiestunde');

      this.$bvModal.hide(this.id)
    },

  },
  computed: {
      vonindex: function() {
          if (!this.therapiestunde || !this.therapiestunde.updated_at) return 0;
          return 2;
      },

  }
}
</script>
