import _ from "lodash";
import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
import lokiHelper from '~/mixins/lokiHelper';
export default {
  mixins: [suche, lokiHelper],
  methods: {
    loadVorlagen(manuell = false) {
      if (!this.$can('Schreiben erstellen') && !this.$can('Schreiben Vorlagen verwalten')) return;
      this.$store.dispatch('schreiben-vorlagen/sync', manuell);
    },
    resetVorlagen() {
      this.$store.commit('schreiben-vorlagen/reset');
      this.loadVorlagen(true);
    },
    async speichernVorlage(model) {
      if (!this.$can('Schreiben Vorlagen verwalten')) return;
      this.$store.dispatch('schreiben-vorlagen/speichern', model);
      this.$notifySuccess('Vorlage gespeichert');
      setTimeout(() => {
        this.loadVorlagen(true);
      }, 1000);
    },
    async vorlagenAbrufen() {
      this.getVorlagen = true;
      this.registerVorlagenEvent();
      if (!this.suchbegriff) {
       this.vorlagen = await this.getElemente('schreiben-vorlagen', this.vorlagenFilter);
       this.vorlagenCount = this.vorlagen.length;
       return;
      }

      let vorlagenCollection = await dbHelper.getDb('schreiben-vorlagen');
      this.vorlagen = await vorlagenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    vorlagenAenderungenAbrufen() {
      this.getVorlagenAenderungen = true;
      this.registerVorlagenEvent();
      return this.getAenderungen('schreiben-vorlagen').then(data => this.vorlageAenderungen = data);
    },
    vorlageAbrufen(id) {
      this.getVorlage = id
      this.registerVorlagenEvent();
      return this.getElement('schreiben-vorlagen', {id: id}).then(vorlage => this.vorlage = vorlage);
    },
    registerVorlagenEvent() {
      if (!this.vorlageEvent) this.vorlageEvent = this.$nuxt.$on('schreiben-vorlagen-sync', this.vorlagenErneuern);
    },
    vorlagenErneuern(event) {
      if (this.getVorlage) {
        if (event && this.getVorlage == event.id) this.vorlage = event;
        else  this.vorlageAbrufen(this.getVorlage);
      }
      if (this.getVorlagen) this.vorlagenAbrufen();
      if (this.getVorlagenAenderungen) this.vorlagenAenderungenAbrufen();
    },
    vorlagenKinderSuchen(elemente) {
      return this.$helpers.sortArray(elemente, this.orderVorlagenby)
    }
  },
  created() {
    this.standVorlagen;
  },
  mounted() {
    if (!this.$store.getters['schreiben-vorlagen/filled']) this.loadVorlagen();
  },
  computed: {
    standVorlagen: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['schreiben-vorlagen/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadVorlagen();
      return stand;
    }
  },
  data() {
    return {
      vorlage: null,
      vorlagen: [],
      vorlagenCount: 0,
      vorlagenAenderungen: [],
      getVorlage: false,
      getVorlagen: false,
      getVorlagenAenderungen: false,
      vorlageEvent: false,
      vorlagenFilter: null,
      orderVorlagenby: 'name'
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('schreiben-vorlagen-sync', this.vorlagenErneuern);
    this.vorlageEvent = null;
  },
}
