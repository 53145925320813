<template>
<div class="kalender-grid">


  <!-- Spalte 1 Beschriftung Links -->
    <div class="kalender-tag kalender-tag-uhrzeiten">
      <div class="kalender-ecke">&nbsp;</div>
      <div class="kalender-uhrzeit" v-for="(zeit, index) in uhrzeiten" :key="index">{{zeit.displaytime}}</div>
    </div>
    <!-- Inhaltsspalten -->
    <kalender-spalte v-for="(spalte, index) in spalten" :spalte="spalte" :key="'spalte'+index+spalte.id+spalte.key"></kalender-spalte>
    <!-- Add Spalte -->
    <div class="kalender-spalte kalender-spalte-add kalender-tag" v-if="editSpalte">
      <kalenderspalte-formular :spalte="editSpalte" target="addspalte" @speichern="fillEditSpalte()" :key="'editspalte'+editSpalteKey"></kalenderspalte-formular>
        <div class="kalender-spaltentitel kalender-spalte-add" id="addspalte"  @click="show()">
          <div class="kalender-spalte-add-content my-auto">
            <ion-icon name="add-outline"></ion-icon>
          </div>
        </div>
        <div class="kalender-box" v-for="(zeit, index) in uhrzeiten" :key="index">&nbsp;</div>
    </div>
</div>
</template>
<script>
import KalenderSpalte from '~/components/kalender/KalenderSpalte';
import KalenderspalteFormular from '~/components/kalender/KalenderspalteFormular';
import kalender from '~/mixins/kalender';
export default {
  mixins: [kalender],
  components: {KalenderSpalte, KalenderspalteFormular},
  data() {
    return {
      editSpalte: null,
      editSpalteKey: 0
    }
  },
  methods: {
    fillEditSpalte() {
      this.editSpalte = {id: this.$uuid.v1(), titel: '', datum: this.$moment().format('YYYY-MM-DD'), filter: {
        mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null

      }, key: 0, show: false};
      this.editSpalteKey++;
    },
    show() {
      this.editSpalte.show = !this.editSpalte.show
      this.editSpalteKey++;
    }
  },
  mounted() {
    this.fillEditSpalte();
  }
}
</script>
