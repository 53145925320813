export default {
  data() {
    return {
      standorte: [],
      raeume: []
    }
  },
  computed: {
    materialien() {
      if (!this.standorte.length) return [];
      let materialien = [];
      this.standorte.forEach(standort => {
        materialien = materialien.concat(standort.materialien);
      })
      return materialien;
    },
    fahrzeuge() {
      if (!this.standorte.length) return [];
      let fahrzeuge = [];
      this.standorte.forEach(standort => {
        fahrzeuge = fahrzeuge.concat(standort.fahrzeuge);
      })
      return fahrzeuge;
    },
  },
  methods: {
    standortFachbereiche(standort_id) {
      let standort = this.$helpers.findByKey(this.standorte, standort_id);
      if (!standort) return this.$fachbereiche;
      let fachbereiche = Object.keys(this.$fachbereiche).filter(fb => {
        return standort.fachbereich.includes(fb)
      })
      return fachbereiche
    },
    async speichernStandort(standort) {
      let response = await this.$axios.put('{kalender}/v1/standorte', standort);
      return response.data;
    },
    async speichernRaum(raum) {
      let response = await this.$axios.put('{kalender}/v1/raeume', raum);
      return response.data;
    },
    async speichernFahrzeug(fahrzeug) {
      let response = await this.$axios.put('{kalender}/v1/fahrzeuge', fahrzeug);
      return response.data;
    },
    async speichernMaterial(material) {
      let response = await this.$axios.put('{kalender}/v1/materialien', material);
      return response.data;
    },
    replaceStandort(standort) {
      this.standorte = this.standorte.map(el => {
        if (el.id == standort.id) return standort;
        return el;
      });
      this.$store.commit('standorte/set', this.standorte);
      this.standorteAbrufen();
    },
    standorteAbholen() {
      this.standorte = this.$helpers.copy(this.$store.getters['standorte/get']);
      let raeume = [];
      if (!this.standorte.length) return this.raeume = raeume;
      this.standorte.forEach(standort => {
        raeume = raeume.concat(standort.raeume);
      })
      this.raeume = raeume;

    },
    standorteAbrufen() {
      if (this.$store.getters['standorte/get'].length) {
        this.standorteAbholen();
      }
      else {
        this.$store.dispatch('standorte/abrufen').then(() => {
          this.standorteAbholen();
        });
      }
    }
  }
}
