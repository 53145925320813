export const state = () => ({
  von: 10,
  bis: 14,
  schritt: 0.25,
  kalenderhoehe: 0,
  loading: false,
  spalten: [
    // {id: 'blub', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub1', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub2', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub3', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub4', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub5', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub6', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
    // {id: 'blub7', titel: 'Bögelein, Xenia', datum: '2021-03-08', filter: {
    //   mitarbeiter_id: [], termintyp: [], fachbereich: [], raum_id: [], patient_id: [], hausbesuch: null, teletherapie: null, abgesagt: null, stattgefunden: null
    // }, key: 0, show: false},
  ],
  spaltenAbgerufen: null
})

const mutations = {
  speichernSpalte(state, spalte) {
    // Alle anderen Spalten ausblenden, wenn eine neue eingeblendet wird
    if (spalte.show) {
      state.spalten.map(spalte => {
        spalte.show = false;
        spalte.key = spalte.key+1;
        return spalte;
      })
    }
    const item = state.spalten.find(el => el.id === spalte.id)
    if (item) state.spalten.splice(state.spalten.indexOf(item), 1, spalte)
    else state.spalten.push(spalte);
  },
  speichernSpalten(state, spalten) {
    state.spalten = spalten;
  },
  speichernTermin(state, termin) {
    if (!state.spaltenAbgerufen) return;
    let spalte = _.find(state.spaltenAbgerufen.spalten, spalte => spalte.request.datum == this.$moment(termin.von).format('YYYY-MM-DD'));
    if (!spalte) return;
    const item = spalte.termine.find(el => el.id === termin.id)
    if (item) spalte.termine.splice(spalte.termine.indexOf(item), 1, termin)
    else spalte.termine.push(termin);
  },
  loeschenSpalte(state, spalte) {
    const item = state.spalten.find(el => el.id === spalte.id)
    if (item) state.spalten.splice(state.spalten.indexOf(item), 1);
  },
  resetSpalten(state) {
    state.spalten = [];
  },
  setKalenderHoehe(state, value) {
    state.kalenderhoehe = value;
  },
  setVon(state, value) {
    state.von = value;
  },
  setBis(state, value) {
    state.bis = value;
  },
  speichernSpaltenData(state, spalten) {
    state.spaltenAbgerufen = spalten;
  },
  loading(state, val = true) {
    if (val) $nuxt.$loading.start('Kalender aktualisieren');
    else $nuxt.$loading.finish();
    state.loading = val;
  }
}

const getters = {
  von(state) {
    return state.von;
  },
  bis(state) {
    return state.bis;
  },
  schritt(state) {
    return state.schritt;
  },
  kalenderhoehe(state) {
    // 50 Abzug, weil die Titelzeile 50 hoch ist
    if (state.kalenderhoehe) return state.kalenderhoehe - 50
    return 0;
  },
  spalten(state) {
    return state.spalten;
  },
  spaltenData(state) {
    return state.spaltenAbgerufen;
  },
  loading(state) {
    return state.loading;
  }
}

const actions = {
  getApiData({state}) {
    this.commit('kalender/loading');
    this.$axios.put('{kalender}/v1/kalender/tage', {spalten: state.spalten}).then(response => {
      this.commit('kalender/setVon', response.data.min_uhrzeit);
      this.commit('kalender/setBis', response.data.max_uhrzeit);
      this.commit('kalender/speichernSpaltenData', response.data);
      $nuxt.$emit('kalendertermine-geladen');
      this.commit('kalender/loading', false);
    }, error => {
      this.commit('kalender/loading', false);
      $nuxt.$notifyError('Kalenderabruf', 'Es ist ein Fehler aufgetreten', error);
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
