<template>
  <span v-if="therapiestunden">
    {{ therapiestunden.length }}
    <span v-if="verordnungsmenge(verordnungProp)"> / {{ verordnungsmenge(verordnungProp) }}
      <span v-if="erstbefundungErlaubt(verordnungProp)">(+1) <tooltip-information @show="erklaerungBauen()"><b>+1</b> weil eine zusätzliche Diagnostik erlaubt ist
          <br>(Es ist das erste Rezept, oder das neue Rezept wurde von einem anderen Arzt ausgestellt, oder der ICD10 Code hat sich geändert)
          <span class="d-block mt-3" v-if="erklaerung && erklaerung.kommentar"><b>{{erklaerung.kommentar}}</b></span>
          <span class="d-block mt-3 text-danger" v-else-if="erklaerung && !erklaerung.wert">Es sollte keine EB erlaubt sein. Bitte bearbeite und speichere die Verordnung neu.</span>
          </tooltip-information>
        <tooltip-information type="danger" v-if="verordnungProp.verordnungsmenge_geaendert">{{verordnungProp.verordnungsmenge_geaendert}}</tooltip-information>
      </span>
    </span>
  </span>
</template>
<script>
import verordnunghelfer from '~/mixins/helfer/verordnunghelfer';
import therapiestunden from '~/mixins/therapiestunden';
import lokiHelper from '~/mixins/lokiHelper';
import verordnungen from '~/mixins/verordnungen';
export default {
  mixins: [verordnunghelfer, therapiestunden, lokiHelper, verordnungen],
  props: ['verordnungProp'],
  data() {
    return {
      erklaerung: null,
    }
  },
  methods: {
    async erklaerungBauen() {
      this.verordnungenFilter = {patient_id: this.verordnungProp.patient_id};
      await this.verordnungenAbrufen();

      let kommentar = 'Neuer Verordnungsfall, Begründung: ';
        // Letzte Verordnung nach Datum
        let alt = this.$helpers.sortArray(this.verordnungen, 'datum', 'desc');
        alt = alt.filter(element => {
          return (element.datum < this.verordnungProp.datum && element.fachbereich == this.verordnungProp.fachbereich);
        })

        let neuerFall = false;
        if (alt.length) {
          // Indikation und ICD10 ist anders
          if (alt[0].indikationsschluessel != this.verordnungProp.indikationsschluessel && (alt[0].icd_10_code != this.verordnungProp.icd_10_code || alt[0].icd_10_code_2 != this.verordnungProp.icd_10_code_2)) {neuerFall = true; kommentar += 'Diagnosgruppe und ICD-10-Code geändert';}
          // Ausstellungsdatum älter als 6 Monate
          else if (this.$moment(alt[0].datum) < this.$moment(this.verordnungProp.datum).subtract(6, 'months')) {neuerFall = true; kommentar += 'Mehr als 6 Monate seit Ausstellungsdatum der letzten Verordnung';}
        }
        else {
          neuerFall = true;
          kommentar += 'Keine alte Verordnung vorhanden';
        }

        this.erklaerung = {wert: neuerFall, kommentar: (neuerFall ? kommentar : '')};
    }
  },
  mounted() {
    // Statisch
    if (this.verordnungProp.therapiestunden) {
      this.therapiestunden = this.verordnungProp.therapiestunden;
    }
    else {
      this.therapiestundenFilter = {verordnung_id: this.verordnungProp.id};
      this.therapiestundenAbrufen();
    }
  }
}
</script>
