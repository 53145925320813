const loki = require('lokijs');
// const LokiIndexedAdapter = require("lokijs/src/loki-indexed-adapter.js");
const IncrementalIndexedDBAdapter = require("lokijs/src/incremental-indexeddb-adapter.js");

var idbAdapter = new IncrementalIndexedDBAdapter();
const DB_NAME = 'logoyesportal';
// const DB_VERSION = 4;
let DB = new loki(DB_NAME, {
  adapter: idbAdapter,
  autoload: true,
	autosave: true,
	autosaveInterval: 4000
});

export default {
	async getDb(table) {
    let collection = DB.getCollection(table);
    if (!collection) {
      DB.addCollection(table, {
        unique: 'id'
      });
      collection = DB.getCollection(table);
    }
    return collection;
	},

	async resetTable(table) {
    let collection = await this.getDb(table);
    if ($nuxt.$debugMode) console.log('Tabelle leeren '+table, collection.data);
    collection.remove(collection.data);
    await DB.saveDatabase();
	},

	async getElemente(table, filter = null) {
    let collection = await this.getDb(table);
    if ($nuxt.$debugMode) console.log('Loki getElemente '+table, filter);

    if (filter && typeof filter == 'object') return collection.find(filter);
    return collection.data;
	},
	async getAenderungen(table, last) {
    last = $nuxt.$moment(last).toISOString()
    // Wenn Syncstatus verloren gegangen ist, dann nicht alle alten Elemente nochmal syncen
    if (last == '1970-01-01T00:00:00.000Z') return [];
    let collection = await this.getDb(table);
    let filter = {'$or': [{'updated_at': {'$gt': last}}, {'created_at': {'$aeq': undefined}}], 'socket': {'$aeq': undefined}};
    let aenderungen = await collection.find(filter);
    return aenderungen
	},

  async getElement(table, filter) {
    let collection = await this.getDb(table);
    if ($nuxt.$debugMode) console.log('Loki getElement '+table, filter);
    if (!filter || typeof filter != 'object') return null;
    let element = collection.findOne(filter);
    return element;
	},

	async saveElement(table, element) {
    if (!element) return;
    let collection = await this.getDb(table);
    let alt = null;
    // Syncprotokol und Aktivitätsverlauf brauchen für sich neue meta Infos
    if (table == 'aktivitaetsverlauf' || table == 'syncprotocol') {
      delete element.$loki;
      delete element.meta;
    }
    // Sonst, wenn das Element schon eine Loki ID hat, wird es damit bearbeitet, oder die ID gesucht vom Alten
    else if (!element.$loki) {
      alt = await collection.findOne({id: element.id});

      if (alt) {
        element.$loki = alt.$loki;
        element.meta = alt.meta;
      }
    }

    if (element.$loki) {
      await collection.update(element);
    }
    else {
      delete element.$loki;
      delete element.meta;
      await collection.insert(element);
    }
	},

  async saveElemente(table, elemente) {
    if (!elemente.length) return;
    let collection = await this.getDb(table);
    // Collection ist leer, einfach alle inserten
    if (!collection.data.length) collection.insert(elemente);
    // Sonst Alte suchen und Update & Insert machen
    else {
      let update = [];
      let insert = [];

      await elemente.forEach(async neu => {
        delete neu.$loki;
        delete neu.meta;
        let elalt = await collection.by("id", neu.id)
        if (elalt && elalt.meta && elalt.$loki) {
          if ($nuxt.$moment(neu.updated_at).toISOString() > $nuxt.$moment().toISOString()) {
            neu.updated_at = $nuxt.$moment().toISOString();
          }
          if (neu.updated_at >= elalt.updated_at) {
            neu.$loki = elalt.$loki;
            neu.meta = elalt.meta;
            update.push(neu);
          }
        }
        // Aber Meta Infos sind leer
        else if (elalt && typeof elalt == 'object') {
          collection.remove(elalt);
          insert.push(neu);
        }
        else insert.push(neu);
      })
      if ($nuxt.$debugMode) console.log('Loki Update '+table, update);
      if (update.length) await collection.update(update);
      if ($nuxt.$debugMode) console.log('Loki Insert '+table, insert);
      if (insert.length) await collection.insert(insert);
    }
  }

}
