<template>
  <div>
    <table class="table table-striped table-sm">
      <thead>
        <th>Name</th>
        <th>Tags</th>
        <th></th>
        <th width="90px"></th>
      </thead>
      <tbody>
        <template v-if="$can('Modeldateien herunterladen')">
          <tr class="table-dark">
            <td colspan="100%"><button class="btn btn-sm btn-outline-dark btn mr-1" @click="modelDateienAbrufen('Verordnung', verordnung)"><span><ion-icon name="refresh-outline"></ion-icon></span></button> Dateien</td>
          </tr>
          <tr v-for="(datei, index) in modeldateien" :key="'datei'+datei.id">
            <td>{{datei.aktuelle_version.dateiname}}</td>
            <td>
              <cloud-tags v-model="modeldateien[index]" :editable="false"></cloud-tags>
            </td>
            <td class="text-right">
              <button class="btn btn-outline-primary btn-sm" @click="downloadModelDatei(datei.aktuelle_version)"><ion-icon name="cloud-download-outline"></ion-icon></button>
            </td>
            <td><button @click="$emit('input', datei.aktuelle_version.id)" :class="{active: value == datei.aktuelle_version.id}" class="btn btn-sm btn-outline-primary"><ion-icon name="checkmark-outline"></ion-icon> Wählen</button></td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import modelDateien from '~/mixins/model_dateien';
import cloudtags from '~/mixins/cloudtags';

export default {
  props: ['verordnung', 'value'],
  mixins: [modelDateien, cloudtags],
  data() {
    return {
      fileupload: {}
    }
  },
  methods: {
  },
  mounted() {
    this.modelDateienAbrufen('Verordnung', this.verordnung);
  }
}
</script>
