<template>
  <div v-if="termin">
    <div @click="showDetails = !showDetails; $nuxt.$emit('bv::hide::tooltip')" :id="target"
      :class="{'termin-absage': termin.abgesagt, 'termin-therapiestunde': termin.termintyp == 1, 'termin-hausbesuch': termin.hausbesuch != 0}"
     class="termin d-flex flex-column justify-content-between" :style="'top: '+topAbstand(termin.von)+'px; height: '+(topAbstand(termin.bis) - topAbstand(termin.von))+'px;'
              +'width:'+(1/termin.ueberschneidungen_anzahl * 100)+'%;left: '+(termin.ueberschneidung / termin.ueberschneidungen_anzahl * 100)+'%;'"

              v-if="termin && vonTimestamp && bisTimestamp">
        <div class="d-flex justify-content-between">
          <div><termin-titel :termin="termin" :kontaktProp="kontakt"></termin-titel></div>
          <div><span class="kalender-termin-zeit">{{$moment(termin.von).format('HH:mm')}}</span></div>
        </div>
        <div class="mt-auto d-flex justify-content-between">
          <div>
            <div v-if="termin.ort">{{ termin.ort }}</div>
            <div v-else-if="termin.raum_id && termin.raum">{{termin.raum.name}}</div>
            <div v-else-if="termin.standort_id && termin.standort">{{termin.standort.name}}</div>
          </div>
          <div class="termin-badges">
            <span v-if="termin.hausbesuch == 1 || termin.hausbesuch == 2" class="badge badge-info">HB</span>
            <span v-if="termin.abgesagt" class="badge badge-dark">AB</span>
            <span v-if="termin.verschoben" class="badge badge-light"><bootstrap-icon height="15" name="arrow-left-right" /></span>
            <span v-if="termin.fahrzeuge.length" class="badge badge-info"><ion-icon name="car-outline"></ion-icon></span>
            <span v-if="termin.materialien.length" class="badge badge-info"><ion-icon name="hammer-outline"></ion-icon></span>

          </div>
        </div>

    </div>
    <b-tooltip :target="target" triggers="focus" placement="right" variant="light" custom-class="kalender-tooltip termindetails" :show.sync="showDetails" @show="terminEinblenden()">
        <div class="text-left">
          <div class="border-bottom mb-2 pb-2">

            <termin-titel :termin="termin" :kontaktProp="kontakt" :displayFachbereich="true"></termin-titel>
            <small v-if="kontakt"><span v-html="$helpers.datumFormatieren(kontakt.geburtsdatum)"></span> (<span v-html="$helpers.alter(kontakt.geburtsdatum)"></span>)</small>
            <small v-else-if="termin.termintyp == 1 && termin.patient_id && termin.patient_id.length > 1">Gruppentherapie</small>
            <small v-else-if="termin.termintyp == 1">Therapiestunde</small>
            <small v-else-if="termin.termintyp == 2">Sonstiger Termin</small>
            <small v-else-if="termin.termintyp == 3">Anfahrt</small>
          </div>
          <div class="row mb-2" v-if="termin.patient_id">
            <div class="col-4">Patienten</div>
            <div class="col-6">
              <nuxt-link :to="'/mitarbeiter/patienten/'+patient_id" class="btn btn-outline-dark btn-sm btn-block" @click="loeschen()" v-for="patient_id in termin.patient_id" :key="'zumPatient'+patient_id"><kontakt-anzeigen :filter="{model_type: 'Patient', model_id: patient_id}"></kontakt-anzeigen></nuxt-link>
            </div>
          </div>
          <div class="row mb-1 border-bottom">
            <div class="col-4">Datum</div>
            <div class="col">
              {{$moment(termin.von).format('dddd, DD. MMMM YYYY')}}<br>
              {{$moment(termin.von).format('HH:mm')}} - {{$moment(termin.bis).format('HH:mm')}} Uhr
            </div>
          </div>
          <div class="row mb-1 border-bottom" v-if="termin.ort">
            <div class="col-4">Ort</div>
            <div class="col">{{termin.ort}}</div>
          </div>
          <div class="row mb-1 border-bottom" v-else-if="termin.raum_id && termin.raum">
            <div class="col-4">Raum</div>
            <div class="col">{{termin.raum.name}}</div>
          </div>
          <div class="row mb-1 border-bottom" v-else-if="termin.standort_id && termin.standort">
            <div class="col-4">Standort</div>
            <div class="col">{{termin.standort.name}}</div>
          </div>
          <div class="row mb-1 border-bottom">
            <div class="col-4">Mitarbeiter</div>
            <div class="col">
              <span class="d-block" v-for="mitarbeiter in termin.mitarbeiter" :key="termin.id+mitarbeiter.id" >
                <kontakt-anzeigen :filter="{model_type: 'Mitarbeiter', model_id: mitarbeiter.id}"></kontakt-anzeigen>
              </span>
            </div>
          </div>
          <div class="row mb-1 border-bottom" v-if="termin.materialien.length">
            <div class="col-4">Material</div>
            <div class="col">
              <span class="d-block" v-for="material in termin.materialien" :key="'material'+termin.id+material.id">{{ material.name }}</span>
            </div>
          </div>
          <div class="row mb-1 border-bottom" v-if="termin.fahrzeuge.length">
            <div class="col-4">Fahrzeuge</div>
            <div class="col">
              <span class="d-block" v-for="fahrzeug in termin.fahrzeuge" :key="'fahrzeug'+termin.id+fahrzeug.id">{{ fahrzeug.bezeichnung }}</span>
            </div>
          </div>
          <div class="row" v-if="termin.infos">
            <div class="col-4">Infos</div>
            <div class="col" v-html="$helpers.nl2br(termin.infos)"></div>
          </div>
          <div class="row bg-grey py-2" v-if="termin.letzte_aenderung_model_id && termin.letzte_aenderung_model_type">
            <div class="col-4">Zuletzt geändert</div>
            <div class="col">
              von <kontakt-anzeigen :filter="{model_type: termin.letzte_aenderung_model_type, model_id: termin.letzte_aenderung_model_id}"></kontakt-anzeigen><br>
              am {{ $helpers.datumFormatieren(termin.updated_at, 'DD.MM.YYYY [um] HH:mm [Uhr]') }}
            </div>
          </div>
          <div class="row bg-grey py-2" v-if="termin.verschoben">
            <div class="col-4">Verschoben</div>
            <div class="col">
              ursprünglich {{$moment(termin.verschoben_vorher_von).format('DD.MM.YYYY')}}<br>
              {{$moment(termin.verschoben_vorher_von).format('HH:mm')}} - {{$moment(termin.verschoben_vorher_bis).format('HH:mm')}} Uhr
            </div>
          </div>
          <div class="row bg-danger text-white py-2" v-if="termin.abgesagt">
            <div class="col-4">Abgesagt</div>
            <div class="col" v-html="$helpers.nl2br(termin.abgesagt)"></div>
          </div>
          <!-- Erst anzeigen, wenn der Detailabruf funktioniert hat -->
          <template v-if="termin.details">
            <div class="row mt-3">
              <div class="col">
                <button class="btn btn-outline-danger btn-sm btn-block" @click="$bvModal.show(loeschenModal)">Löschen</button>
              </div>
              <div class="col">
                <button class="btn btn-outline-primary btn-sm btn-block" @click="bearbeiten()">Bearbeiten</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <button class="btn btn-outline-danger btn-sm btn-block" @click="absagen()" v-if="($can('Ausfall erfassen') || $can('Ausfall verwalten')) && termin.termintyp == 1 && !termin.abgesagt">Absagen</button>
              </div>
            </div>
          </template>
        </div>
    </b-tooltip>
    <b-modal :id="loeschenModal" hide-header centered >
      <div>Soll der Termin wirklich gelöscht werden?</div>
      <div v-if="termin.seriennachfolger && termin.seriennachfolger.length">
        <input type="checkbox" name="" id="serieloeschen" v-model="serieloeschen">
        <label for="serieloeschen">Alle {{ termin.seriennachfolger.length }} Folgetermine auch löschen.</label>
      </div>
      <template #modal-footer>
        <button class="btn btn-sm btn-outline-dark" @click="$bvModal.hide(loeschenModal)">Abbrechen</button>
        <button class="btn btn-sm btn-outline-danger" @click="loeschen()">
          <template v-if="!serieloeschen">Termin löschen</template>
          <template v-else>{{ termin.seriennachfolger.length + 1 }} Termine löschen</template>
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import kalender from '~/mixins/kalender';
import terminfunktionen from '~/mixins/terminfunktionen';
import kontakte from '~/mixins/kontakte';
import TerminTitel from '~/components/kalender/TerminTitel';
export default {
  components: {TerminTitel},
  mixins: [kalender, kontakte, terminfunktionen],
  props: ['termin'],
  data() {
    return {
      target: 'termin-'+this.termin.id,
      showDetails: false,
      serieloeschen: false,
      loeschenModal: 'termin-loeschen'+this.termin.id,
    }
  },
  methods: {
    loeschen() {
      this.$bvModal.hide(this.loeschenModal);
      this.terminLoeschen(this.termin, this.serieloeschen).then(() => {
        this.getApiData();
      })
    },
    bearbeiten() {
      this.$nuxt.$emit('kalender-termin-bearbeiten', this.termin);
      this.showDetails = false;
    },
    absagen() {
      this.$nuxt.$emit('kalender-termin-absage', this.termin);
      this.showDetails = false;
    },
    terminEinblenden() {
      this.terminAbrufen(this.termin.id);
    }
  },
  mounted() {
    if (this.termin.patient_id && this.termin.patient_id.length == 1) {
      this.kontaktAbrufenFilter({model_id: this.termin.patient_id[0], model_type: 'Patient'});
    }
  }
}
</script>
