<template>
    <form-wizard
        color="#1D637E"
        title=""
        subtitle=""
        nextButtonText="Weiter"
        backButtonText="Zurück"
        finishButtonText="Speichern"
        stepSize="xs"
        @on-complete="$emit('speichern')"
        ref="wizard"
        :start-index="startindex"
        v-if="verordnung"
    >

        <tab-content title="Basic" icon="&nbsp;" :before-change="checkStep1" >
            <legend>Basics</legend>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" :for="'versicherungsart'+id">Versicherung</label>
              <div class="col">
                <versicherungsart-auswahl :isinvalid="(showError && !verordnung.versicherungsart_id)" v-model="verordnung.krankenkasse_art_id" :id="'versicherungsart'+id"></versicherungsart-auswahl>
              </div>
            </div>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="verordnung.krankenkasse_art_id == 0">
              <label class="col-2 col-form-label" :for="'krankenkasse'+id">Krankenkasse</label>
              <div class="col">
                <krankenkasse-auswahl :isinvalid="(showError && !verordnung.krankenkasse_id)" v-model="verordnung.krankenkasse_id" :id="'krankenkasse'+id"></krankenkasse-auswahl>
              </div>
            </div>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="verordnung.krankenkasse_art_id == 0">
              <label class="col-2 col-form-label" for="versichertennummer">Versicherten-Nr.</label>
              <div class="col">
                <input type="text" id="versichertennummer" v-model="verordnung.versichertennummer" placeholder="Versichertennummer" class="form-control">
              </div>
            </div>
            <!-- <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" for="patient">Patient</label>
              <div class="col">
                <patient-auswahl :isinvalid="(showError && !verordnung.patient_id)" v-model="verordnung.patient_id" :id="'patient'+id"></patient-auswahl>
              </div>
            </div> -->
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" for="arzt">Arzt</label>
              <div class="col">
                <arzt-auswahl v-model="verordnung.arzt_id" :id="'arzt'+id"></arzt-auswahl>
              </div>
            </div>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
                <label class="col-2 col-form-label" for="zuzahlungsbefreit-ja">Zuzahlung</label>
                <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" :value="true" id="zuzahlungsbefreit-ja" required v-model="verordnung.zuzahlungsbefreit">
                      <label class="form-check-label mr-3" for="zuzahlungsbefreit-ja">Zuzahlungsbefreit</label>
                      <input class="form-check-input" type="radio" :value="false" id="zuzahlungsbefreit-nein" required v-model="verordnung.zuzahlungsbefreit" selected>
                      <label class="form-check-label mr-3" for="zuzahlungsbefreit-nein">Zuzahlungspflichtig</label>
                  </div>
                </div>
            </div>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
                <label class="col-2 col-form-label" for="datum">Datum</label>
                <div class="col">
                    <datum-input class="form-control" :class="{'is-invalid': !verordnung.datum && showError}"  id="datum" placeholder="Ausstellungsdatum" v-model="verordnung.datum" />
                </div>
            </div>

            <div class="row mt-5">
              <div class="col offset-2">
                <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="dringlicher_behandlungsbedarf" required v-model="verordnung.dringlicher_behandlungsbedarf">
                            <label class="form-check-label" for="dringlicher_behandlungsbedarf">
                                Dringlicher Behandlungsbedarf<br>innerhalb von 14 Tagen
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                  <div class="col-3 my-auto">
                      <div class="form-check form-check-inline">
                          <label class="form-check-label mr-3" for="hausbesuch-ja">Hausbesuch</label>
                          <input class="form-check-input" type="radio" :value="true" id="hausbesuch-ja" required v-model="verordnung.hausbesuch">
                          <label class="form-check-label mr-3" for="hausbesuch-ja">ja</label>
                          <input class="form-check-input" type="radio" :value="false" id="hausbesuch-nein" required v-model="verordnung.hausbesuch" selected>
                          <label class="form-check-label mr-3" for="hausbesuch-nein">nein</label>
                      </div>
                  </div>
                  <div class="col-3 my-auto">
                    <input class="" type="checkbox" value="" id="therapiebericht" required v-model="verordnung.therapiebericht">
                    <label class="form-check-label" for="therapiebericht">
                        Therapiebericht
                    </label>
                  </div>
                  <!-- <label class="col-2 col-form-label text-right" for="therapiefrequenz">Therapie&shy;frequenz</label>
                  <div class="col-4 my-auto" @keyup.enter="$refs.wizard.nextTab()">
                    <verordnung-therapiefrequenz :fachbereich="fachbereich" v-model="verordnung.therapiefrequenz"></verordnung-therapiefrequenz>
                  </div> -->
                </div>
              </div>
            </div>
        </tab-content>

        <!-- <tab-content title="Sonstiges" icon="&nbsp;">
          <legend class="mt-3">Sonstiges</legend>


            <div class="form-group">
              <verordnung-therapieziele v-model="verordnung.therapieziele"></verordnung-therapieziele>
            </div>

            <div class="form-group row">
                <div class="col">
                </div>
            </div>
        </tab-content> -->

        <tab-content title="Heilmittel" icon="&nbsp;">
          <legend>{{ verordnung.heilmittel[0].heilmittel }}</legend>

          <div class="row">
            <div class="col">
              <span>Therapiedauer</span>
              <div>
                <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].dauer == '30'}" tabindex="-1" @click="verordnung.heilmittel[0].dauer = 30">30 Min</button>
                <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].dauer == '45'}" tabindex="-1" @click="verordnung.heilmittel[0].dauer = 45">45 Min</button>
                <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].dauer == '60'}" tabindex="-1" @click="verordnung.heilmittel[0].dauer = 60">60 Min</button>
              </div>
            </div>
            <div class="col">
              <span>Anzahl pro Woche</span>
              <div>
                <button class="btn btn-outline-secondary btn-sm" :class="{active: proWoche[1]}" tabindex="-1" @click="proWoche[1] = !proWoche[1]; updateAnzahl()">1x</button>--
                <button class="btn btn-outline-secondary btn-sm" :class="{active: proWoche[2]}" tabindex="-1" @click="proWoche[2] = !proWoche[2]; updateAnzahl()">2x</button>--
                <button class="btn btn-outline-secondary btn-sm" :class="{active: proWoche[3]}" tabindex="-1" @click="proWoche[3] = !proWoche[3]; updateAnzahl()">3x</button>
              </div>

              <div class="mt-5">
                <span>Verordnungsmenge</span>
                <div class="row">
                  <div class="col">
                    <datalist :id="'verordnungsmenge'+_uid"><option :value="bez" v-for="bez in verordnungPrefills.verordnungsmengen" :key="bez" /></datalist>
                    <the-mask type="number" @input.native="verordnung.heilmittel[0].menge = Number($event.target.value)" :list="'verordnungsmenge'+_uid"  mask="###" class="form-control" placeholder="Menge" id="verordnungsmenge" :value="verordnung.heilmittel[0].menge" />
                  </div>
                  <div class="col">
                    <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].menge == '5'}" tabindex="-1" @click="verordnung.heilmittel[0].menge = 5">5</button>
                    <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].menge == '10'}" tabindex="-1" @click="verordnung.heilmittel[0].menge = 10">10</button>
                    <button class="btn btn-outline-secondary btn-sm" :class="{active: verordnung.heilmittel[0].menge == '20'}" tabindex="-1" @click="verordnung.heilmittel[0].menge = 20">20</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </tab-content>


        <tab-content title="Diagnose" icon="&nbsp;">
          <legend>Diagnose</legend>
          <div class="row">
            <div class="col-3">
              Indikationsschlüssel
              <verordnung-indikationsschluessel :fachbereich="verordnung.fachbereich"  v-model="verordnung.indikationsschluessel"></verordnung-indikationsschluessel>
              <span>ICD-10-Code</span>
              <verordnung-icd-10-code :fachbereich="verordnung.fachbereich" v-model="verordnung.icd_10_code" id="icd_10_code"></verordnung-icd-10-code>
              <span class="d-block mt-1">ICD-10-Code</span>
              <verordnung-icd-10-code :fachbereich="verordnung.fachbereich" v-model="verordnung.icd_10_code_2" id="icd_10_code_2"></verordnung-icd-10-code>
            </div>
            <div class="col">
              Diagnose mit Leitsymptomatik, ggf. wesentliche Befunde, ggf. Spezifizierung der Therapieziele
              <verordnung-diagnose :fachbereich="verordnung.fachbereich" v-model="verordnung.diagnose"></verordnung-diagnose>
            </div>
          </div>
          <div class="mt-3">
            Weitere Hinweise (ggf. Angaben/Begründung zum langfristigen Heilmittelbedarf, Angaben zur Blankoverordnung, etc.)
            <verordnung-therapieziele v-model="verordnung.therapieziele"></verordnung-therapieziele>
          </div>
        </tab-content>


        <tab-content title="Hintergrund" icon="&nbsp;">
          <legend>Hintergrundinformationen</legend>
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" for="praxiswechsel">Praxiswechsel</label>
              <div class="col">
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="praxiswechsel" required v-model="verordnung.praxiswechsel">
                      <label class="form-check-label" for="praxiswechsel">
                          Der Patient hat die Praxis gewechselt
                      </label>
                  </div>
              </div>
          </div>
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" for="vorher_gelaufen">Vorher</label>
              <div class="col">
                <input type="number" min="1" max="500" class="form-control" placeholder="Stunden vor diesem Rezept (alte Rezepte bei uns oder alte Praxis)" id="vorher_gelaufen" v-model="verordnung.vorher_gelaufen" />
              </div>
          </div>
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
              <label class="col-2 col-form-label" for="behandlungsabbruch">Abbruch</label>
              <div class="col">
                  <datum-input class="form-control" id="behandlungsabbruch" placeholder="Die Behandlung wurde abgebrochen am" v-model="verordnung.behandlungsabbruch" />
              </div>
          </div>
        </tab-content>
        <tab-content title="Zusammenfassung" icon="&nbsp;">
            <legend v-if="verordnung.patient">Verordnung für {{verordnung.patient.kontakt.vorname}} {{verordnung.patient.kontakt.nachname}}</legend>
            <div class="row">
              <div class="col">
                <span class="d-block" v-if="verordnung.arzt_id">Arzt: <kontakt-anzeigen :filter="{model_type: 'Arzt', model_id: verordnung.arzt_id}" :display="{firma: true}"></kontakt-anzeigen></span>
                <span class="d-block" v-if="verordnung.datum">Datum: {{ $helpers.datumFormatieren(verordnung.datum) }}</span>
                <span class="d-block" v-if="verordnung.spaetester_behandlungsbeginn">Spätester Beginn: {{ $helpers.datumFormatieren(verordnung.spaetester_behandlungsbeginn) }}</span>
                <br />
                <span v-if="verordnung.zuzahlungsbefreit" class="badge badge-secondary">Zuzahlungsbefreit</span>
                <span v-if="verordnung.hausbesuch" class="badge badge-secondary">Hausbesuch</span>
                <span v-if="verordnung.therapiebericht" class="badge badge-secondary">Therapiebericht</span>
                <span v-if="verordnung.praxiswechsel" class="badge badge-secondary">Praxiswechsel</span>
              </div>
              <div class="col">
                  <span class="d-block" v-if="verordnung.indikationsschluessel">Indikationsschlüssel: {{verordnung.indikationsschluessel}}</span>
                  <span class="d-block" v-if="verordnung.icd_10_code">ICD-10 Code: {{verordnung.icd_10_code}}</span>
                  <span class="d-block" v-if="verordnung.icd_10_code_2">2. ICD-10 Code: {{verordnung.icd_10_code_2}}</span>
                  <span class="d-block" v-if="verordnung.diagnose"><b>Diagnose</b> <span v-html="$helpers.nl2br(verordnung.diagnose)"></span></span>
                  <span class="d-block" v-if="verordnung.therapieziele"><b>Sonstige Hinweise</b> <span v-html="$helpers.nl2br(verordnung.therapieziele)"></span></span>

                  <template v-if="verordnung.heilmittel && verordnung.heilmittel[0].menge || verordnung.heilmittel[0].dauer || verordnung.heilmittel[0].heilmittel">
                    <b>Heilmittel</b>
                    <span class="d-block" v-for="(heilmittel, index) in verordnung.heilmittel" :key="index" v-if="heilmittel.menge || heilmittel.dauer || heilmittel.heilmittel">
                      <span v-if="heilmittel">{{heilmittel.heilmittel}}</span>
                      <span v-else>Kein Heilmittel gewählt</span>&nbsp; <span v-if="heilmittel.dauer">- {{heilmittel.dauer}} Min</span> <span v-if="heilmittel.menge">- Menge: {{heilmittel.menge}}</span>
                    </span>
                  </template>
                  <span class="d-block" v-if="verordnung.therapiefrequenz">Frequenz: {{therapiefrequenz(verordnung.therapiefrequenz)}} pro Woche</span>
                  <span class="d-block" v-if="verordnung.vorher_gelaufen">Therapien Vorher: {{verordnung.vorher_gelaufen}}</span>
                  <span class="d-block">Verordnungstyp: {{($parent.neuerFall.wert) ? 'Neuer Behandlungsfall, EBF erlaubt' : 'Keine EBF erlaubt'}} <tooltip-information v-if="$parent.neuerFall.kommentar"><span>{{ $parent.neuerFall.kommentar }}</span></tooltip-information></span>
              </div>
            </div>
            <div class="row" v-if="verordnung.behandlungsabbruch">
              <div class="col">
                Verordnung abgebrochen: {{ $helpers.datumFormatieren(verordnung.behandlungsabbruch) }}
              </div>
            </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                  <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Zurück</wizard-button>
                </div>
                <div class="wizard-footer-right">
                  <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Weiter</wizard-button>

                  <template v-else-if="$can('Verordnungsänderungen verwalten')">
                    <button @click="$emit('speichern', false)" class="btn btn-outline-primary mr-3"><b>Nur Speichern</b></button>
                    <wizard-button  @click.native="$emit('speichern', true)" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">Speichern und Prüfen</wizard-button>
                  </template>
                  <wizard-button v-else  @click.native="$emit('speichern')" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">Speichern</wizard-button>
                </div>
        </template>
    </form-wizard>
</template>

<script>
import verordnungformular from '~/mixins/verordnungformular';
import prefills from '~/mixins/prefills';
export default {
    mixins: [verordnungformular, prefills],
    props: ['verordnungProp', 'id', 'showError'],
    watch: {
      verordnungProp(neu) {
        this.verordnung = neu;
      }
    },
    data() {
      return {
        proWoche: {1: false, 2: false, 3: false}
      }
    },
    methods: {
      checkStep1() {
          if (!this.verordnung.patient_id || !this.verordnung.datum) {
            this.$emit('showError', true);
            return false;
          }
          this.$emit('showError', false);
          return true;
        },
      heilmittelReset() {
        if (!this.verordnung.heilmittel || this.verordnung.heilmittel.length != 1) this.verordnung.heilmittel = [{heilmittel: 'Sprech- und Sprachtherapie oder Schlucktherapie', menge: '', dauer: ''}];
        else this.verordnung.heilmittel[0].heilmittel = 'Sprech- und Sprachtherapie oder Schlucktherapie';
      },
      updateAnzahl() {
        let anzahl = '';
        let von = 0;
        let bis = 0;
        _.forEach(this.proWoche, (val, zahl) => {
          if (val && von === 0) von = zahl;
          if (val) bis = zahl;
        })
        if (von == bis) anzahl = von;
        else anzahl = von + '-'+bis;
        this.verordnung.therapiefrequenz = anzahl;
      },
      prefillAnzahl() {
        let frequenz = this.verordnung.therapiefrequenz;
        if (frequenz) {
          if (frequenz.length == 1 && frequenz >= 1 && frequenz <= 3) this.proWoche[frequenz] = true;
          else {
            this.proWoche[frequenz[0]] = true;
            if (frequenz[2] == 3) {
              this.proWoche[2] = true; this.proWoche[3] = true;
            }
            else if (frequenz[2] == 2) this.proWoche[2] = true;
            else this.proWoche = {1: false, 2: false, 3: false};
          }
        }
      }
    },
    mounted() {
      this.verordnung = this.verordnungProp;
      if (!this.verordnung.heilmittel || this.verordnung.heilmittel.length != 1 || this.verordnung.heilmittel[0].heilmittel != 'Sprech- und Sprachtherapie oder Schlucktherapie') {
        this.heilmittelReset();
      }
      if (this.verordnung.ergaenzendes_heilmittel) this.verordnung.ergaenzendes_heilmittel = {};
      if (this.verordnung.leitsymptomatik) this.verordnung.leitsymptomatik = {};
      this.prefillAnzahl();
    }
}
</script>
