<template>
  <input :type="typ" ref="datumsfeld" @blur="blur()" @focus="focus()" v-model="inhalt" @input="input()" @update="update()" class="form-control">
</template>

<script>
export default {
  props: ['value'],
  watch: {
    value(neu) {
      this.fillData(neu)
    }
  },
  data() {
      return {
          inhalt: this.value,
          typ: 'text',
          ignoreBlur: false
      }
  },
  methods: {
      input() {
          this.$emit('input', this.inhalt)
      },
      blur() {
          if (this.ignoreBlur) return;
          if (this.inhalt == null || this.inhalt == '') this.typ='text';
          this.$emit('blur');
      },
      focus() {
          this.ignoreBlur = true
          this.typ = 'date';
          this.$nextTick(function() {
              this.$refs.datumsfeld.focus();
              this.ignoreBlur = false;
          });
      },
      fillData(value) {
        if (value) {
          this.inhalt = this.$moment(value).format('YYYY-MM-DD');
          this.typ = 'date';
        }
      }
  },
  mounted() {
    this.fillData(this.value);
  }
}
</script>
