<template>
<div>
    <div class="row">
      <div class="col-1 my-auto text-center" v-if="thisKommentar.user_id">
        <div class="border border-primary rounded-circle kommentar-autor text-center align-middle"><div>
          <kontakt-anzeigen :filter="{model_type: 'Mitarbeiter', model_id: thisKommentar.user_id}" :display="{vorname: false, nachname: false, initialen: true}"></kontakt-anzeigen>
        </div></div>
      </div>
      <div class="col">
        <p class="kommentar-headline">
          <b v-if="thisKommentar.titel">{{ thisKommentar.titel }}</b> <small class="text-mutet" @click="showDate=!showDate" v-if="thisKommentar.created_at">
            <timeago v-if="!showDate" :datetime="thisKommentar.created_at" :auto-update="30"></timeago>
            <span v-if="showDate">{{$helpers.datumFormatieren(thisKommentar.created_at, 'DD.MM HH:mm')}} Uhr</span>
          </small>
          <button @click="antworten=!antworten" class="btn btn-sm btn-link"><ion-icon name="arrow-undo"></ion-icon></button>
        </p>
        <div v-html="thisKommentar.text"></div>
      </div>
    </div>
    <div v-if="antworten">
      <kommentar-textfeld @absenden="antwortAbsenden($event)"></kommentar-textfeld>
    </div>
    <div class="row mb-3">
      <!-- <div class="col-1">
        Hier darf die Zierleiste links hin
      </div> -->
      <div class="offset-1 col mt-1">
          <kommentare :kommentare="thisKommentar.kinder" :showDateSet="showDate" :id="id" :type="type"></kommentare>
      </div>
    </div>
</div>
</template>
<script>
export default {
  props: ['kommentar', 'showDate', 'id', 'type'],
  data() {
    return {
      antworten: false,
      thisKommentar: {}
    }
  },
  methods: {
    antwortAbsenden(content) {
      let payload = {
        id: 'neu',
        model_id: this.id,
        model_type: this.type,
        text: content.text,
        titel: content.titel,
        parent_id: this.kommentar.id,
        user_id: this.$auth.user.model_id
      };
      this.$axios.put('{kommentare}/v1/kommentare', payload).then(response => {
        this.antworten = false;
        if (!this.thisKommentar.kinder) this.thisKommentar.kinder = [];
        this.thisKommentar.kinder.push(response.data);
      });
    }
  },
  mounted() {
    this.thisKommentar = this.kommentar;
  },

}
</script>
