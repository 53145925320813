import dbHelper from '~/mixins/dbHelper';
import lokiHelper from '~/mixins/lokiHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    // findKrankenkasse(id) {
    //   return this.$helpers.findByKey(this.krankenkassen, id);
    // },
    loadKrankenkassen(manuell = false) {
      this.$store.dispatch('krankenkassen/sync', manuell);
    },
    resetKrankenkassen() {
      this.$store.commit('krankenkassen/reset');
      this.loadKrankenkassen(true);
    },
    findArt(art) {
      return this.$helpers.findByKey(this.krankenkassenArten, art, 'id');
    },
    speichernKrankenkasse(model) {
      this.$store.dispatch('krankenkassen/speichern', model);
      setTimeout(() => {
        this.loadKrankenkassen();
      }, 1000);
    },

    async krankenkassenAbrufen() {
      this.getKrankenkassen = true;
      this.registerKrankenkassenEvent();
      if (!this.suchbegriff) {
        this.krankenkassen = await this.getElemente('krankenkassen', this.krankenkassenFilter);
        this.krankenkassenCount = this.krankenkassen.length;
        return;
      }
      let krankenkasseCollection = await dbHelper.getDb('krankenkassen');
      this.krankenkassen = await krankenkasseCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    krankenkassenAenderungenAbrufen() {
      this.getKrankenkasseAenderungen = true;
      this.registerKrankenkassenEvent();
      return this.getAenderungen('krankenkassen').then(data => this.krankenkassenAenderungen = data);
    },
    krankenkasseAbrufen(id) {
      this.getKrankenkasse = id
      this.registerKrankenkassenEvent();
      return this.getElement('krankenkassen', {id: id}).then(krankenkasse => {if (Object.keys(krankenkasse).length) this.krankenkasse = krankenkasse});
    },
    registerKrankenkassenEvent() {
      if (!this.krankenkasseEvent) this.krankenkasseEvent = this.$nuxt.$on('krankenkassen-sync', this.krankenkassenErneuern);
    },
    krankenkassenErneuern(event) {
      if (this.getKrankenkasse) {
        if (event && this.getKrankenkasse == event.id) this.krankenkasse = event;
        else  this.krankenkasseAbrufen(this.getKrankenkasse);
      }
      if (this.getKrankenkassen) this.krankenkassenAbrufen();
      if (this.getKrankenkasseAenderungen) this.krankenkassenAenderungenAbrufen();
    }
  },
  created() {
    this.standKrankenkassen;
  },
  mounted() {
    if (!this.$store.getters['krankenkassen/filled']) this.loadKrankenkassen();
  },
  computed: {
    krankenkassenOptions: function() {
      let send = [];
      this.krankenkassen.forEach(kasse => {
          send.push({value: kasse.id, text: kasse.name});
      })
      return send;
    },
    standKrankenkassen: function() {
      let stand = this.$store.getters['krankenkassen/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadKrankenkassen();
      return stand
    },
    krankenkassenArten: function() {
      return [
        {id: 1, name: 'Primärkasse', short: 'RVO'},
        {id: 2, name: 'Ersatzkasse', short: 'EKK'},
        {id: 3, name: 'Landwirtschaftliche Krankenkasse', short: 'LKK'},
      ];
    },
  },
  data() {
    return {
      krankenkasse: null,
      krankenkassen: [],
      krankenkassenCount: 0,
      krankenkassenAenderungen: [],
      getKrankenkasse: false,
      getKrankenkassen: false,
      getKrankenkasseAenderungen: false,
      krankenkasseEvent: false,
      krankenkassenFilter: null,
      orderbyKrankenkassen: 'name',
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('krankenkassen-sync', this.krankenkassenErneuern);
    this.krankenkassevent = null;
  },
}
