<template>
     <b-modal :id="id" size="lg" :title="'Ausfallstunde '+ ((ausfallstunde && ausfallstunde.patient_id && kontakt) ? $ganzerName({kontakt: kontakt}) : '')" hide-footer @shown="formShow()" :ref="'modal-'+id">
        <form-wizard
        color="#1D637E"
            :key="formCounter"
            title=""
            subtitle=""
            nextButtonText="Weiter"
            backButtonText="Zurück"
            finishButtonText="Speichern"
            stepSize="xs"
            :von-index="vonindex"
            @on-complete="speichern()"
            ref="wizard"
        >
            <tab-content title="Ausfall" icon="&nbsp;" :before-change="therapieValide">
                <ausfallstunde-formular :verordnungProp="verordnungProp" :key="formCounter" :id="id" v-model="ausfallstunde" @change-zeit="arbeitszeitKey++" :showError="showError" :neu="true"></ausfallstunde-formular>
            </tab-content>
            <tab-content title="Zusammenfassung" icon="&nbsp;">
                <div class="row" v-if="ausfallstunde">
                  <div class="col">
                    <zusammenfassung-allgemein
                      titel="Ausfallstunde"
                     :therapiestunde="ausfallstunde"></zusammenfassung-allgemein>
                  </div>
                </div>
            </tab-content>
        </form-wizard>
    </b-modal>
</template>

<script>
import therapieformular from '~/mixins/therapieformular';
import ausfallstunden from '~/mixins/ausfallstunden';
import AusfallstundeFormular from '~/components/mitarbeiter/therapie/ausfall/formular';

export default {
  props: ['verordnungProp'],
  components: {AusfallstundeFormular},
  mixins: [therapieformular, ausfallstunden],
  data() {
      return {
          ausfallstunde: null,
      }
  },
  methods: {
    fillChange(neu) {
      if (neu != null) this.ausfallstunde = this.$helpers.copy(neu);
      if (neu.patient_id) this.kontaktAbrufenFilter({model_type: 'Patient', model_id: neu.patient_id});
      this.showError = false;
      this.formCounter++;
    },

    therapieValide() {
        this.showError = true;
        if (!this.ausfallstunde.patient_id || !this.ausfallstunde.mitarbeiter_id || !this.ausfallstunde.von || !this.ausfallstunde.dauer) return false;
        this.showError = false;
        if (this.ausfallstunde.hausbesuch) {
          this.anfahrt.erfassen = true;
          this.anfahrtPrefill();
        }
        return true;
    },
    // arbeitszeitSync() {
    //   if (!this.arbeitszeit.erfassen) return;
    //   if (!this.arbeitszeit.von_changed) this.arbeitszeit.von = this.ausfallstunde.von;
    //   if (!this.arbeitszeit.dauer_changed) this.arbeitszeit.dauer = this.ausfallstunde.dauer;
    //   this.arbeitszeitKey++;
    // },
    // anfahrtPrefill() {
    //   if (!this.anfahrt.erfassen) return;
    //   if (!this.anfahrt.von) this.anfahrt.von = this.$moment(this.ausfallstunde.von).subtract(15, 'm').format("YYYY-MM-DDTHH:mm");
    //   if (!this.anfahrt.dauer) this.anfahrt.dauer = 15;
    // },
    speichern() {
      // Keine Verordnung, keine Kekse
      if (!this.ausfallstunde.verordnung_id) this.ausfallstunde.verordnung_id = this.verordnungProp.id;
      if (!this.ausfallstunde.verordnung_id) return;

      // ausfallstunde speichern
      this.speichernAusfallstunde(this.ausfallstunde);
      this.$bvModal.hide(this.id)
    }
  },
  computed: {
      vonindex: function() {
          if (!this.ausfallstunde || !this.ausfallstunde.updated_at) return 0;
          return 2;
      }
  }
}
</script>
