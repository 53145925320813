<template>
    <div>
      <div class="form-row form-group">
        <div class="col-4">
          Heilmittel
        </div>
        <div class="col-2 offset-2">Dauer</div>
        <div class="col offset-1">Behandlungseinheiten</div>
      </div>
      <verordnung-heilmittel-zeile v-for="(hm, index) in heilmittel" :key="'hm'+index" v-model="heilmittel[index]"
      @remove="removeLine(index)" :deleteable="heilmittel.length > 1" :fachbereich="fachbereich"></verordnung-heilmittel-zeile>
      <div class="form-row form-group">
        <div class="col-2 offset-9"><span v-if="gesamtEinheiten > 0">Gesamt: {{gesamtEinheiten}}</span></div>
        <div class="col-1 text-right"><button class="btn btn-outline-primary" @click="addLine()"><ion-icon name="add-outline"></ion-icon></button></div>
      </div>
    </div>
</template>
<script>
import prefills from '~/mixins/prefills';
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
export default {
  mixins: [prefills, heilmittelkatalog],
  props: ['value', 'fachbereich'],
  data() {
    return {
      heilmittel: this.value,
      empty: {heilmittel: '', menge: '', dauer: '', doppelbehandlung: false},
      heilmittelGewaehlt: null
    }
  },
  methods: {
    update() {
      this.$emit('input', this.heilmittel);
      this.$emit('change', this.heilmittel);
      this.heilmittelGewaehlt = this.findHeilmittelByString(this.heilmittel.heilmittel)
    },
    addLine() {
      if (typeof(this.heilmittel) != 'object') this.heilmittel = [];
      this.heilmittel.push(this.$helpers.copy(this.empty));
    },
    removeLine(index) {
      this.heilmittel.splice(index, 1);
    }
  },
  mounted() {
    if (typeof(this.heilmittel) != 'object') this.heilmittel = [];
    if (this.heilmittel.length == 0) {
      this.addLine();
    }
    this.update();
  },
  computed: {
    gesamtEinheiten() {
      let gesamt = 0;

      if (typeof(this.heilmittel) != 'array' && typeof(this.heilmittel) != 'object') return 0;
      this.heilmittel.filter((hm) => {
        gesamt += hm.menge
      })
      return gesamt;
    },
  }
}
</script>
