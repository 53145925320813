<template>
  <b-nav-item-dropdown text="Sync Status" right @show="refreshData(); displayed = true" @hide="displayed = false" v-if="daten">
    <div class="p-1" v-if="displayed">
      <table class="table table-sm table-striped" style="min-width: 800px">
        <thead>
          <th width="300px">Service</th>
          <th width="300px">Stand</th>
          <th width="300px">ungesichert</th>
          <th width="100px" v-if="$debugMode">Speicher</th>
          <th>Sync</th>
          <th>Reset</th>
        </thead>
        <tbody>
          <tr v-for="(el, index) in liste" :key="index">
            <td>{{ el.titel }}</td>
            <td :class="{'table-danger': syncDatum($store.getters[el.storekey+'/getStand']) == 'noch nie'}">{{ syncDatum($store.getters[el.storekey+'/getStand']) }}</td>
            <td>
              <span v-if="daten[el.storekey]">{{daten[el.storekey].aenderungen}} / {{daten[el.storekey].count}}</span>
            </td>
            <td v-if="$debugMode">{{total[el.storekey]}}</td>
            <td>
              <span @click="load(el)" :class="{'rotating': $store.getters[el.storekey+'/isLoading']}"><ion-icon name="refresh-outline"></ion-icon></span>
            </td>
            <td class="text-danger"><ion-icon @click="reset(el)" name="trash-outline"></ion-icon></td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="$debugMode">
            <td><b>Summe</b></td>
            <td></td>
            <td></td>
            <td>{{total['total']}}</td>
            <td></td>
            <td></td>
          </tr>

          <!-- <tr>
            <td></td>
            <td><button @click="resetZeiten()" class="btn btn-outline-danger btn-sm">Alle Uhrzeiten zurücksetzen
              <tooltip-information>Nur nötig, wenn alles synchronisiert ist, du aber 0 Elemente von etwas hast. Achtung! Möglicherweise gibt es Datenverlust!</tooltip-information>
              </button></td>
            <td v-if="$debugMode"></td>
            <td></td>
            <td></td>
          </tr> -->
          <tr>
            <td colspan="100%"><small>Maximales Alter vor automatischem Sync: {{maxAlter}}</small></td>
          </tr>
          <tr>
            <td colspan="4"><nuxt-link class="btn btn-outline-primary btn-block" to="/sync" >Zum Sync Protokoll</nuxt-link></td>
            <td colspan="2"><nuxt-link class="btn btn-outline-primary btn-block" to="/aktivitaet" >Aktivität</nuxt-link></td>
          </tr>
        </tfoot>
      </table>

    </div>
  </b-nav-item-dropdown>
</template>
<script>
import lokiHelper from '~/mixins/lokiHelper';
export default {
  mixins: [lokiHelper],
  data() {
    return {
      liste: [],
      daten: null,
      displayed: false
    }
  },
  methods: {
    syncDatum(datum) {
      let heute = this.$moment();
      let date = this.$moment(datum);
      if (date.format('YYYY-MM-DD') == '1970-01-01') return 'noch nie';
      if (date.format('YYYY-MM-DD') != heute.format('YYYY-MM-DD')) return date.format('DD.MM.YYYY HH:mm')+' Uhr';
      return date.format('HH:mm')+' Uhr';
    },
    load(el) {
      let doit = true;
      if (this.$store.getters[el.storekey+'/isLoading']) doit = confirm('Service '+el.titel+ ' wird gerade synchronisiert. Trotzdem versuchen?');
      if (!doit) return;

      this.$store.commit(el.storekey+'/loading', false);
      let params = el.params;
      let manuell = true;
      if (el.params) this.$store.dispatch(el.storekey+'/sync', {manuell, params})
      else this.$store.dispatch(el.storekey+'/sync', true)
    },
    reset(el) {
      let message = 'Soll die Tabelle '+ el.titel +' neu geladen werden?';
      let aenderungen = this.daten[el.storekey].aenderungen;
      if (aenderungen.length > 0) message += ' Dabei geh'+(aenderungen.length > 1 ? 'en' : 't')+' '+aenderungen.length+' Änderung'+(aenderungen.length > 1 ? 'en' : '')+' verloren!';
        this.$confirm(
            {
              message: message,
              button: {
                no: 'Lieber nicht',
                yes: 'Tabelle leeren'
              },
              callback: confirm => {
                if (confirm) {
                  this.$store.commit(el.storekey+'/reset');
                }
              }
            }
          )
    },
    getDaten(el) {
      this.getElemente(el.storekey).then(elemente => {
        this.daten[el.storekey].count = elemente.length;
      })
      this.getAenderungen(el.storekey).then(elemente => {
        this.daten[el.storekey].aenderungen = elemente.length;
      })
      // Automatisch erneuern
      if (!this.daten[el.storekey].event) {
        this.daten[el.storekey].event = this.$nuxt.$on(el.storekey+'-sync', () => {
          this.getDaten(el);
        })
      }
    },
    refreshData() {
      this.liste.forEach(el => {
        this.getDaten(el);
      });
    },
    resetZeiten() {
      let datum = new Date(0);
      this.liste.forEach(el => {
        this.$store.commit(el.storekey+'/stand', datum);
      });
    },
    listeFuellen() {
      if (!this.$auth.user || !this.$auth.user.mitarbeiter) return setTimeout(() => {this.listeFuellen()}, 100);
      this.liste = [
        {titel: 'Patienten', storekey: 'patienten'},

        {titel: 'Verordnungen', storekey: 'verordnungen'},
        {titel: 'Therapiestunden', storekey: 'therapiestunden'},
        {titel: 'Ausfallstunden', storekey: 'ausfallstunden'},
        {titel: 'Verordnungsänderungen', storekey: 'verordnungsaenderungen'},

        {titel: 'Mitarbeiter / Therapeuten', storekey: 'mitarbeiter'},
        {titel: 'Arbeitszeiten', storekey: 'arbeitsstunden', params: {mitarbeiter: this.$auth.user.mitarbeiter.id, monat: this.$moment().format('YYYY-MM')}},
        {titel: 'Anfahrten', storekey: 'anfahrten', params: {mitarbeiter: this.$auth.user.mitarbeiter.id, monat: this.$moment().format('YYYY-MM')}},
        {titel: 'Krankenkassen', storekey: 'krankenkassen'},
        {titel: 'Ärzte', storekey: 'aerzte'},
        {titel: 'Faqs', storekey: 'faqs'},
        {titel: 'Kontakte', storekey: 'kontakte'},
        {titel: 'Schreiben', storekey: 'schreiben'},
        {titel: 'Schreiben Vorlagen', storekey: 'schreiben-vorlagen'}
      ];
      let daten = {};
      this.liste.forEach(el => {
        daten[el.storekey] = {}
      });
      this.daten = daten;
      this.refreshData();
    }
  },
  computed: {
    maxAlter() {
      let env = process.env.MAXAGE / 1000;
      return env / 60 + ' Minuten';
    },
    total() {
      let resp = {};
      var _lsTotal=0,_xLen,_x;
      for(_x in localStorage){
      if(!localStorage.hasOwnProperty(_x)){continue;}
        _xLen= ((localStorage[_x].length + _x.length)* 2);
        _lsTotal+=_xLen;
        resp[_x.substr(0,50)] = (_xLen/1024).toFixed(2)+" KB";
      };
      resp['total'] = (_lsTotal / 1024).toFixed(2) + " KB";
      return resp;
    }
  },
  mounted() {
    this.listeFuellen();
  },
}
</script>
