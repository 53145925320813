<template>
  <div v-if="!$nuxt.isOffline && $can('Telefonanrufe starten') && laufendeAnrufe.length" class="bg-success">
     <div class="container clearfix">
       <div class="float-left"><b>Aktueller Anruf</b></div>
       <div class="float-left ml-3">
        <template v-for="(anruf, index) in laufendeAnrufe">
          <div class="clearfix" :key="index+anruf.call_id+timeagokey" >
            <span class="float-left text-right" style="width: 50px" :key="timeagokey">{{ timeago(anruf.created_at) }}</span>
            <template v-if="anruf.ausgehend == 1">
              <span><ion-icon name="log-out-outline"></ion-icon></span>
              <span v-if="findSip(anruf.peer)">{{findSip(anruf.peer).name}}</span>
              <span v-if="anruf.kontaktsip"><ion-icon name="arrow-forward"></ion-icon>{{anruf.kontaktsip.name}}</span>
              <template v-else>
              <span v-if="anruf.peer"><ion-icon name="arrow-forward"></ion-icon>
                <span v-if="anruf.kontakt && anruf.kontakt.model_type == 'Patient'"><nuxt-link :to="'/mitarbeiter/patienten/'+anruf.kontakt.model_id">{{ $ganzerName(anruf.kontakt) }}</nuxt-link></span>
                <span v-if="anruf.kontakt && anruf.kontakt.model_type != 'Patient'">{{ $ganzerName(anruf.kontakt) }}</span>
                {{anruf.kontaktnummer}} </span>
                  <div v-if="anruf.patienten && anruf.patienten.length && anruf.kontakt && anruf.kontakt.model_type != 'Patient'" class="float-left">
                    <ul class="mb-0">
                      <li v-for="patient in anruf.patienten" :key="patient.id"><nuxt-link :to="'/mitarbeiter/patienten/'+patient.id"><kontakt-anzeigen :filter="{model_type: 'Patient', model_id: patient.id}"></kontakt-anzeigen></nuxt-link></li>
                    </ul>
                  </div>
                </template>
            </template>
            <template v-else>
              <span><ion-icon name="log-in-outline"></ion-icon></span>
              <span v-if="anruf.kontaktsip">{{anruf.kontaktsip.name}}</span>
              <template v-else>
                <span v-if="anruf.kontakt && anruf.kontakt.model_type == 'Patient'"><nuxt-link :to="'/mitarbeiter/patienten/'+anruf.kontakt.model_id">{{ $ganzerName(anruf.kontakt) }}</nuxt-link></span>
                <span v-if="anruf.kontakt && anruf.kontakt.model_type != 'Patient'">{{ $ganzerName(anruf.kontakt) }}</span>
                {{anruf.kontaktnummer}} <span v-if="anruf.peer"><ion-icon name="arrow-forward"></ion-icon> <span v-if="findSip(anruf.peer)">{{findSip(anruf.peer).name}}</span></span>
                <div v-if="anruf.patienten && anruf.patienten.length && anruf.kontakt && anruf.kontakt.model_type != 'Patient'" class="float-left">
                  <ul class="mb-0">
                    <li v-for="patient in anruf.patienten" :key="patient.id"><nuxt-link :to="'/mitarbeiter/patienten/'+patient.id"><kontakt-anzeigen :filter="{model_type: 'Patient', model_id: patient.id}"></kontakt-anzeigen></nuxt-link></li>
                  </ul>
                </div>
              </template>
            </template>
            <div class="float-right ml-1" title="Anruf läuft nicht mehr" @click="deleteAnruf(anruf)">
              <ion-icon name="close-circle-outline"></ion-icon>
            </div>
          </div>
        </template>
       </div>
     </div>
   </div>
</template>
<script>
import patienten from '~/mixins/patienten';
import sipusers from '~/mixins/sipusers';
import moment from 'moment';
import dbHelper from '~/mixins/dbHelper';
export default {
  mixins: [sipusers, patienten],
  data() {
    return {
      laufendeAnrufe: [],
      channelname: 'anrufe.'+this.$auth.user.praxis_id,
      timeagokey: 0,
      kontakteCollection: null,
      interval: null
    }
  },
  methods: {
    loginChannel() {
      if (!this.$can('Telefonanrufe starten')) return;
      this.$echo.leave(this.channelname);
      this.$echo.private(this.channelname).listen('LaufendeAnrufe', (event) => {
        this.parseEvent(event.anrufe);
      });
    },
    async parseEvent(anrufe) {
      if (anrufe.length == 0) {
        clearInterval(this.interval);
        this.interval = null;
        if (this.laufendeAnrufe.length) this.$nuxt.$emit('AnrufUpdate');
        return this.laufendeAnrufe = [];
      }
      await anrufe.map(async anruf => {
        if (anruf) {
          if (anruf.kontaktnummer.length <= 4) anruf.kontaktsip = this.findSipByNummer(anruf.kontaktnummer);
          let patienten = await this.patientZuAnruf(anruf)
          anruf.kontakt = patienten.kontakt;
          anruf.patienten = patienten.patienten;
        }

      })
      if (this.laufendeAnrufe.length != anrufe.length) this.$nuxt.$emit('AnrufUpdate');
      this.laufendeAnrufe = this.$helpers.sortArray(anrufe, 'created_at');

      if (!this.interval) {
        this.interval = setInterval(() => {
          if (this.laufendeAnrufe.length == 0) {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }
          this.timeagokey++;
        }, 1000)

      }
    },
    async patientZuAnruf(anruf) {
      if (!this.kontakteCollection) this.kontakteCollection = await dbHelper.getDb('kontakte');
      let nummer = anruf.kontaktnummer
      let kontakt = null;
      let patienten = [];

      let kontakte = await this.kontakteCollection.where((kontakt) => {
        let test = kontakt.telefonnummern.filter(telefonnummer => {
          return telefonnummer.nummer.replace(' ', '') == anruf.kontaktnummer;
        });
        return (test.length > 0);
      });

      if (kontakte.length) kontakt = kontakte[0];
      if (kontakt) {
        // Das ist die Nachregulierung wenn das System vorher den Patienten nicht schon gefunden hat
        if (kontakt.model_type == 'Patient') patienten.push(kontakt);
        else if (kontakt.model_type == 'Patientenkontakt' && anruf.kontakt_id) {
          patienten = await this.patientenByPatientenkontakt(anruf.kontakt_id);
        }
      }
      return {kontakt, patienten};
    },
    getLaufendeAnrufe() {
      this.$axios.get('{placetel}/v1/laufendeanrufe').then(response => {
        this.parseEvent(response.data);
      });
    },
    deleteAnruf(anruf) {
      this.$axios.delete('{placetel}/v1/laufendeanrufe/'+anruf.id).then(response => {
        this.parseEvent(response.data);
      });
    },
    timeago(datum) {
      let diff = moment().diff(datum);
      let format = (diff > 3600000) ? 'HH:mm:ss' : 'mm:ss'
      return moment(diff).subtract(1, 'hour').format(format);
    },

  },
  mounted() {
    if (!this.$dev) {
      this.loginChannel();
    }
      this.getLaufendeAnrufe();
    // this.reconnect();
  }
}
</script>
