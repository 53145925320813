<template>
<div></div>
</template>
<script>
  export default {
    mounted() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type == 'notification/notify') {
          if (mutation.payload.type == 'error') {
            this.$notifyError(mutation.payload.title, mutation.payload.message);
          }
          else if (mutation.payload.type == 'success') {
            this.$notifySuccess(mutation.payload.title, mutation.payload.message);
          }
        }

      });

    }
}
</script>
