<template>
<div v-if="therapiestunde && therapiestunde.id">
  <!-- <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" id="therapie-formular-details">
      <legend>Therapiestunde</legend>
      <label class="col-2 col-form-label" :for="'patient'+id">Patient</label>
      <div class="col">
        <patient-auswahl :isinvalid="(showError && !therapiestunde.patient_id)" v-model="therapiestunde.patient_id" :id="'patient'+id" @input="patientAuswahl()"></patient-auswahl>
      </div>
  </div>
  <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" v-if="therapiestunde.patient_id">
      <label class="col-2 col-form-label" :for="'verordnung'+id">Verordnung</label>
      <div class="col">
        <verordnung-auswahl :isinvalid="(showError && !therapiestunde.verordnung_id)" :patient="therapiestunde.patient_id" :id="'verordnung'+id" v-model="therapiestunde.verordnung_id" @input="verordnungAuswahl($event)"></verordnung-auswahl>
      </div>
  </div>
  <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" v-if="$can('Therapie verwalten')">
      <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Logopäde</label>
      <div class="col">
          <therapeut-auswahl :isinvalid="(showError && !therapiestunde.mitarbeiter_id)" v-model="therapiestunde.mitarbeiter_id"></therapeut-auswahl>
      </div>
  </div> -->
  <div class="row form-group text-danger" @keyup.enter="$refs.wizard.nextTab()" v-if="missingTherapeutError">
      <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Therapeut</label>
      <div class="col my-auto">
          Kein Therapeut mitgeliefert.
      </div>
  </div>
  <template v-if="therapiestunde.verordnung_id">
    <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="ebErlaubt">
        <label class="col-2 col-form-label" :for="'erstbefundung'+id">Erstbefundung</label>
        <div class="col">
          <label class="col-form-label form-check-label" :for="'erstbefundung'+id">
              <input class="" type="checkbox" value="1" :id="'erstbefundung'+id" v-model="therapiestunde.erstbefundung">
              Die Therapiestunde war eine Erstbefundung
          </label>
        </div>
    </div>
    <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="hbErlaubt">
        <label class="col-2 col-form-label" :for="'hausbesuch'+id">Hausbesuch</label>
        <div class="col">
          <label class="col-form-label form-check-label" :for="'hausbesuch'+id">
              <input class="" type="checkbox" value="1" :id="'hausbesuch'+id" v-model="therapiestunde.hausbesuch">
              Die Therapiestunde war ein Hausbesuch
          </label>
        </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_von'+id" class="col-2 col-form-label mt-auto">Start</label>
      <div class="col">
        <input type="datetime-local" name="" :id="'therapie_von'+id" v-model="therapiestunde.von" class="form-control" :class="{'is-invalid': showError && !therapiestunde.von}" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group" :class="{'table-danger': ($helpers.datumFormatieren(spaetesterBehandlungsbedarf, 'YYYY-MM-DD') < $helpers.datumFormatieren(therapiestunde.von, 'YYYY-MM-DD'))}" v-if="spaetesterBehandlungsbedarf && therapiestunden.length == 0">
      <div class="col offset-2">
        <div>Spätester Behandlungsbeginn: {{ $helpers.datumFormatieren(spaetesterBehandlungsbedarf) }}</div>
      </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_von'+id" class="col-2 col-form-label mt-auto">Ende</label>
      <div class="col">
        <input type="datetime-local" disabled name="" :id="'therapie_von'+id" v-model="therapiestunde.bis" class="form-control" :class="{'is-invalid': showError && !therapiestunde.bis}" :key="biskey" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_dauer'+id" class="col-2 col-form-label mt-auto">Dauer</label>
      <div class="col">
        <input type="number" step="1" :id="'therapie_dauer'+id" v-model="therapiestunde.dauer" class="form-control" placeholder="Dauer in Minuten" :class="{'is-invalid': showError && !therapiestunde.dauer}" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group">
      <label for="" class="col-2 col-form-label mt-auto">Dokumentation</label>
      <div class="col">
        <textarea-autosize
            v-if="!$isIOS()"
            class="form-control"
            placeholder="Dokumentation"
            v-model="therapiestunde.dokumentation"
            rows="1"
            :min-height="35"
          />

        <textarea
          v-if="$isIOS()"
          class="form-control"
          v-model="therapiestunde.dokumentation"
          placeholder="Dokumentation"
          rows="10">
        </textarea>

      </div>
    </div>
    <div class="row form-group">
      <label for="" class="col-2 col-form-label mt-auto">Vorplanung</label>
      <div class="col">
        <textarea-autosize
            v-if="!$isIOS()"
            class="form-control"
            placeholder="Vorplanung"
            v-model="therapiestunde.naechster_termin"
            rows="1"
            :min-height="35"
          />

        <textarea
          v-if="$isIOS()"
          class="form-control"
          v-model="therapiestunde.naechster_termin"
          placeholder="Vorplanung"
          rows="10">
        </textarea>
      </div>
    </div>
  </template>
</div>
</template>
<script>
import patienten from '~/mixins/patienten';
import verordnungen from '~/mixins/verordnungen';
import therapiestunden from '~/mixins/therapiestunden';

export default {
  mixins: [patienten, verordnungen, therapiestunden],
  props: ['value', 'showError', 'id', 'neu', 'verordnungProp'],
  watch: {
    therapiestunde: function(neu, alt) {
      this.bisBerechnen();
      this.$emit('input', neu);
    },
  },
  data() {
    return {
      mitarbeiterKey: 0,
      verordnungenKey: 0,
      biskey: 0,
      stopAll: false,
      missingTherapeutError: null
    }
  },
  methods: {
    fillChange(neu) {
      if (this.stopAll) return;
      let therapiestunde = this.$helpers.copy(neu);
      if (therapiestunde == null) this.therapiestunde = { };
      else if (therapiestunde.patient_id) {
        this.therapiestunde = therapiestunde;
        this.patientAuswahl();
        if (this.therapiestunde.verordnung_id && !this.therapiestunde.updated_at) this.verordnungAuswahl(this.therapiestunde.verordnung_id);
      }
      if (!this.therapiestunde.id) this.therapiestunde.id = this.$uuid.v1();
    },
    async patientAuswahl() {
      await this.patientAbrufen(this.therapiestunde.patient_id)
      let patientTherapeuten = this.$helpers.make_array(this.patient.mitarbeiter_id);
      // Wenn die Therapiestunde keinen Therapeuten hat, und der User Therapeut ist, wird der User automatich Therapeut
      if (this.$istTherapeut() && !this.therapiestunde.mitarbeiter_id) this.therapiestunde.mitarbeiter_id = this.$auth.user.mitarbeiter.id;
      // Wenn der Patient nur einen Therapeuten hat, dann den setzen
      else if (patientTherapeuten.length == 1 && patientTherapeuten[0] != this.therapiestunde.mitarbeiter_id && !this.$istTherapeut()) this.therapiestunde.mitarbeiter_id = patientTherapeuten[0];
      if (!this.therapiestunde.mitarbeiter_id) this.missingTherapeutError = true;
    },
    async verordnungAuswahl(verordnung) {
      this.therapiestunde.verordnung_id = verordnung;
      if (this.verordnungProp && this.verordnungProp.id == verordnung) {
        this.verordnung = this.verordnungProp;
      }
      else {
        await this.verordnungAbrufen(this.therapiestunde.verordnung_id);
      }
      if (!this.therapiestunde.dauer && this.verordnung) {
        this.therapiestunde.dauer = this.verordnung.therapiedauer;
      }

      this.verordnungenKey++;
    },
    bisBerechnen() {
      if (this.therapiestunde.von) this.therapiestunde.von = this.$moment(this.therapiestunde.von).format("YYYY-MM-DDTHH:mm");
      if (this.therapiestunde.von && this.therapiestunde.dauer) this.therapiestunde.bis = this.$moment(this.therapiestunde.von).add(this.therapiestunde.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
      else this.therapiestunde.bis = null;
      this.biskey++;
    },

  },
  computed: {
    ebErlaubt() {
      if (!this.verordnung || !this.therapiestunden.length) return;
      if (!this.erstbefundungErlaubt(this.verordnung)) {
        this.therapiestunde.erstbefundung = false;
        return false;
      }

      // Alte Erstbefundung suchen
      let eb = this.therapiestunden.find(therapie => {
        return therapie.erstbefundung
      });
      if (!eb) {
        this.therapiestunde.erstbefundung = true;
        return true;
      }
      // Erstbefundung ist die aktuelle Stunde
      if (eb && eb.id == this.therapiestunde.id) return true;
    },
    hbErlaubt() {
      if (!this.therapiestunde || !this.therapiestunde.verordnung_id || !this.verordnung) return false;
      if (this.verordnung.hausbesuch || this.therapiestunde.hausbesuch) {
        if(!this.therapiestunde.updated_at) this.therapiestunde.hausbesuch = true;
        return true;
      }
      this.hb = false;
      return false;
    },
    spaetesterBehandlungsbedarf() {
      if (!this.therapiestunde || !this.therapiestunde.verordnung_id || !this.verordnung) return false;
      if (this.verordnung.spaetester_behandlungsbeginn) return this.verordnung.spaetester_behandlungsbeginn;
      return null;
    }
  },
  mounted() {
    if (this.verordnungProp) {
      this.verordnung = this.verordnungProp;
      this.therapiestunden = this.$helpers.sortArray(this.verordnung.therapiestunden, 'von', 'desc');
    }
    else {
      this.therapiestundenFilter = {verordnung_id: this.value.verordnung_id};
      this.therapiestundenAbrufen();
      this.verordnungAbrufen(this.value.verordnung_id);
    }
    this.fillChange(this.value);
  },
  beforeDestroy() {
    this.stopAll = true;
  }
}
</script>
