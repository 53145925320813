export default {
  data() {
    return {
      modeldateien: [],
      model_type: '',
      model_id: ''
    }
  },
  methods: {
    modelDateienAbrufen(model_type, model_id) {
      if (!this.$can('Modeldateien herunterladen')) return;
      this.model_type = model_type;
      this.model_id = model_id;
      this.$axios.get('{cloud}/v1/modeldateien/'+model_type+'/'+model_id).then(response => {
        this.modeldateien = response.data;
        this.$nuxt.$emit('modeldateien-'+model_type+'-'+model_id, response.data);
      });
    },
    urlModelDatei(datei = undefined) {
      let url = '{cloud}/v1/modeldateien/'+this.model_type+'/'+this.model_id+'/';
      if (datei) url += datei.id;
      return url;
    },
    urlModelDateiBlank() {
      return '{cloud}/v1/modeldateien/';
    },
    downloadModelDatei(datei) {
      if (!this.$can('Modeldateien herunterladen')) return;
      this.$axios({url: '{cloud}/v1/modeldateien/'+this.model_type+'/'+this.model_id+'/'+datei.id, method: 'GET', responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = datei.dateiname;
        document.body.appendChild(link);
        link.click();
      }, error => {this.$notifyError('Fehler', 'Download nicht möglich',error);});
    },
    modelDateiUpload(dateiarray, id = 'neu') {
      if (!this.$can('Modeldateien herunterladen')) return;
      if (!dateiarray.length) return;
      // append the files to FormData
      _.forEach(dateiarray, datei => {
        let formData = new FormData();
        formData.append('file', datei);
        this.$axios.post('{cloud}/v1/modeldateien/'+this.model_type+'/'+this.model_id+'/'+id, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
          this.modeldateien.push(response.data);
        }, error => {this.$notifyError('Fehler', 'Upload nicht möglich',error);});
      })
    },
    dateiUpdateTags(datei, tags) {
      if (!this.$can('Modeldateien herunterladen')) return;
      this.$axios.post('{cloud}/v1/dateien/'+datei.id+'/tags', {tags: this.$helpers.pluck(tags, null, 'id')}).then(response => {
        datei = response.data;
      },
        error => {this.$notifyError('Fehler', 'Tags konnten nicht aktualisiert werden',error);});
    }
  }
}
