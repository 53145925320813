export default {
  data() {
    return {
      verordnungenKey: 0
    }
  },
  methods: {
    async verordnungenAbrufenStatic() {
      let verordnungen = await this.$axios.get('{verordnungen}/v1/verordnungen', {params: {
        filter: this.verordnungenFilter
      }});
      this.verordnungen = this.$helpers.sortArray(verordnungen.data, 'datum', 'desc');
      this.verordnungenCount = this.verordnungen.length;
      this.verordnungenKey++;
      return;
    },
    async verordnungAbrufenStatic(id) {
      let verordnung = await this.$axios.get('{verordnungen}/v1/verordnungen', {params: {
        filter: {id}
      }});
      this.verordnung = verordnung.data[0];
      this.verordnungenKey++;
    }
  }
}
