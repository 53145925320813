<template>
  <div v-if="arbeitszeit && anfahrtObj && abfahrtObj" :key="formkey">
    <legend>Arbeitszeit</legend>
    <small>Arbeitszeit wird automatisch erfasst</small>
        <div v-if="taetigkeit">
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="!edit">
              <label class="col-2 col-form-label" :for="'arbeitszeit'+id">Arbeitszeit</label>
              <div class="col">
                <label class="form-check-label" :for="'arbeitszeit'+id">
                    <input class="form-check-input" type="checkbox" value="1" :id="'arbeitszeit'+id" v-model="arbeitszeit.erfassen" @change="arbeitszeitSync()">
                    Arbeitszeit erfassen
                </label>
              </div>
          </div>
          <template v-if="arbeitszeit.erfassen">
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'arbeitszeit_von'+id" class="col-2 col-form-label mt-auto">Start</label>
              <div class="col">
                <input type="datetime-local" name="" :id="'arbeitszeit_von'+id" v-model="arbeitszeit.von" class="form-control" :class="{'is-invalid': showError && !arbeitszeit.von}" @change="arbeitszeit.von_changed = true" @input="anfahrtPrefill()">
              </div>
            </div>
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'arbeitszeit_dauer'+id" class="col-2 col-form-label mt-auto">Dauer</label>
              <div class="col">
                <input type="number" step="1" :id="'arbeitszeit_dauer'+id" v-model="arbeitszeit.dauer" class="form-control" placeholder="Dauer in Minuten" :class="{'is-invalid': showError && !arbeitszeit.dauer}" @change="arbeitszeit.dauer_changed = true"  @input="anfahrtPrefill()">
              </div>
            </div>
          </template>
        </div>
        <template  v-if="!edit">
        <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
            <label class="col-2 col-form-label" :for="'anfahrt'+id">Anfahrt</label>
            <div class="col">
              <label class="form-check-label" :for="'anfahrt'+id">
                  <input class="form-check-input" type="checkbox" value="1" :id="'anfahrt'+id" v-model="anfahrtObj.erfassen" @change="anfahrtPrefill()">
                  Anfahrt erfassen
              </label>
            </div>
        </div>
        <template v-if="anfahrtObj.erfassen">
          <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
            <label :for="'anfahrt_von'+id" class="col-2 col-form-label mt-auto">von</label>
            <div class="col">
              <input type="datetime-local" name="" :id="'anfahrt_von'+id" v-model="anfahrtObj.von" class="form-control" @input="anfahrtvonUpdate()" :class="{'is-invalid': showError && !anfahrtObj.von}">
            </div>
          </div>
          <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
            <label :for="'anfahrt_bis'+id" class="col-2 col-form-label mt-auto">bis</label>
            <div class="col">
              <input type="datetime-local" name="" :id="'anfahrt_bis'+id" v-model="anfahrtObj.bis" class="form-control" disabled>
            </div>
          </div>
          <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
            <label :for="'anfahrt_dauer'+id" class="col-2 col-form-label mt-auto">Dauer</label>
            <div class="col">
              <input type="number" step="1" :id="'anfahrt_dauer'+id" v-model="anfahrtObj.dauer" class="form-control" placeholder="Dauer in Minuten" @input="anfahrtDauerUpdate()" :class="{'is-invalid': showError && !anfahrtObj.dauer}">
            </div>
          </div>
          <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
            <label :for="'anfahrt_km'+id" class="col-2 col-form-label mt-auto">Strecke</label>
            <div class="col">
              <input type="number" step="1" :id="'anfahrt_km'+id" v-model="anfahrtObj.km" class="form-control" placeholder="Strecke in km" @input="abfahrtAnAnfahrtAnpassen()">
            </div>
          </div>
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
            <label class="col-2 col-form-label" :for="'anfahrt_arbeitszeit'+id">Arbeitszeit</label>
            <div class="col">
              <label class="form-check-label" :for="'anfahrt_arbeitszeit'+id">
                  <!-- <input class="form-check-input" type="checkbox" value="1" :id="'anfahrt_arbeitszeit'+id" v-model="anfahrtObj.arbeitszeit" @change="abfahrtAnAnfahrtAnpassen()"> -->
                  Die Anfahrt wird automatisch als Arbeitszeit gebucht
              </label>
            </div>
          </div>
          <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()">
            <label class="col-2 col-form-label" :for="'abfahrt'+id">Zweifach</label>
            <div class="col">
              <label class="form-check-label" :for="'abfahrt'+id">
                  <input class="form-check-input" type="checkbox" value="1" :id="'abfahrt'+id" v-model="abfahrtObj.erfassen" @change="abfahrtPrefill()">
                  Die Anfahrt ist vorher und hinterher angefallen
              </label>
            </div>
          </div>
          <template v-if="abfahrtObj.erfassen">
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'abfahrt_von'+id" class="col-2 col-form-label mt-auto">von</label>
              <div class="col">
                <input type="datetime-local" name="" :id="'abfahrt_von'+id" v-model="abfahrtObj.von" class="form-control" disabled :class="{'is-invalid': showError && !abfahrtObj.von}" @change="abfahrtObj.changed = true">
              </div>
            </div>
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'anfahrt_bis'+id" class="col-2 col-form-label mt-auto">bis</label>
              <div class="col">
                <input type="datetime-local" name="" :id="'anfahrt_bis'+id" v-model="abfahrtObj.bis" class="form-control" :class="{'is-invalid': showError && !abfahrtObj.bis}" @change="abfahrtObj.changed = true" @input="abfahrtbisUpdate()" >
              </div>
            </div>
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'abfahrt_dauer'+id" class="col-2 col-form-label mt-auto">Dauer</label>
              <div class="col">
                <input type="number" step="1" :id="'abfahrt_dauer'+id" v-model="abfahrtObj.dauer" class="form-control" placeholder="Dauer in Minuten" :class="{'is-invalid': showError && !abfahrtObj.dauer}" @change="abfahrtObj.changed = true" @input="abfahrtDauerUpdate()" >
              </div>
            </div>
            <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
              <label :for="'abfahrt_km'+id" class="col-2 col-form-label mt-auto">Strecke</label>
              <div class="col">
                <input type="number" step="1" :id="'abfahrt_km'+id" v-model="abfahrtObj.km" class="form-control" placeholder="Strecke in km" @change="abfahrtObj.changed = true">
              </div>
            </div>
            <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" >
              <label class="col-2 col-form-label" :for="'abfahrt_arbeitszeit'+id">Arbeitszeit</label>
              <div class="col">
                <label class="form-check-label" :for="'abfahrt_arbeitszeit'+id">
                    <!-- <input class="form-check-input" type="checkbox" value="1" :id="'abfahrt_arbeitszeit'+id" v-model="abfahrtObj.arbeitszeit" @change="abfahrtObj.changed = true"> -->
                    Die Abfahrt wird automatisch als Arbeitszeit gebucht
                </label>
              </div>
            </div>
          </template>
        </template>
      </template>
  </div>
</template>
<script>

  export default {
    props: ['value', 'showError', 'id', 'anfahrt', 'therapiestunde', 'taetigkeit', 'abfahrt', 'edit'],
    data() {
      return {
        arbeitszeit: null,
        anfahrtObj: null,
        abfahrtObj: null,
        formkey: 0
      }
    },
    watch: {
      arbeitszeit: function(neu, alt) {
        this.bisBerechnen();
        this.$emit('input', neu);
      },
      anfahrtObj: function(neu, alt) {
        this.bisBerechnen();
        this.$emit('anfahrtUpdate', neu);
      },
      abfahrtObj: function(neu, alt) {
        this.bisBerechnen();
        this.$emit('abfahrtUpdate', neu);
      },
      therapiestunde: function (neu, alt) {
        this.arbeitszeit.trigger_type = 'Therapiestunde';
        this.arbeitszeit.trigger_id = neu.id;
        this.fillChange(this.value);
      },
      taetigkeit: function (neu, alt) {
        this.arbeitszeit.trigger_type = 'Sonstiges';
        this.arbeitszeit.trigger_id = neu.id;
        this.fillChange(this.value);
      }
    },
    methods: {
      fillChange(neu) {
        let arbeitszeit = this.$helpers.copy(neu);
        if (arbeitszeit == null) arbeitszeit = { };
        if (!arbeitszeit.id) arbeitszeit.id = this.$uuid.v1();
        if (arbeitszeit.erfassen == undefined) arbeitszeit.erfassen = false;
        if (!arbeitszeit.dauer) arbeitszeit.dauer = 0;
        this.arbeitszeit = arbeitszeit;
        this.arbeitszeitSync();

        let anfahrt = this.$helpers.copy(this.anfahrt);
        if (anfahrt == null) anfahrt = { };
        if (!anfahrt.id) anfahrt.id = this.$uuid.v1();
        if (anfahrt.erfassen == undefined) anfahrt.erfassen = false;
        if (anfahrt.arbeitszeit == undefined) anfahrt.arbeitszeit = false;
        if (!anfahrt.dauer) anfahrt.dauer = 0;
        this.anfahrtObj = anfahrt;

        let abfahrt = this.$helpers.copy(this.abfahrt);
        if (abfahrt == null) abfahrt = { };
        if (!abfahrt.id) abfahrt.id = this.$uuid.v1();
        if (abfahrt.erfassen == undefined) abfahrt.erfassen = false;
        if (abfahrt.arbeitszeit == undefined) abfahrt.arbeitszeit = false;
        if (!abfahrt.dauer) abfahrt.dauer = 0;
        this.abfahrtObj = abfahrt;
        this.bisBerechnen();
        this.anfahrtPrefill();
        this.formkey++;
      },
      arbeitszeitSync() {
          if (!this.arbeitszeit.erfassen || !this.basis) return;
          if (!this.arbeitszeit.von_changed) this.arbeitszeit.von = this.$moment(this.basis.von).format("YYYY-MM-DDTHH:mm");;
          if (!this.arbeitszeit.dauer_changed) this.arbeitszeit.dauer = this.basis.dauer;
          this.formkey++;
      },
      bisBerechnen() {
        if (this.anfahrtObj.von && this.anfahrtObj.dauer) this.anfahrtObj.bis = this.$moment(this.anfahrtObj.von).add(this.anfahrtObj.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
        if (this.abfahrtObj.von && this.abfahrtObj.dauer) this.abfahrtObj.bis = this.$moment(this.abfahrtObj.von).add(this.abfahrtObj.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
        if (this.arbeitszeit.von && this.arbeitszeit.dauer) this.arbeitszeit.bis = this.$moment(this.arbeitszeit.von).add(this.arbeitszeit.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
      },
      anfahrtPrefill() {
        this.bisBerechnen();
        if (!this.anfahrtObj) return;
        if (this.anfahrtObj.erfassen == false) return;
        this.abfahrtPrefill();
        if (!this.anfahrtObj.von) this.anfahrtObj.von = this.$moment(this.basis.von).subtract(15, 'm').format("YYYY-MM-DDTHH:mm");
        if (!this.anfahrtObj.dauer) this.anfahrtObj.dauer = 15;
        this.abfahrtPrefill();
        this.bisBerechnen();
      },
      abfahrtPrefill() {
        if (!this.abfahrtObj.erfassen) {
          this.abfahrtObj.changed = false;
          return;
        }
        this.abfahrtObj.von = this.$moment(this.basis.bis).format("YYYY-MM-DDTHH:mm");
        if (!this.abfahrtObj.dauer) this.abfahrtObj.dauer = 15;
        this.bisBerechnen();
      },
      anfahrtvonUpdate() {
        let end = this.$moment(this.arbeitszeit.von);
        let von = this.$moment(this.anfahrtObj.von);
        this.anfahrtObj.dauer = this.$moment.duration(end.diff(von)).asMinutes();
        this.abfahrtAnAnfahrtAnpassen();
      },
      abfahrtbisUpdate() {
        let end = this.$moment(this.abfahrtObj.von);
        let bis = this.$moment(this.abfahrtObj.bis);
        this.abfahrtObj.dauer = this.$moment.duration(bis.diff(end)).asMinutes();
      },
      abfahrtAnAnfahrtAnpassen() {
        if (!this.abfahrtObj.changed) {
          this.abfahrtObj.dauer = this.anfahrtObj.dauer;
          this.abfahrtObj.km = this.anfahrtObj.km;
          this.abfahrtObj.arbeitszeit = this.anfahrtObj.arbeitszeit;
          this.bisBerechnen();
        }
      },
      anfahrtDauerUpdate() {
        this.anfahrtObj.von = this.$moment(this.basis.von).subtract(this.anfahrtObj.dauer, 'm').format("YYYY-MM-DDTHH:mm");
        this.abfahrtAnAnfahrtAnpassen();
      },
      abfahrtDauerUpdate() {
        this.abfahrtObj.bis = this.$moment(this.abfahrtObj.von).add(this.abfahrtObj.dauer, 'm').format("YYYY-MM-DDTHH:mm");
      },
    },
    computed: {
      basis() {
        if (this.therapiestunde) return this.therapiestunde;
        else if (this.taetigkeit) return this.taetigkeit;
        return null;
      },
    },
    mounted() {
      this.fillChange(this.value);
    }

  }
</script>
