import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';
import moment from 'moment';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty),
  name: 'anrufe',
  von: moment().subtract(1, 'd').format('YYYY-MM-DD'),
  bis: moment().format('YYYY-MM-DD'),
})

const mutations = {
  setAll: storeHelperOld.setAll,
  set: storeHelperOld.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
  setVon(state, von) {
    state.von = von;
  },
  setBis(state, bis) {
    state.bis = bis;
  }
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled
}

const actions = {
  async load({commit, state}, payload) {
    let url = "{placetel}/v1/anrufe";
    let notifyBetreff = "Anrufe aktualisieren";
    return storeHelperOld.load(commit, state, url, notifyBetreff, payload);
  },
  async speichern({commit}, model) {
    let url = "{placetel}/v1/anrufe/"+model.id;
    let notifyBetreff = "Anruf aktualisieren";
    return storeHelperOld.speichern(commit, model, url, notifyBetreff);
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
