<template>
    <div class="row">
      <div class="col-3 my-auto">
        <button class="btn btn-outline-secondary btn-sm" :class="{active: text == '10'}" tabindex="-1" @click="update('10')">10</button>
      </div>
      <span class="col">
          <datalist :id="'verordnungsmenge'+_uid"><option :value="bez" v-for="bez in verordnungPrefills.verordnungsmengen" :key="bez" /></datalist>
          <the-mask type="number" :masked="true" @change="update($event)" @input="update($event)" :list="'verordnungsmenge'+_uid"  mask="###" class="form-control" placeholder="Verordnungsmenge" id="verordnungsmenge" v-model="text" />
      </span>
    </div>
</template>
<script>
import prefills from '~/mixins/prefills';
export default {
  mixins: [prefills],
  props: ['value'],
  data() {
    return {
      text: this.value
    }
  },
  methods: {
    update(neu) {
      this.text = neu;

      this.$emit('input', neu);
      this.$emit('change', neu);
    }
  }
}
</script>
