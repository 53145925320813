<template>
     <b-modal :id="id" size="xl" :title="'Verordnung prüfen'" hide-footer :ref="'modal-'+id" @show="start()">
       <template v-if="verordnung">
        <h3>
          Verordnung vom: {{ $helpers.datumFormatieren(verordnung.datum) }}
        </h3>

        <template v-if="infos.length">
          Folgende Fehler wurden festgestellt:
          <ul class="alert alert-danger pl-5">
            <li v-for="(info, index) in infos" :key="index">{{info}}</li>
          </ul>

          <template v-if="verordnungsaenderungen.length">
            <h3 class="mt-3">Mit anderer Änderung zusammenführen</h3>
            <verordnungsaenderung-auswahl :verordnung="verordnung.id" :id="'aenderung'+id" v-model="alteAenderung_id" @input="verordnungsaenderungAbrufen($event)"></verordnungsaenderung-auswahl>
          </template>


          <div class="row">
            <div class="col">
              <h3 class="mt-5">Vorgeschlagene Änderungen</h3>
              <table class="table table-striped" v-if="eingehendeAenderung.length">
                <thead>
                  <th>Feld</th>
                  <th width="200px">Aktuell</th>
                  <th></th>
                  <th>Vorschlag</th>
                  <template v-if="verordnungsaenderung">
                    <th></th>
                    <th>Aus gewählter Änderung</th>
                  </template>

                </thead>
                <tbody>
                  <tr v-for="(aenderung, index) in $helpers.sortArray(eingehendeAenderung, 'feld')" :key="index">
                    <td>{{aenderung.name}}</td>
                    <td>
                      <span v-if="aenderung.ist != null">
                        <span v-if="aenderung.feld == 'heilmittel'">
                          <span class="d-block" v-for="(hm, index) in aenderung.ist" :key="index">
                            {{ hm.heilmittel }} - {{ hm.dauer }} Min - {{ hm.menge }} Einheiten
                          </span>
                        </span>
                        <span v-else-if="aenderung.feld == 'leitsymptomatik'">
                          <span class="d-block" v-for="(wert, buchstabe) in aenderung.ist" :key="buchstabe" v-if="wert">
                            {{ buchstabe }}
                          </span>
                        </span>
                        <span v-else-if="aenderung.feld == 'hausbesuch' || aenderung.feld == 'dringlicher_handlungsbedarf'">
                          {{ (aenderung.ist) ? 'Ja' : 'Nein' }}
                        </span>
                        <span v-else>{{aenderung.ist}}</span>
                      </span>
                      <span v-else-if="aenderung.feld != 'freifeld'">Fehlt</span>
                    </td>
                    <template v-if="aenderung.aendern">
                      <td>
                        <input type="checkbox" name="" id="" v-model="aenderungenNeu[aenderung.feld]" value="true" @click="aenderungenAlt[aenderung.feld]=false">
                      </td>
                      <td>
                        <textarea-autosize
                            v-if="!$isIOS()"
                            class="form-control"
                            placeholder="Änderung"
                            rows="1"
                            :min-height="35"
                            v-model="aenderung.soll"
                          />
                        <textarea
                            v-if="$isIOS()"
                            class="form-control"
                            placeholder="Änderung"
                            v-model="aenderung.soll"
                            rows="2">
                          </textarea>
                      </td>
                    </template>
                    <template v-else>
                      <td></td>
                      <td>Keine Änderung</td>
                    </template>

                      <template v-if="verordnungsaenderung">
                        <template v-if="!alteAenderungSoll(aenderung.feld) || !alteAenderungSoll(aenderung.feld).aendern">
                          <td></td>
                          <td>Keine Änderung</td>
                        </template>
                        <template v-else>
                          <td>
                            <input type="checkbox" name="" id="" v-model="aenderungenAlt[aenderung.feld]" value="true"  @click="aenderungenNeu[aenderung.feld]=false">
                          </td>
                          <td>
                            <textarea-autosize
                                v-if="!$isIOS()"
                                class="form-control"
                                placeholder="Änderung"
                                rows="1"
                                :min-height="35"
                                v-model="alteAenderungSoll(aenderung.feld).soll"
                              />
                            <textarea
                              v-if="$isIOS()"
                              class="form-control"
                              placeholder="Änderung"
                              v-model="alteAenderungSoll(aenderung.feld).soll"
                              rows="2">
                            </textarea>
                        </td>
                        </template>
                      </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button class="btn btn-outline-primary" :disabled="speichernErlaubt" v-if="!verordnungsaenderung" @click="neueAenderung()">Änderung erstellen</button>
          <button class="btn btn-outline-primary" :disabled="speichernErlaubt" @click="aenderungMergen()" v-else>Änderung zusammenführen</button>
        </template>
        <p class="alert alert-success" v-else>Keine Fehler gefunden</p>
      </template>
      <div class="float-right">
        <tooltip-information>
          Geprüft wird:
          <ul>
            <li>ICD-10 Code vorhanden</li>
            <li>Therapiefreuenz gefüllt</li>
            <li>Diagnosegruppe gefüllt</li>
            <li>Heilmittel & Behandlungseinheiten passt zu Diagnosegruppe gemäß Heilmittelkatalog</li>
            <li>Ergänzendes Heilmittel bei dem gewählten Heilmittel erlaubt</li>
          </ul>
        </tooltip-information>
      </div>
    </b-modal>
</template>

<script>
import verordnungen from '~/mixins/verordnungen';
import verordnungsaenderungen from '~/mixins/verordnungsaenderungen';
import verordnungpruefen from '~/mixins/verordnungpruefen';

/**
 * this.verordnungsaenderung ist die Alte Änderung mit der Gemerged werden soll
 * this.eingehendeAenderung ist die neue Änderung, die gerade erstellt wird
 */
export default {
    props: ['verordnungProp', 'id'],
    mixins: [verordnungen, verordnungsaenderungen, verordnungpruefen],
    data() {
      return {
        infos: [],
        eingehendeAenderung: [],
        alteAenderung_id: null,
        aenderungenAlt: {},
        aenderungenNeu: {},
        verordnung: this.verordnungProp,
      }
    },
    computed: {
      speichernErlaubt() {
        let irgendwasAngeklickt = false;
        _.forEach(this.aenderungenAlt, (val) => {
          if (val) return irgendwasAngeklickt = true;
        })
        _.forEach(this.aenderungenNeu, (val) => {
          if (val) return irgendwasAngeklickt = true;
        })
        return !irgendwasAngeklickt;
      }
    },
    methods: {
      start() {
        if (this.verordnung) {
          let pruefen =  this.verordnungPruefen(this.verordnung);
          this.infos = pruefen.info;
          this.eingehendeAenderung = pruefen.aenderungen;
        }
      },
      alteAenderungSoll(feld) {
        return this.$helpers.findByKey(this.verordnungsaenderung.details, feld, 'feld');
      },
      neueAenderung() {
        let datum = this.$moment().format('YYYY-MM-DD');
        let aenderung = { id: this.$uuid.v1(), 'patient_id': this.verordnung.patient_id, verordnung_id: this.verordnung.id, details: [], datum: datum };
        let details = this.$helpers.copy(this.eingehendeAenderung);
        _.forEach(details, (obj) => {
          let neu = this.aenderungenNeu[obj.feld];
          if (neu) {
            obj.aendern = true;
          }
          else obj.aendern = false;
        })
        aenderung.details = details;
        this.speichernAenderungen(aenderung);
        this.$bvModal.hide(this.id);
      },
      aenderungMergen() {
        let aenderung = this.$helpers.copy(this.verordnungsaenderung);
        let details = this.$helpers.copy(this.verordnungsaenderung.details);
        _.forEach(details, (obj) => {
          let neu = this.aenderungenNeu[obj.feld];
          let alt = this.aenderungenAlt[obj.feld];
          if (alt) obj.aendern = true;
          else if (neu) {
            obj.aendern = true;
            obj.soll = this.$helpers.findByKey(this.eingehendeAenderung, obj.feld, 'feld').soll;
          }
          else obj.aendern = false;
          obj.ist = this.verordnung[obj.feld];
        })
        aenderung.details = details;
        this.speichernAenderungen(aenderung);
        this.$bvModal.hide(this.id);
      }
    },
    mounted() {
      this.getVerordnung = this.verordnung.id;
      if (this.verordnung.aenderungen) {
        this.verordnungsaenderungen = this.verordnung.aenderungen;
        this.verordnungsaenderungenCount = this.verordnungsaenderungen.length
      }
      else {
        this.registerVerordnungenEvent();
        this.verordnungsaenderungenFilter = {verordnung_id: this.verordnung.id};
        this.verordnungsaenderungenAbrufen();
      }
    }
}
</script>
