import heilmittelkatalog from '~/mixins/heilmittelkatalog';
export default {
  mixins: [heilmittelkatalog],
  methods: {
    /** Errechnet die Summe der verschriebenen Einheiten */
    verordnungSummeEinheiten(verordnung) {
      let summeHeilmittel = 0;
      if (verordnung.heilmittel) {
        _.forEach(verordnung.heilmittel, (hm) => {
          if (hm.menge) summeHeilmittel += hm.menge;
        })
      }
      else if (verordnung.verordnungsmenge) summeHeilmittel = verordnung.verordnungsmenge;
      return summeHeilmittel;
    },
    /** Verordnung ist besonderer Behandlungsbedarf oder nicht */
    besondererBehandlungsbedarf(verordnung) {
      if (!verordnung.icd_10_code || !verordnung.indikationsschluessel) return false;
      let katalog = this.katalog(verordnung);
      if (!katalog) return false;
      let bedarf = false;

      if (katalog.besonderer_verordnungsbedarf.indexOf(verordnung.icd_10_code) != -1) return true;
      else if (katalog.langfristiger_heilmittelbedarf.indexOf(verordnung.icd_10_code) != -1) return true;
      // Wenn 2. ICD 10, dann muss nur einer davon passen
      else if (verordnung.icd_10_code_2 && katalog.besonderer_verordnungsbedarf.indexOf(verordnung.icd_10_code_2) != -1)  return true;
      else if (verordnung.icd_10_code_2 && katalog.langfristiger_heilmittelbedarf.indexOf(verordnung.icd_10_code_2) != -1)  return true;
      // Kombination aus beiden Codes prüfen
      else if (verordnung.icd_10_code_2) {
        let kombi = [verordnung.icd_10_code, verordnung.icd_10_code_2];
        let kombi2 = [verordnung.icd_10_code_2, verordnung.icd_10_code];

        let ret = false;
        katalog.langfristiger_heilmittelbedarf.forEach(el => {
          if (JSON.stringify(el) == JSON.stringify(kombi)) return ret = true;
          else if (JSON.stringify(el) == JSON.stringify(kombi2)) return ret = true;
        })
        if (ret) return true;
        katalog.besonderer_verordnungsbedarf.forEach(el => {
          if (JSON.stringify(el) == JSON.stringify(kombi)) return ret = true;
          else if (JSON.stringify(el) == JSON.stringify(kombi2)) return ret = true;
        })
        if (ret) return true;
      }
    },
    /** Prüft die Maximale Menge anhand des Heilmittelkatalogs und dessen was in der Verordnung hinterlegt ist  */
    verordnungMaximaleMenge(verordnung) {
      let max_besonders = 0;
      // Dann maximal 12 Wochen bei gewählter Frequenz
      if (this.besondererBehandlungsbedarf(verordnung)) {
        max_besonders = verordnung.max_frequenz * 12;
      }

      let maxKatalog = 0;
      if (verordnung.indikationsschluessel) {
        let katalog = this.katalog(verordnung);
        if (katalog) maxKatalog = katalog.maximale_verordnungsmenge;
      }

      if (max_besonders > maxKatalog && max_besonders != 0) return max_besonders;
      return maxKatalog;
    },
    verordnungsmenge(verordnung) {
      let summeHeilmittel = this.verordnungSummeEinheiten(verordnung)
      let maxMenge = this.verordnungMaximaleMenge(verordnung);

      // Besonderer Heilmittelbedarf
      if (maxMenge < summeHeilmittel && maxMenge != 0) {
        verordnung.verordnungsmenge_geaendert = 'Maximal erlaubte Verordnungsmenge geändert, gemäß Heilmittelkatalog';
        return maxMenge;
      }

      return summeHeilmittel;
    },
    therapiefrequenz(verordnung) {
      // Eh gefüllt
      if (verordnung.therapiefrequenz) return verordnung.therapiefrequenz;
      // // Logo
      // if (!verordnung.fachbereich) return null;

      // Logo und Ergo Frequenz aus Katalog ziehen
      let ergebnis = '';
      let katalog = this.katalog(verordnung)
      if (!katalog) return null;
      if (katalog.minimale_frequenz) ergebnis += katalog.minimale_frequenz;
      if (katalog.maximale_frequenz) {
        if (ergebnis) ergebnis += '-';
        ergebnis += katalog.maximale_frequenz;
      }
      return ergebnis;
    },
    erstbefundungErlaubt(verordnung) {
      if (!verordnung) return false;
      // Wenn Erstverordnung oder Praxiswechsel
      if ((verordnung.typ && verordnung.typ == 1) || verordnung.praxiswechsel) return true;
      return false;
    },
    verordnungDauer(verordnung) {
      if (!verordnung.heilmittel) return false;
      let dauer = false;
      _.forEach(verordnung.heilmittel, hm => {
        if (hm.dauer) dauer = hm.dauer;
      })
      return dauer;
    },
  }
}
