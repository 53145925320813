import suche from '~/mixins/suche';
export default {
  mixins: [suche],
  mounted() {
    if (!this.$store.getters['cloudtags/filled']) this.loadCloudtags();
  },
  methods: {
    findCloudtag(id) {
      return this.$store.getters['cloudtags/find'](id);
    },
    loadCloudtags() {
      this.$store.dispatch('cloudtags/load');
    },
    tagsAufbereiten(tags) {
      let neu = [];
      _.forEach(tags, tag => {
        neu.push({text: tag.name, id: tag.id});
      })
      return neu;
      // return this.$helpers.pluck(tags, null, 'name');
    },
    neueTags(element, event) {
      let tags = [];
      _.forEach(event, tag => {
        tags.push({name: tag.text, id: tag.id});
      });
      element.tags = tags;
    }
  },
  computed: {
    cloudtags: function() {
      this.standCloudtags;
      return this.$helpers.sortArray(this.$store.getters['cloudtags/getAll'],this.orderCloudtagsBy);
    },
    cloudTagsDateien: function() {
      return this.cloudtags.filter(tag => {
        return (tag.dateien);
      })
    },
    cloudTagsOrdner: function() {
      return this.cloudtags.filter(tag => {
        return (tag.ordner);
      })
    },
    cloudtagsDateienInput: function() {
      let tags = [];
      if (!this.tagtext || this.tagtext == '') tags = this.cloudTagsDateien;
      else tags = this.suchen(this.cloudTagsDateien, this.tagtext);
      return (this.tagsAufbereiten(tags));
    },
    standCloudtags: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['cloudtags/getStand'];
      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadCloudtags();
      return stand;
    }

  },
  data() {
    return {
      orderCloudtagsBy: 'name'
    }
  }
}
