<template>
  <div class="kommentar-editor">
    <div class="form-group editor" v-if="titel">
      <input type="text" v-model="content.titel" placeholder="Titel des Kommentars" class="form-control" >
    </div>
    <html-editor :autoFocus="false" @update="content.text = $event"></html-editor>
    <button class="btn btn-outline-primary btn-sm mt-1 mb-3" @click="absenden()">Antwort absenden</button>
  </div>
</template>
<script>
export default {
  props: ['kommentare', 'showDateSet', 'id', 'type', 'titel'],
  watch: {
    showDateSet: function(neu) {
      this.showDate = neu;
    }
  },
  data() {
    return {
      showDate: false,
      content: {
        text: null,
        titel: ''
      }
    }
  },
  methods: {
    absenden() {
      this.$emit('absenden', this.content);
    }
  }
}
</script>
