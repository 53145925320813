import permissions from '~/mixins/can'
import {Helpers} from '@/plugins/helpers';

export default function({$auth, route, redirect}) {
  let perArray = [
    {route: 'mitarbeiter-listen-mitarbeiter', permission: 'Mitarbeiter verwalten'},
    {route: 'mitarbeiter-mitarbeiter-id', permission: 'Mitarbeiter verwalten'},

    {route: 'mitarbeiter-listen-patienten', permission: 'Patienten verwalten'},
    {route: 'mitarbeiter-patienten-id', permission: 'Patienten verwalten'},

    {route: 'mitarbeiter-listen-rollen', permission: 'Permissions verwalten'},
    {route: 'mitarbeiter-listen-status', permission: 'Service Status prüfen'},
    {route: 'mitarbeiter-listen-verordnungen', permission: 'Verordnungen verwalten'},

    {route: 'mitarbeiter-dokumente-schreiben-index', permission: 'Schreiben erstellen'},
    {route: 'mitarbeiter-dokumente-schreiben-id', permission: 'Schreiben erstellen'},

    {route: 'mitarbeiter-system-vorlagen-index', permission: 'Schreiben Vorlagen verwalten'},
    {route: 'mitarbeiter-system-vorlagen-id', permission: 'Schreiben Vorlagen verwalten'},
  ]

  let perm = Helpers.findByKey(perArray, route.name, 'route');
  let neededPermission = (perm) ? perm.permission : '';

  if (!neededPermission) return true;
  if (Helpers.findByKey($auth.user.allePermissions, neededPermission, 'name')) return true;
  console.log('Zugriff verweigert. Nicht die entsprechende Permission '+neededPermission);

  return redirect('/dashboard');

}
