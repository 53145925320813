import lokiHelper from '~/mixins/lokiHelper';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    async speichernArbeitsstunde(model) {
      if (model.sonstiges) model.sonstiges.updated_at = this.$nuxt.$moment().format('YYYY-MM-DD HH:mm:ss');
      model.von = this.$helpers.datumFormatieren(model.von, 'YYYY-MM-DD HH:mm:ss');
      model.bis = this.$helpers.datumFormatieren(model.bis, 'YYYY-MM-DD HH:mm:ss');

      this.$store.dispatch('arbeitsstunden/speichern', model);
      setTimeout(() => {
        this.loadArbeitsstunden(false, model.mitarbeiter_id);
      }, 3000);
    },
    loadArbeitsstunden(manuell = false, mitarbeiter = null) {
      let params = {
        mitarbeiter: mitarbeiter,
        monat: this.arbeitsstundenMonat
      }
      this.$store.dispatch('arbeitsstunden/sync', {manuell, params});
    },
    // getArbeitsstunden(mitarbeiter) {
    //   let check = this.$moment(this.arbeitsstundenMonat);
    //   let stunden = this.arbeitsstunden.filter((h) => {
    //     let mom = this.$moment(h.von);
    //     // Nur aktueller Mitarbeiter und nur gewählter Monat anzeigen
    //     return (h.mitarbeiter_id == mitarbeiter && mom.format('M') == check.format('M') && mom.format('Y') == check.format('Y'))
    //   });
    //   return this.$helpers.sortArray(stunden, 'von', 'desc');
    // },
    getStandMitarbeiter(mitarbeiter) {
      if (!mitarbeiter) return;
      // Nur damit das refreshed wird
      // let anzahl = this.getArbeitsstunden(mitarbeiter).length;
      this.$store.getters['arbeitsstunden/getStand'];
      let jetzt = new Date();
      let stand = this.$store.getters['arbeitsstunden/getStandModels'](mitarbeiter, this.arbeitsstundenMonat);
      if (!stand || stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadArbeitsstunden(false, mitarbeiter);
      return stand;
    },

    async arbeitsstundenAbrufen() {
      this.getArbeitsstunden = true;
      this.registerArbeitsstundenEvent();
      if (!this.suchbegriff) {
       this.arbeitsstunden = this.$helpers.sortArray(await this.getElemente('arbeitsstunden', this.arbeitsstundenFilter), 'von', 'desc');
       this.arbeitsstundenCount = this.arbeitsstunden.length;
       return;
      }

      // arbeitsstunden suchen
      let arbeitsstundenCollection = await dbHelper.getDb('arbeitsstunden');
      this.arbeitsstunden = await arbeitsstundenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    arbeitsstundenAenderungenAbrufen() {
      this.getArbeitsstundenAenderungen = true;
      this.registerArbeitsstundenEvent();
      return this.getAenderungen('arbeitsstunden').then(data => this.arbeitsstundenAenderungen = data);
    },
    arbeitsstundeAbrufen(id) {
      this.getArbeitsstunde = id
      this.registerArbeitsstundenEvent();
      return this.getElement('arbeitsstunden', {id: id}).then(arbeitsstunde => {if (Object.keys(arbeitsstunde).length) this.arbeitsstunde = arbeitsstunde});
    },
    registerArbeitsstundenEvent() {
      if (!this.arbeitsstundeEvent) this.arbeitsstundeEvent = this.$nuxt.$on('arbeitsstunden-sync', this.arbeitsstundenErneuern);
    },
    arbeitsstundenErneuern(event) {
      if (this.getArbeitsstunde) {
        if (event && this.getArbeitsstunde == event.id) this.arbeitsstunde = event;
        else  this.arbeitsstundeAbrufen(this.getArbeitsstunde);
      }
      if (this.getArbeitsstunden) this.arbeitsstundenAbrufen();
      if (this.getArbeitsstundenAenderungen) this.arbeitsstundenAenderungenAbrufen();
    },


    arbeitszeitLoeschenDurchfuehren(arbeitszeit) {
      let arbeitsstunde = this.$helpers.copy(arbeitszeit);
      arbeitsstunde.deleted_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');
      this.speichernArbeitsstunde(arbeitsstunde);
    },
    arbeitszeitLoeschen(arbeitszeit) {
      this.$confirm(
        {
          message: `Möchtest du die gewählte Arbeitszeit am ` +this.$helpers.datumFormatieren(arbeitszeit.von) + ' wirklich löschen? Dadurch werden keine Therapien gelöscht.',
          button: {
            no: 'Nicht löschen',
            yes: 'Ja löschen'
          },
          callback: confirm => {
            if (confirm) {
              this.arbeitszeitLoeschenDurchfuehren(arbeitszeit);
            }
          }
        }
      )
    }

  },
  data() {
    return {
      arbeitsstunde: null,
      arbeitsstunden: [],
      arbeitsstundenCount: 0,
      arbeitsstundenAenderungen: [],
      getArbeitsstunde: false,
      getArbeitsstunden: false,
      getArbeitsstundenAenderungen: false,
      arbeitsstundeEvent: false,
      arbeitsstundenFilter: null,
      arbeitsstundenMonat: this.$moment().format('YYYY-MM'),
      arbeitsstundenkey: 0
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('arbeitsstunden-sync', this.arbeitsstundenErneuern);
    this.arbeitsstundeEvent = null;
  },
}
