<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div>
        <kalender-global-navigation></kalender-global-navigation>
      </div>
      <div>
        <termin-formular></termin-formular>
      </div>
    </div>
    <div class="card">
      <div class="kalender-wrapper">
        <div class="kalender-body" ref="kalenderBody">
          <!-- dafür da, dass Absolute possitioniertes nach Oben eine Begrenzung hat -->
          <div class="topspacer">
            <div v-if="aktuelleUhrzeitMoment && aktuelleUhrzeitMoment.unix() > vonTimestamp && aktuelleUhrzeitMoment.unix() < bisTimestamp" class="aktuelleZeit" :style="'top: '+((topAbstand($moment().toISOString())))+'px;'"><div class="uhrzeit-linie"></div></div>
          </div>
          <kalender-hintergrund></kalender-hintergrund>
        </div>
      </div>
      <kalender-presets></kalender-presets>

      <kalender-termin-absage></kalender-termin-absage>
    </div>
  </div>
</template>
<script>
import KalenderHintergrund from '~/components/kalender/KalenderHintergrund';
import KalenderPresets from '~/components/kalender/KalenderPresets';
import KalenderGlobalNavigation from '~/components/kalender/KalenderGlobalNavigation';
import KalenderTerminAbsage from '~/components/kalender/TerminAbsage';
import TerminFormular from '~/components/kalender/TerminFormular';
import kalender from '~/mixins/kalender';
import kontakte from '~/mixins/kontakte';
export default {
  mixins: [kalender, kontakte],
  components: {KalenderHintergrund, KalenderGlobalNavigation, TerminFormular, KalenderPresets, KalenderTerminAbsage},
  data() {
    return {
      aktuelleUhrzeitUnix: 0,
      aktuelleUhrzeitMoment: null,
      erneuernInterval: null
    }
  },
  computed: {

  },
  methods: {
    aktuelleUhrzeitErneuern() {
      this.aktuelleUhrzeitMoment = this.$moment().year(1970).dayOfYear(1);

      this.erneuernInterval = setInterval(() => {
        this.aktuelleUhrzeitErneuern()
      }, 300000);
    },
    kalenderHoeheSetzen() {
      this.$nextTick(() => {
        if (!this.$refs.kalenderBody) return 0;
        this.$store.commit('kalender/setKalenderHoehe', this.$refs.kalenderBody.clientHeight);
      })
    },
    // Mit eigener KW
    async kalenderFuellen() {
      await this.kontaktAbrufenFilter({model_type: this.$auth.user.model_type, model_id: this.$auth.user.model_id});

      for (let index = 0; index < 5; index++) {
        let addSpalte = this.$helpers.copy(this.leereSpalte);
        addSpalte.id = this.$uuid.v1();
        addSpalte.object = this.$auth.user.mitarbeiter;
        addSpalte.model_id = this.$auth.user.model_id;
        addSpalte.model_type = "Mitarbeiter";
        addSpalte.titel = this.kontakt.nachname+', '+this.kontakt.vorname;
        addSpalte.filter.mitarbeiter_id.push(this.$auth.user.model_id);
        addSpalte.datum = this.$moment().startOf('isoweek').add(index, 'days').format('YYYY-MM-DD');
        this.speichernSpalte(addSpalte, false);
      }
      this.getApiData();
    }
  },
  mounted() {
    this.aktuelleUhrzeitErneuern();
    this.kalenderHoeheSetzen();
    this.$nuxt.$on('kalendertermine-geladen', this.kalenderHoeheSetzen);
    this.kalenderFuellen();
  },
  beforeDestroy() {
    clearInterval(this.erneuernInterval);
    this.$nuxt.$off('kalendertermine-geladen', this.kalenderHoeheSetzen);
  }
}
</script>
