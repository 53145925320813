export default {
  methods: {
    suchen(array, suchbegriff) {
      if (!array || !array.length) return [];
      if (!suchbegriff) return array;
      let keys = Object.keys(array[0]);
      let suchbegriffe = suchbegriff.split(' ');
      // Durch alle Items iterieren
      let filtered = array.filter(item => {
        // Alle Keys durchsuchen und Suchbegriffe einzeln suchen
        this.suchenObject(item, suchbegriffe, keys);
      });
      return filtered;
    },
    suchenObject(item, suchbegriffe, keys = null) {
      let gefunden = true;
      if (!keys) keys = Object.keys(item);
      _.forEach(suchbegriffe, begriff => {
        if (!this.searchHelper(item, keys, begriff)) gefunden = false;
      })
      return gefunden;
    },
    searchHelper(item, keys, suchbegriff) {
      let check = false;
      _.forEach(keys, key => {
        if (item[key] && typeof item[key] == 'string' && (item[key].toLowerCase().includes(suchbegriff.toLowerCase()) || item[key].toLowerCase().replace(' ', '').includes(suchbegriff.toLowerCase()))) {
          check = true;
        }
        // Rekursiv in die Tiefe
        else if (item[key] && item[key] && item[key] != null && typeof item[key] == 'object') {
          let keysSub = [];
          // Item ist ein JSON Object, kein Array
          if (!item[key][0]) {
            keysSub = Object.keys(item[key]);
            if (this.searchHelper(item[key], keysSub, suchbegriff)) check = true;
          }
          else {
            keysSub = Object.keys(item[key][0]);
            // Gibt alle Subitems zurück auf die das zutrifft, wenn Length Größer 0, dann triffts auf ein Subitem zu.
            if (item[key].filter(subitem => {
              return this.searchHelper(subitem, keysSub, suchbegriff);
            }).length > 0) {
              check = true;
            }
          }
        }
      })
      return check;
    }
  }
}
