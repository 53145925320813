<template>
    <div class="card">
        <div class="card-body">
            <h3>
              <button class="btn btn-sm btn-outline-dark btn mr-1" @click="loadSips();"><span><ion-icon name="refresh-outline"></ion-icon></span></button>
              Durchwahlen
            </h3>
            <table class="table table-sm table-striped table-hover">
              <thead>
                <th></th>
                  <th>Durchwahl</th>
                  <th>Name</th>
              </thead>
              <tbody :key="liste">
                <tr v-if="!$store.getters['sipusers/filled']"><td colspan="100%">Daten werden abgerufen...</td></tr>

                <tr v-for="sip in sipusersFiltered" :key="sip.id">
                  <td>
                    <span v-if="sip.online" class="badge badge-success badge-pill">&nbsp;</span>
                    <span v-else class="badge badge-danger badge-pill">&nbsp;</span>
                  </td>
                  <td>
                    <telefonnummer class="d-block" :telefon="{nummer: sip.did}" :bezeichnung="null" ></telefonnummer>
                  </td>
                  <td>{{sip.name}}</td>
                </tr>

              </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import RufnummerZuordnen from '~/components/mitarbeiter/telefon/RufnummerZuordnen';

import sipusers from '~/mixins/sipusers';
import suche from '~/mixins/suche';
export default {
  mixins: [sipusers, suche],
  components: {RufnummerZuordnen},
  data() {
    return {
      suchbegriff: '',
      liste: 0
    }
  },
  computed: {
    sipusersFiltered() {
      return this.suchen(this.sipusers, this.suchbegriff);
    }
  }
}
</script>
