
import verordnungsaenderungen from '~/mixins/verordnungsaenderungen';
import heilmittelkatalog from '~/mixins/heilmittelkatalog';
import prefills from '~/mixins/prefills';
export default {
  mixins: [verordnungsaenderungen, prefills, heilmittelkatalog],
  methods: {
    verordnungPruefen(model) {
      let aenderungen = this.felderPrefill(model);
      let info = []
      // ICD-10 Code fehlt, ist nur bei nicht Zahnarzt pflicht
      if (!model.icd_10_code && model.verordnungstyp != 1) {
        let feld = this.$helpers.findByKey(aenderungen, 'icd_10_code', 'feld');
        feld.soll = 'Bitte füllen';
        feld.aendern = true;
        info.push("Der ICD-10 Code muss gefüllt sein");
      }

      // // Leitsymptomatik fehlt
      // if (model.leitsymptomatik) {
      //   let angekreuzt = false;
      //   _.forEach(model.leitsymptomatik, (val, index) => {
      //     if (val) angekreuzt = true;
      //   });
      //   if (!angekreuzt) {
      //     let feld = this.$helpers.findByKey(aenderungen, 'leitsymptomatik', 'feld');
      //     feld.soll = '';
      //     feld.aendern = true;
      //     info.push("Es muss eine Leitsymptomatik angekreuzt sein");
      //   }
      // }

      // Diagnose fehlt bei Zahnarzt
      if (model.verordnungstyp == 1 && !model.individuelle_leitsymptomatik) {
        let feld = this.$helpers.findByKey(aenderungen, 'individuelle_leitsymptomatik', 'feld');
        feld.soll = 'Bitte füllen';
        feld.aendern = true;
        info.push("Es muss eine Diagnose angegeben werden");
      }

      // Therapiefrequenz in Logo und Ergo einfach die maximale Menge nehmen, also 1-3
      // if (!model.therapiefrequenz && model.verordnungstyp != 1 && !model.fachbereich) {
      //     let feld = this.$helpers.findByKey(aenderungen, 'therapiefrequenz', 'feld');
      //     feld.soll = '1-3x';
      //     feld.aendern = true;
      //     info.push("Es muss eine Therapiefrequenz angegeben werden");
      // }

      // Diagnosegruppe fehlt
      if (!model.indikationsschluessel) {
        let feld = this.$helpers.findByKey(aenderungen, 'indikationsschluessel', 'feld');
        feld.soll = 'Bitte füllen';
        feld.aendern = true;
        info.push("Die Diagnosegruppe (Bei Zahnarzt Indikationsschlüssel) muss gefüllt sein");
      }
      // Diagnosgruppe ist da und richtig?
      else {
        // Diagnosegruppe muss eine aus der Liste sein, jeweils für Ergo, Logo oder alle, falls kein Fachbereich
        let find = null;
        if (model.fachbereich == 1) find = this.indikationsschluesselPrefillsErgo.includes(model.indikationsschluessel)
        else if (!model.fachbereich || model.fachbereich == 0) find = this.indikationsschluesselPrefillsLogo.includes(model.indikationsschluessel)
        else find = this.indikationsschluesselPrefills.includes(model.indikationsschluessel)

        if (!find) {
          let feld = this.$helpers.findByKey(aenderungen, 'indikationsschluessel', 'feld');
          feld.soll = '';
          feld.aendern = true;
          info.push("Die Diagnosegruppe ist in diesem Fachbereich nicht erlaubt");
        }
        else {
          // entsprechenden Bereich aus Heilmittelkatalog abrufen
          let katalog = this.katalog(model);
          if (model.heilmittel) {
            let erlaubteErgaenzendeHeilmittel = [];
            _.forEach(model.heilmittel, (heilmittel) => {
                // Prüfen ob das Heilmittel bei der Indikation erlaubt ist
                if (katalog.heilmittel.einzel.heilmittel.indexOf(heilmittel.heilmittel) == -1) {
                  // Heilmittel nicht erlaubt
                  let feld = this.$helpers.findByKey(aenderungen, 'heilmittel', 'feld');
                  feld.soll = 'Heilmittel ändern auf '+ katalog.heilmittel.einzel.heilmittel;
                  feld.aendern = true;
                  let infotext = "Das Gewählte Heilmittel ist bei dieser Diagnosegruppe nicht erlaubt.";
                  if (katalog.heilmittel.einzel.heilmittel.length == 0) infotext += " Erlaubt wäre nur: "+katalog.heilmittel.einzel.heilmittel;
                  else infotext += " Erlaubt wären: "+katalog.heilmittel.einzel.heilmittel;
                  info.push(infotext);
                }

                // Heilmittel aus Katalog
                let verfuegbaresHeilmittel = this.findHeilmittelByString(heilmittel.heilmittel)

                // Ergänzende Heilmittel die erlaubt sind
                if (verfuegbaresHeilmittel.ergaenzendeHeilmittel) erlaubteErgaenzendeHeilmittel = erlaubteErgaenzendeHeilmittel.concat(verfuegbaresHeilmittel.ergaenzendeHeilmittel)

                // Prüfen ob die Dauer erlaubt ist
                // Dauer prüfen nur für Logo
                if (!heilmittel.dauer || verfuegbaresHeilmittel.dauer.indexOf(heilmittel.dauer) == -1 && (model.fachbereich == 0 || !model.fachbereich)) {
                  // Dauer nicht erlaubt
                  let feld = this.$helpers.findByKey(aenderungen, 'heilmittel', 'feld');
                  feld.aendern = true;
                  if (!feld.soll) feld.soll = 'Dauer ändern auf 45';
                  else feld.soll += '\nDauer ändern auf 45';
                  info.push("Die Dauer sollte immer mindestens 45 Minuten betragen");
                }

              });
            // Ergänzendes Heilmittel prüfen
            if (model.ergaenzendes_heilmittel && model.ergaenzendes_heilmittel.heilmittel) {
              if (erlaubteErgaenzendeHeilmittel.indexOf(model.ergaenzendes_heilmittel.heilmittel) == -1) {
                // Ergänzendes Heilmittel nicht erlaubt
                let feld = this.$helpers.findByKey(aenderungen, 'ergaenzendes_heilmittel', 'feld');
                feld.aendern = true;
                if (!feld.soll) feld.soll = 'Ergänzendes Heilmittel nicht erlaubt';
                else feld.soll += '\nErgänzendes Heilmittel nicht erlaubt';
                info.push("Das ergänzende Heilmittel ist in Kombination mit diesem Heilmittel nicht erlaubt");
              }
            }


            // Verordnungsmenge überhaupt gefüllt
            if (this.verordnungsmenge(model) == 0) {
              let feld = this.$helpers.findByKey(aenderungen, 'heilmittel', 'feld');
              feld.aendern = true;
              if (!feld.soll) feld.soll = 'Behandlungseinheiten: '+this.verordnungMaximaleMenge(model);
              else feld.soll += '\nBehandlungseinheiten: '+this.verordnungMaximaleMenge(model);
              info.push("Die Verordnungsmenge fehlt. Maximal auf alle Heilmittel verteilt: "+this.verordnungMaximaleMenge(model));
            }
            // Maximale Verordnungsmenge prüfen
            // Muss nicht geprüft werden, einfach maximum erlauben
            // if (model.verordnungsmenge > katalog.maximale_verordnungsmenge) {
            //   let feld = this.$helpers.findByKey(aenderungen, 'heilmittel', 'feld');
            //   feld.aendern = true;
            //   if (!feld.soll) feld.soll = 'Die Summe der Behandlungseinheiten darf '+katalog.maximale_verordnungsmenge+ ' nicht überschreiten';
            //   else feld.soll += '\nDie Summe der Behandlungseinheiten darf '+katalog.maximale_verordnungsmenge+ ' nicht überschreiten';
            //   info.push("Die Verordnungsmenge passt nicht. Maximal: "+katalog.maximale_verordnungsmenge);
            // }
          }
        }
      }
      return {info: info, aenderungen: aenderungen};
    },
    sonderfall(model) {
      if (!model.icd_10_code || !model.indikationsschluessel) return false;
      let katalog = this.katalog(model);
      if ((katalog.langfristiger_heilmittelbedarf.indeOf(model.icd_10_code) || katalog.besonderer_verordnungsbedarf.indeOf(model.icd_10_code))) {
        // Es ist besonderer Behandlungsbedarf
        return true;
      }
      return false;
    }
  },
}
