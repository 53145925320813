import _ from 'lodash';
import lokiHelper from '~/mixins/lokiHelper';
import dbHelper from '~/mixins/dbHelper';
import suche from '~/mixins/suche';
export default {
  mixins: [lokiHelper, suche],
  methods: {
    loadTherapiestunden(manuell = false) {
      this.$store.dispatch('therapiestunden/sync', manuell);
    },
    resetTherapiestunden() {
      this.$store.commit('therapiestunden/reset');
      this.loadTherapiestunden(true);
    },
    async speichernTherapiestunde(model) {
      this.$store.dispatch('therapiestunden/speichern', model);
      setTimeout(() => {
        this.loadTherapiestunden();
      }, 1000);
    },
    therapiestundeLoeschen(stunde) {
      this.$confirm(
        {
          message: `Möchtest du die Stunde vom ` +this.$helpers.datumFormatieren(stunde.von) + ' wirklich löschen? Deine Arbeitszeit bleibt unverändert.',
          button: {
            no: 'Nicht löschen',
            yes: 'Ja löschen'
          },
          callback: confirm => {
            if (confirm) {
              this.$store.dispatch('therapiestunden/loeschen', stunde);
              setTimeout(() => {
                this.loadTherapiestunden();
              }, 1000);
            }
          }
        }
      )
    },

    async therapiestundenAbrufen() {
      this.getTherapiestunden = true;
      this.registerTherapiestundenEvent();
      if (!this.suchbegriff) {
        let elemente = await this.getElemente('therapiestunden', this.therapiestundenFilter);
        this.therapiestunden = this.$helpers.sortArray(elemente, 'von', 'desc');
        this.therapiestundenCount = this.therapiestunden.length;
        return;
      }

      // therapiestunden suchen
      let therapiestundenCollection = await dbHelper.getDb('therapiestunden');
      this.therapiestunden = await therapiestundenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    therapiestundenAenderungenAbrufen() {
      this.getTherapiestundenAenderungen = true;
      this.registerTherapiestundenEvent();
      return this.getAenderungen('therapiestunden').then(data => this.therapiestundenAenderungen = data);
    },
    therapiestundeAbrufen(id) {
      this.getTherapiestunde = id
      this.registerTherapiestundenEvent();
      return this.getElement('therapiestunden', {id: id}).then(therapiestunde => {if (Object.keys(therapiestunde).length) this.therapiestunde = therapiestunde});
    },
    registerTherapiestundenEvent() {
      if (!this.therapiestundeEvent) this.therapiestundeEvent = this.$nuxt.$on('therapiestunden-sync', this.therapiestundenErneuern);
    },
    therapiestundenErneuern(event) {
      if (this.getTherapiestunde) {
        if (event && this.getTherapiestunde == event.id) this.therapiestunde = event;
        else  this.therapiestundeAbrufen(this.getTherapiestunde);
      }
      if (this.getTherapiestunden) this.therapiestundenAbrufen();
      if (this.getTherapiestundenAenderungen) this.therapiestundenAenderungenAbrufen();
    },


  },
  created() {
    this.standTherapiestunden;
  },
  mounted() {
    if (!this.$store.getters['therapiestunden/filled']) this.loadTherapiestunden();
  },
  computed: {
    standTherapiestunden: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['therapiestunden/getStand'];
      let jetzt = new Date();

      // if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadTherapiestunden();
      return stand;
    }
  },
  data() {
    return {
      therapiestunde: null,
      therapiestunden: [],
      therapiestundenCount: 0,
      therapiestundenAenderungen: [],
      getTherapiestunde: false,
      getTherapiestunden: false,
      getTherapiestundenAenderungen: false,
      therapiestundeEvent: null,
      therapiestundenFilter: null,
      therapiestundenOrderby: ['von', 'desc'],
    }
  },
  beforeDestroy() {
    this.$nuxt.$off('therapiestunden-sync', this.therapiestundenErneuern);
    this.therapiestundeEvent = null;
  },
}
