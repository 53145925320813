
<template>
  <b-modal :id="id" :title="(faq && faq.titel) ? faq.titel : 'FAQ'" hide-footer size="xl" >
      <div class="faq-modal" v-if="faq && faq.text">
        <v-runtime-template :template="'<div>'+faq.text+'</div>'" />
      </div>

  </b-modal>
</template>
<script>
import VRuntimeTemplate from "v-runtime-template";
export default {
  props: ['faq', 'id'],
  components: {VRuntimeTemplate}
}
</script>
