<template>
    <div class="form-group"><input v-shortkey.focus="['meta', 'f']" class="form-control" type="text" placeholder="Suchen..." v-model="suchbegriff" @input="suchen" @focus="$emit('focus', $event)"></div>
</template>

<script>
    export default {
        props: ['value'],
        data() {
            return {
                suchbegriff: this.value
            }
        },
        methods: {
            suchen() {
                this.$emit('input', this.suchbegriff);
            }
        }
    }
</script>
