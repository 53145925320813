<template>
<div>
  <!-- <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" id="therapie-formular-details">
      <legend>Ausfallstunde</legend>
      <label class="col-2 col-form-label" :for="'patient'+id">Patient</label>
      <div class="col">
        <patient-auswahl :isinvalid="(showError && !ausfallstunde.patient_id)" v-model="ausfallstunde.patient_id" :id="'patient'+id" @input="patientAuswahl()"></patient-auswahl>
      </div>
  </div>
  <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" v-if="ausfallstunde.patient_id">
      <label class="col-2 col-form-label" :for="'verordnung'+id">Verordnung</label>
      <div class="col">
          <verordnung-auswahl :isinvalid="(showError && !ausfallstunde.verordnung_id)" :patient="ausfallstunde.patient_id" :id="'verordnung'+id" v-model="ausfallstunde.verordnung_id" @input="verordnungAuswahl($event)"></verordnung-auswahl>
      </div>
  </div> -->
  <!-- <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()" v-if="$can('Therapie verwalten')">
      <label class="col-2 col-form-label" :for="'mitarbeiter'+id">Logopäde</label>
      <div class="col">
          <therapeut-auswahl :isinvalid="(showError && !ausfallstunde.mitarbeiter_id)" v-model="ausfallstunde.mitarbeiter_id"></therapeut-auswahl>
      </div>
  </div> -->
  <template v-if="ausfallstunde.verordnung_id">
    <div class="form-group row" @keyup.enter="$refs.wizard.nextTab()" v-if="hbErlaubt">
        <label class="col-2 col-form-label" :for="'hausbesuch'+id">Hausbesuch</label>
        <div class="col">
          <label class="col-form-label form-check-label" :for="'hausbesuch'+id">
              <input class="" type="checkbox" value="1" :id="'hausbesuch'+id" v-model="ausfallstunde.hausbesuch">
              Die Ausfallstunde war ein Hausbesuch und du warst bereits vor Ort
          </label>
        </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_von'+id" class="col-2 col-form-label mt-auto">Start</label>
      <div class="col">
        <input type="datetime-local" name="" :id="'therapie_von'+id" v-model="ausfallstunde.von" class="form-control" :class="{'is-invalid': showError && !ausfallstunde.von}" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_von'+id" class="col-2 col-form-label mt-auto">Ende</label>
      <div class="col">
        <input type="datetime-local" disabled name="" :id="'therapie_von'+id" v-model="ausfallstunde.bis" class="form-control" :class="{'is-invalid': showError && !ausfallstunde.bis}" :key="biskey" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group" @keyup.enter="$refs.wizard.nextTab()">
      <label :for="'therapie_dauer'+id" class="col-2 col-form-label mt-auto">Dauer</label>
      <div class="col">
        <input type="number" step="1" :id="'therapie_dauer'+id" v-model="ausfallstunde.dauer" class="form-control" placeholder="Dauer in Minuten" :class="{'is-invalid': showError && !ausfallstunde.dauer}" @change="$emit('change-zeit'); bisBerechnen()">
      </div>
    </div>
    <div class="row form-group">
      <label for="" class="col-2 col-form-label mt-auto">Begründung</label>
      <div class="col">
        <textarea-autosize
            v-if="!$isIOS()"
            class="form-control"
            placeholder="Begründung für den Ausfall"
            v-model="ausfallstunde.begruendung"
            rows="1"
            :min-height="35"
          />
          <textarea
            v-if="$isIOS()"
            class="form-control"
            v-model="ausfallstunde.begruendung"
            placeholder="Begründung für den Ausfall"
            rows="10">
        </textarea>
      </div>
    </div>
    <div class="row" @keyup.enter="$refs.wizard.nextTab()">
        <label class="col-2 col-form-label" :for="'kurzfristig'+id">Kurzfristig</label>
        <div class="col">
          <label class="col-form-label form-check-label" :for="'kurzfristig'+id">
              <input class="" type="checkbox" value="1" :id="'kurzfristig'+id" v-model="ausfallstunde.kurzfristig" @change="kurzfristig()">
              Die Stunde wurde kurzfristig (weniger als 24h vorher) abgesagt
          </label>
        </div>
    </div>
    <div class="row" @keyup.enter="$refs.wizard.nextTab()" v-if="ausfallstunde.kurzfristig">
        <label class="col-2 col-form-label" :for="'berechnen'+id">Berechnen</label>
        <div class="col">
          <label class="col-form-label form-check-label" :for="'berechnen'+id">
              <input class="" type="checkbox" value="1" :id="'berechnen'+id" v-model="ausfallstunde.berechnen">
              Die Stunde soll dem Patienten in Rechnung gestellt werden
          </label>
        </div>
    </div>
  </template>
</div>
</template>
<script>
import patienten from '~/mixins/patienten';
import verordnungen from '~/mixins/verordnungen';
import mitarbeiter from '~/mixins/mitarbeiter';

export default {
  mixins: [patienten, verordnungen, mitarbeiter],
  props: ['value', 'showError', 'id', 'neu', 'verordnungProp'],
  watch: {
    ausfallstunde: function(neu, alt) {
      this.bisBerechnen();
      this.$emit('input', neu);
    },
  },
  data() {
    return {
      ausfallstunde: {},
      mitarbeiterKey: 0,
      verordnungenKey: 0,
      biskey: 0,
      stopAll: false
    }
  },
  methods: {
    fillChange(neu) {
      if (this.stopAll) return;
      let ausfallstunde = this.$helpers.copy(neu);
      if (ausfallstunde == null) ausfallstunde = { };
      else if (ausfallstunde.patient_id) {
        this.ausfallstunde = ausfallstunde;
        this.patientAuswahl();
        if (this.ausfallstunde.verordnung_id && !this.ausfallstunde.updated_at) this.verordnungAuswahl(this.ausfallstunde.verordnung_id);
      }
      if (!this.ausfallstunde.id) this.ausfallstunde.id = this.$uuid.v1();
    },
    async patientAuswahl() {
      await this.patientAbrufen(this.ausfallstunde.patient_id)
      let patientTherapeuten = this.$helpers.make_array(this.patient.mitarbeiter_id);
      // Wenn die Ausfallstunde keinen Therapeuten hat, und der User Therapeut ist, wird der User automatich Therapeut
      if (this.$istTherapeut() && !this.ausfallstunde.mitarbeiter_id) this.ausfallstunde.mitarbeiter_id = this.$auth.user.mitarbeiter.id;
      // Wenn der Patient nur einen Therapeuten hat, dann den setzen
      else if (patientTherapeuten.length == 1 && patientTherapeuten[0] != this.ausfallstunde.mitarbeiter_id && !this.$istTherapeut()) this.ausfallstunde.mitarbeiter_id = patientTherapeuten[0];
    },
    async verordnungAuswahl(verordnung) {
      if (this.verordnungProp && this.verordnungProp.id == verordnung) {
        this.verordnung = this.verordnungProp;
      }
      else {
        this.ausfallstunde.verordnung_id = verordnung;
        await this.verordnungAbrufen(this.ausfallstunde.verordnung_id);
      }
      if (!this.ausfallstunde.dauer && this.verordnung) {
        this.ausfallstunde.dauer = this.verordnung.therapiedauer;
      }
      this.verordnungenKey++;
    },
    bisBerechnen() {
      if (this.ausfallstunde.von) this.ausfallstunde.von = this.$moment(this.ausfallstunde.von).format("YYYY-MM-DDTHH:mm");
      if (this.ausfallstunde.von && this.ausfallstunde.dauer) this.ausfallstunde.bis = this.$moment(this.ausfallstunde.von).add(this.ausfallstunde.dauer, 'minutes').format("YYYY-MM-DDTHH:mm");
      else this.ausfallstunde.bis = null;
      this.biskey++;
    },
    kurzfristig() {
      if (this.ausfallstunde.kurzfristig) this.ausfallstunde.berechnen = true;
      else this.ausfallstunde.berechnen = false;
    }
  },
  computed: {
    hbErlaubt() {
      if (!this.ausfallstunde || !this.ausfallstunde.verordnung) return false;
      if (this.ausfallstunde.verordnung.hausbesuch || this.ausfallstunde.hausbesuch) {
        if (!this.ausfallstunde.updated_at) this.ausfallstunde.hausbesuch = true;
        return true;
      }
      this.hb = false;
      return false;
    }
  },
  mounted() {
    this.fillChange(this.value);
  },
  beforeDestroy() {
    this.stopAll = true;
  }
}
</script>
