import _ from 'lodash';
import kontakte from '~/mixins/kontakte';
import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
import lokiHelper from '~/mixins/lokiHelper';
export default {
  mixins: [kontakte, suche, lokiHelper],
  methods: {
    loadAerzte(manuell = false) {
      this.$store.dispatch('aerzte/sync', manuell);
    },
    resetAerzte() {
      this.$store.commit('aerzte/reset');
      this.loadAerzte(true);
    },
    findFachgebiet(id) {
      return this.$helpers.findByKey(this.fachgebieteArzt, id);
    },
    async speichernAerzte(model) {
      this.$store.dispatch('aerzte/speichern', model);
      setTimeout(() => {
        this.loadAerzte();
      }, 1000);
    },
    async aerzteAbrufen() {
      this.getAerzte = true;
      this.registerAerzteEvent();
      if (!this.suchbegriff) {
       this.aerzte = await this.getElemente('aerzte', this.aerzteFilter);
       this.aerzteCount = this.aerzte.length;
       return;
      }

      // Kontakte mit Suchbegriff suchen
      let kontakteIds = await this.kontakteSuchen('model_id', 'Arzt');
      // Fachgebiete Suchen
      let fachgebieteSuche = this.fachgebieteArzt.filter(obj => {
        return this.suchenObject(obj, this.suchbegriff.split(' '));
      }).map(obj => {return obj.id})
      // Ärzte suchen anhand Fachgebiete und Kontakte
      let aerzteCollection = await dbHelper.getDb('aerzte');
      this.aerzte = await aerzteCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')) || kontakteIds.includes(obj.id) || fachgebieteSuche.includes(Number(obj.fachgebiet_id)))}
      );
    },
    aerzteAenderungenAbrufen() {
      this.getAerzteAenderungen = true;
      this.registerAerzteEvent();
      return this.getAenderungen('aerzte').then(data => this.aerzteAenderungen = data);
    },
    arztAbrufen(id) {
      this.getArzt = id
      this.registerAerzteEvent();
      return this.getElement('aerzte', {id: id}).then(arzt => {if (Object.keys(arzt).length) this.arzt = arzt});
    },
    registerAerzteEvent() {
      if (!this.arztEvent) this.arztEvent = this.$nuxt.$on('aerzte-sync', this.aerzteErneuern);
    },
    aerzteErneuern(event) {
      if (this.getArzt) {
        if (event && this.getArzt == event.id) this.arzt = event;
        else this.arztAbrufen(this.getArzt);
      }
      if (this.getAerzte) this.aerzteAbrufen();
      if (this.getAerzteAenderungen) this.aerzteAenderungenAbrufen();
    },
  },
  created() {
    this.standAerzte;
  },
  mounted() {
    if (!this.$store.getters['aerzte/filled']) this.loadAerzte();
  },
  beforeDestroy() {
    this.$nuxt.$off('aerzte-sync', this.aerzteErneuern);
    this.arztEvent = null;
  },
  computed: {
    standAerzte: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['aerzte/getStand'];

      let jetzt = new Date();
      if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadAerzte();
      return stand;
    },
    fachgebieteArzt: function() {
      return this.$helpers.sortArray([
        {id: 1, name: 'Allgemeinmedizin'},
        {id: 2, name: 'Hals-Nasen-Ohren-Heilkunde'},
        {id: 3, name: 'Innere Medizin '},
        {id: 4, name: 'Kieferorthopädie'},
        {id: 5, name: 'Kinder- und Jugendmedizin (Pädiatrie)'},
        {id: 6, name: 'Mund-Kiefer-Gesichts-Chirurgie'},
        {id: 7, name: 'Neurologie'},
        {id: 8, name: 'Pädaudiologie'},
        {id: 9, name: 'Phoniatrie'},
        {id: 10, name: 'Zahnmedizin'},
        {id: 11, name: 'Anästhesiologie'},
        {id: 12, name: 'Anatomie'},
        {id: 13, name: 'Arbeitsmedizin'},
        {id: 14, name: 'Augenheilkunde'},
        {id: 15, name: 'Biochemie'},
        {id: 16, name: 'Chirurgie'},
        {id: 17, name: 'Frauenheilkunde und Geburtshilfe'},
        {id: 18, name: 'Haut- und Geschlechtskrankheiten'},
        {id: 19, name: 'Humangenetik'},
        {id: 20, name: 'Hygiene und Umweltmedizin'},
        {id: 21, name: 'Kinder- und Jugendpsychiatrie und -psychotherapie'},
        {id: 22, name: 'Laboratoriumsmedizin'},
        {id: 23, name: 'Mikrobiologie, Virologie und Infektionsepidemiologie'},
        {id: 24, name: 'Mund-Kiefer-Gesichtschirurgie'},
        {id: 25, name: 'Neurochirurgie'},
        {id: 26, name: 'Nuklearmedizin'},
        {id: 27, name: 'Öffentliches Gesundheitswesen'},
        {id: 28, name: 'Pathologie'},
        {id: 29, name: 'Pharmakologie'},
        {id: 30, name: 'Physikalische und Rehabilitative Medizin'},
        {id: 31, name: 'Physiologie'},
        {id: 32, name: 'Psychiatrie und Psychotherapie'},
        {id: 33, name: 'Psychosomatische Medizin und Psychotherapie'},
        {id: 34, name: 'Radiologie'},
        {id: 35, name: 'Rechtsmedizin'},
        {id: 36, name: 'Strahlentherapie'},
        {id: 37, name: 'Transfusionsmedizin'},
        {id: 38, name: 'Urologie'},
      ], 'name');
    }
  },
  data() {
    return {
      arzt: null,
      aerzte: [],
      aerzteCount: 0,
      aerzteAenderungen: [],
      getArzt: false,
      getAerzte: false,
      getAerzteAenderungen: false,
      arztEvent: false,
      aerzteFilter: null,
      orderbyAerzte: ['name', 'asc']
    }
  }
}
