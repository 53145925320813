<template>
  <b-nav-item-dropdown text="Arbeitszeiten" right v-if="ma">
    <div class="p-1" style="width: 600px">
      <h1>Arbeitszeiten</h1>
      <mitarbeiter-stundenuebersicht :maProp="ma"></mitarbeiter-stundenuebersicht>
    </div>
  </b-nav-item-dropdown>
  <b-nav-item-dropdown disabled text="Arbeitszeiten" right v-else>
    Mitarbeiter noch nicht synchronisiert
  </b-nav-item-dropdown>
</template>
<script>
import MitarbeiterStundenuebersicht from '~/components/mitarbeiter/mitarbeiter/profil/stundenuebersicht';
import mitarbeiter from '~/mixins/mitarbeiter';
export default {
  components: {MitarbeiterStundenuebersicht},
  mixins: [mitarbeiter],
  data() {
    return {
      displayed: false
    }
  },
  mounted() {
    this.maAbrufen(this.$auth.user.model_id);
  }
}
</script>
