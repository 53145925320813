<template>
    <span v-if="telefon.nummer">
      <span v-if="bezeichnung !== null" class="badge badge-secondary">{{(telefon.bezeichnung) ? telefon.bezeichnung : 'Unbekannt'}}</span> <a  role="button" class="btn-link" @click="nummerclick(telefon.nummer)" >{{telefon.nummer}}</a>
      <b-modal :id="id" :title="((telefon.bezeichnung) ? (telefon.bezeichnung+': ') : '') +telefon.nummer" hide-footer>
        <div class="row mb-4" v-if="$can('Telefonanrufe starten')">
          <div class="col offset-1">
          <legend>Anruf über Placetel starten mit</legend>
            <template v-for="user in sipusersOnline">
              <button @click="anrufStarten(user)" class="btn btn-outline-primary btn-block" :key="user.id">{{ user.name }}</button>
            </template>
          </div>
          <div class="col-1"></div>
        </div>

        <button @click="copy(telefon.nummer)" class="btn btn-outline-primary btn-block">Nummer kopieren</button>
        <a :href="'tel:'+telefon.nummer" class="btn btn-outline-primary btn-block">Anruf über Standardprogramm starten</a>


        <input type="text" ref="copyelement" style="position: absolute; left: -99999px">
      </b-modal>
    </span>
</template>

<script>
import sipusers from '~/mixins/sipusers';
export default {
    props: ['telefon', 'bezeichnung', 'emitable'],
    mixins: [sipusers],
    data() {
      return {
        id: 'telefon-'+this._uid,
        displaycopy: false
      }
    },
    methods: {
      nummerclick(nummer) {
        // Entweder Emit oder Call Starten Modal, jenachdem ob der Listener da ist
        if (!this.emitable) this.$bvModal.show(this.id);
        else this.$emit('nummerclick', nummer);
      },
      copy(nummer) {
        let el = this.$refs.copyelement;
        el.value = nummer;
        el.select();
        document.execCommand('copy');
      },
      anrufStarten(user) {
        this.$axios.post('{placetel}/v1/anrufe', {from: user.sipuid, to: this.telefon.nummer}).then(response => {
          this.$notifySuccess('Anruf gestartet', 'Der Telefonanruf wurde gestartet.');
        }, error => {this.$notifyError('Fehler', 'Anruf konnte nicht gestartet werden.', error); this.$bvModal.show(this.id);});
        this.$bvModal.hide(this.id);
      }
  }
}
</script>
