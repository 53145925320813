<template>
  <vue-tags-input
    v-if="editable"
      :add-only-from-autocomplete="true"
      :autocomplete-min-length="0"
      :autocomplete-items="cloudtagsDateienInput"
      :tags="tagsAufbereiten(value.tags)"
      @tags-changed="neueTags(value, $event); dateiUpdateTags(value, $event)"
      v-model="tagtext"
    />
    <div v-else>
      <span class="badge badge-primary mx-1" v-for="(tag, index) in value.tags" :key="index">{{tag.name}}</span>
    </div>
</template>
<script>
import VueTagsInput from '@johmun/vue-tags-input';
import cloudtags from '~/mixins/cloudtags';
import model_dateien from '~/mixins/model_dateien';
export default {
  components: {VueTagsInput},
  mixins: [cloudtags, model_dateien],
  props: {
    value: Object,
    'editable': {
      default: true
    },
  },
  data() {
    return {
      tagtext: ''
    }
  }
}
</script>
