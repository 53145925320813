export const state = () => ({
  user_access_token: "",
  impersonate: false
})

const mutations = {
  cacheclear(state) {
    state.user_access_token = "";
    state.impersonate = false;
  },
  set(state, accessToken) {
    state.user_access_token = accessToken;
    state.impersonate = true;
  },
  leave(state) {
    state.impersonate = false;
  }
}

const getters = {
  isImpersonated(state) {
    return state.impersonate;
  },
  getUserToken(state) {
    return state.user_access_token;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
