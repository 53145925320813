import suche from '~/mixins/suche';
import dbHelper from '~/mixins/dbHelper';
import lokiHelper from '~/mixins/lokiHelper';
import _ from 'lodash';
export default {
  mixins: [suche, lokiHelper],
  methods: {
    // findAenderung(id) {
    //   return this.$helpers.findByKey(this.aenderungen, id);
    // },
    loadVerordnungsaenderungen(manuell = false) {
      if (!this.$can('Verordnungsänderungen verwalten')) return;
      this.$store.dispatch('verordnungsaenderungen/sync', manuell);
    },
    resetVerordnungsaenderungen() {
      this.$store.commit('verordnungsaenderungen/reset');
      this.loadVerordnungsaenderungen(true);
    },
    async speichernAenderungen(model) {
      this.$store.dispatch('verordnungsaenderungen/speichern', model);
      setTimeout(() => {
        this.loadVerordnungsaenderungen();
      }, 1000);
    },
    async aenderungErledigt(model) {
      let aenderung = this.$helpers.copy(model);
      aenderung.erledigt = this.$moment().format('YYYY-MM-DD');
      this.speichernAenderungen(aenderung);
    },
    aenderungName(aenderung) {
      return this.$helpers.findByKey(this.moeglicheAenderungen, aenderung, 'feld').name;
    },

    async verordnungsaenderungenAbrufen() {
      this.getVerordnungsaenderungen = true;
      this.registerVerordnungsaenderungenEvent();
      if (!this.suchbegriff) {
       this.verordnungsaenderungen = await this.getElemente('verordnungsaenderungen', this.verordnungsaenderungenFilter);
       this.verordnungsaenderungenCount = this.verordnungsaenderungen.length;
       return;
      }

      let verordnungsaenderungenCollection = await dbHelper.getDb('verordnungsaenderungen');
      this.verordnungsaenderungen = await verordnungsaenderungenCollection.where((obj) => {
        return (this.suchenObject(obj, this.suchbegriff.split(' ')))}
      );
    },
    verordnungsaenderungenAenderungenAbrufen() {
      this.getVerordnungsaenderungenAenderungen = true;
      this.registerVerordnungsaenderungenEvent();
      return this.getAenderungen('verordnungsaenderungen').then(data => this.verordnungsaenderungAenderungen = data);
    },
    verordnungsaenderungAbrufen(id) {
      this.getVerordnungsaenderung = id
      this.registerVerordnungsaenderungenEvent();
      return this.getElement('verordnungsaenderungen', {id: id}).then(verordnungsaenderung => {if (Object.keys(verordnungsaenderung).length) this.verordnungsaenderung = verordnungsaenderung});
    },
    registerVerordnungsaenderungenEvent() {
      if (!this.verordnungsaenderungEvent) this.verordnungsaenderungEvent = this.$nuxt.$on('verordnungsaenderungen-sync', this.verordnungsaenderungenErneuern);
    },
    verordnungsaenderungenErneuern(event) {
      if (this.getVerordnungsaenderung) {
        if (event && this.getVerordnungsaenderung == event.id) this.verordnungsaenderung = event;
        else  this.verordnungAbrufen(this.getVerordnungsaenderung);
      }
      if (this.getVerordnungsaenderungen) this.verordnungsaenderungenAbrufen();
      if (this.getVerordnungsaenderungenAenderungen) this.verordnungsaenderungenAenderungenAbrufen();
    },

    // verordnungsaenderungenErneuern() {
    //   this.getElemente('verordnungsaenderungen', this.verordnungsaenderungenFilter).then(elemente => {
    //     this.verordnungsaenderungenZusammenbauen(elemente);
    //   }, error => {
    //     this.verordnungsaenderungenErneuern();
    //   });
    // },
    // verordnungsaenderungenZusammenbauen(elemente) {
    //   this.aenderungen = this.$helpers.sortArray(elemente, this.aenderungenOrderby[0], this.aenderungenOrderby[1]);
    //   // this.setErneuern('verordnungsaenderungen', false);
    //   // this.sendErneuernEvent('verordnungsaenderungen', this.aenderungen);
    // },
    // verordnungAenderungen(verordnung) {
    //   // Erlaube ID oder Object als verordnung Input
    //   let verordnungid = (typeof verordnung == 'object') ? verordnung.id : verordnung;
    //   return this.aenderungen.filter((aenderung) => {
    //     return aenderung.verordnung_id == verordnungid;
    //   });
    // },
    felderPrefill(verordnung) {
      let aenderungen = [];
      _.forEach(this.moeglicheAenderungen, aenderung => {
        aenderung.aendern = false;
        if (verordnung) aenderung.ist = verordnung[aenderung.feld];
        aenderung.soll = '';
        aenderungen.push(aenderung);
      });
      return aenderungen;
    },
  },
  created() {
    this.standVerordnungsaenderungen;
  },
  mounted() {
    if (!this.$store.getters['verordnungsaenderungen/filled']) this.loadVerordnungsaenderungen();
  },
  computed: {
    standVerordnungsaenderungen: function() {
      // Wenn älter als x, dann automatisch erneuern
      let stand = this.$store.getters['verordnungsaenderungen/getStand'];
      let jetzt = new Date();

      // if (stand.getTime() < (jetzt.getTime() - process.env.MAXAGE)) this.loadVerordnungsaenderungen();
      return stand;
    },
    moeglicheAenderungen: function() {
      return [
        {feld: 'leitsymptomatik', name: 'Leitsymptomatik'},
        {feld: 'therapiefrequenz', name: 'Therapiefrequenz'},
        {feld: 'icd_10_code', name: 'ICD-10 Code'},
        {feld: 'diagnose', name: 'Diagnose'},
        {feld: 'indikationsschluessel', name: 'Diagnosegruppe'},
        {feld: 'heilmittel', name: 'Heilmittel & Behandlungseinheiten'},
        {feld: 'ergaenzendes_heilmittel', name: 'Ergänzendes Heilmittel'},
        {feld: 'hausbesuch', name: 'Hausbesuch'},
        {feld: 'dringlicher_behandlungsbedarf', name: 'Dringlicher Behandlungsbedarf'},
        {feld: 'freifeld', name: 'Freitext'}
      ]
    }
  },
  data() {
    return {
      verordnungsaenderung: null,
      verordnungsaenderungen: [],
      verordnungsaenderungenCount: 0,
      verordnungsaenderungenAenderungen: [],
      getVerordnungsaenderung: false,
      getVerordnungsaenderungen: false,
      getVerordnungsaenderungenAenderungen: false,
      verordnungsaenderungEvent: null,
      verordnungsaenderungenFilter: null,
      aenderungenOrderby: ['datum', 'desc'],
      verordnungsaenderungenFilter: null
    }
  }
}
