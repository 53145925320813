import {Helpers} from '~/plugins/helpers';

import _ from 'lodash';
export default {
  setAll (state, elemente) {
    state.globals.elemente = elemente;
    state.globals.loading = false;
    state.globals.date = new Date();
  },

  set(state, element) {
    const item = state.globals.elemente.find(el => el.id === element.id)
    if (item) state.globals.elemente.splice(state.globals.elemente.indexOf(item), 1, element)
    else state.globals.elemente.push(element);

    if (state.name) {
      $nuxt.$emit(state.name+'-sync', element);
    }
  },

  getAll(state) {
    if (!Array.isArray(state.globals.elemente)) return state.globals.elemente;
    if ($nuxt && $nuxt.$trashedMode) return state.globals.elemente;
    return state.globals.elemente.filter(el => {
      return (!el.deleted_at || Helpers.make_date(el.deleted_at) > Helpers.make_date(state.globals.date))
    });
  },

  delete (state, element) {
    const item = state.globals.elemente.find(el => el.id === element.id)
    if (item) state.globals.elemente.splice(state.globals.elemente.indexOf(item), 1);
  },

  async load(commit, state, url, notifyBetreff, payload = undefined) {
    if ($nuxt && $nuxt.isOffline) return $nuxt.$notifyError('Abruf nicht möglich. Keine Internetverbindung.');
    // Wenn schon läldt und nicht 3 mal auf neuladen geklickt
    if (state.globals.loading) return;
    commit('loading');
    try {
      let response = null;
      // Lokal eine Zufallslatenz
      let zufallszahl = Math.random()*2000;
      if (process.env.NODE_ENV == 'development') await Helpers.wait(zufallszahl);

      if (payload) response = await $nuxt.$axios.get(url, {params: payload});
      else response = await $nuxt.$axios.get(url);
      commit('setAll', response.data);
      if (state.name) $nuxt.$emit(state.name+'-load', response.data);
      // $nuxt.$notifySuccess(notifyBetreff, 'Die Daten wurden aktualisiert.');
    }
    catch (error) {
      $nuxt.$notifyError(notifyBetreff, 'Die Daten konnten nicht aktualisiert werden.', error);
      setTimeout(() => {
        commit('loading', false);
      }, 5000)
    }
  },

  async speichern(commit, payload, url, notifyBetreff) {
    try {
      let response = await $nuxt.$axios.put(url, payload);
      commit('set', response.data);
      $nuxt.$notifySuccess(notifyBetreff, 'Die Daten wurden aktualisiert.');
      return true;
    } catch (error) {
      $nuxt.$notifyError(notifyBetreff, 'Daten konnten nicht aktualisiert werden.', error);
      return false;
    }
  },
}
