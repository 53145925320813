export const state = () => ({
  debugmode: false,
  trashedmode: false
})

const mutations = {
  setDebug(state, val) {
    state.debugmode = val;
  },
  setTrashed(state, val) {
    state.trashedmode = val;
  }
}

const getters = {
  isDebugmode(state) {
    return state.debugmode;
  },
  isTrashedmode(state) {
    return state.trashedmode;
  }
}

const actions = {

}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
