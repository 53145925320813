<template>
     <b-modal :id="id" :title="'Verordnung'" size="xl"  hide-footer @show="formCounter++">
        <div :key="formCounter">
          <slot></slot>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: ['id'],
    data() {
      return {
        formCounter: 0
      }
    }
}
</script>
