<template>
  <div v-if="loading" class="loading-page" @click="loading = false">
    <p v-if="displayText" class="displayText">{{displayText}}</p>
    <div class="loading"></div>
    <!-- <small class="ladezeit">Ladezeit: {{ loadingSeconds }} s</small> -->
    <!-- <button class="btn btn-outline-primary">Laden abbrechen</button> -->
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data: () => ({
    loading: false,
    startTime: new Date(),
    // loadingSeconds: 0,
    displayText: ''
  }),
  methods: {
    start(text = '') {
      this.displayText = text;
      this.startTime = new Date();
      this.loading = true
      // this.secondsLoading();
    },
    finish() {
      this.loading = false
    },
    // secondsLoading() {
    //   let diff = moment().local('de').diff(this.startTime, 'seconds', false);
    //   this.loadingSeconds = diff;
    //   setTimeout(() => {
    //     if (!this.loading) return;
    //     return this.secondsLoading();
    //   }, 1000)
    // }
  },
  computed: {
  }
}
</script>
<style scoped>
.loading-page {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, 0.3);
}
.loading {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  border: 15px solid #fff;
  border-radius: 50%;
  border-top-color: #dfe300;
  animation: spin 1s ease-in-out infinite;
}
.ladezeit {
  font-size: 12pt;
  margin-top: 10px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
