<template>
<div class="mt-3">
  <h4>
    <button class="btn btn-sm btn-outline-dark btn mr-1" @click="load()"><span><ion-icon name="refresh-outline"></ion-icon></span></button>
    Kommentare
  </h4>
  <button class="btn btn-sm btn-outline-primary mb-3" @click="kommentieren = !kommentieren">Kommentieren</button>
  <div v-if="kommentieren">
    <kommentar-textfeld titel="true" @absenden="kommentarAbsenden($event)"></kommentar-textfeld>
  </div>
  <div class="row">
    <div class="col">
      <kommentare :kommentare="userKommentare" :id="id" :type="type" />
    </div>
    <div class="col">
      <kommentare :kommentare="portalKommentare" :id="id" :type="type" />
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: ['id', 'type'],
  data() {
    return {
      kommentare: [],
      kommentieren: false
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    kommentarAbsenden(content) {
      let payload = {
        id: 'neu',
        model_id: this.id,
        model_type: this.type,
        text: content.text,
        titel: content.titel,
        user_id: this.$auth.user.model_id
      };
      this.$axios.put('{kommentare}/v1/kommentare', payload).then(response => {
        this.kommentieren = false;
        this.kommentare.push(response.data);
      });
    },
    load() {
      this.$axios.get('{kommentare}/v1/kommentare/'+this.type+'/'+this.id).then(response => {
        this.kommentare = response.data;
      })
    }
  },
  computed: {
        portalKommentare: function() {
            return this.kommentare.filter(kommentar => {
                return (!kommentar.user_id);
            })
        },
        userKommentare: function() {
            return this.kommentare.filter(kommentar => {
                return (kommentar.user_id);
            })
        }
    },
}
</script>
