import storeHelper from '~/mixins/storeHelper';
import storeHelperOld from '~/mixins/storeHelperOld';
import {Helpers} from '~/plugins/helpers';

export const state = () => ({
  globals: Helpers.copy(storeHelper.empty)
})

const mutations = {
  setAll: storeHelperOld.setAll,
  // set: storeHelper.set,
  loading: storeHelper.loading,
  reset: storeHelper.reset,
}

const getters = {
  isLoading: storeHelper.isLoading,
  getAll: storeHelperOld.getAll,
  getStand: storeHelper.getStand,
  filled: storeHelper.filled
}

const actions = {
  async load({commit, state}) {
    let url = "{faq}/v1/faqtags";
    let notifyBetreff = "Tags abrufen";
    return storeHelperOld.load(commit, state, url, notifyBetreff);
  },
  async speichern({commit}, model) {
    let url = "{faq}/v1/faqtags/"+model.id;
    let notifyBetreff = "Tag aktualisieren";
    return storeHelperOld.speichern(commit, model, url, notifyBetreff);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
