<template>
  <b-tooltip :target="target" placement="bottom" triggers="click" variant="light" :show.sync="editSpalte.show" v-if="editSpalte" @show="show()" custom-class="kalender-tooltip">
      <div class="form-group">
        <input :ref="'suchfeld'" type="text" name="test" id="test" placeholder="Spalteninhalt ändern..." class="form-control" v-model="suchbegriff" @input="suchenAusfuehren()">
      </div>
      <template v-if="suchbegriff.length">
        <div class="list-group list-group-flush">
          <div v-for="(ma, index) in mitarbeiter.slice(0, 4)" :key="index+ma.id" class="list-group-item list-group-item-action  p-1 cursor-pointer" @click="selectMitarbeiter(ma)">
            <kontakt-anzeigen :filter="{model_type: 'Mitarbeiter', model_id: ma.id}"></kontakt-anzeigen>
          </div>
        </div>
        <hr>
        <template v-if="raeumeFiltered.length">
          <div class="list-group list-group-flush">
            <div v-for="(raum, index) in raeumeFiltered.slice(0, 4)" :key="index+raum.id" class="list-group-item list-group-item-action  p-1 cursor-pointer" @click="selectRaum(raum)">
              {{ raum.name }}
            </div>
          </div>
        </template>
        <template v-if="materialienFiltered.length">
          <div class="list-group list-group-flush">
            <div v-for="(material, index) in materialienFiltered.slice(0, 4)" :key="index+material.id" class="list-group-item list-group-item-action  p-1 cursor-pointer" @click="selectMaterial(material)">
              {{ material.name }}
            </div>
          </div>
        </template>
        <template v-if="fahrzeugeFiltered.length">
          <div class="list-group list-group-flush">
            <div v-for="(fahrzeug, index) in fahrzeugeFiltered.slice(0, 4)" :key="index+fahrzeug.id" class="list-group-item list-group-item-action  p-1 cursor-pointer" @click="selectFahrzeug(fahrzeug)">
              {{ fahrzeug.bezeichnung }}
            </div>
          </div>
        </template>
      </template>
      <div class="form-group">
        <div class="btn-group" role="group">
          <button class="btn btn-sm btn-outline-secondary" @click="editSpalte.datum = $moment(editSpalte.datum).subtract(7, 'days').format('YYYY-MM-DD'); renew()"><ion-icon :src="require('~/assets/img/double-chevron-back-outline.svg')"></ion-icon></button>
          <button class="btn btn-sm btn-outline-secondary" @click="editSpalte.datum = $moment(editSpalte.datum).subtract(1, 'days').format('YYYY-MM-DD'); renew()"><ion-icon name="chevron-back-outline"></ion-icon></button>
          <button class="btn btn-sm btn-outline-secondary" @click="editSpalte.datum = $moment().format('YYYY-MM-DD'); renew()">Heute</button>
          <button class="btn btn-sm btn-outline-secondary" @click="editSpalte.datum = $moment(editSpalte.datum).add(1, 'days').format('YYYY-MM-DD'); renew()"><ion-icon name="chevron-forward-outline"></ion-icon></button>
          <button class="btn btn-sm btn-outline-secondary" @click="editSpalte.datum = $moment(editSpalte.datum).add(7, 'days').format('YYYY-MM-DD'); renew()"><ion-icon :src="require('~/assets/img/double-chevron-forward-outline.svg')"></ion-icon></button>
        </div>
      </div>
      <div class="form-group">
        <input type="date" class="form-control" v-model="datum" @change="editSpalte.datum = datum; editSpalte.show = false; renew()">
      </div>
      <template v-if="editSpalte.object">
        <div>
          <button class="btn btn-sm btn-block btn-outline-primary" @click="duplizieren()">Duplizieren</button>
        </div>
        <div class="mt-2">
          <button class="btn btn-sm btn-block btn-outline-danger" @click="loeschen()">Löschen</button>
        </div>
      </template>
    </b-tooltip>
</template>
<script>
import mitarbeiter from '~/mixins/mitarbeiter';
import kalenderfunktionen from '~/mixins/kalenderfunktionen';
import ressourcen from '~/mixins/ressourcen';
import suche from '~/mixins/suche';
export default {
  mixins: [mitarbeiter, kalenderfunktionen, ressourcen, suche],
  props: ['target', 'spalte'],
  data() {
    return {
      suchbegriff: '',
      editSpalte: null,
      datum: null,
      raeumeFiltered: [],
      materialienFiltered: [],
      fahrzeugeFiltered: [],
    }
  },
  methods: {
    renew() {
      this.editSpalte.key++;
      this.datum = this.$moment(this.editSpalte.datum).format('YYYY-MM-DD');
      this.speichernSpalte(this.editSpalte);
    },
    suchenAusfuehren() {
      this.mitarbeiterAbrufen();
      this.raeumeFiltered = this.raeume.filter(raum => {
        return raum.name.toLowerCase().includes(this.suchbegriff.toLowerCase());
      })
      this.materialienFiltered = this.materialien.filter(material => {
        return material.name.toLowerCase().includes(this.suchbegriff.toLowerCase());
      })
      this.fahrzeugeFiltered = this.fahrzeuge.filter(fahrzeug => {
        return fahrzeug.bezeichnung.toLowerCase().includes(this.suchbegriff.toLowerCase());
      })
      // this.suchen funktioniert hier nicht, warum auch immer
      // if (this.raeume.length) this.raeumeFiltered = this.suchen(this.raeume, this.suchbegriff);
    },
    async selectMitarbeiter(ma) {
      await this.kontaktAbrufenFilter({model_type: 'Mitarbeiter', model_id: ma.id});
      this.editSpalte.object = ma;
      this.editSpalte.model_type = 'Mitarbeiter';
      this.editSpalte.model_id = ma.id;
      this.editSpalte.titel = this.kontakt.nachname+', '+this.kontakt.vorname;
      this.editSpalte.filter.mitarbeiter_id = [ma.id];
      this.editSpalte.filter.raum_id = [];
      this.editSpalte.filter.materialien = [];
      this.editSpalte.filter.fahrzeuge = [];
      this.renew();
    },
    selectRaum(raum) {
      this.editSpalte.object = raum;
      this.editSpalte.model_type = 'Raum';
      this.editSpalte.model_id = raum.id;
      this.editSpalte.titel = raum.name;
      this.editSpalte.filter.mitarbeiter_id = [];
      this.editSpalte.filter.raum_id = [raum.id];
      this.editSpalte.filter.materialien = [];
      this.editSpalte.filter.fahrzeuge = [];
      this.renew();
    },
    selectMaterial(model) {
      this.editSpalte.object = model;
      this.editSpalte.model_type = 'Material';
      this.editSpalte.model_id = model.id;
      this.editSpalte.titel = model.name;
      this.editSpalte.filter.mitarbeiter_id = [];
      this.editSpalte.filter.raum_id = [];
      this.editSpalte.filter.materialien = [model.id];
      this.editSpalte.filter.fahrzeuge = [];
      this.renew();
    },
    selectFahrzeug(model) {
      this.editSpalte.object = model;
      this.editSpalte.model_type = 'Fahrzeug';
      this.editSpalte.model_id = model.id;
      this.editSpalte.titel = model.bezeichnung;
      this.editSpalte.filter.mitarbeiter_id = [];
      this.editSpalte.filter.raum_id = [];
      this.editSpalte.filter.materialien = [];
      this.editSpalte.filter.fahrzeuge = [model.id];
      this.renew();
    },
    show() {
      this.standorteAbrufen();
      this.$nextTick(() => {
        this.$refs.suchfeld.focus({
          preventScroll: true
        });
      })
    },
    duplizieren() {
      let neu = this.$helpers.copy(this.editSpalte);
      neu.id = this.$uuid.v1();
      this.editSpalte.show = false;
      this.speichernSpalte(this.editSpalte, false);
      this.speichernSpalte(neu);
    },
    loeschen() {
      this.loeschenSpalte(this.editSpalte);
    }
  },
  mounted() {
    this.suchenAusfuehren();
    this.editSpalte = this.$helpers.copy(this.spalte);
    this.datum = this.$moment(this.editSpalte.datum).format('YYYY-MM-DD');
  }
}
</script>
