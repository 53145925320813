<template>
<!-- Jeder der Freitexte verwalten kann, darf alle verwalten. Sonst checken ob eine spezielle Permission hinterlegt ist und die abprüfen -->
<div class="mt-3" v-if="freitext && (freitext.text || darfBearbeiten)">
  <h4>
    <button class="btn btn-sm btn-outline-dark btn mr-1" @click="load()"><span><ion-icon name="refresh-outline"></ion-icon></span></button> {{ freitext.titel }}
    <button v-if="darfBearbeiten && !bearbeiten" class="btn btn-sm btn-outline-dark btn mr-1" @click="bearbeiten = true"><span><ion-icon name="pencil-outline"></ion-icon></span></button>
  </h4>
  <div v-if="darfBearbeiten && bearbeiten">
    <html-editor :autoFocus="false" @update="freitext.text = $event" v-model="freitext.text"></html-editor>

    <div class="row">
      <div class="col">
        <button class="btn btn-outline-primary btn-block mt-3" @click="speichern()">Speichern</button>
      </div>
      <div class="col">
        <button class="btn btn-outline-primary btn-block mt-3" @click="speichernSchliessen()">Speichern und Zurück</button>
      </div>
    </div>
  </div>
  <div v-else>
    <v-runtime-template :template="'<div>'+freitext.text+'</div>'" />
  </div>



</div>
</template>
<script>
import VRuntimeTemplate from "v-runtime-template";
export default {
  components: {VRuntimeTemplate},
  props: ['id', 'titel', 'permission'],
  data() {
    return {
      freitext: null,
      bearbeiten: false
    }
  },
  computed: {
    darfBearbeiten() {
      if (this.$can('Freitexte verwalten') || (this.freitext.permission && this.$can(this.freitext.permission))) return true;
      return false;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    speichern() {
      this.$axios.put('{kommentare}/v1/freitexte/'+this.id, this.freitext).then(response => {
        this.freitext = response.data;
        this.$notifySuccess('Erfolgreich aktualisiert.');
      });
    },
    speichernSchliessen() {
      this.speichern();
      this.bearbeiten = false;
    },
    load() {
      this.$axios.get('{kommentare}/v1/freitexte/'+this.id, {params: {titel: this.titel, permission: this.permission}}).then(response => {
        if (!response.data.text) this.bearbeiten = true;
        else this.bearbeiten = false;
        this.freitext = response.data;
      })
    }
  },
}
</script>
